import React, { Suspense } from 'react';
import useDevice from './hooks/useDevice';
import { Route, Switch } from 'react-router-dom';

// Queries
import webservice from './components/Queries/webservice';

//Components
import Loadingpage from './components/Loadingpage';
import Header from './components/Header';
import Home from './components/Home';
import HomeMX from './components/HomeMX';
import About from './components/About';
import Footer from './components/Footer';
import Catalog from './components/Catalog';
import Career from './components/Career';
import Videos from './components/Videos';
import Joinus from './components/Joinus';
import Products from './components/Products';
import Product from './components/Product';
import Responsibility from './components/Footer/Responsibility';
import Faq from './components/Footer/Faq';
import Contact from './components/Footer/Contact';
import RegistrationForm from './components/RegistrationForm';
import CreandoSonrisas from './components/CreandoSonrisas';
import LastFooter from './components/Footer/LastFooter';
import DigitalCatalog from './components/DigitalCatalog';
import Legal from './components/Legales';
import ImageZoom from './components/ImageZoom';
import IpaperOrder from './components/IpaperOrder';
import DigitalMagazine from './components/DigitalMagazine';

const location = process.env.REACT_APP_COUNTRY;

const Terramar = ({ history, userAgent }) => {
  const device = useDevice({ userAgent });

  return (
    <div className='App'>
      <Route
        path='/'
        render={(props) => {
          return <Header {...props} device={device} history={history} />;
        }}
      />
      <Switch>
        <Route
          path='/legal'
          render={(props) => {
            return <Legal {...props} device={device} history={history} />;
          }}
        />
        <Route
          path='/about'
          render={(props) => {
            return <About {...props} device={device} history={history} />;
          }}
        />
        <Route
          path='/responsibility'
          render={(props) => {
            return (
              <Responsibility
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/faq'
          render={(props) => {
            return <Faq {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/contact'
          render={(props) => {
            return <Contact {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/catalog'
          render={(props) => {
            return <Catalog {...props} device={device} history={history} />;
          }}
        />
        <Route
          path='/career'
          render={(props) => {
            return <Career {...props} device={device} history={history} />;
          }}
        />
        <Route
          path='/videos'
          render={(props) => {
            return <Videos {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/creandoSonrisas'
          render={(props) => {
            return (
              <CreandoSonrisas
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/joinus'
          render={(props) => {
            return <Joinus {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/products/product/:id'
          render={(props) => {
            return <Product {...props} device={device} history={history} webservice={webservice} />;
          }}
        />
        <Route
          path='/products/:catid?/:type?'
          render={(props) => {
            return (
              <Products {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
        <Route
          path='/registrationForm'
          render={(props) => {
            return (
              <RegistrationForm
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/digitalcatalog'
          render={(props) => {
            return (
              <DigitalCatalog
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/digitalmagazine'
          render={(props) => {
            return (
              <DigitalMagazine
                {...props}
                device={device}
                history={history}
                webservice={webservice}
              />
            );
          }}
        />
        <Route
          path='/imagezoom'
          render={(props) => {
            return (
              <ImageZoom {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
        <Route
          path={'/digitalorder/:orderId'}
          render={(props) => {
            return (
              <IpaperOrder {...props} history={history} webservice={webservice} device={device} />
            );
          }}
        />
        <Route
          path='/'
          render={(props) => {
            return (
              (location === 'MX' && (
                <HomeMX {...props} device={device} history={history} webservice={webservice} />
              )) || <Home {...props} device={device} history={history} webservice={webservice} />
            );
          }}
        />
      </Switch>
      <Footer device={device} history={history} />
      <LastFooter device={device} history={history} />
    </div>
  );
};

function App({ history, userAgent = null }) {
  return (
    <Suspense fallback={<Loadingpage />}>
      <Terramar history={history} userAgent={userAgent} />
    </Suspense>
  );
}

export default App;
