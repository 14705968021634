import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Components
import LanguageSelect from '../LanguageSelect';

// Styles
import styles from './index.module.sass';

//Objects
import taxonomy from './menuObject';

const country = process.env.REACT_APP_COUNTRY;

const Menu = (props) => {
  const { device, open, setOpen, history = {} } = props;
  const { location = {} } = history;
  const { hash = ' ', pathname = '' } = location;
  const [itemSelected, setItemSelected] = useState(0);
  const screenSize = device.screenSize;
  const { t } = useTranslation(['menu', 'home']);

  function menuFunction(key) {
    setItemSelected(key);
  }

  return (
    <>
      {open && screenSize === 'phone' && (
        <div className={styles.overlay} onClick={() => setOpen(false)} />
      )}
      <div id={styles.departaments} className={`${styles[screenSize]} ${styles[open]}`}>
        {screenSize === 'phone' && <div className={styles.space}></div>}
        {taxonomy.map((val, key) => {
          if (val.country && val.country !== country) return null;
          const { id = '' } = val;
          return (
            <div
              className={`${
                pathname.includes(id) || (pathname === '/' && id === 'home')
                  ? styles.departamentContainerActive
                  : styles.departamentContainer
              }`}
              key={key}
              onClick={() => {
                menuFunction(key);
              }}
              style={{
                order: val.index,
                width: `${(screenSize !== 'phone' && 100 / taxonomy.length) || 100}%`,
              }}>
              <Link
                to={`${val.url}${hash}`}
                className={`${styles.departament} ${styles[key === itemSelected]} ${
                  val.children.length > 0 && styles.haveChildren
                }`}
                onClick={(e) => {
                  if (val.children.length > 0) e.preventDefault();
                  else setOpen(false);
                }}>
                {t(id)}
              </Link>
              <div
                className={`${styles.families} ${
                  styles[itemSelected === key && val.children.length > 0]
                }`}>
                {taxonomy[key].children.map((subval, subkey) => {
                  if (subval.external) {
                    return (
                      <a
                        className={styles.familyTitle}
                        key={subkey}
                        href={`${subval.url}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => {
                          setItemSelected(null);
                          setOpen(false);
                        }}>
                        {t(subval.id)}
                      </a>
                    );
                  } else {
                    return (
                      <Link
                        className={styles.familyTitle}
                        key={subkey}
                        to={`${val.url}${subval.url}${hash}`}
                        onClick={() => {
                          setItemSelected(null);
                          setOpen(false);
                        }}>
                        {t(subval.id)}
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
        {screenSize === 'phone' && (
          <>
            <div
              className={styles.button}
              onClick={() => {
                if (country === 'MX') window.open('https://carrito.terramarbrands.com.mx', '_self');
                else window.open('https://cart.terramarbrandsusa.com', '_self');
              }}>
              {/* <img src={cart} alt='' /> */}
              {t('home:cartName')}
            </div>
            <div className={styles.language}>
              <LanguageSelect />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Menu;
