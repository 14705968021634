import React from 'react';
import styles from './index.module.sass';

const HeroBanner = (props) => {
  const { device, desktopImg, mobileImg } = props;
  const { screenSize = 'desktop' } = device;
  return (
    <div id={styles.HeroBanner}>
      <img src={(screenSize !== 'phone' && desktopImg) || mobileImg} alt='' />
    </div>
  );
};

export default HeroBanner;
