import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

// Commponents
import HeroBanner from '../HeroBanner';
import { Link } from 'react-router-dom';

const location = process.env.REACT_APP_COUNTRY;

const Catalog = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;

  const { t } = useTranslation('catalog');

  return (
    <div id={styles.Catalog} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <div className={styles.documentsContainer}>
        <div className={styles.document}>
          <label className={styles.label}>{t('documents.catalog.name')}</label>
          <img
            src={
              (location === 'MX' && t('documents.catalog.image')) || t('documents.catalog.imageUS')
            }
            alt={t('documents.catalog.name')}
          />
          <a
            href={
              (location === 'MX' && t('documents.catalog.pdfLink')) ||
              t('documents.catalog.pdfLinkUS')
            }
            target='_blank'
            rel='noopener noreferrer'>
            <div className={styles.button}>
              <i className={`material-icons`}>download</i>
              {t('button')}
            </div>
          </a>
        </div>

        <div className={styles.document}>
          <label className={styles.label}> {t('documents.booklet.name')}</label>
          <img
            src={
              (location === 'MX' && t('documents.booklet.image')) || t('documents.booklet.imageUS')
            }
            alt={t('documents.booklet.name')}
          />
          <Link className={styles.prefooterOption} to='/digitalcatalog'>
            <div className={styles.button}>{t('button2')}</div>
          </Link>
        </div>

        <div className={styles.document}>
          <label className={styles.label}>{t('documents.magazine.name')}</label>
          <img
            src={
              (location === 'MX' && t('documents.magazine.image')) ||
              t('documents.magazine.imageUS')
            }
            alt={t('documents.magazine.name')}
          />
          <Link className={styles.prefooterOption} to='/digitalmagazine'>
            <div className={styles.button}>{t('button2')}</div>
          </Link>
          {/* <a
            href={
              (location === 'MX' && t('documents.magazine.pdfLink')) ||
              t('documents.magazine.pdfLinkUS')
            }
            target='_blank'
            rel='noopener noreferrer'>
            <div className={styles.button}>
              <i className={`material-icons`}>download</i>
              {t('button')}
            </div>
          </a> */}
        </div>
        {location === 'MX' && (
          <>
            <div className={styles.document}>
              <label className={styles.label}> {t('documents.request.name')}</label>
              <img
                src={
                  (location === 'MX' && t('documents.request.image')) ||
                  t('documents.request.imageUS')
                }
                alt={t('documents.request.name')}
              />
              <a
                href={
                  (location === 'MX' && t('documents.request.pdfLink')) ||
                  t('documents.request.pdfLinkUS')
                }
                target='_blank'
                rel='noopener noreferrer'>
                <div className={styles.button}>
                  <i className={`material-icons`}>download</i>
                  {t('button')}
                </div>
              </a>
            </div>

            <div className={styles.document}>
              <label className={styles.label}> {t('documents.sgipping.name')}</label>
              <img
                src={
                  (location === 'MX' && t('documents.sgipping.image')) ||
                  t('documents.sgipping.imageUS')
                }
                alt={t('documents.sgipping.name')}
              />
              <a
                href={
                  (location === 'MX' && t('documents.sgipping.pdfLink')) ||
                  t('documents.sgipping.pdfLinkUS')
                }
                target='_blank'
                rel='noopener noreferrer'>
                <div className={styles.button}>
                  <i className={`material-icons`}>download</i>
                  {t('button')}
                </div>
              </a>
            </div>

            <div className={styles.document}>
              <label className={styles.label}>{t('documents.bonus.name')}</label>
              <img
                src={
                  (location === 'MX' && t('documents.bonus.image')) || t('documents.bonus.imageUS')
                }
                alt={t('documents.bonus.name')}
              />
              <a
                href={
                  (location === 'MX' && t('documents.bonus.pdfLink')) ||
                  t('documents.bonus.pdfLinkUS')
                }
                target='_blank'
                rel='noopener noreferrer'>
                <div className={styles.button}>
                  <i className={`material-icons`}>download</i>
                  {t('button')}
                </div>
              </a>
            </div>

            <div className={styles.document}>
              <label className={styles.label}> {t('documents.car.name')}</label>
              <img
                src={(location === 'MX' && t('documents.car.image')) || t('documents.car.imageUS')}
                alt={t('documents.car.name')}
              />
              <a
                href={
                  (location === 'MX' && t('documents.car.pdfLink')) || t('documents.car.pdfLinkUS')
                }
                target='_blank'
                rel='noopener noreferrer'>
                <div className={styles.button}>
                  <i className={`material-icons`}>download</i>
                  {t('button')}
                </div>
              </a>
            </div>

            <div className={styles.document}>
              <label className={styles.label}>{t('documents.howToPay.name')}</label>
              <img
                src={
                  (location === 'MX' && t('documents.howToPay.image')) ||
                  t('documents.howToPay.imageUS')
                }
                alt={t('documents.howToPay.name')}
              />
              <a
                href={
                  (location === 'MX' && t('documents.howToPay.pdfLink')) ||
                  t('documents.howToPay.pdfLinkUS')
                }
                target='_blank'
                rel='noopener noreferrer'>
                <div className={styles.button}>
                  <i className={`material-icons`}>download</i>
                  {t('button')}
                </div>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Catalog;
