import React from 'react';
import styles from '../../index.module.sass';

const AgreementESP = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Acuerdo del </div>
          <div className={styles.subtitle}>Consultor independiente</div>
        </div>
        <p className={styles.pharagraph}>
          Tengo la edad legal en el estado en el que firmo este Acuerdo. Entiendo que no soy un
          Consultor Independiente hasta que la Compañía acepte el Acuerdo original debidamente
          firmado.
        </p>
        <p className={styles.pharagraph}>
          No compraré productos con el único fin de calificar para comisiones o bonos. Revenderé o
          consumiré personalmente al menos 70% o más de todos los productos que he comprado de la
          Compañía antes de hacer un pedido posterior de productos. Los productos que personalmente
          consuma en mi hogar, en cantidades razonables, y que no hayan sido adquiridos con la
          finalidad de cumplir con los requisitos del plan de ventas, se considerarán ventas al por
          menor. Los productos considerados como vendidos bajo la regla del 70% no son válidos para
          la recompra.
        </p>
        <p className={styles.pharagraph}>
          Tengo el derecho de cancelar este Acuerdo en cualquier momento y por cualquier motivo
          mediante una notificación por escrito a la Compañía. Este modelo de negocio no prevé que
          los productos o ayudas de ventas entren en el inventario de un Consultor Independiente
          para su reventa. La Compañía es consciente que algunas leyes de recompra se extiende a las
          tarifas y otras consideraciones pagadas, y en la medida en que tales leyes apliquen, la
          Compañía respetará las leyes de recompra en Georgia, Idaho, Indiana, Luisiana, Maryland,
          Massachusetts, Mississippi, Montana, Nebraska, Nueva Jersey, Oklahoma, Puerto Rico,
          Carolina del Sur, Dakota del Sur, Tennessee, Texas, Washington y Wyoming. Además, los
          Consultores de Montana que se den de baja en un plazo de 15 días tendrán derecho a un
          reembolso del 100% de las consideraciones dadas para participar.
        </p>
        <p className={styles.pharagraph}>Como un Consultor Independiente yo me encargaré de:</p>
        <ul>
          <li className={styles.list}>
            <span className={styles.span}>
              Proporcionar entrenamiento y motivación a mis Consultores.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Estudiar la literatura del producto y promover las ventas al por menor.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Representar los productos de la Compañía de forma honesta.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Respetar las garantías que ofrece la Compañía con los clientes.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Ser profesional, cortés y considerado.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              No tergiversar el Plan de Compensación de la Compañía.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Familiarizarme y cumplir con las Políticas y Procedimientos de la Compañía y otros
              materiales establecidos por la Compañía.
            </span>
          </li>
        </ul>
        <p className={styles.pharagraph}>
          Entiendo que los Consultores Independientes no pueden, bajo ninguna circunstancia,
          incurrir en ninguna deuda, gasto u obligación en nombre de, o para, la Compañía.
        </p>
        <p className={styles.pharagraph}>
          Entiendo que, como Consultor Independiente, no actuaré, por ninguna razón o motivo, como
          un portavoz de la Compañía y sus productos, de ninguna manera, a cualquier medio o
          publicación, sin la previa autorización por escrito. No crearé, imprimiré, publicaré o
          distribuiré ninguna literatura o material que represente a la Compañía o a sus productos
          que no provenga de, o sea aprobado por escrito por la Compañía.
        </p>
        <p className={styles.pharagraph}>
          Entiendo que, como Consultor Independiente, tengo los siguientes derechos: (a) solicitar
          la venta de productos/servicios ofrecidos por la Compañía de acuerdo con el Plan de
          Compensación de la Compañía y las Políticas de Ventas, incluyendo, pero sin limitarse a
          las Políticas y Procedimientos de la Compañía y (b) patrocinar a otros Consultores
          Independientes de acuerdo con el Plan de Compensación de la Compañía y a las Políticas y
          Procedimientos de la Compañía.
        </p>
        <p className={styles.pharagraph}>
          Entiendo que no haré afirmaciones o garantías de ningún tipo, incluyendo, pero no limitado
          a, cualquier afirmación de ganancias o beneficios relacionado al Plan de Compensación de
          la Compañía o de sus productos, distintos a los incluidos en la literatura oficial de la
          Compañía. No haré afirmaciones de los productos que no estén indicadas en la literatura
          oficial de la Compañía, y no se me permite crear mi propia literatura, ayudas de ventas, o
          material de entrenamiento, sin el consentimiento por escrito de la Compañía.
        </p>
        <p className={styles.pharagraph}>
          Como un Consultor Independiente, entiendo que soy un contratista independiente y no un
          agente, empleado o franquiciado de la Compañía. Entiendo y acepto que no seré tratado como
          un empleado para fines fiscales federales o estatales, ni para fines de la Ley Federal de
          Impuestos sobre el Desempleo, La Ley Federal de Contribuciones al Seguro, la Ley del
          Seguro Social, las Leyes Estatales de Desempleo, las Leyes Estatales de Seguridad en el
          Empleo o las Leyes Estatales de Compensación a los Trabajadores. Comprendo y acepto pagar
          todos los impuestos federales y estatales de autoempleo, impuestos de las ventas,
          impuestos locales y/o tarifas de las licencias locales que puedan deberse como resultado
          de mis actividades en virtud de este Acuerdo.
        </p>
        <p className={styles.pharagraph}>
          Entiendo que mi aceptación de este Acuerdo no constituye la venta de una franquicia o un
          seguro/valor, no se puede conceder territorios exclusivos a nadie, y que no se ha pagado
          ninguna cuota de franquicia, ni puedo adquirir ningún interés en una garantía por la
          aceptación de este Acuerdo.
        </p>
        <p className={styles.pharagraph}>
          La Compañía puede, a su discreción, modificar el Plan de Compensación de la Compañía y las
          Políticas y Procedimientos de la Compañía y/o los términos del Acuerdo de Consultor
          Independiente. Los cambios se publicarán en boletines informativos, en el sitio web de la
          Compañía, se difundirán por correo electrónico, y se distribuirán o se podrán a
          disposición de todos los Consultores Independientes. Me comprometo a cumplir con todas las
          modificaciones. La continuación de mi Negocio, y/o la aceptación de productos, comisiones
          y cheques de bonificaciones, u otros pagos de la Compañía, constituye mi aceptación de
          todas y cada una de las modificaciones.
        </p>
        <p className={styles.pharagraph}>
          Mi Negocio no puede venderse, cederse o transferirse sin una aprobación por escrito de la
          Compañía.
        </p>
        <p className={styles.pharagraph}>
          He revisado cuidadosamente el Plan de Compensación de la Compañía y las Políticas y
          Procedimientos de la Compañía y reconozco que se incorporan como parte de este Acuerdo en
          su forma actual y según las modificaciones que de vez en cuando realice la Compañía. Mi
          violación a cualquiera de los términos de este Acuerdo o de las Políticas y Procedimientos
          de la Compañía pueden tener como resultado, a discreción de la Compañía, la pérdida de los
          cheques de comisiones y bonificaciones, u otros pagos de la Compañía; la pérdida total o
          parcial de mi organización de mercadeo; o la cancelación de este Acuerdo.
        </p>
        <p className={styles.pharagraph}>
          Este Acuerdo constituye el acuerdo completo entre las partes, y ninguna promesa,
          representación, garantía o acuerdo de cualquier tipo será válida a menos que se realice
          por escrito. Si alguna de las disposiciones del presente documento se considera inválida,
          todas las demás disposiciones seguirán siendo válidas y ejecutables.
        </p>
        <p className={styles.pharagraph}>
          La duración de este Acuerdo es de un año. Este Acuerdo se puede renovar anualmente en la
          fecha de aniversario de la aceptación de este Acuerdo, a menos que sea cancelado o
          extendido por la Compañía.
        </p>
        <p className={styles.pharagraph}>
          Este Acuerdo se regirá por las leyes del Estado de Florida, y cualquier reclamación o
          disputa entre las partes de este Acuerdo estará sujeta a un arbitraje vinculante bajo las
          Reglas Comerciales de la Asociación Americana de Arbitraje, con un arbitraje que se
          llevará a cabo en Miami, Florida. Los residentes de Luisiana pueden elegir la jurisdicción
          y el arbitraje en Nueva Orleans, Luisiana.
        </p>
      </div>
    </div>
  );
};

export default AgreementESP;
