import React from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './index.module.sass';

type Props = {
  node: Object,
  setCatselected: any,
  catSelected: String,
  itemId: Number,
  open: String,
  setOpen: Function,
  history: Object,
  match: Object,
  getArrayProducts: any,
};

export default function MenuItem({
  node,
  setCatselected,
  itemId,
  open,
  setOpen,
  catSelected,
  history,
  match,
  screenSize,
  getArrayProducts,
}: Props): React$Node {
  const { i18n = {} } = useTranslation();
  const { language = '' } = i18n;

  const { Descripcion1, Descripcion2, subCategorias = [] } = node;

  function handleClick() {
    const thisOpen = open === itemId ? '' : itemId;
    setOpen(thisOpen);
  }

  function showProduct(subdepto) {
    history.push('/products');
    getArrayProducts(itemId, subdepto);
    setCatselected([itemId, subdepto]);
  }

  return (
    <div className={`${styles.menuItem} ${styles[screenSize]}`}>
      <div className={`${styles.item} ${styles[open === itemId]}`} onClick={handleClick}>
        <span>{(language.includes('es') && Descripcion1) || Descripcion2}</span>
        <i className={`material-icons `}>navigate_next</i>
      </div>
      <div className={`${styles.subitems} ${styles[open === itemId]}`}>
        {subCategorias.map((val, key) => {
          const { imagen, value = '1' } = val;
          return (
            <div
              className={`${styles[catSelected === value]} ${styles.subitem}`}
              key={key}
              onClick={() => {
                showProduct(key);
              }}>
              <div
                className={styles.sectionImage}
                style={{
                  backgroundImage: `url(${imagen})`,
                }}></div>
              <span className={styles.sectionName}>
                {(language.includes('es') && val?.Descripcion1) || val?.Descripcion2}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
