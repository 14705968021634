import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

// Styles
import styles from './index.module.sass';

export default function CategoriesCarousel({ screenSize = 'desktop', history }) {
  const { t } = useTranslation('home');
  const sliderRef = useRef();
  const [dragging, setDraggin] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: (screenSize === 'phone' && 1) || (screenSize === 'tablet' && 3) || 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: () => {
      setDraggin(true);
    },
    afterChange: () => {
      setDraggin(false);
    },
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  function onClick(route) {
    if (dragging) return;
    if (history) {
      window.scrollTo(0, 300);
      history.push(route);
    }
  }

  return (
    <div id={styles.CategoriesCarousel} className={styles[screenSize]}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t('carouselTitle')}</div>
        <div className={styles.subtitle}>{t('carouselSubtitle')}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.sliderContainer}>
          <Slider {...settings} className={styles.slider} ref={sliderRef}>
            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/1/all')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-01.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>{t('cat1')}</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/2/all')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-02.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>{t('cat2')}</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/3/all')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-03.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>{t('cat3')}</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/4/all')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-05.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>{t('cat4')}</div>
                </div>
              </div>
            </div>

            <div className={styles.category}>
              <div className={styles.slideContainer} onClick={() => onClick('/products/5/all')}>
                <div
                  className={styles.bgImage}
                  style={{
                    backgroundImage: `url(https://webimages.terramarbrands.com.mx/usawebpage/home/cat-06.jpg)`,
                  }}
                />
                <div className={styles.slideContent}>
                  <div className={styles.categoryName}>{t('cat5')}</div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        {screenSize !== 'phone' && (
          <>
            <div className={`${styles.arrow} ${styles.leftArrow}`} onClick={() => previous()}>
              <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_before</i>
            </div>
            <div className={`${styles.arrow} ${styles.rightArrow}`} onClick={() => next()}>
              <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_next</i>
            </div>
          </>
        )}
      </div>

      {screenSize === 'phone' && (
        <div className={styles.mobileArrows}>
          <div className={`${styles.arrow} ${styles.leftArrow}`} onClick={() => previous()}>
            <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_before</i>
          </div>
          <div className={`${styles.arrow} ${styles.rightArrow}`} onClick={() => next()}>
            <i className={`material-icons-outlined ${styles.arrowIcon}`}>navigate_next</i>
          </div>
        </div>
      )}
    </div>
  );
}
