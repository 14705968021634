import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

const Contact = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('contact');
  const ubicaciones = t('ubicaciones', { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      <div className={styles.container}>
        {ubicaciones.map((val, key) => {
          return (
            <div className={styles.boxCity} key={key}>
              <div className={styles.headerBox}>
                <div className={styles.bloqueCity}>
                  <div className={styles.cityName}>&nbsp;{val.ciudad}</div>
                  <img className={styles.city} src={val.img} alt='' />
                </div>
                <div className={styles.bodyBox}>
                  <div className={styles.button}>
                    <i className={`material-icons-outlined ${styles.icon}`}>location_on</i>
                    <label>&nbsp;{t('address')}</label>
                  </div>
                  <div className={styles.info}>{val.direccion}</div>

                  <div className={styles.button}>
                    <i className={`material-icons-outlined ${styles.icon}`}>phone</i>
                    <label>&nbsp;{t('tel')}</label>
                  </div>
                  <div className={styles.info}>{val.telefonos}</div>
                </div>
              </div>
              <br />
              <div>
                <iframe
                  src={val.mapa}
                  title='mapa'
                  frameBorder='0'
                  allowFullScreen
                  className={styles.iframe}></iframe>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Contact;
