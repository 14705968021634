import React, { useEffect } from 'react';
import styles from './index.module.sass';
import Reconstruye from './Reconstruye';
import Schools from './Schools';
import { useTranslation } from 'react-i18next';

const CreandoSonrisas = (props) => {
  const { device, webservice } = props;
  const { screenSize } = device;
  const { t } = useTranslation('creatingSmiles');
  const infoBloque = t('infoBloque', { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles[screenSize]} id={styles.CreandoSonrisas}>
      {screenSize === 'phone' ? (
        <>
          <div className={styles.imageBanner}>
            <img
              src='https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/portada-creando-sonrisas.jpg'
              alt=''
              className={styles.image}
            />
            <div className={styles.colorDiv}>
              <div className={styles.titleMobile}>{t('title')}</div>
              <div className={styles.subTitleGoldMobile}>{t('subTitle')}</div>
              <div className={styles.textMobile}>{t('textBanner')}</div>
            </div>
          </div>

          <div className={styles.secondBloqueMobile}>
            <div className={styles.subBloqueMobile}>
              <div className={styles.titleMobileSecondBloque}>{t('titleBloque')}</div>
              <div className={styles.subTitleMobile}>{t('textBloque')}</div>

              <div className={styles.imageMobileDiv}>
                <img
                  src='https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/fotos-creando.jpg'
                  alt=''
                  className={styles.imgMobile}
                />
              </div>

              {infoBloque.map((val, key) => {
                return (
                  <div className={styles.optionsMobile} key={key}>
                    <div>
                      <img src={val.icon} alt='' />
                    </div>
                    <div>
                      <label className={styles.infoOption}>{val.title}</label>
                      <p className={styles.textInfoOptionMobile}>{val.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.banner}>
            <div className={styles.bannerText}>
              <label className={styles.titleSmiles}>
                <label className={styles.titleBlue}>{t('title')}</label>
                <label className={styles.titleGold}>&nbsp;{t('subTitle')}</label>
              </label>
              <p className={styles.text}>{t('textBanner')}</p>
            </div>
          </div>
        </div>
      )}

      {screenSize !== 'phone' && (
        <div className={styles.secondBloque}>
          <div>
            <img
              src='https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/fotos-creando.jpg'
              alt=''
              className={styles.img}
            />
          </div>
          <div className={styles.subBloque}>
            <div className={styles.title}>
              <label className={styles.SubtitleBlue}>{t('titleBloqueDsk')}</label>
              <label className={styles.SubtitleGold}>&nbsp;{t('subTitleBloqueDsk')}</label>
            </div>
            <div className={styles.subTitle}>{t('textBloque')}</div>

            {infoBloque.map((val, key) => {
              return (
                <div className={styles.options} key={key}>
                  <div>
                    <img src={val.icon} alt='' />
                  </div>
                  <div>
                    <label className={styles.infoOption}>{val.title}</label>
                    <p className={styles.textInfoOption}>{val.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* {screenSize === 'phone' ? (
        <div className={styles.bannerFondoMobile}>
          <div className={styles.imgRecaudado}>
            <img
              src='https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/recaudado.svg'
              alt=''
              className={styles.imageRecaudadoMobile}
            />
          </div>
          <div className={styles.metaMobile}>{t('titleMeta')}</div>
          <div className={styles.metaNumber}>{t('meta')}</div>
          <div className={styles.recaudado}>{t('titleRecaudado')}</div>
          <div className={styles.recaudadoNumber}>{t('recaudado')}</div>
        </div>
      ) : (
        <div className={styles.bannerFondo}>
          <div className={styles.imgRecaudadoMobile}>
            <img
              src='https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/recaudado.svg'
              alt=''
              className={styles.imageRecaudado}
            />
          </div>
          <div>
            <div className={styles.meta}>{t('titleMeta')}</div>
            <div className={styles.metaNumber}>{t('meta')}</div>
            <div className={styles.recaudado}>{t('titleRecaudado')}</div>
            <div className={styles.recaudadoNumber}>{t('recaudado')}</div>
          </div>
        </div>
      )} */}

      <Reconstruye screenSize={screenSize} webservice={webservice} />
      <Schools screenSize={screenSize} webservice={webservice} />
    </div>
  );
};

export default CreandoSonrisas;
