import React, { useState } from 'react';

import styles from './index.module.sass';

import PRELOAD from '../../images/preload.jpg';

export default function ImageZoomTwo({
  imgsrc = PRELOAD,
  device = {},
  idComplement = '',
  imageSize = 200,
}) {
  const deviceType = device?.device || 'desktop';

  const [showZoom, setShowZoom] = useState(false);

  function zoom(e) {
    let zoomer = e.currentTarget;

    let offsetX = e?.nativeEvent?.offsetX || 0;

    let offsetY = e?.nativeEvent?.offsetY || 0;

    const x = (offsetX / zoomer.offsetWidth) * 100;
    const y = (offsetY / zoomer.offsetHeight) * 100;
    zoomer.style.backgroundSize = `${imageSize * 2}px ${imageSize * 2}px`;
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  }

  function zoomMobile(e) {
    document.body.style.overflow = 'hidden';
    const rect = e.target.getBoundingClientRect();

    let zoomer = e.currentTarget;

    let offsetX = e?.touches[0]?.pageX - rect.left || 0;

    let offsetY = e?.touches[0]?.pageY - rect.top || 0;

    const x = (offsetX / zoomer.offsetWidth) * 100;
    const y = (offsetY / zoomer.offsetHeight) * 100;
    zoomer.style.backgroundSize = `${imageSize * 2}px ${imageSize * 2}px`;
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  }

  function enableZoom() {
    let zoomer = document.getElementById(`Zoom${idComplement}`);
    let product = document.getElementById(`ZoomProduct${idComplement}`);
    product.style.opacity = 0;
    zoomer.style.backgroundSize = `${imageSize * 2}px ${imageSize * 2}px`;
    zoomer.style.backgroundPosition = '50% 50%';
  }

  function disableZoom() {
    let product = document.getElementById(`ZoomProduct${idComplement}`);
    product.style.opacity = 1;
  }

  return (
    <div
      className={styles.container}
      style={{
        width: `${imageSize}px`,
        height: `${imageSize}px`,
      }}>
      <figure
        id={`Zoom${idComplement}`}
        className={styles.zoom}
        onMouseMove={(event) => {
          if (deviceType === 'desktop') zoom(event);
        }}
        onTouchMove={(event) => {
          if ((deviceType === 'phone' || deviceType === 'tablet') && showZoom) zoomMobile(event);
        }}
        onTouchEnd={(event) => {
          if ((deviceType === 'phone' || deviceType === 'tablet') && showZoom)
            document.body.style.overflow = 'auto';
        }}
        style={{
          backgroundImage: `url(${imgsrc})`,
          width: `${imageSize}px`,
          height: `${imageSize}px`,
        }}>
        <img src={imgsrc} alt='' id={`ZoomProduct${idComplement}`} />
      </figure>
      {(deviceType === 'phone' || deviceType === 'tablet') && (
        <>
          {showZoom && (
            <div
              className={styles.closeIcon}
              onClick={(event) => {
                if (deviceType === 'phone' || deviceType === 'tablet') {
                  disableZoom();
                  setShowZoom(false);
                }
              }}>
              <i className='material-icons-outlined'>close</i>
            </div>
          )}
          {!showZoom && (
            <div
              className={styles.zoomIcon}
              onClick={(event) => {
                if (deviceType === 'phone' || deviceType === 'tablet') {
                  setShowZoom(true);
                  enableZoom();
                }
              }}>
              <i className='material-icons-outlined'>zoom_in</i>
            </div>
          )}
        </>
      )}
    </div>
  );
}
