import React from 'react';
import styles from '../../index.module.sass';

const PrivacyUSAEsp = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Política de</div>
          <div className={styles.subtitle}>Privacidad</div>
        </div>
        <div className={styles.paragraph}>
          <ol>
            <li className={styles.list}>Introducción.</li>
            <ol>
              <li className={styles.list}>
                <span> Fecha de Entrada en Vigor.</span>
              </li>
              <p className={styles.paragraph}>
                La fecha efectiva cuando esta Política de Privacidad entra en vigor es el 9 de
                diciembre de 2016.
              </p>

              <li className={styles.list}>
                <span>Objetivo.</span>
              </li>
              <p className={styles.paragraph}>
                TERRAMAR (TBL o la Empresa) se compromete a respetar los derechos de privacidad de
                los usuarios del Sitio Web de TBL y de cualquier software, plugin o aplicaciones
                móviles disponibles por TBL (en conjunto, el “Sitio Web”). TBL ha publicado esta
                “Política de Privacidad” para mostrar su compromiso con las prácticas de información
                justas y con la protección de la privacidad.
              </p>

              <li className={styles.list}>
                <span> Alcance.</span>
              </li>
              <p className={styles.paragraph}>
                La presente Política de Privacidad sólo aplica al Sitio Web, y no a los sitios web o
                servicios de terceros (“Sitios Web de Terceros”) que pueden tener prácticas y
                políticas de recopilación, almacenamiento y uso de datos que difieren
                sustancialmente de esta Política de Privacidad. Para información adicional, consulte
                la sección 12 (Sitios Web de Terceros) y la sección 4.5 (Publicidad y Cookies de
                Terceros)
              </p>

              <li className={styles.list}>
                <span> No hay autorización sin la aceptación de dicho Acuerdo.</span>
              </li>
              <p className={styles.paragraph}>
                Al utilizar el Sitio Web, usted declara y confirma que ha leído y comprendido los
                términos de esta Política de Privacidad, y que acepta dichos términos. Si no la
                entiende o no está de acuerdo con esta Política de Privacidad, debe dejar de usar o
                acceder inmediatamente el Sito Web.
              </p>
            </ol>

            <li className={styles.list}>Actualizaciones y Cambios a la Política de Privacidad.</li>
            <ol>
              <li className={styles.list}>
                <span className={styles.span}>
                  Cada vez que acceda al Sitio Web, se aplicará la versión vigente de esta Política
                  de Privacidad. Cada vez que utilice el Sitio Web, le recomendamos revisar la fecha
                  de esta Política de Privacidad en las Sección 1.1 (Fecha de Implementación) y
                  revisar cualquier modificación que se haya realizado al documento desde la última
                  ocasión que utilizó el Sito Web
                </span>
              </li>

              <li className={styles.list}>
                <span className={styles.span}>
                  Aunque probablemente la mayoría de los cambios sean menores, TBL se reserva el
                  derecho, en cualquier momento y sin previo aviso, de añadir, actualizar, cambiar o
                  modificar su Política de Privacidad, simplemente al publicar una nueva versión en
                  esta página. Cualquier adición, actualización o cambio, entrará en vigor
                  inmediatamente después de ser publicado. Salvo que TBL tenga su expreso
                  consentimiento, cualquier Política de Privacidad actualizada será aplicada
                  únicamente a la información recopilada después de la fecha en que entre en vigor
                  la Política de Privacidad actualizada, y no a la información recopilada bajo
                  cualquier Política de Privacidad anterior.
                </span>
              </li>
            </ol>

            <li className={styles.list}>Privacidad Infantil.</li>

            <p className={styles.paragraph}>
              TBL no solicita ni recopila deliberadamente Información Personal de niños menores de
              13 años. Si TBL tiene conocimiento de que ha recopilado Información Personal de un
              niño menor a 13 años, TBL eliminará inmediatamente dicha información de su base de
              datos. Dado que TBL no recopila Información Personal de niños menores de 13 años, TBL
              no tiene dicha información para utilizar o revelar a terceros. TBL ha diseñado esta
              Política de Privacidad para cumplir con la Ley de Protección de la Privacidad Infantil
              en Línea (“COPPA”).
            </p>

            <li className={styles.list}>Práctica de Recopilación de Información.</li>

            <ol>
              <li className={styles.list}>Tráfico de Red.</li>
              <p className={styles.paragraph}>
                Al igual que la mayoría de los Sitos Web y aplicaciones móviles, TBL recopila
                automáticamente información que los navegadores web ponen a disposición
                automáticamente, incluyendo: (i) direcciones IP, (ii) nombre de domino de Internet,
                (iii) tipos de dispositivos que acceden el Sito Web; y (iv) tipos de navegadores web
                que acceden al Sitio Web (colectivamente “Tráfico de Red” o “Tráfico de datos). Los
                tráficos de red son información anónima que no tiene como objetivo identificarle
                personalmente.
              </p>

              <li className={styles.list}>Política de Cookies.</li>
              <p className={styles.paragraph}>
                Las “Cookies” son archivos de texto que son colocados en su computadora por los
                sitios web que visita el usuario o por ciertos correos electrónicos que abre. Las
                “Cookies” almacenan una cadena de información en la computadora del usuario o en un
                dispositivo móvil, que el navegador web del usuario proporciona al Sitio Web
                automáticamente cada vez que interactúa con el Sitio Web. La finalidad de las
                Cookies es identificar al usuario como un usuario único del Sito Web. TBL utiliza
                dos tipos de Cookies: “Cookies propias” y “Cookies de terceros”.
              </p>

              <li className={styles.list}>Cookies Propias o de Origen.</li>
              <p className={styles.paragraph}>
                TBL utiliza Cookies Propias en el Sitio Web, por ejemplo, el Cookie de Google
                Analytics, con los siguientes fines: (1) recopilar información relevante de
                visitantes y de acceso para personalizar el Sitio Web según sus intereses; (2) Para
                almacenar un token de sesión, el cual está encriptado y es utilizado para mantener
                tu sesión activa determinado tiempo y no tengas que ingresar tu usuario y contraseña
                cada vez que entres al Sito Web.
              </p>

              <li className={styles.list}>
                <span className={styles.span}>
                  Si no desea que se coloquen Cookies Propias en su computadora o dispositivo móvil,
                  debe configurar su navegador para que rechace las Cookies antes de acceder al
                  Sitio Web. Sin embargo, ciertas características del Sitio Web no funcionarán
                  correctamente sin la ayuda de los Cookies. Si usted rechaza las Cookies, asume
                  toda la responsabilidad por la pérdida de cualquier funcionalidad resultante.
                </span>
              </li>

              <li className={styles.list}>Cookies de Terceros y Publicidad</li>
              <ol>
                <li className={styles.list}>Publicidad en Sitios Web de Terceros.</li>
                <p className={styles.paragraph}>
                  TBL puede hacer publicidad en otros Sitos Web de Terceros después de que usted
                  acceda al Sito Web. Nosotros y los socios de publicidad de terceros, incluyendo
                  sin limitación Google, Inc. (“Google”), podemos utilizar Cookies Propias (como las
                  cookies de Google Analytics), Cookies de Terceros (como el Cookie de DoubleClick)
                  y balizas de web (como el pixel de seguimiento de conversiones de Facebook, véase
                  <span>
                    <a
                      href='https://www.facebook.com/business/a/online-sales/conversion-tracking'
                      className={styles.link}
                      rel='noopener noreferrer'
                      target='_blank'>
                      &nbsp;
                      https://www.facebook.com/business/a/online-sales/conversion-tracking&nbsp;
                    </a>
                  </span>
                  ), incluso a través de múltiples dispositivos, para informar, optimizar, y ofrecer
                  anuncios basados en su uso del Sitio Web. Esta práctica es comúnmente conocida
                  como retargeting o remarketing (veáse
                  <span>
                    <a
                      href='https://en.wikipedia.org/wiki/Behavioral_retargeting'
                      className={styles.link}
                      rel='noopener noreferrer'
                      target='_blank'>
                      &nbsp; https://en.wikipedia.org/wiki/Behavioral_retargeting&nbsp;
                    </a>
                  </span>
                  ).
                </p>

                <li className={styles.list}>Google Analytics.</li>
                <ol>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Google Analytics es una herramienta que ayuda a los propietarios de Sitios Web
                      y aplicaciones a entender como el visitante interactúa con sus propiedades.
                      Podría utilizar un conjunto de cookies para recopilar información y reportar
                      estadísticas de uso del Sitio Web sin identificar personalmente a los
                      visitantes individualmente de Google. El principal Cookie utilizado por Google
                      Analytics es la cooke “_ga”.
                    </span>
                  </li>
                </ol>

                <p className={styles.paragraphTitle}>Firebase</p>
                <p className={styles.paragraph}>
                  Firebase es una plataforma diseñada y creada por Google.
                </p>
                <p className={styles.paragraph}>
                  En nuestra aplicación móvil se utilizan librerías de Firebase, las cuales se usan
                  principalmente para el monitoreo del rendimiento de la misma y algunas
                  funcionalidades en tiempo real. Las librerías utilizadas se enlistan a
                  continuación:
                </p>

                <ul>
                  <li className={styles.list}>Firebase Messaging</li>
                  <p className={styles.paragraph}>
                    Permite recibir notificaciones en la aplicación y para segmentar a los usuarios
                    por temas, por ejemplo, para agruparlos por versiones de aplicación.
                  </p>

                  <li className={styles.list}>Firebase Crashlytics</li>
                  <p className={styles.paragraph}>
                    Permite monitorear los errores que pueden existir en la aplicación y nos da un
                    reporte de los eventos que desencadenaron la falla. Con esto podemos dar
                    mantenimiento a la aplicación y mejorar la estabilidad.
                  </p>

                  <li className={styles.list}>Firebase Core</li>
                  <p className={styles.paragraph}>
                    Es la librería base para conectarse a la plataforma (Firebase) y tener los
                    analytics predeterminados, como tipo de dispositivo, sesiones, ubicaciones, etc.
                    Esto para tener un panorama general del uso de la aplicación.
                  </p>
                </ul>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Recopilación de Datos por Terceros. Cualquiera de estos terceros puede colocar
                    Cookies en su computadora o dispositivo móvil, utilizar balizas web (véase
                    https://en.wikipedia.org/wiki/Web_beacon) y recopilar direcciones IP y datos de
                    acceso para recopilar información de tráfico de red y actividades para
                    proporcionar métricas y contenidos relevantes.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>Exclusión de las Cookies de Terceros.</span>
                  <ol>
                    <li className={styles.list}>
                      <span className={styles.span}>
                        Pueden personalizar sus preferencias de Google Cookies o inhabilitar el uso
                        de Google Cookies en la página de configuración de los anuncios de Google en
                        <span>
                          <a
                            href='http://www.google.com/settings/ads'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;http://www.google.com/settings/ads.&nbsp;
                          </a>
                        </span>
                      </span>
                    </li>
                    <li className={styles.list}>
                      <span className={styles.span}>
                        Pueden conocer más de los Cookies de Facebook y como personalizar sus
                        preferencias en
                        <span>
                          <a
                            href='https://www.facebook.com/about/ads'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;https://www.facebook.com/about/ads.&nbsp;
                          </a>
                        </span>
                      </span>
                    </li>
                    <li className={styles.list}>
                      <span className={styles.span}>
                        Puede optar no utilizar los Cookies de terceros visitando la página de
                        inhabilitación de Network Advertising Initiative en
                        <span>
                          <a
                            href='http://www.networkadvertising.org/managing/opt_out.asp'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;http://www.networkadvertising.org/managing/opt_out.asp&nbsp;
                          </a>
                        </span>
                        También pude obtener más información acerca de cómo eliminar o regular los
                        Cookies en
                        <span>
                          <a
                            href='http://www.aboutcookies.org/'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;http://www.aboutcookies.org/ .&nbsp;
                          </a>
                        </span>
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>

              <li className={styles.list}>Información Identificable de un Individuo.</li>
              <p className={styles.paragraph}>
                Para utilizar ciertas funciones del Sito Web, es posible que TBL le pida que
                proporcione cierta Información Identificable Personal (“Información Personal”. La
                información personal incluye las siguientes categorías, sin limitación: (1) “Datos
                de Contacto” (como su nombre, número de teléfono, número de fax, dirección postal y
                dirección de correo electrónico); (2) “Datos Financieros” (como su número de tarjeta
                de crédito/débito y fecha de vencimiento); (3) “Datos Demográficos” (como su sexo y
                código postal); y (4) “Datos de Cuentas de Redes Sociales de Terceros” (como la
                información de su cuenta en redes sociales de terceros cuando accede al Sitio Web en
                relación con esas cuentas). En cada caso, se le pedirá que proporcione Información
                Personal; el Sitio Web no lo recopilará en secreto. TBL podrá complementar la
                Información Personal que usted proporcione con Información Personal adicional
                obtenida de fuentes públicas o de terceros (por ejemplo, agencias de información del
                consumidor) que pueden proporcionar legalmente dicha información a TBL. No tiene
                ninguna obligación de proporcionar Información Personal, con la advertencia de que
                su rechazo a hacerlo puede impedirle de utilizar ciertas funciones del Sitio Web.
              </p>

              <li className={styles.list}>Información Específica Recopilada.</li>
              <p>TBL recopila los siguientes elementos específicos de Información Personal:</p>
              <ol>
                <li className={styles.list}>
                  <span className={styles.span}>Su nombre y apellido.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Su dirección.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Su dirección de correo electrónico.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Su número telefónico.</span>
                </li>
              </ol>

              <li className={styles.list}>Información que Usted hace Pública.</li>

              <p className={styles.paragraph}>
                Este Sito Web puede contener funciones (e.g, mensajes de texto, mensajes
                electrónicos, foros, etc.) que le permiten cargar, publicar, transmitir, mostrar,
                realizar o distribuir contenido, u otra información, incluyendo su Información
                Personal y/o información de salud. Cualquier información que usted decida revelar a
                través de estas funciones se convierte en información pública sobre la que TBL es
                incapaz de ejercer control. Debe de tener precaución cuando decida revelar su
                Información Personal a través de dichas funciones y acepte asumir responsabilidad de
                hacerlo.
              </p>
            </ol>

            <li className={styles.list}>Uso de la Información.</li>
            <ol>
              <li className={styles.list}> Datos de Tráfico de Red recopilada mediante Cookies.</li>
              <p className={styles.paragraph}>
                TBL analiza los Datos de Tráfico de Red y la información recopilada mediante las
                Cookies para ayudar a TBL a entender mejor quien esta utilizando el Sitio Web y cómo
                lo utiliza. Al identificar patrones y tendencias de uso, TBL es capaz de diseñar un
                mejor Sitio Web para mejorar su experiencia, y ofrecerle un contenido más relevante
                e interesante. De vez en cuando, TBL puede divulgar los datos de Tráfico de Red y la
                información recopilada utilizando las cookies en conjunto, por ejemplo. Publicando
                un reporte sobre las tendencias en el uso del Sitio Web. TBL no intenta vincular la
                información recopilada mediante las cookies de Información Personal.
              </p>

              <li className={styles.list}>Información Personal.</li>
              <p className={styles.paragraph}>
                TBL utiliza sus Datos de Contacto para enviarle información acerca de TBL y de los
                productos y servicios de TBL, y para ponerse en contacto con usted cuando sea
                necesario en temas relacionados con el Sitio Web. TBL utiliza sus datos financieros
                para verificar sus calificaciones para ciertas funciones del Sitio Web y, de ser
                necesario, facturarle. TBL utiliza sus datos Demográficos para personalizar y
                adaptar su experiencia del Sitio Web. TBL utiliza su información de sus cuentas de
                Redes Sociales de Tercero de acuerdo con esta Política de Privacidad y las políticas
                de Privacidad de las empresas de redes sociales de terceros, como Facebook y
                Twitter. Como los Datos de Tráfico de Red y la información recopilada utilizando las
                Cookies, de vez en cuando TBL podría publicar los Datos Demográficos en conjunto,
                por ejemplo, publicando un reporte sobre las tendencias en el uso del del Sitio Web.
              </p>
            </ol>

            <li className={styles.list}>Prácticas de divulgación.</li>
            <p className={styles.paragraph}>
              Excepto de las siguientes circunstancias, TBL mantendrá sus Información Personal
              privada y no la compartirá con terceros.
            </p>

            <ol>
              <li className={styles.list}>Intercambio de información no identificada.</li>
              <p className={styles.paragraph}>
                TBL puede compartir o vender Información Personal que ha sido desidentificada de
                manera que no pueda ser relacionada con ella. TBL espera que este tipo de datos
                agregados sean de utilidad para la investigación. TBL tomará las medidas razonables
                para asegurar que el proceso de desidentificación sea eficaz, pero no garantiza que
                nunca se le identifique personalmente con sus datos.
              </p>

              <li className={styles.list}>Divulgación a Socios de Marketing.</li>
              <p className={styles.paragraph}>
                TBL puede utilizar su Información Personal para ayudar a nuestros clientes a
                entender mejor la demografía de nuestro público objetivo, como también apoyar
                nuestros productos y servicios de datos de marketing. Nosotros también podremos
                proporcionar datos de identificación personal a empresas asociadas que pueden
                incluir empresas de marketing de terceros, afiliados, agencias de publicidad y
                empresas de agregación de datos. Su información Personal puede ser utiliza por estas
                empresas asociadas para proporcionar a los consumidores información acerca de sus
                productos y servicios que puedan ser de interés de acuerdo con esta Política de
                Privacidad.
              </p>

              <li className={styles.list}> Divulgación en Relación con el Servicio.</li>
              <p className={styles.paragraph}>
                TBL divulgara Información Personal con aquellos que le ayuden a proporcionar
                servicios, incluyendo, sin limitación, a quienes realizan tareas técnicas,
                administrativas y de procesamiento de información, como el hosting, la facturación,
                el cumplimiento, el almacenamiento de datos y la seguridad.
              </p>

              <li className={styles.list}>Redes Sociales.</li>
              <p className={styles.paragraph}>
                Bajo su dirección, TBL divulgará su información y su uso del Sito Web en las
                plataformas de redes sociales. TBL envía su información a través de los API de las
                plataformas de las redes sociales que usted autoriza, en cumplimiento con las leyes
                aplicables y las políticas de privacidad de las plataformas que aplican cuando se
                envía su información.
              </p>

              <li className={styles.list}>Contenido del Usuario.</li>

              <ol>
                <li className={styles.list}>Definición de Contenido del Usuario.</li>
                <p>
                  El “Contenido del Usuario” es cualquier contenido, material o información, sin
                  incluir la Información Personal, que usted envíe, cargue y/o publique, o
                  transmita, muestre, ejecute o distribuya a través del Sitio Web, ya sea en
                  relación con su uso del Sito Web o de otra manera. Esto incluye, sin limitación,
                  fotos y vídeos personales.
                </p>

                <li className={styles.list}>Licencia del Contenido del Usuario.</li>
                <p className={styles.paragraph}>
                  Al utilizar este Sito Web, usted otorga a la Compañía y a sus accionistas,
                  funcionarios, directores, empleados, agentes, afiliados, consultores,
                  representantes, sublicenciatarios, sucesores y cesionarios (colectivamente, las
                  “Partes de la Compañía”) el derecho a publicar su nombre y cualquier nombre de
                  usuario suyo en relación con el uso por parte de las Partes de la Compañía de su
                  licencia sobre su Contenido de Usuario. Asimismo, usted renuncia a cualquier
                  reclamación derivada del uso de este derecho por parte de las partes de la
                  Compañía.
                </p>
              </ol>

              <li className={styles.list}>Por Ley o para Proteger los Derechos.</li>
              <p className={styles.paragraph}>
                TBL divulga Información Personal cuando lo exija la ley, en respuesta a una
                situación u orden judicial, o cuando TBL crea a su entera discreción, que la
                divulgación es razonablemente necesaria para proteger la propiedad o los derechos de
                TBL, te terceros, o del público.
              </p>

              <li className={styles.list}>Transferencia de la Compañía; bancarrota.</li>
              <p className={styles.paragraph}>
                TBL se reserva el derecho de transferir toda la Información Personal que posea a una
                organización sucesora en el caso de una fusión, adquisición o bancarrota o cualquier
                compra total o parcial de los activos de TBL. A excepción de lo ordenado por un
                tribunal de bancarrota, el uso y la divulgación de toda la Información Personal
                transferida estarán sujetos a esta Política de Privacidad, o a una nueva política de
                privacidad si se le notifica de esa nueva política de privacidad y se le da una
                oportunidad de excluirse de ella. Sin embargo, la Información Personal que se
                ingreso después de la transferencia, puede estar sujeta a una nueva política de
                privacidad que establezca la organización sucesora de TBL.
              </p>

              <li className={styles.list}>Cuentas sin Pagar.</li>
              <p className={styles.paragraph}>
                Si no paga cualquier saldo adeudado en relación a los servicios, TBL podrá, según lo
                permita la ley, reportar su Información Personal incluyendo, sin limitación, su
                saldo sin pagar, a los servicios de información al consumidor, agencias de cobro y
                otros.
              </p>
            </ol>

            <li className={styles.list}>
              Autorización para Recibir comunicaciones Electrónicas de la Compañía.
            </li>
            <p className={styles.paragraph}>
              Sin limitación, al registrarse en el Sitio Web y/o al proporcionar su nombre, correo
              electrónico, dirección postal o residencial, y/o número telefónico a través del Sitio
              Web, usted otorga su autorización para recibir comunicaciones electrónicas y de otro
              tipo de TBL, a corto plazo y periódicamente, incluyendo comunicaciones por correo
              electrónico y mensajes (“SMS” o “Mensajes de Texto”), relacionadas al Sitio Web,
              nuevas ofertas de productos, promociones, y para otros asuntos. Usted podrá optar por
              no recibir las comunicaciones electrónicas en cualquier momento (a) siguiendo las
              instrucciones para darse de baja que se mencionan en cada comunicación; o (b) enviando
              un correo electrónico a unsubscribe@terramarbrands.com.
            </p>

            <li className={styles.list}>
              Autorización para Recibir Comunicaciones Electrónica de Otros Usuarios.
            </li>
            <p className={styles.paragraph}>
              Sin limitación, al registrarse en el Sitio Web y/o al proporcionar su nombre, correo
              electrónico, dirección postal o residencial, su número de teléfono a través del Sitio
              Web, usted autoriza a recibir comunicaciones electrónicas, incluyendo correo
              electrónico, mensajes instantáneos, video conferencias, y otros mensajes personales de
              otros usuarios del Sitio Web.
            </p>

            <li className={styles.list}>Seguridad de la Información Personal.</li>
            <p className={styles.paragraph}>
              TBL se toma muy en serio la seguridad. Utilizamos medidas de seguridad comercialmente
              razonables para protegerle en contra del acceso, uso, modificación, destrucción o
              divulgación no autorizada de su Información Personal. Sin embargo, debido a la
              realidad de la seguridad de los datos, TBL no puede garantizar que no se acceda,
              piratee, divulgue o destruya cualquier información que se nos proporcione por parte de
              personas no autorizadas.
            </p>

            <li className={styles.list}>Pérdida o Robo de Información.</li>
            <p className={styles.paragraph}>
              Deberá de notificarnos inmediatamente si su información de crédito, bancaria, de otra
              institución financiera, nombre de usuario o contraseña ha sido extraviada, robada o
              utilizada sin su permiso. En tal caso, eliminaremos el número de tarjeta de crédito,
              la información de la cuenta bancaria, o de información de otra institución financiera,
              nombre de usuario o la contraseña de su cuenta y actualizaremos nuestros registros
              según corresponda.
            </p>

            <li className={styles.list}>
              Capacidad del Usuario para Acceder, Actualizar y Corregir su Información Personal.
            </li>
            <p className={styles.paragraph}>
              TBL desea que su Información Personal sea completa y correcta. Al utilizar el Sitio
              Web, usted declara y garantiza que toda la información que proporciona en relación con
              su uso del Sitio Web es actual, completa y precisa, y que actualizará la información
              según se requiera para mantener su integridad y precisión. Para confirmar la
              integridad y exactitud de su información o para realizar cambios en ella, visite su
              perfil personal. A través de su perfil personal, podrá revisar y actualizar su
              Información Personal que ya hemos recabado.
            </p>

            <li className={styles.list}>Sitios Web de Terceros.</li>
            <p className={styles.paragraph}>
              TBL no posee ni controla Sitios Web de Terceros. En consecuencia, los Sitios Web de
              Terceros no tienen ninguna obligación de cumplir con esta Política de Privacidad a
              excepción en lo que respecta a la Información Personal proporcionada directamente por
              ellos por TBL. Antes de visitar o proporcionar Información Personal a Sitios Web de
              Terceros, deberá informarse de las políticas y prácticas (si las hay) de esos Sitios
              Web de Terceros, y deberá tomar las medidas necesarias para, a su propia discreción,
              proteger su privacidad.
            </p>

            <li className={styles.list}>Actividades Promocionales.</li>
            <p className={styles.paragraph}>
              De vez en cuando, TBL podrá llevar a cabo concurso, sorteos y otras promociones
              (conocidas también como “Actividades Promocionales”). Cualquier información
              proporcionada relacionada con las Actividades Promocionales será tratada de acuerdo
              con esta Política de Privacidad. De vez en cuando, TBL también puede solicitar su
              participación en encuestas diseñadas para ayudar a TBL a mejorar el Sitio Web.
              Cualquier Información Personal proporcionada a TBL en relación con cualquier encuesta
              será utilizada únicamente en relación con dicha encuesta y se revelará a terceros no
              vinculados con esta Política de Privacidad sólo en la forma global.
            </p>

            <li className={styles.list}>Transacciones Empresariales.</li>
            <p className={styles.paragraph}>
              En caso de que TBL pase por una transacción empresarial, incluyendo, sin limitación,
              cualquier fusión, adquisición, asociación, reorganización empresarial, financiación de
              deudas o venta de activos de la compañía, o en el caso de insolvencia, quiebra o
              administración judicial (en conjunto una “Transacción Empresarial”), TBL podrá
              utilizar la información recopilada de acuerdo con esta Política de Privacidad y estar
              sujeta a sus restricciones, como parte de una Transacción Empresarial. En tales casos,
              su información podrá ser parte de los activos transferidos.
            </p>

            <li className={styles.list}>Divulgación de No Rastreo (Do-Not-Track).</li>
            <p className={styles.paragraph}>
              TBL no responde a las señales de “No Rastreo” (Do Not Track) enviada por los
              navegadores.
            </p>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyUSAEsp;
