import React from 'react';

// Components
// import Prefooter from './Prefooter';
import Center from './Center';

// Styles
import styles from './Footer.module.sass';

export default function Footer({ device, history }) {
  const screenSize = device?.screenSize || 'desktop';
  return (
    <div className={`${styles.Footer} ${styles[screenSize]}`}>
      {/* <div className={styles.preFooter}>
        <Prefooter device={device} />
      </div> */}
      <div className={styles.middleFooter}>
        <Center screenSize={screenSize} history={history} />
      </div>
    </div>
  );
}
