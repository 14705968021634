import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

//Queries
import getVideos from '../../Queries/getVideos2';

const Reconstruye = (props) => {
  const { screenSize, webservice } = props;
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('creatingSmiles');

  useEffect(() => {
    setLoading(true);
    async function showVideos() {
      setLoading(false);
      const response = await getVideos();
      if (response.length > 0) {
        for (let index = 0; index < response.length; index++) {
          if (response[index].categoria === 'Creando sonrisas') {
            setVideos(response[index].videos);
          }
        }
      }
    }
    showVideos();
  }, [webservice]);

  return (
    <div id={styles.Videos} className={styles[screenSize]}>
      <div className={styles.title}>
        <label className={styles.SubtitleBlue}>{t('titleReconstruye')}&nbsp;</label>
        <label className={styles.SubtitleGold}>{t('subTitleReconstruye')}</label>
      </div>
      {(loading && (
        <div className={styles.loading}>
          <CircularProgress className={styles.circularProgressStyle} />
        </div>
      )) || (
        <>
          <div className={styles.thumbsContainer}>
            <div className={styles.thumbs}>
              {videos.map((val, key) => {
                const { enlace = '' } = val;
                return (
                  <div className={styles.videoContainer} key={key}>
                    <iframe
                      width='95%'
                      height='300'
                      src={`https://www.youtube.com/embed/${enlace}?rel=0&amp;showinfo=0`}
                      frameBorder='0'
                      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen='allowfullscreen'
                      className={styles.youtube}
                      title='TMBVideos'></iframe>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Reconstruye;
