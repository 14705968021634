import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import SimpleHeader from '../../SimpleHeader';
import LastFooter from '../../Footer/LastFooter';
import Loadingpage from '../../Loadingpage';

// Styles
import styles from './index.module.sass';

import useDevice from '../../../hooks/useDevice';

const digitalCatalog = window?.localStorage?.getItem('i18nextLng')?.includes('es')
  ? process.env.REACT_APP_DIGITAL_ES
  : process.env.REACT_APP_DIGITAL_EN;

//?kit=9000000

export default function MyDigitalCatalog({ history, userAgent }) {
  const device = useDevice({ userAgent });
  const { kitNumber = '' } = useParams();

  return (
    <Suspense fallback={<Loadingpage />}>
      <div>
        <SimpleHeader />
        <div className={styles.catalogContainer}>
          <iframe
            title='Terramar Digital Catalog'
            src={`${digitalCatalog}/?kit=${kitNumber}`}
            frameborder='0'
            style={{
              width: '100%',
              height: '100%',
            }}
            allow='autoplay; fullscreen;'
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen></iframe>
        </div>
        <LastFooter device={device} history={history} />
      </div>
    </Suspense>
  );
}
