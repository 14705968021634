import React from 'react';
import styles from './../index.module.sass';

const TableESP = (props) => {
  const { screenSize = 'desktop', tableItems = [] } = props;
  const size = '';
  const dataCellWidth = (screenSize === 'phone' && '180px') || `${100 / tableItems.length}%`;

  return (
    <div className={`${styles.tableContainer} ${styles[screenSize]}`}>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.tableHeader}`}>
          <div
            className={`${styles.cell} ${styles.headerCell}`}
            style={{ width: (size !== '' && screenSize !== 'phone' && size) || dataCellWidth }}>
            Las categorías de Información Personal que recabamos podrían incluir:
          </div>
          <div
            className={`${styles.cell} ${styles.headerCell}`}
            style={{ width: (size !== '' && screenSize !== 'phone' && size) || dataCellWidth }}>
            Ejemplos de cómo se utiliza la Información Personal incluye:
          </div>
          <div
            className={`${styles.cell} ${styles.headerCell}`}
            style={{ width: (size !== '' && screenSize !== 'phone' && size) || dataCellWidth }}>
            Las empresas con las que se podría compartir sus datos incluyen:
          </div>
        </div>

        <div className={styles.cell}>
          <div className={styles.row}>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>Identificadores</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Datos con protección especial, si usted opta por proporcionarlos.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Información Comercial, si usted decide proporcionarla.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  {' '}
                  Fotoseimágenesde su rostro que pueden ser utilizadas para crear plantillas de
                  reconocimiento facial si usted u otros deciden proporcionarla y tiene la
                  configuración activada.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Informacióndela actividad en internet o de otras redes electrónicas, incluyendo el
                  contenido que usted ve o en el que participa.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Información relacionada a ubicación, incluyendo ubicación precisa del dispositivo
                  si usted decide proporcionarla.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Informacióndeaudio o visual, incluyendo fotos y videos, si usted u otros deciden
                  proporcionarla.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Información Profesional o laboral, si usted decide proporcionarla.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Informacióndesu nivel de escolaridad, si usted decide proporcionarla.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Información Financiera, si usted decide proporcionarla
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  {' '}
                  Informaciónderivada de otra Información Personal sobre usted, que podría incluir
                  sus preferencias, intereses y otra información utilizada para personalizar su
                  experiencia.
                </span>
              </li>
            </ul>
          </div>

          <div className={styles.row}>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  Proporcionar, personalizar y mejorar nuestros productos.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Facilitar transacciones, proporcionar medición, análisis, publicidad y otros
                  servicios empresariales.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Promoverla seguridad, la integridad y la protección.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Comunicarsecon usted.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Investigareinnovar para el bien social y</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Paraotrosfines empresariales.</span>
              </li>
            </ul>
          </div>

          <div className={styles.row}>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  Personas y cuentas con las que compartes y te comunicas.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Personas y Cuentas con las que otros comparten o que vuelven a compartir contenido
                  sobre ti.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  integraciones de terceros en nuestros Productos o que los utilizan.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Paralosnuevos propietarios en caso de un cambio de propiedad o control total o
                  parcial de nuestros Productos o el cambio de sus activos.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Socios,incluyendo socios que utilizan nuestros servicios de análisis, publicidad
                  de anuncios, medición socios, socios que ofrecen bienes y servicios en nuestros
                  Productos y proveedores de servicios, e investigadores y académicos.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Autoridadesjudiciales u otros terceros en relación con solicitudes legales y
                  compañías XYZ.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableESP;
