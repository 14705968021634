import React from 'react';
import styles from '../../index.module.sass';

const PolicesAndProceduresING = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Policies </div>
          <div className={styles.subtitle}>and Procedures.</div>
        </div>
        <p className={styles.paragraphTitle}>Code of Ethics</p>
        <p>
          As an independent Consultant of TM BRANDS MANAGEMENT USA LLC (hereinafter “TERRAMAR” or
          “the Company”) I hereby promise and agree that:
        </p>
        <ol>
          <li className={styles.list}>
            <span className={styles.span}>
              I will be honest and forthright in all my transactions while acting as an Independent
              Consultant of TERRAMAR and will perform all activities in a manner that will enhance
              my reputation and the reputation of TERRAMAR.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will not engage in any illegal or deceptive practice.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will respect the time and privacy of everyone I contact to become retail customers
              or Independent Consultants of TERRAMAR. I will be courteous and respectful to everyone
              contacted in the course of my TERRAMAR business. I recognize TERRAMAR’s officers have
              strong personal convictions regarding the ethical conduct of all TERRAMAR Independent
              Consultants.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will fulfill my leadership responsibilities as a Sponsor, including training and
              providing support to the Independent Consultants in my organization. I will provide
              ongoing support to my organization, acknowledging that the people I sponsor will have
              an increased opportunity for success in TERRAMAR when they receive ongoing training
              and support.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will make no income claims or representations regarding the TERRAMAR compensation
              plan, except those in TERRAMAR literature. I acknowledge that a Terramar Independent
              Consultant’s success depends on many variables, such as amount of time and effort
              committed to his or her business and his or her organizational ability.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will not make claims for TERRAMAR products, services or its income opportunity
              except as published in official TERRAMAR literature. I will remember that even my
              personal experience of benefits received from TERRAMAR products, services or its
              income opportunity may be interpreted as TERRAMAR claims if I use those experiences as
              a sales device.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will always honor TERRAMAR’s 100 percent satisfaction, money-back guarantee when
              dealing with my retail customers.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I understand and agree that I am solely responsible for all financial and legal
              obligations incurred by me in the course of my business as an Independent Consultant
              of TERRAMAR products and services, including self-employment taxes, income taxes,
              sales taxes, license fees, etc. I understand that I am an independent contractor for
              all federal and state tax purposes.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              I will compete aggressively but fairly, and I will respect the participants of other
              network marketing opportunities. I will not solicit from the Independent Consultant
              lists or customer lists of other network marketing companies, nor focus sales or
              recruiting efforts solely on the customers or Independent Consultants of any one other
              company. I will not use sales materials that are regarded as proprietary by other
              companies.
            </span>
            <p className={styles.paragraphTitle}>
              Independent Consultant Statement of Policies and Procedures
            </p>
            <ol>
              <li className={styles.list}>Becoming an Independent Consultant.</li>
              <p className={styles.span}>
                An applicant becomes an Independent Consultant of TERRAMAR when the online
                application is completed and accepted by TERRAMAR.
              </p>
              <p className={styles.span}>
                Once the application is accepted, the Independent Consultant is entitled to buy
                TERRAMAR products at a consultant price and develop their lineage and qualify under
                the compensation plan to receive commissions.
              </p>
              <p className={styles.span}>
                TERRAMAR reserves the right to decline any Independent Consultant Application or
                renewal application, including, but not limited to, the application of any person
                who has had a financial interest of any kind in any TERRAMAR Independent
                Consultantship under a different enroller in the preceding six months.
              </p>

              <li className={styles.list}>Legal age</li>
              <p className={styles.span}>
                Independent Consultants must be of legal age in the state or province of their
                residence.
              </p>

              <li className={styles.list}>Independent Consultant rights</li>
              <p className={styles.span}>
                All Independent Consultants are authorized to sell TERRAMAR products and services,
                and to participate in the TERRAMAR compensation plan. All Independent Consultants
                may enroll new Independent Consultants.
              </p>

              <li className={styles.list}>No purchase required.</li>
              <p className={styles.span}>
                No person is required to purchase any TERRAMAR products or services.
              </p>

              <li className={styles.list}>Simultaneous interests.</li>
              <p className={styles.span}>
                ndependent Consultants and their spouses or dependents may not have a financial
                interest in more than one Independent Consultantship without the written consent of
                TERRAMAR. For example, neither a shareholder of a corporation that is a Terramar
                Independent Consultant, nor a partner of a partnership that is a Terramar
                Independent Consultant may become a Terramar Independent Consultant individually.
              </p>

              <li className={styles.list}>Business Entities.</li>
              <p className={styles.span}>
                A registered corporation, LLC, or partnership (collectively referred to in this
                section as a “Business Entity”) may apply to be a TERRAMAR Independent Consultant by
                submitting an Independent Business Entity Registration Form along with a properly
                completed set of documents indicated for the jurisdiction in which it is legally
                registered. The Business Entity, as well as all shareholders, members, managers,
                partners, trustees, or other parties with any ownership (legal or equitable)
                interest in, or management responsibilities for, the Business Entity (collectively
                “affiliated parties”) are individually, jointly, and severally liable for any
                indebtedness to TERRAMAR, the TERRAMAR Policies and Procedures, the TERRAMAR
                Independent Consultant Agreement, and other obligations to TERRAMAR.
              </p>
              <p className={styles.span}>
                A Business Entity consists of the changing of the information in the TERRAMAR
                account (otherwise known as Kit Number) for tax, commission, recognition, and
                celebration purposes. It does not consist of the opening of a new TERRAMAR account,
                therefore, no change in sponsorship will be approved. However, only the name of one
                woman must be assigned as the “Applicant”, in order for her to receive recognitions,
                awards, trips, experiences, or winning any of the programs mentioned in the
                Consultant Manual.
              </p>
              <p className={styles.span}>
                Spouses, partners, friends, siblings, or two persons of any relationship who wish to
                work together in one TERRAMAR account must register as a Business Entity in the
                jurisdiction in which they reside. For tax and commission purposes, no two persons
                shall share a TERRAMAR account until proper Business Entity documentation is
                submitted to the Executive Committee for approval.
              </p>
              <ol type='A'>
                <li className={styles.list}>Removal of an Affiliated Party</li>
                <p className={styles.span}>
                  If at any time, one of the persons in a registered Business Entity (referred to in
                  this section as a “affiliated party”) wants to cancel their Agreement in the
                  Business Entity account, they must first terminate their affiliation with the
                  Business Entity, notify the Executive Committee that a party has terminated their
                  affiliation with the Business Entity, and comply with the Inactive, Reactivation,
                  Discharge and Change of Lineage rules, established in this document and in the
                  Consultant Manual.
                </p>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      The person(s) remaining in the TERRAMAR account must notify the Executive
                      Committee of their intentions to then remain as a registered Business Entity,
                      or request to change to a personal TERRAMAR account, providing their person
                      tax identification number, with the same Kit Number.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      The affiliated party terminating their Agreement in the Business Entity may
                      not participate in any other TERRAMAR business for six (6) consecutive
                      calendar months in accordance with the Consultant Manual.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      If the Business Entity wishes to bring on any new Affiliated Party, it must
                      adhere to the requirements of this section, Business Entities.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Documents relating to addition or removal of an Affiliate Party must be
                      submitted to TERRAMAR’s Executive Committee. TERRAMAR may, at its discretion,
                      require notarized documents before implementing any changes to a TERRAMAR
                      business.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      If any individual registered in a Business Entity violates the Agreement, the
                      action(s) will be deemed a violation by the Business Entity. TERRAMAR may take
                      disciplinary action against the Business Entity.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      If an Independent Consultant enrolls in TERRAMAR as a Business Entity, each
                      Affiliated Party of the Business Entity shall be personally and individually
                      bound to, and must comply with, the terms and conditions of the Agreement.
                      Under no circumstances will the downline organization of a dissolving Business
                      Entity or divorcing spouses be divided.
                    </span>
                  </li>
                </ul>
                <li className={styles.list}>Changes to a Business Entity</li>
                <p className={styles.span}>
                  Each Independent Consultant must immediately notify TERRAMAR of all changes to the
                  type of Business Entity they utilize in operating their businesses and the
                  addition or removal of business Affiliated Parties.
                </p>

                <li className={styles.list}>Management of a Business Entity Account</li>
                <p className={styles.span}>
                  In the event a decision needs to be made regarding the management of an
                  Independent Consultant Account, and the Independent Consultant Account is owned by
                  a Business Entity or by more than one person or party, TERRAMAR will rely upon the
                  following for purposes of managing the Independent Consultant Account. If the
                  Independent Consultant Account is owned by any form of a Business Entity, TERRAMAR
                  will rely upon the Entity Documents of the Business Entity for management
                  decisions of the Independent Consultant Account. In the event the Entity Documents
                  are not clear or if the decisions of those designated to make management decisions
                  do not comprise a majority, TERRAMAR will defer management decisions to the
                  individual noted as “Applicant” on the Independent Consultant Agreement.
                </p>

                <li className={styles.list}>Separation of a TERRAMAR Business</li>
                <p className={styles.span}>
                  TERRAMAR Independent Consultants sometimes operate their TERRAMAR businesses as
                  spousal partnerships, registered as Business Entities. At any time, the marriage
                  or partnership ends in divorce or separation, arrangements must be made to assure
                  that any separation or division of the business is accomplished so as not to
                  adversely affect the interests and income of other businesses up or down the line
                  of sponsorship.
                </p>
                <p className={styles.span}>
                  During the divorce or entity dissolution process, the parties must adopt one of
                  the following methods of operation:
                </p>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      One of the parties removes themselves from the Business Entity, as outlined in
                      the point A.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      The parties may continue to operate the TERRAMAR business jointly on a
                      “business-as-usual” basis, whereupon all compensation paid by TERRAMAR will be
                      paid according to the status quo as it existed prior to the divorce filing or
                      dissolution proceedings. This is the default procedure unless the Executive
                      Committee is notified of different.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      Under no circumstances will the downline organization of divorcing spouses or
                      a dissolving Business Entity be divided. Similarly, under no circumstances
                      will TERRAMAR split commission and bonus checks between divorcing spouses or
                      members of dissolving entities. TERRAMAR will recognize only one downline
                      organization and will issue only one commission payment per TERRAMAR business
                      per commission cycle.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      Commission payment shall always be issued to the same individual or entity.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      In the event of divorce, the Company will abide by the terms of any Final
                      Order from a Court of competent jurisdiction concerning the division and award
                      of property interests and rights to each party. Documentation including
                      certified copy of the Court’s ruling is required by the Executive Committee
                      before any division is made. The Executive Committee will not process any
                      changes to an account during a pending divorce proceeding unless it receives a
                      court order directing such change or written consent signed by both persons.
                    </span>
                  </li>
                </ul>
                <p className={styles.span}>
                  In the case of Business Entity dissolutions, the former partner, shareholder,
                  member, or other entity affiliate who retains no interest in the business must
                  wait 6 calendar months from the date of the final dissolution before re-enrolling
                  as an Independent Consultant with the same sponsor. In either case, the former
                  spouse or business affiliate shall have no rights to any Independent Consultants
                  in their former organization or to any former Customer. They must develop the new
                  business in the same manner as would any other new Independent Consultant.
                </p>
              </ol>

              <li className={styles.list}>Fictitious and assumed names prohibited.</li>
              <p className={styles.span}>
                A person or entity may not apply as an independent Consultant using a fictitious or
                assumed name.
              </p>

              <li className={styles.list}>Independent Contractor status.</li>
              <p className={styles.span}>
                All Independent Consultants are independent contractors with TERRAMAR, and not
                franchisees, joint ventures, partners, employees, or agents of TERRAMAR. Independent
                Consultants are strictly prohibited from stating or implying, whether orally or in
                writing that they are franchisees, joint ventures, partners, employees, or agents of
                TERRAMAR. Independent Consultants may not bind TERRAMAR to any obligation.
              </p>

              <li className={styles.list}>Annual renewal.</li>
              <p className={styles.span}>
                Independent Consultants must annually renew their Consultantship on the anniversary
                of their application to TERRAMAR. Although the responsibility for renewing belongs
                with each Independent Consultant, TERRAMAR will remind when their renewal is due.
                Any Independent Consultant wishing not to renew shall be deemed to have voluntarily
                terminated their Independent Consultantship, and thereby forfeit the Independent
                Consultantship, all sponsorship rights, position earned, and any future
                compensation.
              </p>

              <li className={styles.list}>Indemnity</li>
              <p className={styles.span}>
                Independent Consultants agree to indemminfy and hold harmless TERRAMAR, its
                shareholders, employees, agents, and successors in interest from and against any
                claim, demand, liability, loss, cost, or expense, including, but not limited to,
                attorney’s fees arising or alleged to arise in connection with the Independent
                Consultantship, and any matter related to the Independent Consultant’s performance
                under the Independent Consultant Agreement.
              </p>

              <li className={styles.list}>Taxes</li>
              <p className={styles.span}>
                Independent Consultants will be treated as independent contractors for all federal
                or state tax purposes. As independent contractors, Independent Consultants will not
                be treated as employees, franchisees, joint ventures, partners, employees, or agents
                with respect to the Internal Revenue Code, Social Security Act, Federal Unemployment
                Act, state unemployment acts, or any other federal, state, or local statue,
                ordinance, rule, or regulation
              </p>

              <li className={styles.list}>Legal compliance</li>
              <p className={styles.span}>
                All Independent Consultants shall comply with all federal and state statutes and
                regulations and local ordinances and regulations concerning the operation of their
                businesses. All Independent Consultants are responsible for their own managerial
                decisions and expenditures, including all estimated income and self-employment
                taxes. At the end of each calendar year, TERRAMAR will issue an IRS Form 1099-MISC
                for non- employee compensation for Independent Consultants as required by law
              </p>

              <li className={styles.list}>Independent Consultant identification number</li>
              <p className={styles.span}>
                All Independent Consultants will be required to provide a Social Security number or
                Federal ID number, and TERRAMAR will use this number until such time as an
                independent Consultant identification number is assigned and for all government
                reporting purposes. While respecting the rights of the “un-numbered,” TERRAMAR
                chooses, as a right of contract, to only enter into agreements with those who are
                willing to provide a taxpayer identification number and a copy of their completed
                IRS form W9. The Independent Consultant identification number must be indicated by
                the Independent Consultant on all correspondence with TERRAMAR, including paperwork
                for enrolling, product ordering, etc.
              </p>

              <li className={styles.list}>Other products and services</li>
              <p className={styles.span}>
                Independent Consultants are not restricted from selling the products and services of
                other companies. However, promotion of products, services or income opportunities of
                other direct selling companies, to TERRAMAR customers or Independent Consultants, is
                strictly prohibited. Any Independent Consultant found in violation of this rule
                risks suspension or termination of their Independent Consultantship.
              </p>

              <li className={styles.list}>No exclusive territories.</li>
              <p className={styles.span}>
                There are no exclusive territories for marketing or enrolling purposes, nor shall
                any Independent Consultant imply or state that he or she does have an exclusive
                territory. There are no geographical limitations (within the U.S. and its
                possessions) on Independent Consultant enrolling.
              </p>

              <li className={styles.list}>Enrolling not compensated</li>
              <p className={styles.span}>
                TERRAMAR Independent Consultants may enroll other Independent Consultants into the
                TERRAMAR program. However, Independent Consultants are compensated only on business
                volume, and never for enrolling additional participants into the program.
              </p>

              <li className={styles.list}>Multiple applications</li>
              <p className={styles.span}>
                If one applicant submits multiple Applications, only the first completed form to be
                received by TERRAMAR will be accepted. TERRAMAR reserves the right to resolve such
                disputes.
              </p>

              <li className={styles.list}>Obligations of Enrollers.</li>
              <p className={styles.span}>
                Independent Consultants who choose to enroll are required to assure the adequate
                training of their enrolled Independent Consultants. “Adequate training” includes
                (but is not limited to) education regarding TERRAMAR rules and regulations,
                compensation plan, product information, sound business practices, sales strategies,
                and ethical behavior. An enroller must maintain an ongoing, professional leadership
                association with Independent Consultants in her organization and must fulfill the
                obligation of performing good practices as a sales mentor or sales trainer in the
                sale or delivery of products to the final consumer. Examples of such supervision may
                include, but are not limited to: newsletters, written correspondence, personal
                meetings, telephone contact, voice mail, electronic mail, training sessions,
                accompanying individuals to TERRAMAR training, and sharing genealogy information
                with those enrolled. Independent Consultants should be able to provide evidence to
                TERRAMAR upon request of ongoing fulfillment of enroller responsibilities.
              </p>

              <li className={styles.list}>Transferring sponsorship.</li>
              <p className={styles.span}>
                Because of the critically important relationship between a sponsoring upline and a
                downline consultant, TERRAMAR strongly discourages requests or attempts to transfer
                sponsorship. However, in an extraordinary situation, a transfer request, without
                group, will be considered but only with the notarized signatures of consent from the
                five immediate uplines. A transfer request, with group, will be considered with the
                notarized signatures of consent from the five immediate uplines, and the notarized
                signatures of consent of each downline wishing to be included in the transfer. All
                transfers of sponsorship require the final approval of TERRAMAR, whose decision will
                take into account the overall good of the TERRAMAR. An administrative fee may be
                charged for changing the corporate records.
              </p>

              <li className={styles.list}>Voluntary termination.</li>
              <p className={styles.span}>
                An independent Consultant may voluntarily terminate his or her Independent
                Consultantship by failing to renew when applicable or upon written notice to
                TERRAMAR. Voluntary termination is effective upon receipt of such notice by TERRAMAR
                at its home office. An independent Consultant who terminates his or her Independent
                Consultantship may reapply after waiting six months from termination.
              </p>

              <li className={styles.list}>Involuntary termination.</li>
              <p className={styles.span}>
                An independent Consultant may be terminated for violating any of the terms of this
                Agreement. TERRAMAR will give written notice of termination of the Independent
                Consultantship of a violating Independent Consultant, citing evidence of the
                violation. Should no response be provided by the Independent Consultant within 15
                days, termination will become effective upon final decision of TERRAMAR. Notice of
                the decision will be sent by certified mail to the violating Independent
                Consultant’s address of record. In instances where applicable state law is
                inconsistent with the foregoing, such procedure shall be automatically amended for
                compliance. An involuntarily terminated Independent Consultant may not make
                application to TERRAMAR for a period of one year following the effective date of
                termination.
              </p>

              <li className={styles.list}>Appeals</li>
              <p className={styles.span}>
                An involuntarily terminated Independent Consultant may appeal the termination by
                submitting a letter of appeal, stating the grounds of the appeal. This letter must
                be sent U. S. Mail or private delivery service RETURN RECEIPT REQUESTED and must be
                received by TERRAMAR within 15 days of the date of mailing of the Company’s
                termination notice. If the Company has not received a letter of appeal by that
                deadline, the involuntary termination shall automatically become final. If an
                independent Consultant files a timely appeal, TERRAMAR will, in its sole discretion,
                review and reconsider the termination and notify the Independent Consultant of its
                decision. The decision of the Company shall be final and subject to no further
                review. If the appeal is denied,the termination shall remain in effect as of the
                date of the Company’s original termination notice.
              </p>

              <li className={styles.list}>Effect of suspension</li>
              <p className={styles.span}>
                Should TERRAMAR deem it necessary to suspend an independent Consultant, such
                suspension could mean that the Independent Consultant may not have the right to
                represent themselves as an independent Consultant of TERRAMAR and that any
                commissions, royalty overrides, or bonuses due will be held by TERRAMAR pending
                resolution. At the discretion of the Company, products and services may be purchased
                by a suspended Independent Consultant at wholesale. However, should termination
                result, product and services may be purchased only from another TERRAMAR Independent
                Consultant.
              </p>

              <li className={styles.list}>Effect of termination.</li>
              <p className={styles.span}>
                In the event an independent Consultant is terminated, effective with such
                termination the Independent Consultant can no longer sell TERRAMAR products and
                services or enroll other Independent Consultants. The Independent Consultant also
                loses all rights to commissions, bonuses, and all other benefits.
              </p>

              <li className={styles.list}>Limits on transferability or sale. </li>
              <p className={styles.span}>
                An independent Consultant may sell, assign, or transfer his or her Independent
                Consultantship to a non-TERRAMAR Independent Consultant, but first must grant the
                immediate sponsor a right of first refusal. No sale, assignment, or transfer of any
                Independent Consultantship shall be effective without the prior written approval of
                TERRAMAR whose approval will not be unreasonably withheld. If it is determined in
                the Company’s sole discretion that the Independent Consultantship was transferred in
                an effort to circumvent compliance with this Agreement, the transfer shall be
                declared null and void, and the Independent Consultant entity shall revert back to
                the transferring Independent Consultant, who shall be treated as if the transfer had
                never occurred from the reversion day forward. An administrative fee may be charged
                for changing the corporate records.
              </p>

              <li className={styles.list}>Vendor relationships.</li>
              <p className={styles.span}>
                No Independent Consultant shall contact, directly or indirectly, or speak to or
                communicate with any representative of any supplier or manufacturer of TERRAMAR,
                except as arranged by TERRAMAR. Violation of this rule may result in termination of
                Independent Consultant benefits and possible claims for damages if the vendor
                relationship is compromised by the Independent Consultant contact.
              </p>

              <li className={styles.list}>Confidentiality Agreement. </li>
              <p className={styles.span}>
                Information contained in any genealogy or downline report provided to an independent
                Consultant by TERRAMAR is proprietary and confidential to TERRAMAR and is
                transmitted to the Independent Consultant in strictest confidence. The Independent
                Consultant agrees that he or she will not disclose any such information to any third
                party directly or indirectly, nor use the information to compete with TERRAMAR or
                for any purpose other than promoting the TERRAMAR income opportunity. The
                Independent Consultant and TERRAMAR agree that, but for this agreement of
                confidentiality and non- disclosure, TERRAMAR would not provide the information to
                the Independent Consultant. Any Independent Consultant who is found to be in
                violation of this rule risks suspension or termination. TERRAMAR reserves the right
                to seek damages to the fullest extent of the law.
              </p>

              <li className={styles.list}>Succession</li>
              <p className={styles.span}>
                Upon the death of an independent Consultant, the Independent Consultantship shall
                pass by will or intestate succession, as provided by law. TERRAMAR will require the
                successor in interest to sign and be bound by the then current Independent
                Consultant Agreement. The successor shall then be entitled to all the rights and
                subject to all the obligations, just as any other TERRAMAR Independent Consultant.
              </p>

              <li className={styles.list}>Trademarks</li>
              <p className={styles.span}>
                The name TERRAMAR and the names of all TERRAMAR products and services are the
                registered or unregistered trademarks of and owned by TERRAMAR. Only TERRAMAR is
                authorized to produce and market products and literature under these trademarks. Use
                of the TERRAMAR name on any item not produced or authorized by TERRAMAR is
                prohibited.
              </p>

              <li className={styles.list}>Imprinted checks.</li>
              <p className={styles.span}>
                TERRAMAR Independent Consultants are not permitted to use the TERRAMAR trade name or
                any of its trademarks on their business or personal checking accounts. However,
                Independent Consultants may imprint their TERRAMAR business checks as being a
                “Independent Consultant of TERRAMAR products.”
              </p>

              <li className={styles.list}>Yellow and white page listing</li>
              <p className={styles.span}>
                Independent Consultants are not permitted to use the TERRAMAR trade name in
                advertising their telephone and fax numbers in the white or yellow page sections of
                the telephone book.
              </p>

              <li className={styles.list}>Imprinted business cards or letterheads</li>
              <p className={styles.span}>
                TERRAMAR Independent Consultants are not permitted to incorporate into their own
                business card or letterhead graphics any TERRAMAR trade name or trademarks. Only the
                approved TERRAMAR graphics version and wording are permitted, and letterhead must be
                ordered either from TERRAMAR directly or from a Terramar approved source.
              </p>

              <li className={styles.list}>Company literature</li>
              <p className={styles.span}>
                Only official TERRAMAR literature may be used in presenting TERRAMAR products,
                services and the TERRAMAR income opportunity. Company literature may not be
                duplicated or reprinted without prior written permission from TERRAMAR. All
                promotional material of any nature, including electronic, must be approved in
                advance and in writing by TERRAMAR.
              </p>

              <li className={styles.list}>Media interviews..</li>
              <p className={styles.span}>
                Independent Consultants are prohibited from granting radio, television, newspaper,
                or magazine interviews, or using public appearances, public speaking engagements, or
                making any type of statement to the public media to publicize TERRAMAR, its
                products, services or their individual TERRAMAR business except with the express,
                written approval of TERRAMAR. All media contacts and inquiries must be coordinated
                through the approval and representation of a Terramar Corporate Representative.
              </p>

              <li className={styles.list}>Internet and web site policy.</li>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    No Independent Consultant may independently design a web site that uses the
                    names, logos, or product descriptions of TERRAMAR without written permission
                    from TERRAMAR.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    No Independent Consultant may use “blind” ads on the Internet making product or
                    income opportunity representations are or can be associated with TERRAMAR.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    No Independent Consultant may under any circumstance use the Internet for the
                    purpose of indiscriminate “bulk” or unsolicited mailing, generally known as
                    “spamming.”
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    No Independent Consultant may violate any local, state, or federal laws
                    regarding the Internet or any generally accepted ethical codes of Internet
                    conduct.
                  </span>
                </li>
              </ol>

              <li className={styles.list}>Independent communications.</li>
              <p className={styles.span}>
                Independent Consultants, as independent contractors, are encouraged to distribute
                information and direction to their respective downlines. TERRAMAR encourages the use
                of newsletters, training workshops, and other organizational programs. Independent
                Consultants must identify their personal communications as such, and not give the
                impression that such communications are the official communications of TERRAMAR.
              </p>

              <li className={styles.list}>Independent Consultant training.</li>
              <p className={styles.span}>
                Independent Consultants are responsible for providing training to the Independent
                Consultants they enroll. Training assistance is provided in their Backoffice and
                audiovisual materials. Also, each Independent Consultant receives periodic TERRAMAR
                publications that includes ongoing training and product and services information.
                Further, Independent Consultants may attend local and regional training workshop for
                Independent Consultants and participate in TERRAMAR-originated national training
                conference calls.
              </p>

              <li className={styles.list}>Endorsements</li>
              <p className={styles.span}>
                No endorsements of any kind, including by TERRAMAR officers, may be alleged, except
                as expressly communicated in TERRAMAR literature or approved in writing.
              </p>

              <li className={styles.list}>Copying prohibited. </li>
              <p className={styles.span}>
                Independent Consultants shall not copy for sale or personal use audio, or videotaped
                material detailing the TERRAMAR income opportunity or product or service
                presentations, events, or speeches, including conference calls without written
                permission from TERRAMAR. TERRAMAR may seek injunctive relief or damages from the
                violator for the unauthorized use of TERRAMAR copyrights, trademarks, and materials.
                Video or audio taping of TERRAMAR meetings and conferences is strictly prohibited.
                Still photography is allowable at the discretion of the meeting host.
              </p>

              <li className={styles.list}>Telephone answering</li>
              <p className={styles.span}>
                Independent Consultants may not answer the telephone by saying “TERRAMAR,” or in any
                other manner that would lead the caller to believe that he or she has reached the
                corporate offices of TERRAMAR.
              </p>

              <li className={styles.list}>Repackaging prohibited.</li>
              <p className={styles.span}>
                Independent Consultants may not repackage any TERRAMAR products, services, or
                materials in any way.
              </p>

              <li className={styles.list}>Independent Consultant Application and Agreement..</li>
              <p className={styles.span}>
                Commissions and Overrides cannot be paid until a completed Independent Consultant
                Application and Agreement has been received and accepted by TERRAMAR. Commissions
                are paid ONLY on the sale of TERRAMAR products and services. No commissions are paid
                on the purchase of an independent Consultant Start-Up kit, which is sold at Company
                cost.
              </p>

              <li className={styles.list}>Calendar period.</li>
              <p className={styles.span}>
                Commissions, overrides, and achievement awards and recognition are calculated on a
                calendar month basis.
              </p>

              <li className={styles.list}>Commission and override payment date.</li>
              <p className={styles.span}>
                Commissions are paid monthly on or about the first 10 days of the month for
                commissions earned during the preceding calendar month.
              </p>

              <li className={styles.list}>Commission payments.</li>
              <p className={styles.span}>
                Consult the compensation plan described in the Consultant Manual.
              </p>

              <li className={styles.list}>Stockpiling prohibited.</li>
              <p className={styles.span}>
                The TERRAMAR program depends on, and requires, retail sales to the ultimate
                consumer; therefore, all forms of stockpiling or product loading are discouraged.
                TERRAMAR recognizes that Independent Consultants may wish to purchase certain
                products in reasonable amounts for their own use and for availability to new
                Independent Consultants as they are enrolled. However, TERRAMAR strictly prohibits
                the purchase of products in unreasonable amounts solely in an attempt to qualify for
                advancement in the compensation plan.
              </p>

              <li className={styles.list}>Sales volume qualification by order taking.</li>
              <p className={styles.span}>
                The Company has adopted minimum personal and group business volume requirements.
                Minimum business volume requirements may also be fulfilled by taking orders from
                retail customers which will be fulfilled by the Company directly shipping to the
                retail customer.
              </p>

              <li className={styles.list}>Shipping costs.</li>
              <p className={styles.span}>
                Independent Consultants have the responsibility to indicate destination address. The
                methods available are stated on each order form and the prepaid costs, if selected,
                can be calculated by the shipping and handling cost information also provided. NOTE:
                Should the receiving party of any order shipped from TERRAMAR refuse delivery and
                the shipment be returned to TERRAMAR, the ordering Independent Consultant’s
                Independent Consultant status will be made “inactive” pending resolution of the
                delivery refusal. Non-accepted delivery charges will be debited to the Independent
                Consultant’s account.
              </p>

              <li className={styles.list}>Damaged goods.</li>
              <p className={styles.span}>
                The shipping Company is responsible for any damage that occurs after it takes
                physical custody of the goods. An independent Consultant who receives damaged goods
                should follow this procedure:
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>Accept delivery.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Before the driver leaves, document on the delivery receipt the number of boxes
                    which seem to be damaged.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Save the damaged products or boxes for inspection by the shipping agent.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Make an appointment with the shipping Company to have the damaged goods
                    inspected.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>File a claim with the shipping Company.</span>
                </li>
              </ol>

              <li className={styles.list}>Receipts and suggested retail pricing.</li>
              <p className={styles.span}>
                Independent Consultants must provide all retail purchasers of TERRAMAR products with
                written receipts. This receipt form is available to all Consultants in their
                Backoffice. Although TERRAMAR provides a suggested retail price as a guideline,
                Independent Consultants may sell TERRAMAR products at whatever retail price they
                choose.
              </p>

              <li className={styles.list}>Sales tax.</li>
              <p className={styles.span}>
                For purchases made from the Company, TERRAMAR collects and remits applicable state
                tax which may be due on the suggested selling price of those products and/or
                materials which are subject to tax. The applicable rate of tax due is based on the
                address to which the product and/or materials is shipped. Independent Consultants
                who request a tax-exempt purchase for resale from TERRAMAR (not permitted in all
                states) must provide the Company with a copy of their valid and current resale
                exemption certificate showing a resale tax number. This number must appear on all
                orders placed with TERRAMAR. The Independent Consultant must then collect the tax
                from his/her retail customer and remit it to the proper state and local taxing
                authority. All Independent Consultants must pay tax to the Company on their personal
                purchases made for personal use and consumption. As an independent Consultant you
                agree to abide by the rules and procedures as set forth in the sales tax collection
                agreements that the Company may enter into with the various states and local
                jurisdictions.
              </p>

              <li className={styles.list}>Retail outlets.</li>
              <p className={styles.span}>
                TERRAMAR products may not be sold to or in, or be displayed by any retail outlet,
                including, but not limited to, flea markets or swap meets, shopping mall booths or
                restaurants. Exceptions require prior written approval.
              </p>

              <li className={styles.list}>Service oriented establishments.</li>
              <p className={styles.span}>
                It is permissible to take orders for TERRAMAR products and services in businesses
                such as professional services offices, “by appointment” establishments or similar
                non-retail establishments.
              </p>

              <li className={styles.list}>Price changes.</li>
              <p className={styles.span}>
                All TERRAMAR product and literature prices are subject to change without notice.
              </p>

              <li className={styles.list}>Retail customer returns.</li>
              <p className={styles.span}>
                TERRAMAR offers a 100 percent unconditional money-back guarantee on products sold to
                all retail customers. Every TERRAMAR Independent Consultant is to honor this
                guarantee. If a retail customer is dissatisfied with any TERRAMAR product for any
                reason, then that retail customer may return that product to the Independent
                Consultant from whom it was purchased, within 30 days, for either a replacement or a
                full refund of the purchase price.
              </p>

              <p className={styles.span}>
                TERRAMAR will replace the returned product providing the following steps and
                conditions are met:
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    The product is returned to TERRAMAR by the Independent Consultant through whom
                    the purchase was made.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    The product must be received by TERRAMAR within ten days of the return date to
                    the Independent Consultant.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    The return is accompanied by a completed and signed statement indicating:
                  </span>
                </li>
                <ol>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      A signed statement from the retail customer identifying the reason for the
                      return,
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      A copy of the original retail sales receipt, and The unused portion of the
                      product in its original container.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      The name, address, and telephone number of the retail customer.
                    </span>
                  </li>
                </ol>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Proper shipping box(es) and packing materials are to be used in packaging the
                    product(s) being returned for replacement, and the most economical means of
                    shipping is recommended.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    TERRAMAR will prepay the cost of shipping the replacement.
                  </span>
                </li>
              </ol>
              <p className={styles.span}>
                TERRAMAR will not refund to any Independent Consultant, the purchase price of any
                retail customer returns, and no replacement of product will be made if the
                conditions of this Rule are not met.
              </p>

              <li className={styles.list}>Quality control.</li>
              <p className={styles.span}>
                TERRAMAR will replace, within 30 days of purchase, any product found to be
                defective. However, no product should be returned to TERRAMAR before prior approval
                is received. Please contact TERRAMAR at 01 800 313 0903 or by email to
                <span>
                  <a href='mailto:customerservices@tmbrandsusa.com' className={styles.link}>
                    &nbsp;customerservices@tmbrandsusa.com&nbsp;
                  </a>
                </span>
                and strictly comply with the following is requirements:
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    A written replacement request must be submitted, stating the reason for the
                    request, and accompanied by proof of payment and a copy of the purchase order
                    form or packing slip.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    TERRAMAR will instruct the Independent Consultant where to ship the product for
                    inventory and verification and will also provide the appropriate quantity of
                    authorized product return shipping labels. Upon receipt and verification of the
                    product, TERRAMAR will ship replacement product as appropriate.
                  </span>
                </li>
              </ol>
              <p className={styles.span}>
                NOTE: Any return received without prior approval will cause the status of that
                Independent Consultant to be made “inactive.” Further, there is no assurance that
                the product will be considered replaceable.
              </p>

              <li className={styles.list}>Termination returns.</li>
              <p className={styles.span}>
                Upon termination, if the Independent Consultant has purchased products for inventory
                purposes or mandatory sales aides while the Consultant agreement was in effect, all
                unencumbered products in a resalable condition then in possession of the Independent
                Consultant, which have been purchased within 12 months of cancellation, may be
                tendered for repurchase. The repurchase shall be at a price of not less than 90
                percent of the original net cost to the participant. In addition, the Company will
                honor statutory buyback requirements of every jurisdiction. TERRAMAR will also
                repurchase any sales materials and the TERRAMAR Start- Up kit for a period of one
                year from the time of purchase providing the materials are current and in resalable
                condition.
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    A written return request must be submitted and accompanied by proof of payment
                    and a copy of the purchase order.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    TERRAMAR will instruct the Independent Consultant where to ship the product for
                    inventory and verification and will also provide the Independent Consultant with
                    the appropriate quantity of authorized product return shipping labels. Upon
                    receipt and inspection of the return, TERRAMAR will process the appropriate
                    refund for payment.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Independent Consultant must pay the cost of return freight.
                  </span>
                </li>
              </ol>
              <p className={styles.span}>
                NOTE: Product or sales materials not in resalable condition will be returned to
                Independent Consultant at the Independent Consultant’s cost.
              </p>

              <li className={styles.list}>Buyer’s right to cancel.</li>
              <p className={styles.span}>
                Federal law empowers a buyer to cancel certain sales without penalty prior to
                midnight of the third business day after the transaction. This rule covers retail
                consumer sales of $25 or more that occur away from the seller’s main office. The
                TERRAMAR Sales Order form contains all legally required notices. It must be signed
                by the buyer and two copies must be given to the buyer on every sale. In addition,
                the Independent Consultant must orally inform the buyer of the three-day right to
                cancel at the time the buyer signs the contract of sale or purchases the goods. With
                regard to products, in all cases where the customer, at his or her sole discretion,
                deems the Retail Customer Returns policy, stated above, more favorable, that policy
                shall apply and override this policy. With regard to services, at the end of the
                three-day period as stated herein, all sales of services are final and
                non-returnable.
              </p>

              <li className={styles.list}>Independent Consultant’s responsibility.</li>
              <p className={styles.span}>
                If a retail customer mails or delivers to an independent Consultant a valid notice
                of cancellation prior to midnight on the third business day after ordering or
                purchasing the program, service, and/or product, it must be honored by the
                Independent Consultant. If the buyer has taken delivery of any goods, they must be
                returned with the notice in substantially as good condition as when delivered. With
                regard to services, the shrink-wrapped materials describing and explaining the
                services must be returned unopened. Within ten business days after receiving the
                notice, the Independent Consultant must refund all payments made under the contract
                of sale.
              </p>

              <li className={styles.list}>Liability.</li>
              <p className={styles.span}>
                To the extent permitted by law, TERRAMAR shall not be liable for and Independent
                Consultant releases TERRAMAR from, and waives all claims for any loss of profits,
                indirect, direct, special or consequential damages or any other loss incurred or
                suffered by Independent Consultant as a result of (a) the breach by Independent
                Consultant of this Agreement, (b) the operation of Independent Consultant’s
                business, (c) any incorrect or wrong data or information provided by Independent
                Consultant, or (d) the failure to provide any information or data necessary for
                TERRAMAR to operate its business, including without limitation, the enrollment and
                acceptance of Independent Consultant into the income opportunity or the payment of
                commissions and bonuses.
              </p>

              <li className={styles.list}>Record-keeping.</li>
              <p className={styles.span}>
                TERRAMAR encourages all its Independent Consultants to keep complete and accurate
                records of all their business dealings. A recommended publication is IRS Publication
                911 For Direct Sellers, available from any IRS Office, or the IRS web site.
              </p>

              <li className={styles.list}>Income claims.</li>
              <p className={styles.span}>
                No income claims, income projections nor income representations may be made to
                prospective Independent Consultants. Obviously, any false, deceptive, or misleading
                claims regarding the opportunity or product/service are prohibited. Independent
                Consultants occasionally represent hypothetical income figures based upon the power
                of network marketing as actual income projections. This is counter-productive and
                prohibited since new Independent Consultants may be quickly disappointed if their
                results are not as rapid as the hypothetical model.
              </p>

              <li className={styles.list}>Governmental endorsement.</li>
              <p className={styles.span}>
                Federal and state regulatory agencies do not approve or endorse direct selling
                programs. Therefore, Independent Consultants may not represent or imply, directly or
                indirectly, that the TERRAMAR program has been approved or endorsed by any
                governmental agency.
              </p>

              <li className={styles.list}>Amendments.</li>
              <p className={styles.span}>
                TERRAMAR reserves the right to amend this Agreement, its prices, product
                availability, and compensation plan as it deems appropriate. Amendments will be
                communicated to all Independent Consultants through TERRAMAR publications.
                Amendments are effective and binding on all Independent Consultants as of the date
                of issuance.
              </p>

              <li className={styles.list}>Non-waiver provision.</li>
              <p className={styles.span}>
                No failure of TERRAMAR to exercise any power under this Agreement or to insist upon
                strict compliance by an independent Consultant with any obligation or provision
                herein, and no custom or practice of the parties at variance with this Agreement,
                shall constitute a waiver of the Company’s right to demand exact compliance with
                this Agreement. Waiver by the Company can only be in writing by an authorized
                officer of the Company.
              </p>
              <p className={styles.span}>
                The Company’s waiver of any particular default by an independent Consultant shall
                not affect or impair the Company’s rights with respect to any subsequent default,
                nor shall it affect in any way the rights or obligations of any other Independent
                Consultant. Nor shall any delay or omission by the Company to exercise any right
                arising from default affect or impair the Company’s rights as to that or any
                subsequent default.
              </p>

              <li className={styles.list}>Severability.</li>
              <p className={styles.span}>
                If, under any applicable and binding law or rule of any applicable jurisdiction, any
                provision of the Agreement, including these rules and regulations, or any
                specification, standard or operating procedure which TERRAMAR has prescribed is held
                to be invalid or unenforceable, TERRAMAR shall have the right to modify the invalid
                or unenforceable provision, specification, standard or operating procedure or any
                portion thereof to the extent required to be valid and enforceable. The Independent
                Consultant shall be bound by any such modification. The modification will be
                effective only in the jurisdiction in which it is required.
              </p>

              <li className={styles.list}>Arbitration.</li>
              <ol type='A'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    All disputes and claims relating to TERRAMAR, its products, the Consultant
                    Agreement, or the TERRAMAR Compensation Plan, including but not limited to (1)
                    the rights and obligations of the Consultant and TERRAMAR, (2) any other claims
                    or causes of action relating to the performance of either the Consultant or
                    TERRAMAR under the Consultant Agreement or the Rules and Regulations, and/or (3)
                    the Consultant’s purchase of TERRAMAR products shall be settled totally and
                    finally by arbitration in Miami, Florida or such other location as TERRAMAR
                    prescribes, in accordance with the Federal Arbitration Act and the Commercial
                    Arbitration Rules of the American Arbitration Association. Louisiana residents
                    may arbitrate in New Orleans. There shall be one arbitrator, an attorney at law,
                    who shall have expertise in business law transactions with preference being an
                    attorney knowledgeable in the direct selling industry, selected from the panel
                    that the American Arbitration Association provides. Each party to the
                    arbitration shall be responsible for its own costs and expenses of arbitration,
                    including legal and filing fees. If the Consultant files a claim or counterclaim
                    against TERRAMAR, the Consultant shall do so on an individual basis and not with
                    any other Consultant or as part of a class action. The decision of the
                    arbitrator shall be final and binding on the parties and may, if need be, be
                    reduced to a judgment in any court of competent jurisdiction. This agreement to
                    arbitrate shall survive any termination or expiration of the Consultant
                    Agreement.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Notwithstanding the foregoing, the arbitrator shall have no jurisdiction over
                    disputes relating to the ownership, validity or registration of any mark or
                    other intellectual property or proprietary confidential information of TERRAMAR
                    without TERRAMAR prior written consent. TERRAMAR seek any applicable remedy in
                    any applicable forum with respect to these disputes and with respect to money
                    owing to TERRAMAR. In addition to monetary damages, TERRAMAR may obtain
                    injunctive relief against the Consultant for any violation of the Consultant
                    Agreement and for any violation or misuse of TERRAMAR trademark, copyright, or
                    confidential information policies.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Nothing in this rule shall prevent TERRAMAR from applying to and obtaining from
                    any court having jurisdiction a writ of attachment, a temporary injunction,
                    preliminary injunction and/or other injunctive or emergent relief available to
                    safeguard and protect TERRAMAR interest prior to the filing of or during or
                    following any arbitration or other proceeding or pending the handing down of a
                    decision or award in connection with any arbitration or other proceeding.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    The existence of any claim or cause of action of the Consultant against
                    TERRAMAR, whether predicated on the Consultant Agreement or otherwise, shall not
                    constitute a defense to TERRAMAR’s enforcement of the Consultant’s covenants and
                    agreements contained in the Consultant Agreement or the Rules.
                  </span>
                </li>
              </ol>

              <li className={styles.list}>Jurisdiction.</li>
              <p className={styles.span}>
                All disputes and claims relating to TERRAMAR, this Agreement, the TERRAMAR
                compensation plan, or its products, the rights and obligations of an independent
                Consultant and TERRAMAR, or any other claims or causes of action relating to the
                performance of either an independent Consultant or TERRAMAR under the Agreement or
                the rules and regulations, and/or an independent Consultant’s purchase of products
                shall be governed by the laws of Florida and shall be adjudicated totally and
                finally in Miami, Florida, or such other location as TERRAMAR prescribes. Louisiana
                residents only: In the event of a dispute for jurisdictional purposes, an
                independent Consultant shall be entitled to file an adjudicatory claim or lawsuit in
                the jurisdiction of Louisiana and the governing law shall be Louisiana law.
              </p>

              <li className={styles.list}>Limitation of damages.</li>
              <p className={styles.span}>
                To the extent permitted by law, TERRAMAR and its affiliates, officers, directors,
                employees, and other representatives shall not be liable for, and the Independent
                Consultant hereby releases the foregoing from and waives any claim for loss of
                profit, incidental, special, consequential, or exemplary damages which may arise out
                of any claim whatsoever relating to TERRAMAR’s performance, non-performance, act, or
                omission with respect to the business relationship or other matters between the
                Independent Consultant and TERRAMAR whether sounding in contract, tort, or strict
                liability. Furthermore, it is agreed that any damage to the Independent Consultant
                shall not exceed, and is hereby expressly limited to, the amount of unsold TERRAMAR
                products and services owned or held by the Independent Consultant and commissions
                owing.
              </p>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PolicesAndProceduresING;
