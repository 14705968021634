import React from 'react';
import styles from '../../index.module.sass';

const PolicesAndProceduresESP = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Políticas </div>
          <div className={styles.subtitle}>y Procedimientos.</div>
        </div>
        <p className={styles.paragraphTitle}>Código de Ética</p>
        <p>
          Al ser un Consultor Independiente de TM BRANDS MANAGEMENT USA LLC (de aquí en adelante se
          referirá como “TERRAMAR” o “La Compañía”) prometo y acepto que:
        </p>
        <ol>
          <li className={styles.list}>
            <span className={styles.span}>
              Seré honesto y directo en todas mis operaciones y transacciones mientras actúe como un
              Consultor Independiente de TERRAMAR y realizaré todas las actividades de una forma que
              mejoren mi reputación y la reputación de TERRAMAR.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>No incurriré en ninguna práctica ilegal o engañosa.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Respetare el tiempo y la privacidad de todas las personas con las que este en contacto
              para que se conviertan en clientes o Consultores Independientes de TERRAMAR. Seré
              cortes y respetuoso con todas las personas con las que este en contacto mientras
              desarrolle de mi Negocio de TERRAMAR. Reconozco que los directivos de TERRAMAR tienen
              fuertes convicciones personales respecto a la conducta ética de todos los Consultores
              Independientes de TERRAMAR.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Cumpliré con mis responsabilidades de liderazgo como Patrocinador, incluyendo,
              brindando entrenamiento y apoyo a los Consultores Independientes de mi organización.
              Proporcionaré apoyo continuo a mi organización, reconociendo que las personas que
              patrocino tendrán una mayor oportunidad de éxito en TERRAMAR si reciben entrenamiento
              y apoyo de forma continua.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              No haré ninguna declaración o manifestación de ingresos con respecto al plan de
              compensación de TERRAMAR, a excepción de los mencionados en la literatura oficial de
              TERRAMAR. Reconozco que el éxito de un Consultor Independiente de TERRAMAR depende de
              muchas variables, como la cantidad de tiempo y esfuerzo dedicado a su negocio y a su
              capacidad de organización.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              No haré declaraciones sobre los productos, servicios o la oportunidad de ingreso de
              TERRAMAR excepto las que se encuentran publicadas en la literatura oficial de
              TERRAMAR. Tendré en cuenta que incluso mi experiencia personal de los beneficios
              obtenidos de los productos de TERRAMAR, servicios y su oportunidad de ingresos pueden
              ser interpretados como declaraciones de TERRAMAR si utilizo esas experiencias como un
              instrumento de venta.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Siempre respetaré y honraré la garantía de TERRAMAR del 100 por ciento de
              satisfacción, y la devolución del dinero cuando se trate con mis clientes minoristas.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Entiendo y acepto que soy el/la único(a) responsable de todas las obligaciones
              financieras y legales que yo incurra en el desarrollo de mi negocio como un Consultor
              Independiente de los productos y servicios de TERRAMAR, incluyendo los impuestos de
              autoempleo, los impuestos sobre la renta, los impuestos sobre las ventas, el costo de
              las licencias, etc. Entiendo que soy un Consultor Independiente para todos los fines
              fiscales federales y estatales.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Competiré de forma agresiva pero justa y respetaré a los miembros de otras
              oportunidades de redes de mercadeo. No solicitaré las listas de los Consultores
              Independientes o lista de clientes de otras compañías de redes de mercado, ni enfocaré
              mis ventas u oportunidades de reclutamiento únicamente en clientes o Consultores
              Independientes de cualquier otra compañía. No utilizaré materiales de venta que sean
              considerados como propiedad de otras compañías.
            </span>
            <p className={styles.paragraphTitle}>
              Declaración de Políticas y Procedimientos para Consultores Independientes
            </p>
            <ol>
              <li className={styles.list}>Convertirse en Consultores Independiente.</li>
              <p className={styles.span}>
                Un solicitante se convierte en Consultor Independiente de TERRAMAR cuando se
                completa la solicitud en línea y esta es aceptada por TERRAMAR.
              </p>
              <p className={styles.span}>
                Una vez que la solicitud es aceptada, el/la Consultor(a) Independiente tiene derecho
                a comprar los productos de TERRAMAR al precio ofrecido al Consultor y desarrollar su
                linaje y calificar para recibir comisiones según lo establecido en el plan de
                compensación.
              </p>
              <p className={styles.span}>
                TERRAMAR se reserva el derecho de rechazar cualquier Solicitud de Consultor
                Independiente o una solicitud de renovación, incluyendo, pero no limitado a, la
                solicitud de cualquier persona que haya tenido un interés financiero de cualquier
                tipo en cualquier Consultor Independiente de TERRAMAR bajo otro patrocinador
                diferente en los seis meses anteriores.
              </p>

              <li className={styles.list}>Edad Legal</li>
              <p className={styles.span}>
                Los Consultores Independientes deben ser mayores de edad en el estado o provincia de
                su residencia.
              </p>

              <li className={styles.list}>Derechos de los Consultores Independiente</li>
              <p className={styles.span}>
                Todos los Consultores Independientes están autorizados a vender productos y
                servicios de TERRAMAR, y a participar en el plan de compensación de TERRAMAR. Todos
                los Consultores Independientes pueden inscribir nuevos Consultores Independientes.
              </p>

              <li className={styles.list}>No es necesario comprar.</li>
              <p className={styles.span}>
                No es necesaria la compra de ningún producto o servicio de TERRAMAR.
              </p>

              <li className={styles.list}>Intereses Simultáneos.</li>
              <p className={styles.span}>
                Los Consultores Independientes y sus conyugues o dependientes no pueden tener un
                interés financiero en más de un Negocio Independiente sin el consentimiento por
                escrito de TERRAMAR. Por ejemplo, ni un accionista de la corporación que es un
                Consultor Independiente de TERRAMAR, ni un socio de la 6. sociedad que es un
                Consultor Independiente puede convertirse en Consultor Independiente de TERRAMAR
                individualmente
              </p>

              <li className={styles.list}>Entidades comerciales.</li>
              <p className={styles.span}>
                Una corporación, LLC o sociedad registrada (denominadas colectivamente en esta
                sección como "Entidad comercial") puede solicitar ser un Consultor Independiente de
                TERRAMAR presentando un Formulario de Registro de Entidad Comercial Independiente
                debidamente completado junto con un conjunto de documentos que se indican para la
                jurisdicción en la que está legalmente registrada. La Entidad de Negocio, así como
                todos los accionistas, miembros, gerentes, socios, fideicomisarios u otras partes
                con cualquier interés de propiedad (legal o equitativa) o responsabilidades de
                gestión para la Entidad de Negocio (colectivamente "partes afiliadas") son
                individual, conjunta y solidariamente responsables de cualquier deuda con TERRAMAR,
                las Políticas y Procedimientos de TERRAMAR, el Acuerdo de Consultor Independiente de
                TERRAMAR y otras obligaciones con TERRAMAR.
              </p>
              <p className={styles.span}>
                Una Entidad Empresarial consta en la modificación de los datos en la cuenta de
                TERRAMAR (también conocida como Número de Kit) para efectos fiscales, comisiones,
                reconocimientos y fines de premiación. No consiste en la apertura de una nueva
                cuenta de TERRAMAR, por lo que no se aprobará ningún cambio de patrocinio. Sin
                embargo, sólo se debe asignar el nombre de una mujer como "Solicitante", para que
                pueda recibir reconocimientos, premios, viajes, experiencias o ganar alguno de los
                programas mencionados en el Manual del Consultor.
              </p>
              <p className={styles.span}>
                Los cónyuges, parejas, amigos, hermanos o dos personas de cualquier parentesco que
                deseen trabajar juntos en una misma cuenta de TERRAMAR deberán registrarse como
                Entidad de Negocio en la jurisdicción en la que residan. Para efectos fiscales y de
                comisiones, ninguna de las dos personas podrá compartir una cuenta de TERRAMAR hasta
                que se entregue la documentación de la Entidad de Negocios al Comité Ejecutivo para
                su verificación y aprobación.
              </p>
              <ol type='A'>
                <li className={styles.list}>Baja de una parte afiliada</li>
                <p className={styles.span}>
                  Si en cualquier momento, una de las personas de una Entidad de Negocio registrada
                  (denominada en esta sección "parte afiliada") quiere cancelar su Acuerdo en la
                  cuenta de la Entidad de Negocio, debe primero cancelar su afiliación con la
                  Entidad de Negocio, notificar al Comité Ejecutivo que una parte ha cancelado su
                  afiliación con la Entidad de Negocio, y cumplir con las reglas de Inactividad,
                  Reclasificación, Cancelación y Cambio de Linaje establecidas en este documento y
                  en el Manual del Consultora.
                </p>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      La(s) persona(s) que permanezca(n) en la cuenta de TERRAMAR deberá(n)
                      notificar al Comité Ejecutivo sus intenciones de permanecer entonces como
                      Entidad de Negocio registrada, o solicitar el cambio a una cuenta personal de
                      TERRAMAR, proporcionando su número de identificación fiscal, con el mismo
                      Número de Kit.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      La parte afiliada que resuelva su Acuerdo en la Entidad de Negocio no podrá
                      participar en ningún otro negocio de TERRAMAR durante un plazo de seis (6)
                      meses naturales consecutivos, de acuerdo con lo establecido en el Manual de la
                      Consultora.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Si la Entidad de Negocio desea incorporar alguna nueva Parte Afiliada, deberá
                      atenerse a los requisitos de este apartado, Entidades de Negocio.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Los documentos relacionados con la incorporación o eliminación de una Parte
                      Afiliada deben ser presentados al Comité Ejecutivo de TERRAMAR. TERRAMAR
                      podrá, a su discreción, exigir documentos notariales antes de implementar
                      cualquier cambio en un negocio de TERRAMAR.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Si cualquier individuo registrado en una Entidad de Negocio viola el Acuerdo,
                      la(s) acción(es) será(n) considerada(s) como una violación por parte de la
                      Entidad de Negocio. TERRAMAR podrá tomar medidas disciplinarias contra la
                      Entidad de Negocio
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Si un Consultor Independiente se inscribe en TERRAMAR como Entidad de Negocio,
                      cada Parte Afiliada de la Entidad de Negocio estará personal e individualmente
                      obligada a, y deberá cumplir, los términos y condiciones del Acuerdo. En
                      ningún caso se dividirá la organización de la línea descendente de una Entidad
                      de Negocio que se disuelva o de los cónyuges que se divorcien.
                    </span>
                  </li>
                </ul>
                <li className={styles.list}>Cambios en la Entidad Comercial</li>
                <p className={styles.span}>
                  Cada Consultora Independiente deberá notificar inmediatamente a TERRAMAR de todos
                  los cambios realizados en el tipo de Entidad Comercial que utiliza en la operación
                  de sus negocios y la incorporación o eliminación de Partes Afiliadas comerciales.
                </p>

                <li className={styles.list}>Administración de una Cuenta de Entidad Comercial</li>
                <p className={styles.span}>
                  En el caso de que sea necesario tomar una decisión respecto a la administración de
                  una Cuenta de una Consultora Independiente, y la Cuenta de la Consultora
                  Independiente sea propiedad de una Entidad de Negocios o de más de una persona o
                  parte, TERRAMAR se basará en lo siguiente para fines de administración de la
                  Cuenta de la Consultora Independiente. Si la Cuenta de la Consultora Independiente
                  es propiedad de cualquier forma de una Entidad de Negocios, TERRAMAR se basará en
                  los Documentos de la Entidad de Negocios para las decisiones de administración de
                  la Cuenta de la Consultora Independiente. En caso de que los Documentos de la
                  Entidad no sean claros o si las decisiones de los designados para tomar decisiones
                  de administración no constituyen una mayoría, TERRAMAR deferirá las decisiones de
                  administración a la persona anotada como "Solicitante" en el Acuerdo de la
                  Consultora Independiente.
                </p>

                <li className={styles.list}>Separación de un negocio TERRAMAR</li>
                <p className={styles.span}>
                  Las Consultoras Independientes de TERRAMAR a veces operan sus negocios TERRAMAR
                  como sociedades conyugales, registradas como Entidades Comerciales. En cualquier
                  momento, si el matrimonio o la sociedad terminan en divorcio o separación, se
                  deben hacer acuerdos para asegurar que cualquier separación o división del negocio
                  se lleve a cabo de manera que no afecte negativamente los intereses e ingresos de
                  otros negocios en la línea de patrocinio ascendente o descendente.
                </p>
                <p className={styles.span}>
                  Durante el proceso de divorcio o disolución de la entidad, las partes deben
                  adoptar uno de los siguientes métodos de funcionamiento:
                </p>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Una de las partes se retira de la Entidad Empresarial, como se describe en el
                      punto A.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      Las partes pueden continuar operando el negocio de TERRAMAR de manera conjunta
                      sobre la base de un "business as usual", con lo cual todas las compensaciones
                      pagadas por TERRAMAR serán pagadas de acuerdo con la situación que existía
                      antes de la solicitud de divorcio o del procedimiento de disolución. Este es
                      el procedimiento por defecto, a menos que se notifique al Comité Ejecutivo de
                      lo contrario.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      En ningún caso se dividirá la organización de la línea descendente de los
                      cónyuges que se divorcien o de una Entidad Comercial que se disuelva. Del
                      mismo modo, en ninguna circunstancia TERRAMAR dividirá los cheques de
                      comisiones y bonos entre los cónyuges que se divorcian o los miembros de las
                      entidades que se disuelven. TERRAMAR reconocerá sólo una organización de línea
                      descendente y emitirá sólo un pago de comisiones por negocio TERRAMAR por
                      ciclo de comisiones.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      El pago de comisiones se emitirá siempre a la misma persona o entidad.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      En caso de divorcio, la Compañía se atendrá a los términos de cualquier Orden
                      Definitiva de un Tribunal de jurisdicción competente relativa a la división y
                      adjudicación de intereses y derechos patrimoniales a cada parte. El Comité
                      Ejecutivo exigirá la documentación, incluida una copia certificada de la
                      sentencia del Tribunal, antes de realizar cualquier división. El Comité
                      Ejecutivo no tramitará ningún cambio en una cuenta durante un procedimiento de
                      divorcio pendiente, a menos que reciba una orden judicial que ordene dicho
                      cambio o un consentimiento por escrito firmado por ambas personas.
                    </span>
                  </li>
                </ul>
                <p className={styles.span}>
                  En el caso de disoluciones de entidades comerciales, el antiguo socio, accionista,
                  miembro u otro afiliado de la entidad que no conserve ningún interés en la empresa
                  deberá esperar 6 meses naturales desde la fecha de la disolución definitiva antes
                  de volver a inscribirse como Consultora Independiente con el mismo patrocinador.
                  En cualquier caso, el excónyuge o afiliado del negocio no tendrá ningún derecho
                  sobre las Consultoras Independientes de su antigua organización ni sobre ningún
                  antiguo Cliente. Es decir, deberán desarrollar el nuevo negocio de la misma manera
                  que lo haría cualquier otra nueva Consultora Independiente.
                </p>
              </ol>

              <li className={styles.list}>Prohibición de nombres ficticios y supuestos.</li>
              <p className={styles.span}>
                Una persona o entidad no puede solicitar ser un Consultor Independiente usando un
                nombre ficticio o supuesto.
              </p>

              <li className={styles.list}>Estatus de Contratista Independiente.</li>
              <p className={styles.span}>
                Todos los Consultores Independientes son contratistas independientes de TERRAMAR, y
                no franquiciados, empresas conjuntas, socios, empleados, o agentes de TERRAMAR. Los
                Consultores Independientes tienen estrictamente prohibido declarar o insinuar, ya
                sea de forma oral o por escrito, que son franquiciados, empresas conjuntas, socios,
                empleados o agentes de TERRAMAR. Consultores Independientes no pueden vincular a
                TERRAMAR con ninguna obligación.
              </p>

              <li className={styles.list}>Renovación anual.</li>
              <p className={styles.span}>
                Los Consultores Independientes deben renovar anualmente su Negocio Independiente en
                el aniversario cuando ingresaron su solicitud de Consultor Independiente a TERRAMAR.
                Aunque la responsabilidad de renovar pertenece a cada uno de los Consultores
                Independientes, TERRAMAR les recordará cuando deben realizar dicha renovación.
                Cualquier Consultor Independiente que no desee renovar se considerará que ha
                finalizado voluntariamente su Negocio Independiente y, por lo tanto, perderá su
                posición de Consultor Independiente, todos sus derechos de patrocinio, la posición
                ganada y cualquier compensación futura.
              </p>

              <li className={styles.list}>Indemnización</li>
              <p className={styles.span}>
                Los Consultores Independientes aceptan indemnizar y eximir a TERRAMAR, sus
                accionistas, empleados, agentes, y sucesores en interés de cualquier reclamo,
                demanda, responsabilidad, pérdida, costo o gasto, incluyendo, pero no limitado a,
                honorarios de abogados que surjan o que podrían surgir en relación con un Negocio
                Independiente y cualquier asunto relacionado con el desempeño del Consultor
                Independiente bajo el Acuerdo de Consultor Independiente.
              </p>

              <li className={styles.list}>Impuestos</li>
              <p className={styles.span}>
                Para todos los fines de impuestos federales o estatales, los Consultores
                Independientes serán considerados como contratistas independientes. Como
                contratistas independientes, los Consultores Independientes no serán tratados como
                empleados, franquiciados, empresas conjuntas, socios o agentes en relación son el
                Código de Rentas Internas, la Ley de Seguridad Social, la Ley Federal de Desempleo,
                las leyes estatales de desempleo o cualquier otra ley, ordenanza, norma o reglamento
                federal, estatal o local.
              </p>

              <li className={styles.list}>Cumplimiento Legal</li>
              <p className={styles.span}>
                Todos los Consultores Independientes deberán cumplir con todos los estatus y
                regulaciones federales y estatales, así como las ordenanzas y regulaciones
                relacionadas con la operación de sus negocios. Todos los Consultores Independientes
                son responsables de sus propias decisiones administrativas y de sus gastos,
                incluyendo todos los impuestos estimados por ingresos y por autoempleo. Al final de
                cada año calendario, TERRAMAR emitirá un formato de impuesto IRS Form 1099-MISC para
                la compensación de los Consultores Independientes que no sean empleados, tal como lo
                exige la ley.
              </p>

              <li className={styles.list}>Número de Identificación de Consultor Independiente</li>
              <p className={styles.span}>
                Todos los Consultores Independientes deberán proporcionar su número de Seguro Social
                o el número de Identificación Federal, y TERRAMAR utilizará dicho número hasta el
                momento en que se le asigne un Número de identificación de Consultor Independiente y
                para todos los fines de información gubernamental. Respetando los derechos de “no
                numerados”, TERRAMAR opta, como derecho contractual, a únicamente establecer un
                acuerdo con aquellos que estén dispuestos a proporcionar un número de identificación
                fiscal y una copia de su formulario W9 del IRS debidamente completado. El número de
                identificación de Consultor Independiente deberá ser mencionado por el/la
                Consultor(a) Independiente en todo lo relacionado con TERRAMAR, incluyendo su
                documentación de inscripción, pedido de productos, etc.
              </p>

              <li className={styles.list}>Otros productos y servicios</li>
              <p className={styles.span}>
                Los Consultores Independientes no están restringidos a vender productos y servicios
                de otras compañías. Sin embargo, la promoción de productos, servicios o las
                oportunidades de ingresos de otras compañías de ventas directa, a clientes de
                TERRAMAR o a los Consultores Independientes, está estrictamente prohibido. Cualquier
                Consultor Independiente que se le sorprenda infringiendo esta regla se arriesga a la
                suspensión o cancelación de su categoría de Consultor Independiente.
              </p>

              <li className={styles.list}>No hay territorios exclusivos</li>
              <p className={styles.span}>
                No hay territorios exclusivos para mercadeo o reclutamiento, ni ningún Consultor
                Independiente debe insinuar o declarar que él o ella tiene un territorio exclusivo.
                No existe ninguna limitación geográfica (dentro de Estados Unidos y sus territorios)
                para la inscripción de Consultores Independientes.
              </p>

              <li className={styles.list}>Inscripciones no compensadas</li>
              <p className={styles.span}>
                Los Consultores Independientes de TERRAMAR pueden inscribir a otros Consultores
                Independientes dentro del programa de TERRAMAR. Sin embargo, Consultores
                Independientes son compensados únicamente por el volumen del negocio, y nunca por
                inscribir participantes adicionales en el programa.
              </p>

              <li className={styles.list}>Solicitudes Múltiples</li>
              <p className={styles.span}>
                Si un participante entrega varias solicitudes, se aceptará únicamente la primera
                solicitud que esté completada y entregada a TERRAMAR. TERRAMAR se reserva el derecho
                para resolver tales conflictos.
              </p>

              <li className={styles.list}>Obligaciones de los inscritos. </li>
              <p className={styles.span}>
                Los Consultores Independientes que decidan reclutar están obligados a proporcionar y
                asegurar el entrenamiento adecuado a sus Consultores Independientes inscritos. El
                “Entrenamiento Adecuado” incluye (pero no está limitado) a enseñar las reglas y
                regulaciones de TERRAMAR, plan de compensaciones, información de productos,
                prácticas comerciales adecuadas, estrategias de ventas, y comportamiento ético. Un
                reclutador debe de mantener una relación de liderazgo profesional con los
                Consultores Independientes de su organización y debe de cumplir con las obligaciones
                de desempeñarse como mentor de buenas prácticas de ventas para las ventas, o en la
                entrega de productos al consumidor final. Ejemplos de dicha supervisión pueden
                incluir, pero no están limitados a: boletines, comunicación escrita, reuniones
                personales, contacto telefónico, mensaje de voz, correo electrónico, sesiones de
                entrenamiento, acompañamiento de personas a los entrenamientos de TERRAMAR y
                compartir información genealógica a los inscritos. Los Consultores Independientes
                deberán proveer evidencia a TERRAMAR cuando se le solicite acerca del cumplimiento
                continue de las responsabilidades del reclutador.
              </p>

              <li className={styles.list}>Transferencia de patrocinio </li>
              <p className={styles.span}>
                Debido a la gran importancia que existe en la relación entre un patrocinador de
                línea ascendente y un consultor de línea descendente,TERRAMAR no recomienda las
                solicitudes o intentos de transferencia de patrocinio. Sin embargo, en una situación
                extraordinaria, se considerará una solicitud de transferencia, sin grupo sólo con
                las firmas de autorización notariadas de las cinco líneas ascendentes inmediatas.
                Una solicitud de transferencia, con grupo, será considerada con las firmas de
                autorización notariadas de las cinco líneas ascendentes inmediatas, y las firmas de
                autorización notariadas de cada una de las líneas descendentes que deseen ser
                incluidas en la transferencia. Todas las transferencias de patrocinio requieren la
                aprobación final de TERRAMAR, cuya decisión tomará en cuenta el bien general de
                TERRAMAR. Se podrá cobrar una cuota administrativa por el cambio requerido en los
                registros corporativos.
              </p>

              <li className={styles.list}>Cancelación voluntaria</li>
              <p className={styles.span}>
                Un Consultor Independiente puede cancelar su Negocio Independiente de forma
                voluntaria, al no renovar cuando le corresponda o mediante una Debido a la gran
                importancia que existe en la relación entre un patrocinador de línea ascendente y un
                consultor de línea descendente, TERRAMAR no recomienda las solicitudes o intentos de
                transferencia de patrocinio. Sin embargo, en una situación extraordinaria, se
                considerará una solicitud de transferencia, sin grupo, sólo con las firmas de
                autorización notariadas de las cinco líneas ascendentes inmediatas notificación por
                escrito a TERRAMAR. Se considerará su cancelación efectiva en el momento que la
                notificación sea recibida en las oficinas centrales de TERRAMAR. Un Consultor
                Independiente que finalicé su Negocio Independiente podrá aplicar nuevamente una vez
                que hayan transcurrido seis meses a partir la fecha en que fue dado de baja.
              </p>

              <li className={styles.list}>Cancelación involuntaria</li>
              <p className={styles.span}>
                Un Consultor Independiente podrá ser dado de baja por violar los términos y
                condiciones de este Acuerdo. TERRAMAR notificará por escrito la cancelación del
                Negocio Independiente de un Consultor Independiente infractor, citando la evidencia
                de la violación. Si el/la Consultor(a) Independiente no provee una respuesta en un
                lapso de 15 días, la cancelación se hará efectiva tras la decisión final de
                TERRAMAR. La notificación de la decisión se enviará por correo certificado a la
                dirección registrada del Consultor Independiente infractor. En los casos en que la
                ley estatal correspondiente sea inconsistente con lo anterior, dicho procedimiento
                se modificará automáticamente para su cumplimiento. Un Consultor Independiente que
                se le haya cancelado involuntariamente no podrá presentar una solicitud de
                inscripción a TERRAMAR durante un plazo de un año a partir de la fecha efectiva de
                su cancelación.
              </p>

              <li className={styles.list}>Apelaciones</li>
              <p className={styles.span}>
                Un Consultor Independiente cuya cancelación fue involuntaria puede apelar la
                cancelación presentando una carta de apelación, indicando los motivos de esta. Esta
                carta deberá ser enviada por el servicio de correos de Estados Unidos o por un
                servicio de entrega privado con ACUSE DE RECIBO y deberá ser recibida por TERRAMAR
                en un plazo de 15 días a partir de la fecha de envío de la notificación de
                cancelación de la Compañía. Si la Compañía no recibe la carta de apelación en ese
                plazo, la cancelación involuntaria se aplicará automáticamente y será definitiva. Si
                un Consultor Independiente presenta su apelación de forma oportuna, TERRAMAR, a su
                discreción, revisará y reconsiderará la cancelación y notificará al Consultor
                Independiente de su decisión. La decisión de la Compañía será definitiva y no estará
                sujeta a ninguna otra revisión. Si se niega la apelación, la cancelación permanecerá
                en efecto acorde a la fecha de la notificación de cancelación original de la
                Compañía.
              </p>

              <li className={styles.list}>Efecto de la suspensión</li>
              <p className={styles.span}>
                En caso de que TERRAMAR considere necesario suspender a un Consultor Independiente,
                dicha suspensión podría significar que el/la Consultor(a) Independiente no tiene el
                derecho de representarse a sí mismo como un Consultor de TERRAMAR y que cualquier
                comisión o bonificaciones adeudadas serán retenidas por TERRAMAR hasta su solución.
                A criterio de la Compañía, los productos y servicios pueden ser adquiridos por un
                Consultor Independiente suspendido a precio de mayoreo. Sin embargo, si se determina
                una cancelación, los productos y servicios sólo podrán ser adquiridos a través de
                otro Consultor Independiente de TERRAMAR.
              </p>

              <li className={styles.list}>Efectos de la cancelación</li>
              <p className={styles.span}>
                En el caso de que se cancele a un Consultor Independiente, a partir de dicha baja
                el/la Consultor(a) Independiente no podrá seguir vendiendo productos y servicios de
                TERRAMAR, ni inscribir a otros Consultores Independientes. El/La Consultor(a)
                Independiente también pierde todos los derechos a las comisiones, bonos y a todos
                los demás beneficios.
              </p>

              <li className={styles.list}>Límites de la transferibilidad o venta. </li>
              <p className={styles.span}>
                Un Consultor Independiente puede vender, asignar o transferir su Negocio
                Independiente a un Consultor Independiente que no esté inscrito dentro de TERRAMAR,
                pero previamente se debe otorgar al patrocinador inmediato el derecho de
                preferencia. Ninguna venta, asignación o transferencia de la Consultoría
                Independiente será efectiva sin la previa aprobación por escrito de TERRAMAR, cuya
                aprobación no será retenida sin una razón. Si la Compañía determina, a su propia
                discreción, que el Negocio Independiente se transfirió con la intención de evadir el
                cumplimiento de este Acuerdo, la transferencia se anulará y no tendrá validez, la
                entidad del Consultor Independiente se regresará al Consultor Independiente que la
                transfirió, la cuál será tratada como si la transferencia jamás hubiera ocurrido a
                partir de la fecha de la revocación. Se podrá cobrar una tarifa administrativa por
                el cambio de los registros corporativos.
              </p>

              <li className={styles.list}>Relación con los Proveedores.</li>
              <p className={styles.span}>
                Ningún Consultor Independiente podrá contactar, ya sea de forma directa o indirecta,
                o hablar o comunicarse con algún representante de un proveedor o fabricante de
                TERRAMAR, a excepción cuando TERRAMAR lo disponga. La violación de esta regla podrá
                dar como resultado la finalización de los beneficios del Consultor Independiente y
                en posibles demandas por daños en caso de que la relación con el proveedor se vea
                afectada por el/la Consultor(a) Independiente.
              </p>

              <li className={styles.list}>Acuerdo de Confidencialidad. </li>
              <p className={styles.span}>
                La información contenida en cualquier reporte referente a la genealogía o línea
                descendentes entregado a un Consultor Independiente es propiedad confidencial de
                TERRAMAR, y se provee al Consultor Independiente con la absoluta confidencialidad.
                El/La Consultor(a) Independiente se compromete a no divulgar dicha información a
                ningún tercero de forma directa o indirecta, ni utilizará la información para
                competir con TERRAMAR o para cualquier otro propósito que no sea el de promocionar
                la oportunidad de ingresos de TERRAMAR. El/La Consultor(a) Independiente y TERRAMAR
                aceptan que, para este acuerdo de confidencialidad y no divulgación, TERRAMAR no
                proporcionará la información al Consultor Independiente. Cualquier Consultor
                Independiente que se le sorprenda violando esta regla se arriesga a ser suspendido o
                que se cancele su contrato. TERRAMAR se reserva el derecho a reclamar daños y
                perjuicios con todo el peso de la ley.
              </p>

              <li className={styles.list}>Sucesión</li>
              <p className={styles.span}>
                En caso del fallecimiento de un Consultor Independiente, su Negocio Independiente se
                heredará por testamento o sucesión intestada, según lo disponga la ley. TERRAMAR
                exigirá al sucesor interesado que firme y quede sujeto al Acuerdo de Consultor
                Independiente vigente en ese momento. El sucesor tendrá en ese momento todos los
                derechos y estará sujeto a todas las obligaciones, como cualquier otro Consultor
                Independiente de TERRAMAR.
              </p>

              <li className={styles.list}>Marcas Comerciales</li>
              <p className={styles.span}>
                El nombre de TERRAMAR y los nombres de todos los productos y servicios de TERRAMAR
                son marcas comerciales registradas o no registradas y propiedad de TERRAMAR. Sólo
                TERRAMAR está autorizado para producir y comercializar productos y literatura con
                dichas marcas. El uso del nombre TERRAMAR o cualquier artículo que no sea producido
                o autorizado por TERRAMAR está prohibido.
              </p>

              <li className={styles.list}>Cheques Impresos. </li>
              <p className={styles.span}>
                Los Consultores Independientes de TERRAMAR no están autorizados a utilizar el nombre
                comercial de TERRAMAR o cualquiera de sus marcas registradas en sus cuentas
                comerciales o personales. Sin embargo, los Consultores Independientes pueden
                imprimir sus cheques comerciales de TERRAMAR indicando que son “Consultores
                Independientes de productos TERRAMAR”.
              </p>

              <li className={styles.list}>Directorio Telefónico (Yellow and White Pages)</li>
              <p className={styles.span}>
                Los Consultores Independientes no están autorizados a utilizar el nombre comercial
                de TERRAMAR para anunciar sus números de teléfonos y fax en los directorios
                telefónicos (Yellow and White Pages).
              </p>

              <li className={styles.list}>Tarjetas de Presentación o Impresos Membretados</li>
              <p className={styles.span}>
                Los Consultores Independientes no están autorizados a incluir en sus propias
                tarjetas de presentación o impresos membretados cualquier nombre comercial o marca
                registrada de TERRAMAR. Sólo se permite la versión gráfica y la redacción aprobada
                de TERRAMAR, y el membrete deberá solicitarse directamente a TERRAMAR o a una
                entidad aprobada por TERRAMAR.
              </p>

              <li className={styles.list}>Literatura de la Empresa</li>
              <p className={styles.span}>
                Solo se puede utilizar la literatura oficial para presentar los productos, servicios
                y la oportunidad de ingresos de TERRAMAR. La literatura de la Compañía no podrá ser
                duplicada o reimpresa sin la previa autorización por escrito de TERRAMAR. Todo el
                material de cualquier naturaleza, incluyendo el electrónico, debe ser previamente
                aprobado por escrito por TERRAMAR.
              </p>

              <li className={styles.list}>Entrevistas con los medios de comunicación.</li>
              <p className={styles.span}>
                Se prohíbe a los Consultores Independientes a otorgar entrevistas en radio,
                televisión, periódicos o revistas, o hacer apariciones públicas, discursos o
                cualquier tipo de declaraciones en los medios de comunicación para promocionar a
                TERRAMAR, sus productos, servicios o su negocio individual de TERRAMAR, excepto con
                la previa autorización por escrito de TERRAMAR. Todos los contactos y solicitudes de
                los medios de comunicación deben ser coordinados a través de la aprobación y
                representación de un Representante Corporativo de TERRAMAR.
              </p>

              <li className={styles.list}>Política de Internet y sitios web.</li>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Ningún Consultor Independiente podrá diseñar independientemente una página o
                    sitio web que utilicé los nombres, logos, o descripción de los productos
                    TERRAMAR sin la autorización por escrito de TERRAMAR.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Ningún Consultor Independiente podrá utilizar anuncios “ciegos” en Internet
                    haciendo declaraciones de productos u oportunidades de ingresos que estén o
                    puedan ser asociados con TERRAMAR.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Ningún Consultor Independiente podrá en ninguna circunstancia utilizar el
                    Internet con la intención de hacer correos “masivos” o no solicitados,
                    generalmente conocido como “spamming”.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Ningún Consultor Independiente podrá violar ninguna ley local, estatal o federal
                    relacionado con el Internet o con cualquier código de ética generalmente
                    aceptado en el Internet.
                  </span>
                </li>
              </ol>

              <li className={styles.list}>Comunicación Independiente.</li>
              <p className={styles.span}>
                Los Consultores Independientes, como contratistas independientes, se les invita a
                distribuir información y dar dirección a sus respectivas líneas descendentes.
                TERRAMAR recomienda el uso de boletines informativos, talleres de entrenamientos y
                otros programas de la organización. Los Consultores Independientes deben identificar
                sus comunicaciones personales como tal, y no dar la apariencia de que dichas
                comunicaciones son las comunicaciones oficiales de TERRAMAR.
              </p>

              <li className={styles.list}>Entrenamiento a Consultores Independientes.</li>
              <p className={styles.span}>
                Los Consultores Independientes son los responsables de impartir entrenamientos a los
                Consultores Independientes que se inscriben. La ayuda para los entrenamientos se
                proporciona en la sección de administración de negocio en TERRAWEB y en materiales
                audiovisuales. Además, cada Consultor Independiente recibe publicaciones periódicas
                de TERRAMAR que incluyen entrenamientos continuos e información sobre productos y
                servicio. Además, los Consultores Independientes pueden asistir a talleres de
                entrenamiento locales y regionales para Consultores Independientes y participar en
                conferencias nacionales telefónicas de entrenamientos organizados por TERRAMAR.
              </p>

              <li className={styles.list}>Aprobaciones</li>
              <p className={styles.span}>
                No existe ningún tipo de aprobación, incluyendo el de los funcionarios de TERRAMAR,
                a excepción de lo que se comunique expresamente en la literatura de TERRAMAR o se
                apruebe por escrito.
              </p>

              <li className={styles.list}>Prohibido copiar. </li>
              <p className={styles.span}>
                Los Consultores Independientes no copiarán para su venta o uso personal el material
                grabado en audio o video que detalle la oportunidad de ingreso de TERRAMAR o las
                presentaciones de servicios, eventos, discursos, incluyendo conferencias telefónicas
                sin el permiso por escrito de TERRAMAR. TERRAMAR podrá solicitar medidas cautelares
                o daños y perjuicios al infractor por el uso no autorizado de los derechos de autor,
                marcas comerciales y materiales de TERRAMAR. Está estrictamente prohibido grabar en
                vídeo o audios las reuniones y conferencias de TERRAMAR. Se permite tomar
                fotografías a discreción del anfitrión de la reunión.
              </p>

              <li className={styles.list}>Contestación de llamadas telefónicas</li>
              <p className={styles.span}>
                Los Consultores Independientes no podrán contestar el teléfono diciendo “TERRAMAR”,
                o de alguna otra forma que haga creer a la persona que llama que él o ella se ha
                comunicado a las oficinas corporativas de TERRAMAR.
              </p>

              <li className={styles.list}>Prohibición de reempaquetado.</li>
              <p className={styles.span}>
                Los Consultores Independientes no podrán volver a empacar ningún producto, servicio
                o material de TERRAMAR de ninguna manera.
              </p>

              <li className={styles.list}>Solicitud y Acuerdo de Consultor Independiente.</li>
              <p className={styles.span}>
                Las comisiones no se pagarán hasta que TERRAMAR haya recibido y aceptado la
                Solicitud y Acuerdo del Consultor Independiente. Las comisiones se pagarán solo por
                la venta de productos y servicios de TERRAMAR. No se pagarán comisiones por la
                compra de un Kit de Inicio de un Consultor Independientes, los cuales se vende al
                costo de la Compañía.
              </p>

              <li className={styles.list}>Período de calendario.</li>
              <p className={styles.span}>
                Las comisiones, premios y reconocimientos se calculan en base al mes natural.
              </p>

              <li className={styles.list}>Fecha de pago de las comisiones.</li>
              <p className={styles.span}>
                Las comisiones se pagan mensualmente en o alrededor de los primeros 10 días del mes
                por las comisiones ganadas durante el mes calendario anterior.
              </p>

              <li className={styles.list}>Pago de comisiones.</li>
              <p className={styles.span}>
                Consulte el plan de compensaciones descrito en el Manual del Consultor.
              </p>

              <li className={styles.list}>Prohibido el almacenamiento.</li>
              <p className={styles.span}>
                El programa TERRAMAR depende de, y requiere, la venta al por menor al cliente final;
                por lo tanto, se desaconseja toda forma de almacenamiento o acumulación de
                productos. TERRAMAR reconoce que el/la Consultor(a) Independiente desea comprar
                ciertos productos en cantidades razonables para su propio uso o para la
                disponibilidad de nuevos Consultores Independientes que se inscriban. Sin embargo,
                TERRAMAR prohíbe la compra de producto en cantidades no razonables cuyo fin sea el
                de calificar para el ascenso en el plan de compensación.
              </p>

              <li className={styles.list}>
                Calificación del volumen de ventas por toma de pedidos.
              </li>
              <p className={styles.span}>
                La Compañía ha establecido unos requisitos mínimos de volumen comercial personal y
                de grupo. Los requisitos de volumen comercial mínimo también pueden cumplirse
                mediante pedidos de clientes final, lo cuales serán suministrados por la Compañía
                directamente al cliente final.
              </p>

              <li className={styles.list}>Costo de envío.</li>
              <p className={styles.span}>
                Los Consultores Independientes tienen la responsabilidad de indicar la dirección del
                destino. Los métodos de envío disponibles se indican en cada formulario de pedido y
                costo de prepago, si se selecciona, se puede calcular mediante la información del
                costo del envío y manejo que también se proporciona. NOTA: En caso de que el
                destinatario rechace la entrega y el envío sea devuelto a TERRAMAR, el estatus de
                Consultor Independiente solicitante quedara “inactivo” hasta que se resuelva la
                situación del rechazo de la entrega. Los cargos de envío por el rechazo se cargarán
                a la cuenta del Consultor Independiente.
              </p>

              <li className={styles.list}>Mercancía dañada.</li>
              <p className={styles.span}>
                La empresa transportista será responsable de los daños que se produzcan una vez que
                haya recibido custodia física de la mercancía. Un Consultor Independiente que reciba
                la mercancía dañada deberá seguir el siguiente procedimiento:
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>Aceptar la entrega.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Antes de que el conductor se vaya, indicar en el recibo de entrega la cantidad
                    de cajas que parecen estar dañadas.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Guardar los productos dañados o cajas, para que los inspeccione el
                    transportista.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Hacer una cita con la empresa transportista para que inspeccione los productos
                    dañados.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Realice una reclamación con la empresa de transporte.
                  </span>
                </li>
              </ol>

              <li className={styles.list}>Recibos y precios de venta sugeridos.</li>
              <p className={styles.span}>
                TERRAMAR proporcionará a todos los clientes finales de productos TERRAMAR recibos
                por escrito. Aunque TERRAMAR indica un precio sugerido de venta como guía, los
                Consultores Independientes pueden vender los productos al precio de venta al público
                que ellos elijan.
              </p>

              <li className={styles.list}>Impuesto sobre las ventas.</li>
              <p className={styles.span}>
                Para las compras realizadas a la Compañía, TERRAMAR recauda y remite el impuesto
                estatal aplicable que puede ser exigido sobre el precio de venta sugerido de
                aquellos productos y/o materiales que están sujetos a impuestos. La tasa de impuesto
                a pagar se calcula en función de la dirección a la que se envía el producto y/o los
                materiales. Los Consultores Independientes que soliciten una compra libre de
                impuestos para la reventa de TERRAMAR (no se permite en todos los estados) deben
                proporcionar a la Compañía una copia de su certificado de exención de reventa válido
                y actual que muestre un número de impuesto de reventa. Este número debe aparecer en
                todos los pedidos realizados a TERRAMAR. El/La Consultor(a) Independiente debe
                entonces recolectar el impuesto de su cliente final y remitirlo a la autoridad
                fiscal estatal y local correspondiente. Como Consultor Independiente está de acuerdo
                en cumplir con las reglas y procedimientos establecidos en los acuerdos de
                recaudación de impuestos sobre las ventas que la Compañía pueda establecer con los
                distintos estados y jurisdicciones locales.
              </p>

              <li className={styles.list}>Puntos de Venta.</li>
              <p className={styles.span}>
                Los productos TERRAMAR no se pueden vender a o en, o ser exhibidos por cualquier
                punto de venta, incluyendo, pero no limitado a, establecimientos comerciales,
                mercados locales, reuniones de intercambio, puestos de centros comerciales o
                restaurantes. Las excepciones requieren de una autorización previa por escrito.
              </p>

              <li className={styles.list}>Establecimientos orientados al servicio.</li>
              <p className={styles.span}>
                Se permite tomar pedidos de productos y servicios TERRAMAR en negocios como oficinas
                de servicio profesionales, establecimientos “de cita previa” o establecimientos
                similares no minoristas.
              </p>

              <li className={styles.list}>Cambio de precios.</li>
              <p className={styles.span}>
                Todos los productos y literatura de TERRAMAR están sujetos a cambios sin previo
                aviso.
              </p>

              <li className={styles.list}>Devoluciones de Clientes Finales.</li>
              <p className={styles.span}>
                TERRAMAR ofrece una garantía del 100% de reembolso en los productos vendidos a todos
                los clientes finales. Todos los Consultores Independientes de TERRAMAR deben
                respetar esta garantía. Si por alguna razón un cliente final no está satisfecho con
                alguno de los productos TERRAMAR, ese cliente final puede devolver ese producto al
                Consultor Independiente al que se lo compró, dentro de los primeros 30 días, ya sea
                para obtener un reemplazo o un reembolso completo del precio de compra.
              </p>

              <p className={styles.span}>
                TERRAMAR reemplazará el producto devuelto, siempre y cuando se cumplan los
                siguientes requisitos y condiciones:
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    El producto es devuelto a TERRAMAR por el/la Consultor(a) Independiente a través
                    del cual realizó la compra.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    El producto debe recibirse en TERRAMAR dentro de los diez días siguientes a la
                    fecha de devolución al Consultor Independiente.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    La devolución debe estar acompañada por una declaración por parte del cliente
                    mencionando el motivo de la devolución.
                  </span>
                </li>
                <ol>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Una declaración firmada por el cliente final identificando la razón de la
                      devolución,
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      Una copia del recibo original de la compra, y La parte no utilizada del
                      producto en su envase original.
                    </span>
                  </li>

                  <li className={styles.list}>
                    <span className={styles.span}>
                      El nombre, dirección y número telefónico del cliente final.
                    </span>
                  </li>
                </ol>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Se debe utilizar una caja de cartón y material de embalaje adecuado para empacar
                    los productos que se devuelven para su reemplazo, y se recomiendan utilizar el
                    medio de envío más económico.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    TERRAMAR pagará por adelantado el costo de envío del reemplazo.
                  </span>
                </li>
              </ol>
              <p className={styles.span}>
                TERRAMAR no reembolsará a ningún Consultor Independiente, el precio de compra de las
                devoluciones de los clientes finales y no se realizará la sustitución del producto
                si no se cumplen las condiciones de esta Norma.
              </p>

              <li className={styles.list}>Control de Calidad.</li>
              <p className={styles.span}>
                TERRAMAR reemplazará, dentro de los siguientes 30 días de la compra, cualquier
                producto que resulte defectuoso. Sin embargo, ningún producto debe devolverse a
                TERRAMAR sin recibir una aprobación previa. Se requiere que se ponga en contacto con
                TERRAMAR al teléfono 01 800 313 0903 o por correo electrónico a
                <span>
                  <a href='mailto:customerservices@tmbrandsusa.com' className={styles.link}>
                    &nbsp;customerservices@tmbrandsusa.com&nbsp;
                  </a>
                </span>
                y asegúrese de cumplir rigurosamente con lo siguiente:
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Se debe emitir por escrito una requisición de reemplazo, indicando el motivo y
                    acompañada por el comprobante de pago y una copia de la hoja de la orden de
                    compra del pedido o el comprobante de envío.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    TERRAMAR indicará al Consultor Independiente la dirección a dónde enviar el
                    producto para ser inspeccionado e inventariado, también proporcionará la
                    cantidad correspondiente de etiquetas de envío para la devolución autorizada del
                    producto. Una vez recibido e inspeccionado el producto, TERRAMAR enviará el
                    producto de reemplazo correspondiente.
                  </span>
                </li>
              </ol>
              <p className={styles.span}>
                NOTA: Cualquier devolución recibida sin la previa autorización tendrá como
                consecuencia que se ponga el estatus del Consultor Independiente como “inactivo”.
                Además, no hay garantía de que se considere el producto como reemplazable.
              </p>

              <li className={styles.list}>Devoluciones por Cancelación.</li>
              <p className={styles.span}>
                Ante la cancelación/recesión, si el/la Consultor(a) Independiente ha comprado
                producto con el propósito de inventario o ayudas de ventas obligatorios mientras el
                acuerdo de Consultor estaba vigor, todos los productos que posea el/la Consultora
                que estén en condiciones hábiles para reventa y en posesión de el/la Consultora y
                que haya sido comprado dentro de los 12 meses previos a la cancelación de su
                contrato, pueden ofrecerse para su recompra. La recompra se hará a un precio que no
                sea inferior al 90% del costo original neto para el participante. Además, la
                Compañía cumplirá con los requisitos legales de recompra de cada jurisdicción.
                TERRAMAR también recomprará cualquier material de venta y el Kit de inicio de
                TERRAMAR dentro del plazo de un año desde el momento de la compra, siempre y cuando
                los materiales estén vigentes y en condiciones para ser vendidos.
              </p>
              <ol type='a'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Se debe presentar una requisición por escrito y acompañada del comprobante de
                    pago y una copia de la orden de compra.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    TERRAMAR indicará al Consultor Independiente la dirección a dónde enviar el
                    producto para ser inspeccionado e inventariado, y también le proporcionará al
                    Consultor Independiente la cantidad correspondiente de etiquetas de envío para
                    la devolución autorizada del producto. Una vez recibido e inspeccionado la
                    devolución, TERRAMAR procesará el reembolso correspondiente para su pago.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    El/la Consultor(a) Independiente deberá pagar el costo del flete de los
                    retornos.
                  </span>
                </li>
              </ol>
              <p className={styles.span}>
                NOTA: Los productos o materiales de venta que no estén en condiciones adecuadas para
                ser revendidos serán devueltos con cargo al Consultor Independiente.
              </p>

              <li className={styles.list}>Derecho de cancelación del Cliente final.</li>
              <p className={styles.span}>
                La ley federal permite al cliente final a cancelar ciertas ventas sin ninguna
                penalización siempre y cuando sean antes de medianoche del tercer día hábil después
                de la transacción. Esta norma cubre a las ventas del cliente final de $25 dólares o
                más que se realicen fuera de la oficina principal del// la Consultor Independiente.
                El formulario de la orden de compra de TERRAMAR contiene todos los avisos legalmente
                requeridos. Además, el/la Consultor Independiente debe informar verbalmente al
                cliente final sobre el derecho de cancelación de tres días en el momento en que el
                cliente final firme el contrato de venta o adquiera la mercancía. Con respecto a los
                productos, en cualquier caso donde el cliente final, a su entera decisión, considere
                más favorable la Política de Devoluciones del Cliente Final, mencionada
                anteriormente, se aplicará dicha política y prevalecerá sobre la presente. Con
                respecto a los servicios, al finalizar el periodo de tres días que se indica en el
                presente documento, todos los servicios de venta son definitivos y no se puede
                devolver.
              </p>

              <li className={styles.list}>Responsabilidad del Consultor Independiente.</li>
              <p className={styles.span}>
                Si un cliente final envía por correo o entrega a un Consultor Independiente una
                notificación válida de cancelación antes del tercer día hábil después de ordenar o
                comprar el programa, servicio, y/o producto, deberá ser honrada por el/la
                Consultor(a) Independiente. Si el cliente ha recibido el producto, este deberá de
                ser devuelto junto con la notificación y en las mismas condiciones con la que fue
                entregado. Con respecto al servicio, los materiales envueltos en plástico que
                describen y explican los servicios, deben ser devueltos sin abrir. El/La
                Consultor(a) Independiente deberá reembolsar todos los pagos realizados conforme al
                contrato de venta, dentro de los siguientes diez días hábiles posteriores a la
                recepción de la notificación.
              </p>

              <li className={styles.list}>Responsabilidad.</li>
              <p className={styles.span}>
                En la medida de lo permitido por la ley, TERRAMAR no será responsable si un
                Consultor Independiente libera a TERRAMAR de, y renuncia a cualquier reclamación por
                pérdida de ganancias, indirectos, directos, daños especiales o consecuentes o
                cualquier pérdida incurrida o sufrida por el/la Consultor(a) Independiente como
                resultado de (a) el incumplimiento del Acuerdo de Consultor Independiente, (b) la
                operación del Debe de estar firmado por el cliente final y se debe de entregar dos
                copias al comprador en cada venta. negocio de un Consultor Independiente, (c)
                cualquier información incorrecta o errónea proporcionada por el/la Consultor(a)
                Independiente, o (d) la falta de información o datos necesarios para que TERRAMAR
                maneje su negocio, incluyendo pero no limitado a, la inscripción y aceptación de un
                Consultor Independiente a la oportunidad de ingresos o el pago de comisiones y
                bonos.
              </p>

              <li className={styles.list}>Almacenamiento de Registros.</li>
              <p className={styles.span}>
                TERRAMAR recomienda a sus Consultores Independientes mantener registros completos y
                precisos de todos los asuntos relacionado a su negocio. Una publicación recomendada
                es la publicación 911 del IRS para vendedores directos, disponible en cualquier
                oficina del IRS, o en el sitio web del IRS.
              </p>

              <li className={styles.list}>Declaraciones de ingresos.</li>
              <p className={styles.span}>
                No se pueden hacer declaraciones de ingresos, proyecciones de ingresos, ni
                representaciones de ingresos a un potencial Consultor Independiente. Obviamente, se
                prohíbe cualquier afirmación falsa, engañosa o equivoca relacionado a la
                oportunidad, o al producto/servicio. Ocasionalmente los Consultores Independientes
                presentan cantidades de ingresos hipotéticos basados en el poder de la red de
                mercadeo como proyecciones de ingresos reales. Esto es contraproducente y está
                prohibido, ya que los nuevos Consultores Independientes pueden decepcionarse
                rápidamente si sus resultados no son tan rápidos como el modelo hipotético.
              </p>

              <li className={styles.list}>Respaldo gubernamental.</li>
              <p className={styles.span}>
                Las agencias reguladoras federales y estatales no aprueban ni respaldan los
                programas de venta directa. Por lo tanto, los Consultores Independientes no pueden
                representar o implicar, directa o indirectamente, que el programa TERRAMAR ha sido
                aprobado o respaldado por cualquier agencia gubernamental.
              </p>

              <li className={styles.list}>Modificaciones.</li>
              <p className={styles.span}>
                TERRAMAR se reserva el derecho de modificar este Acuerdo, sus precios,
                disponibilidad de producto y plan de compensación cuando lo considere oportuno. Las
                modificaciones serán vigentes y obligatorias para todos los Consultores
                Independientes a partir de la fecha de su emisión.
              </p>

              <li className={styles.list}>Disposición de no renuncia.</li>
              <p className={styles.span}>
                El hecho de que TERRAMAR no ejercite ninguna de las acciones prevista en este
                Acuerdo o no exija el estricto cumplimiento de cualquier obligación o estipulación
                contenida en el mismo por parte de un Consultor Independiente, así como ninguna
                costumbre o práctica de las partes que difiera del presente Acuerdo, constituirá una
                renuncia al derecho de la Compañía a exigir el cumplimiento exacto de este Acuerdo.
                La renuncia por parte de la Compañía sólo puede hacerse por escrito por un
                funcionario autorizado de la Compañía.
              </p>
              <p className={styles.span}>
                La exención por parte de la Compañía de cualquier incumplimiento concreto por parte
                de un Consultor Independiente no afectará, ni perjudicará los derechos de la
                Compañía respecto a cualquier incumplimiento posterior, ni afectará en forma alguna
                los derechos u obligaciones de cualquier otro Consultor Independiente. Tampoco
                cualquier retraso u omisión por parte de la Compañía de ejercer cualquier derecho
                derivado del incumplimiento afectará o perjudicará los derechos de la Compañía con
                respecto a ese o cualquier otro incumplimiento posterior.
              </p>

              <li className={styles.list}>Divisibilidad.</li>
              <p className={styles.span}>
                Si, conforme a cualquier ley o norma de cualquier jurisdicción pertinente y
                vinculante a cualquier disposición del Acuerdo, incluidas en estas normas y
                reglamentos, o cualquier especificación, procedimiento estándar u operativo que
                TERRAMAR haya establecido se considera invalido o no ejecutable, TERRAMAR tendrá el
                derecho de modificar la disposición, especificación estándar o procedimiento
                operacional inválido o no ejecutable o cualquier parte de la misma en la medida que
                sea necesaria para que sea válida y ejecutable. El/La Consultor(a) Independiente
                estará obligado a cumplir con dicha modificación. La modificación será válida sólo
                en la jurisdicción en la que se requiera.
              </p>

              <li className={styles.list}>Arbitraje.</li>
              <ol type='A'>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Todas las disputas y reclamaciones relacionadas con TERRAMAR, sus productos, el
                    Acuerdo de Consultor o el Plan de Compensación de TERRAMAR, incluyendo pero no
                    limitado a (1) los derechos y obligaciones del Consultor y TERRAMAR, (2)
                    cualquier otro reclamo o causa de acción relacionada con el desempeño del
                    Consultor o TERRAMAR bajo el Acuerdo del Consultor o las Reglas y Reglamentos
                    y/o (3) La compra de productos de TERRAMAR por parte del Consultor se resolverá
                    total y definitivamente mediante un arbitraje en Miami, Florida o en cualquier
                    otro lugar que TERRAMAR determine, de acuerdo con la Ley Federal de Arbitraje y
                    las Reglas de Arbitraje Comercial de la Asociación Americana de Arbitraje. Los
                    residentes de Luisana podrán arbitrar en Nueva Orleans. Habrá un mediador, un
                    abogado, que deberá tener experiencia en transacciones de derecho comercial
                    preferentemente que sea un abogado con conocimientos en la industria de la venta
                    directa, seleccionado por el panel que proporciona la Asociación Americana de
                    Arbitraje. Cada una de las partes será responsable de sus costos y gastos de
                    arbitraje, incluyendo los honorarios legales y de registro. Si el/la
                    Consultor(a) presenta una reclamación o una contrademanda contra TERRAMAR, el/la
                    Consultor(a) lo hará de forma individual y no con otro Consultor o como parte de
                    una acción colectiva. La decisión del mediador será definitiva y vinculante para
                    las partes y, si fuera necesario, reducida a un resolución en cualquier tribunal
                    de jurisdicción competente. Este acuerdo de arbitraje sobrevivirá a cualquier
                    finalización o vencimiento del Acuerdo de Consultor.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Sin perjuicio a lo anterior, el mediador no tendrá jurisdicción sobre las
                    disputas relacionadas con la propiedad, validez o registro de cualquier marca o
                    propiedad intelectual o información confidencial de TERRAMAR sin el
                    consentimiento previo por escrito de TERRAMAR. TERRAMAR buscará todos los
                    recursos aplicables en cualquier foro correspondiente con respecto a estas
                    disputas y con respecto al dinero que se le deba a TERRAMAR. Además de los daños
                    monetarios, TERRAMAR podrá obtener medidas cautelares contra el/la Consultor(a)
                    por cualquier violación o uso indebido del Acuerdo del Consultor y por violar o
                    utilizar de forma indebida las políticas de marca registrada, derechos de autor
                    o información confidencial de TERRAMAR.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Nada de lo dispuesto en esta regla impedirá que TERRAMAR solicite y obtenga de
                    cualquier tribunal con jurisdicción una orden de embargo, una orden judicial
                    temporal, una orden judicial preliminar y/u otras medidas cautelares o
                    emergentes disponibles para salvaguardar y proteger los intereses de TERRAMAR
                    antes de presentar o durante o después de cualquier arbitraje u otro
                    procedimiento o hasta que se dicte la decisión o sentencia relacionado a
                    cualquier arbitraje u otro procedimiento.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>
                    La existencia de alguna reclamación o causa de acción del Consultor contra
                    TERRAMAR, ya sea basada en el Acuerdo de Consultor o de otra manera, no
                    constituirá una defensa para el cumplimiento por parte de TERRAMAR de los pactos
                    y acuerdos del Consultor contenidos en el Acuerdo de Consultor o en las Normas.
                  </span>
                </li>
              </ol>

              <li className={styles.list}>Jurisdicción.</li>
              <p className={styles.span}>
                Todas las disputas y reclamos relacionados con TERRAMAR, este Acuerdo, el plan de
                compensaciones de TERRAMAR, o sus productos, los derechos y obligaciones de un
                Consultor Independiente y TERRAMAR, o cualquier otro reclamo o causa de acción
                relacionada con el desempeño de un Consultor o de TERRAMAR bajo el Acuerdo, o las
                reglas y regulaciones, y/o la compra de productos por parte de un Consultor
                Independiente serán gobernados por la leyes de Florida y serán adjudicados total y
                finalmente en Miami, Florida, o en cualquier otro lugar que TERRAMAR establezca.
                Sólo para residentes de Luisiana: En caso de disputa a efectos jurisdiccionales, un
                Consultor Independiente tendrá derecho a presentar una reclamación o demanda
                judicial en la jurisdicción de Luisiana y la ley que rige es la de Luisiana.
              </p>

              <li className={styles.list}>Límite de daños y perjuicios.</li>
              <p className={styles.span}>
                En la medida en que lo permita la ley, TERRAMAR y sus filiales, funcionarios,
                directores, empleados y otros representantes no serán responsables, y el/la
                Consultor(a) Independiente por este medio libera a los anteriores de y renuncia a
                cualquier reclamo por pérdida de ganancias, daños incidentales, especiales,
                consecuentes o ejemplares que puedan surgir de cualquier reclamo relacionado con el
                desempeño de, incumplimiento, acto u omisión de TERRAMAR con respecto a la relación
                comercial u otros asuntos entre el/la Consultor(a) Independiente y TERRAMAR, ya sea
                que estén basados en un contrato, agravio o responsabilidad estricta. Además, se
                acuerda que cualquier daño a un Consultor Independiente no excederá y queda
                expresamente limitado a, la cantidad de productos y servicios no vendidos de
                TERRAMAR que sean propiedad o estén en posesión del Consultor Independiente y a las
                comisiones adeudadas.
              </p>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PolicesAndProceduresESP;
