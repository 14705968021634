import './digital.css';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';

import styles from './index.module.sass';

export default function ProductImageZoom({
  screenSize,
  language,
  image_es,
  image_en,
  digitalImgs,
  zoomDisabled,
  open,
  zoomScale,
}) {
  const divEl = useRef(null);
  const [thisValue, setThisValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });
  const [thisImage, setThisImage] = useState(0);

  const onImgLoad = ({ target: img }) => {
    const { offsetWidth } = img;
    setThisValue({
      scale: 1,
      translation: { x: divEl.current.offsetWidth / 2 - offsetWidth / 2, y: 0 },
    });
    setThisImage(offsetWidth);
  };

  const newScale = useCallback(() => {
    setThisValue((oldValue) => {
      return {
        ...oldValue,
        scale: zoomScale,
      };
    });
  }, [zoomScale, setThisValue]);

  const setNewValue = useCallback(() => {
    setThisValue({
      scale: 1,
      translation: { x: divEl.current.offsetWidth / 2 - thisImage / 2, y: 0 },
    });
  }, [thisImage]);

  useEffect(() => {
    setTimeout(() => {
      setNewValue();
    }, 300);
  }, [zoomDisabled, open, setNewValue]);

  useEffect(() => {
    newScale();
  }, [zoomScale, newScale]);

  return (
    <div className={styles.slideContainer}>
      <div className={styles.sliderContent}>
        <div className={styles.imageContainer} ref={divEl}>
          <MapInteractionCSS
            value={thisValue}
            onChange={(value) => setThisValue(value)}
            minScale={1}
            maxScale={5}
            disableZoom={zoomDisabled}
            disablePan={zoomDisabled}>
            <img
              src={`${digitalImgs}${(language.includes('es') && image_es) || image_en}`}
              alt=''
              className={styles.pageImage}
              onLoad={onImgLoad}
            />
          </MapInteractionCSS>
        </div>
      </div>
    </div>
  );
}
