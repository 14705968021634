import superagent from 'superagent';
import webservice from './webservice';

export default async function getLideres(cp) {
  let response = {};
  const url = `${webservice}/getSepomex?cp=${cp}`;

  await superagent
    .get(url)
    .set('Content-Type', 'application/json')
    .then((res) => {
      response = res?.body || {};
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
      };
      response = resp;
    });

  return response;
}
