import React from 'react';
import styles from '../../index.module.sass';

const PrivacyUSAEsp = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Privacy</div>
          <div className={styles.subtitle}>policy</div>
        </div>
        <div className={styles.paragraph}>
          <ol>
            <li className={styles.list}>Introduction.</li>
            <ol>
              <li className={styles.list}>
                <span> Effective Date.</span>
              </li>
              <p className={styles.paragraph}>
                The effective date of this Privacy Policy is December 9, 2016.
              </p>

              <li className={styles.list}>
                <span>Purpose.</span>
              </li>
              <p className={styles.paragraph}>
                Terramar (TBL or Company) is committed to respecting the privacy rights of users of
                the TBL Website and any software, plug-ins or mobile applications made available by
                TBL (together, the "Website"). TBL has published this "Privacy Policy" to show its
                commitment to fair information practices and to the protection of privacy.
              </p>

              <li className={styles.list}>
                <span> Scope.</span>
              </li>
              <p className={styles.paragraph}>
                This Privacy Policy is only applicable to the Website, and not to any websites or
                services of third parties ("Third Party Websites"), which may have data collection,
                storage, and use practices and policies that differ materially from this Privacy
                Policy. For additional information, see Section 12 (Third Party Websites) and
                Section 4.5 (Third Party Cookies and Advertising).
              </p>

              <li className={styles.list}>
                <span> No Permission Without Agreement..</span>
              </li>
              <p className={styles.paragraph}>
                By using the Website, you represent and warrant that you have read and understood,
                and agree to the terms of, this Privacy Policy. If you do not understand or do not
                agree to be bound by this Privacy Policy, you must immediately cease using or
                accessing the Website.
              </p>
            </ol>

            <li className={styles.list}>Updates and Changes to Privacy Policy.</li>
            <ol>
              <li className={styles.list}>
                <span className={styles.span}>
                  Each time you access the Website, the then-current version of this Privacy Policy
                  will apply. Each time you use the Website, we recommend that you check the date of
                  this Privacy Policy in Section 1.1 (Effective Date) and review any changes since
                  the last time you used the Website.
                </span>
              </li>

              <li className={styles.list}>
                <span className={styles.span}>
                  Although most changes are likely to be minor, TBL reserves the right, at any time
                  and without notice, to add to, update, change, or modify this Privacy Policy,
                  simply by posting a new version on this page. Any such addition, update, or change
                  will be effective immediately on posting. Unless TBL obtains your express consent,
                  any revised Privacy Policy will apply only to information collected after the
                  effective date of the revised Privacy Policy, and not to information collected
                  under any earlier Privacy Policy.
                </span>
              </li>
            </ol>

            <li className={styles.list}>Children's Privacy.</li>

            <p className={styles.paragraph}>
              TBL does not solicit or knowingly collect Personal Information from children under the
              age of 13. If TBL obtains actual knowledge that it has collected Personal Information
              from a child under the age of 13, TBL will immediately delete such information from
              its database. Because TBL does not collect Personal Information from children under
              the age of 13, TBL has no such information to use or disclose to third parties. TBL
              has designed this Privacy Policy to comply with the Children's Online Privacy
              Protection Act ("COPPA").
            </p>

            <li className={styles.list}>Information Collection Practices.</li>

            <ol>
              <li className={styles.list}>Traffic Data.</li>
              <p className={styles.paragraph}>
                Each time you access the Website, the then-current version of this Privacy Policy
                will apply. Each time you use the Website, we recommend that you check the date of
                this Privacy Policy in Section 1.1 (Effective Date) and review any changes since the
                last time you used the Website.
              </p>

              <li className={styles.list}>Cookie Policy.</li>
              <p className={styles.paragraph}>
                "Cookies" are text files that are placed on your computer by websites that you visit
                or certain emails that you open. Cookies store a string of information on a user's
                computer or mobile device, which the user's web browser provides to the Website
                automatically each time it interacts with the Website. The purpose of Cookies is to
                identify the user as a unique user of the Website. TBL uses two types of Cookies:
                "first-party cookies" and "third-party cookies."
              </p>

              <li className={styles.list}>First Party Cookies.</li>
              <p className={styles.paragraph}>
                TBL uses first-party Cookies on the Website, for example the Google Analytics
                Cookie, for the following purposes: (1) to gather visitor and access information
                relevant to customizing the Website to your interests; and (2) to store a session
                token, which is encrypted and is used to keep your session active for a certain
                period of time to avoid entering your username and password every time you log in to
                the Website.
              </p>

              <li className={styles.list}>
                <span className={styles.span}>
                  If you do not wish to have first-party Cookies placed on your computer or mobile
                  device, you should set your web browser to refuse Cookies before accessing the
                  Website. However, certain features of the Website may not function properly
                  without the aid of Cookies. If you refuse Cookies, you assume all responsibility
                  for any resulting loss of functionality.
                </span>
              </li>

              <li className={styles.list}>Third Party Cookies and Advertising.</li>
              <ol>
                <li className={styles.list}>Advertising on Third Party Websites.</li>
                <p className={styles.paragraph}>
                  TBL may advertise on other Third Party Websites to you after you access the
                  Website. We and third-party marketing partners, including without limitation
                  Google, Inc. ("Google"), may use first party Cookies (such as the Google Analytics
                  cookie), third party Cookies (such as the DoubleClick cookie), and web beacons
                  (such as the Facebook conversion tracking pixel, see
                  <span>
                    <a
                      href='https://www.facebook.com/business/a/online-sales/conversion-tracking'
                      className={styles.link}
                      rel='noopener noreferrer'
                      target='_blank'>
                      &nbsp;https://www.facebook.com/business/a/online-sales/conversion-tracking&nbsp;
                    </a>
                  </span>
                  ), including across multiple devices, to inform, optimize, and serve
                  advertisements to you based on your use of the Website. This practice is commonly
                  known as retargeting or remarketing (see
                  <span>
                    <a
                      href='https://en.wikipedia.org/wiki/Behavioral_retargeting'
                      className={styles.link}
                      rel='noopener noreferrer'
                      target='_blank'>
                      &nbsp;https://en.wikipedia.org/wiki/Behavioral_retargeting&nbsp;
                    </a>
                  </span>
                  ).
                </p>

                <li className={styles.list}>Google Analytics.</li>
                <ol>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Google Analytics is Google's analytics tool that helps website and app owners
                      to understand how their visitors engage with their properties. It may use a
                      set of cookies to collect information and report website usage statistics
                      without personally identifying individual visitors to Google. The main cookie
                      used by Google Analytics is the "__ga" cookie.
                    </span>
                  </li>
                </ol>

                <p className={styles.paragraphTitle}>Firebase</p>
                <p className={styles.paragraph}>
                  Firebase is a platform designed and created by Google.
                </p>
                <p className={styles.paragraph}>
                  Firebase libraries are used in our mobile app, mainly for performance monitoring
                  and some real-
                </p>

                <ul>
                  <li className={styles.list}>Firebase Messaging</li>
                  <p className={styles.paragraph}>
                    It allows to receive notifications in the app and to segment users by topics,
                    for example, to group them by application versions.
                  </p>

                  <li className={styles.list}>Firebase Crashlytics</li>
                  <p className={styles.paragraph}>
                    It allows to monitor the errors that might exist in the app and it provides a
                    report of the events that triggered the error. With this information we can
                    provide maintenance to the app and improve its stability
                  </p>

                  <li className={styles.list}>Firebase Core</li>
                  <p className={styles.paragraph}>
                    It’s the basic library that connects the platform (Firebase) an had the default
                    analytics, such as type of device, sessions, locations, etc. This provides a
                    general overview of the app usage.
                  </p>
                </ul>

                <li className={styles.list}>
                  <span className={styles.span}>
                    Data Collection by Third Parties. Any of these third parties may place Cookies
                    on your computer or mobile device, use web beacons (see
                    <span>
                      <a
                        href='https://en.wikipedia.org/wiki/Web_beacon'
                        className={styles.link}
                        rel='noopener noreferrer'
                        target='_blank'>
                        &nbsp;https://en.wikipedia.org/wiki/Web_beacon&nbsp;
                      </a>
                    </span>
                    ), and gather IP addresses and log data to collect traffic and activity data to
                    deliver relevant metrics and content.
                  </span>
                </li>

                <li className={styles.list}>
                  <span className={styles.span}>Opting Out of Third Party Cookies.</span>
                  <ol>
                    <li className={styles.list}>
                      <span className={styles.span}>
                        You may customize your Google Cookies preferences or opt out of using Google
                        Cookies on the Google Ads Settings page at
                        <span>
                          <a
                            href='http://www.google.com/settings/ads'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;http://www.google.com/settings/ads.&nbsp;
                          </a>
                        </span>
                      </span>
                    </li>
                    <li className={styles.list}>
                      <span className={styles.span}>
                        You may learn about Facebook Cookies and how to customize your preferences
                        at
                        <span>
                          <a
                            href='https://www.facebook.com/about/ads'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;https://www.facebook.com/about/ads.&nbsp;
                          </a>
                        </span>
                      </span>
                    </li>
                    <li className={styles.list}>
                      <span className={styles.span}>
                        You may opt out of using other third parties' Cookies by visiting the
                        Network Advertising Initiative opt-out page at
                        <span>
                          <a
                            href='http://www.networkadvertising.org/managing/opt_out.asp'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;http://www.networkadvertising.org/managing/opt_out.asp.&nbsp;
                          </a>
                        </span>
                        You can also learn more about how to delete or control Cookies at
                        <span>
                          <a
                            href='http://www.aboutcookies.org/'
                            className={styles.link}
                            rel='noopener noreferrer'
                            target='_blank'>
                            &nbsp;http://www.aboutcookies.org/.&nbsp;
                          </a>
                        </span>
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>

              <li className={styles.list}>Personally Identifiable Information.</li>
              <p className={styles.paragraph}>
                To use certain features of the Website, you may be asked to provide TBL certain
                personally identifiable information ("Personal Information"). Personal Information
                includes the following categories, without limitation: (1) "Contact Data" (such as
                your name, phone number, fax number, mailing address and email address); (2)
                "Financial Data" (such as your credit/debit card number and expiration date); (3)
                "Demographic Data" (such as your gender and ZIP code); and (4) "Third Party Social
                Media Account Data" (such as your account information for third party social media
                accounts when you access the Website in connection to those accounts). In each case,
                you will be asked to provide Personal Information; the Website will not gather it
                secretly. TBL may supplement the Personal Information you provide with additional
                Personal Information gathered from public sources or from third parties (e.g.,
                consumer reporting agencies) who may lawfully provide that information to TBL. You
                are under no obligation to provide Personal Information, with the caveat that your
                refusal to do so may prevent you from using certain features of the Website.
              </p>

              <li className={styles.list}>Specific Information Collected.</li>
              <p>TBL collects the following specific items of Personal Information:</p>
              <ol>
                <li className={styles.list}>
                  <span className={styles.span}>Your first and last name</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Your address</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Your email address</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Your telephone number</span>
                </li>
              </ol>

              <li className={styles.list}>Information You Make Public.</li>

              <p className={styles.paragraph}>
                The Website may contain features (e.g., instant messages, electronic messaging,
                forums, etc.) that allow you to upload, post, transmit, display, perform, or
                distribute content, or other information, including your Personal Information and/or
                health information. Any information that you choose to disclose through such
                features becomes public information over which TBL is unable to exercise control.
                You should exercise caution when deciding to disclose your Personal Information
                through such features, and you agree to assume all responsibility for doing so.
              </p>
            </ol>

            <li className={styles.list}>Information Use.</li>
            <ol>
              <li className={styles.list}>Traffic Data and Information Gathered Using Cookies.</li>
              <p className={styles.paragraph}>
                TBL analyzes Traffic Data and information gathered using Cookies to help TBL better
                understand who is using the Website and how they are using it. By identifying
                patterns and trends in usage, TBL is able to better design the Website to improve
                your experience, and to serve you more relevant and interesting content. From time
                to time, TBL may release Traffic Data and information gathered using Cookies in the
                aggregate, such as by publishing a report on trends in the usage of the Website. TBL
                does not attempt to link information gathered using Cookies to Personal Information.
              </p>

              <li className={styles.list}>Personal Information.</li>
              <p className={styles.paragraph}>
                TBL uses your Contact Data to send you information about TBL and TBL's products and
                services, and to contact you when necessary in connection with the Website. TBL uses
                your Financial Data to verify your qualifications for certain features of the
                Website and, when necessary, to bill you. TBL uses your Demographic Data to
                customize and tailor your experience on the Website. TBL uses your Third Party
                Social Media Account Data in accordance with this Privacy Policy and the privacy
                policies of third party social media companies, such as Facebook and Twitter. As
                with Traffic Data and information gathered using Cookies, from time to time TBL may
                release Demographic Data in the aggregate, such as by publishing a report on trends
                in the usage of the Website.
              </p>
            </ol>

            <li className={styles.list}>Disclosure Practices.</li>
            <p className={styles.paragraph}>
              Except under the following categories of circumstances, TBL will keep your Personal
              Information private, and will not share it with third parties.
            </p>

            <ol>
              <li className={styles.list}>De-Identified Sharing.</li>
              <p className={styles.paragraph}>
                TBL may share or sell Personal Information that has been de-identified such that you
                cannot be tied back to it. TBL hopes that this kind of aggregate data will be useful
                for research. TBL will take reasonable measures to ensure that its de-identification
                process is effective, but does not guarantee that you will never be personally
                identified with your data.
              </p>

              <li className={styles.list}>Disclosure to Marketing Partners.</li>
              <p className={styles.paragraph}>
                TBL may use any Personal Information to help our clients better understand the
                demographics of our target audiences, as well as to support our marketing data
                products and services. We may also provide personally identifiable data to partner
                firms which may include third party marketing companies, affiliates, advertising
                agencies, and data aggregation companies. Your Personal Information may be used by
                these partner firms to provide consumers with information on products and services
                that may be of interest in accordance with this Privacy Policy.
              </p>

              <li className={styles.list}>Disclosure in Connection with Service.</li>
              <p className={styles.paragraph}>
                TBL discloses Personal Information to those who help it provide services, including
                without limitation those who perform technical, administrative, and data processing
                tasks such as hosting, billing, fulfillment, data storage, and security.
              </p>

              <li className={styles.list}>Social Media.</li>
              <p className={styles.paragraph}>
                At your direction, TBL discloses your information and your use of the Website on
                social media platforms. TBL sends your information through the APIs of social media
                platforms you authorize, in compliance with all applicable laws and the applicable
                platforms' privacy policies in place when your information is sent.
              </p>

              <li className={styles.list}>User Content.</li>

              <ol>
                <li className={styles.list}>User Content Defined.</li>
                <p>
                  "User Content" is any content, material, or information, not including Personal
                  Information, that you submit, upload, and/or post to, or transmit, display,
                  perform, or distribute through, the Website, whether in connection with your use
                  of the Website or otherwise. This includes, without limitation, personal photos
                  and videos.
                </p>

                <li className={styles.list}>License to User Content.</li>
                <p className={styles.paragraph}>
                  By using the Website, you grant Company and its stockholders, officers, directors,
                  employees, agents, affiliates, consultants, representatives, sublicensees,
                  successors, and assigns (collectively, the "Company Parties") the right to publish
                  your name and any username of yours in connection with the Company Parties'
                  exercise of their license in and to your User Content. You further waive any
                  claims arising from the Company Parties' exercise of that right.
                </p>
              </ol>

              <li className={styles.list}>By Law or to Protect Rights.</li>
              <p className={styles.paragraph}>
                TBL discloses Personal Information when required to do so by law, in response to a
                subpoena or court order, or when TBL believes in its sole discretion that disclosure
                is reasonably necessary to protect the property or rights of TBL, third parties, or
                the public.
              </p>

              <li className={styles.list}>Business Transfers; Bankruptcy.</li>
              <p className={styles.paragraph}>
                TBL reserves the right to transfer all Personal Information in its possession to a
                successor organization in the event of a merger, acquisition, or bankruptcy or other
                sale of all or a portion of TBL's assets. Other than to the extent ordered by a
                bankruptcy or other court, the use and disclosure of all transferred Personal
                Information will be subject to this Privacy Policy, or to a new privacy policy if
                you are given notice of that new privacy policy and an opportunity to affirmatively
                opt out of it. Personal Information submitted or collected after a transfer,
                however, may be subject to a new privacy policy adopted by TBL's successor
                organization.
              </p>

              <li className={styles.list}>Unpaid Accounts.</li>
              <p className={styles.paragraph}>
                If you fail to pay any balance owed in consideration of services, TBL may, as
                permitted by law, report your Personal Information, including without limitation
                your unpaid balance, to consumer credit reporting services, collection agencies, and
                others.
              </p>
            </ol>

            <li className={styles.list}>
              Consent to Receive Electronic Communications From Company.
            </li>
            <p className={styles.paragraph}>
              Without limitation, by registering for the Website and/or by providing your name,
              email, postal or residential address, and/or phone number through the Website, you
              expressly consent to receive electronic and other communications from TBL, over the
              short term and periodically, including email and short-message service ("SMS" or "text
              message") communications, regarding the Website, new product offers, promotions, and
              other matters. You may opt out of receiving electronic communications at any time by
              (a) following the unsubscribe instructions contained in each communication; or (b)
              sending an email to unsubscribe@terramarbrands.com.
            </p>

            <li className={styles.list}>
              Consent to Receive Electronic Communications from Other Users.
            </li>
            <p className={styles.paragraph}>
              Without limitation, by registering for the Website and/or by providing your name,
              email, postal or residential address, and/or phone number through the Website, you
              consent to receive electronic communications, including email, instant messages, video
              conferencing, and other personal messages from other users of the Website.
            </p>

            <li className={styles.list}>Security of Personal Information.</li>
            <p className={styles.paragraph}>
              TBL takes security seriously. We use commercially reasonable safeguards to protect
              against the unauthorized access, use, modification, destruction, or disclosure of your
              Personal Information. However, due to the realities of data security, TBL is unable to
              guarantee that any information provided to us will not be accessed, hacked, disclosed,
              altered, or destroyed by unauthorized parties.
            </p>

            <li className={styles.list}>Lost or Stolen Information.</li>
            <p className={styles.paragraph}>
              You must promptly notify us if your credit, bank, other financial institution
              information, username, or password is lost, stolen, or used without permission. In
              such an event, we will remove that credit card number, bank account information, other
              financial institution information, username, or password from your account and update
              our records accordingly.
            </p>

            <li className={styles.list}>
              User Ability to Access, Update, and Correct Personal Information.
            </li>
            <p className={styles.paragraph}>
              TBL wants your Personal Information to be complete and accurate. By using the Website,
              you represent and warrant that all information you provide in connection with your use
              of the Website is current, complete, and accurate, and that you will update that
              information as needed to maintain its completeness and accuracy. To confirm the
              completeness and accuracy of, or make changes to, your Personal Information, visit
              your personal profile. Through your personal profile, you may review and update your
              Personal Information that we have already collected.
            </p>

            <li className={styles.list}>Third Party Websites.</li>
            <p className={styles.paragraph}>
              TBL neither owns nor controls Third Party Websites. Accordingly, Third Party Websites
              are under no obligation to comply with this Privacy Policy except with respect to
              Personal Information provided directly to them by TBL. Before visiting or providing
              Personal Information to Third Party Websites, you should inform yourself of the
              privacy policies and practices (if any) of those Third Party Websites, and should take
              those steps necessary to, in your discretion, protect your privacy.
            </p>

            <li className={styles.list}>Promotional Activities.</li>
            <p className={styles.paragraph}>
              From time to time, TBL may conduct contests, giveaways, and other promotions
              (collectively, "Promotional Activities"). Any information submitted in connection with
              Promotional Activities will be treated in accordance with this Privacy Policy. From
              time to time, TBL may also ask you to participate in surveys designed to help TBL
              improve the Website. Any Personal Information provided to TBL in connection with any
              survey will be used only in relation to that survey, and will be disclosed to third
              parties not bound by this Privacy Policy only in aggregated form.
            </p>

            <li className={styles.list}>Business Transitions.</li>
            <p className={styles.paragraph}>
              In the event TBL goes through a business transition, including without limitation any
              merger, acquisition, partnership, business reorganization, debt finance, or sale of
              company assets, or in the event of an insolvency, bankruptcy, or receivership
              (together a "Business Transition"), TBL may use information collected in accordance
              with this Privacy Policy and subject to its restrictions, as part of any such Business
              Transition. In such instances, your information can be part of the assets transferred.
            </p>

            <li className={styles.list}>Do-Not-Track Disclosure.</li>
            <p className={styles.paragraph}>
              TBL does not respond to "Do Not Track" signals sent by browsers.
            </p>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyUSAEsp;
