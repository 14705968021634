import React from 'react';

import menuObject from '../../Menu/menuObject.js';

// Styles
import styles from './index.module.sass';
import { useTranslation } from 'react-i18next';

// Images
import LOGO from '../../../images/logo_header.png';
import WHATSAPP from '../../../icons/whastapp.svg';
import DSA_LOGO from '../../../images/DSA_LOGO.png';

const location = process.env.REACT_APP_COUNTRY;

export default function Footer({ screenSize, history, match }) {
  const { i18n = {}, t } = useTranslation(['footer', 'menu']);
  const { language = '' } = i18n;

  function privacy() {
    window.scrollTo(0, 0);
    if (location === 'MX') {
      history.push('/legal/privacy');
    } else {
      history.push('/legal/privacy_us');
    }
  }

  function terms() {
    window.scrollTo(0, 0);
    history.push('/legal/terms');
  }

  function faqs() {
    window.scrollTo(0, 0);
    if (location === 'MX') {
      history.push('/faq');
    } else {
      history.push('/legal/faq_us');
    }
  }

  function agreement() {
    window.scrollTo(0, 0);
    history.push('/legal/agreement_us');
  }

  function polices() {
    window.scrollTo(0, 0);
    history.push('/legal/policesAndProcedures_us');
  }

  function california() {
    window.scrollTo(0, 0);
    history.push('/legal/californiaPrivacy_us');
  }

  function handBook() {
    window.scrollTo(0, 0);
    // history.push('/legal/handbook_us');
    if (language.includes('es'))
      window.open(
        'https://webimages.terramarbrands.com.mx/usawebpage/documents/legals/MANUAL_CONSULTORA.pdf',
      );
    else
      window.open(
        'https://webimages.terramarbrands.com.mx/usawebpage/documents/legals/CONSULTANT_MANUAL.pdf',
      );
  }

  function dsaRedirect() {
    window.open('https://www.dsa.org/consumerprotection/code-of-ethics');
  }

  return (
    <div
      className={
        screenSize !== 'phone'
          ? `${styles.Footer} ${styles[screenSize]}`
          : `${styles.FooterMobile} ${styles[screenSize]}`
      }>
      {screenSize !== 'phone' && (
        <>
          <div className={styles.left}>
            <div className={styles.logo}>
              <img src={LOGO} alt='logo' />
            </div>
          </div>

          <div className={styles.left}>
            <div className={styles.brandName}>{t('access')}</div>
            <div className={styles.subMenu}>
              {menuObject.map((val, key) => {
                const { id, url, children = [] } = val;
                return (
                  (children.length === 0 && (
                    <div className={styles.link} onClick={() => history.push(url)} key={key}>
                      {t(`menu:${id}`)}
                    </div>
                  )) ||
                  null
                );
              })}
              <div className={`${styles.menuWhitIcon} ${styles.link}`}>
                <a
                  href={
                    (location === 'MX' && 'https://admin.terramarbrands.com.mx/') ||
                    'https://admin.terramarbrandsusa.com/'
                  }
                  target='_blank'
                  rel='noopener noreferrer'>
                  {t('admin')}
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={styles.left}>
        <div className={styles.brandName}>{t('terramar')}</div>
        <div className={styles.subMenu}>
          <div className={`${styles.menuWhitIcon} ${styles.link}`}>
            <a
              href={`mailto:${
                (location === 'MX' && 'callcenter@terramarbrands.com') ||
                'customerservice@terramarbrands.com'
              }`}
              target='_blank'
              without='true'
              rel='noopener noreferrer'>
              <i className={`material-icons ${styles.menuIcon}`}>mail_outline</i>
              {t('mail')}
            </a>
          </div>
          <div className={`${styles.menuWhitIcon} ${styles.link}`}>
            <a
              href={`https://api.whatsapp.com/send?phone=${
                (location === 'MX' && '525542477210') || '+17579331299'
              }`}
              target='_blank'
              rel='noopener noreferrer'>
              <img className={styles.menuIcon} src={WHATSAPP} alt='' />
              {(location === 'MX' && '554 247 7210') || '+1 (757) 933 1299'}
            </a>
          </div>
          <div className={`${styles.menuWhitIcon} ${styles.link}`}>
            <a
              href={`${(location === 'MX' && 'tel:3332681430') || 'tel:8003130903'}`}
              target='_blank'
              rel='noopener noreferrer'>
              <i className={`material-icons ${styles.menuIcon}`}>call</i>
              {(location === 'MX' && '33 3268 1430') || '800-313-0903'}
            </a>
          </div>
          {location === 'US' && (
            <>
              <div className={`${styles.menuWhitIcon}`}>
                <span>{t('schedule1')}</span>
              </div>
              <div className={`${styles.menuWhitIcon}`}>
                <span>{t('schedule2')}</span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.left}>
        <div className={styles.brandName}>{t('support')}</div>
        <div className={styles.subMenu}>
          <div className={styles.link} onClick={() => faqs()}>
            {t('faq')}
          </div>
        </div>
      </div>

      {screenSize === 'phone' && (
        <>
          <div className={`${styles.brandName} ${styles.secondBrand}`}>DSA</div>
          <div className={`${styles.subMenu} ${styles.secondSubMenu}`}>
            <div>{t('dsalabel1')}</div>
            <div>
              <span>{t('dsalabel2')} </span>
              <span className={styles.linkRedirect} onClick={() => dsaRedirect()}>
                {t('dsalabel3')}
              </span>
            </div>
            <div className={`${styles.brandLogo} ${styles.secondBrand}`}>
              <img className={styles.dsaLogo} src={DSA_LOGO} alt='DSA logo' />
            </div>
          </div>
        </>
      )}

      {screenSize !== 'phone' && (
        <>
          <div className={styles.left}>
            <div className={styles.brandName}>{t('informatio')}</div>
            <div className={styles.subMenu}>
              <div className={styles.link} onClick={() => privacy()}>
                {t('privacy')}
              </div>
              {location === 'MX' && (
                <div className={styles.link} onClick={() => terms()}>
                  {t('terms')}
                </div>
              )}

              {location === 'US' && (
                <>
                  <div className={styles.link} onClick={() => agreement()}>
                    {t('agreement')}
                  </div>
                  <div className={styles.link} onClick={() => polices()}>
                    {t('polices')}
                  </div>
                  <div className={styles.link} onClick={() => california()}>
                    {t('privacyCalifornia')}
                  </div>
                  <div className={styles.link} onClick={() => handBook()}>
                    {t('handBook')}
                  </div>
                  <div className={`${styles.brandName} ${styles.secondBrand}`}>DSA</div>
                  <div className={`${styles.subMenu} ${styles.secondSubMenu}`}>
                    <div>{t('dsalabel1')}</div>
                    <div>
                      <span>{t('dsalabel2')} </span>
                      <span className={styles.linkRedirect} onClick={() => dsaRedirect()}>
                        {t('dsalabel3')}
                      </span>
                    </div>
                    <div className={`${styles.brandLogo} ${styles.secondBrand}`}>
                      <img className={styles.dsaLogo} src={DSA_LOGO} alt='DSA logo' />
                    </div>
                  </div>
                </>
              )}

              {location === 'MX' && (
                <div
                  className={styles.link}
                  onClick={() =>
                    window.open(
                      'https://webimages.terramarbrands.com.mx/webpage/catalog/MANUAL_CONSULTORA_MX.pdf',
                    )
                  }>
                  {t('handBook')} 123
                </div>
              )}
            </div>
          </div>

          <div className={styles.left}>
            <div className={styles.brandName}>{t('site')}</div>
            <div className={styles.subMenu}>
              <div>{t('labelSite')}</div>
              <div>{t('labelSite2')}</div>
              <div>{t('labelSite3')}</div>
              <div>{t('labelSite4')}</div>
              <div>{t('labelSite5')}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
