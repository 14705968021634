import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { useParams } from 'react-router-dom';

import ProductImageZoom from '../ProductImageZoom';

import styles from './index.module.sass';

const ProductContainer = ({ product, categories, catSelected, history, language, screenSize }) => {
  let { type } = useParams();
  const [productSelected, setProductSelected] = useState(product[0] || {});
  const sliderRef = useRef();
  const device = {};
  const father = categories[catSelected[0]];
  const {
    Codigo = '',
    descripcion = '',
    Descripcion1 = '',
    Descripcion2 = '',
    PrecioPublico = '',
    ContenidoNeto = '',
  } = productSelected;

  function next() {
    sliderRef.current.slickNext();
  }

  function previous() {
    sliderRef.current.slickPrev();
  }

  function beforeChange(oldIndex, newIndex) {
    setProductSelected(product[newIndex]);
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    beforeChange: (oldIndex, newIndex) => beforeChange(oldIndex, newIndex),
  };

  return (
    <div
      className={`${styles.productContainer} ${styles[screenSize]}`}
      onClick={() => {
        if (descripcion) history.push(`products/product/${Codigo}`);
      }}>
      <div className={`${styles.productContent} ${descripcion && { cursor: 'pointer' }}`}>
        {product && product.length > 1 && (
          <div className={`${styles.arrow} ${styles.arrowLeft}`} onClick={previous}>
            <i className={`material-icons `}>navigate_before</i>
          </div>
        )}
        {product && product.length > 1 && (
          <div className={`${styles.arrow} ${styles.arrowRight}`} onClick={next}>
            <i className={`material-icons `}>navigate_next</i>
          </div>
        )}
        <div className={styles.productImage}>
          <Slider {...settings} className={styles.slider} ref={sliderRef}>
            {product.map((val, key) => {
              return (
                <ProductImageZoom
                  key={key}
                  device={device}
                  imgsrc={`https://webimages.terramarbrands.com.mx/images-usa/shopping-cart/cart-products-gray/${
                    val.Codigo
                  }${
                    father?.Descripcion2 === 'Promotions' && type !== 'all' ? '-promotion' : ''
                  }.jpg`}
                  idComplement={val?.Codigo || ''}
                />
              );
            })}
          </Slider>
        </div>
        <div className={styles.productName}>
          {(language.includes('es') && Descripcion1) || Descripcion2} {ContenidoNeto}
        </div>
        {PrecioPublico && (
          <div className={styles.productPrice}>
            <span className={styles.pesitos}>$</span>
            <span className={styles.catalogPrice}>{parseFloat(PrecioPublico).toFixed(2)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductContainer;
