import React, { useState, useEffect, useCallback } from 'react';
import styles from './index.module.sass';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

//Components
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import Alert from '../Alert';
import postEnviarEmail from '../Queries/postEnviarEmail';

const SITE_KEY = '6Lfj_iolAAAAAGJbZSKo1xfMlRkvX3oaUYl5rUZ7';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: '5px',
    '&:hover .MuiOutlinedInput-input': {
      color: '#000000',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#9b722a',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#9b722a',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#000000',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#000000',
      backgroundColor: '#000000',
      padding: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#9b722a',
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      color: '#9b722a',
      backgroundColor: '#ffffff',
      padding: '1px',
    },
  },
});

const location = process.env.REACT_APP_COUNTRY;

const ContactForm = (props) => {
  const { leaderSelected } = props;

  const { t } = useTranslation(['joinus', 'footer']);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [smsChecked, setSMSChecked] = useState(false);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  async function Send(token) {
    if (!leaderSelected?.nombre) {
      alert(t('selectLeaderAlert'));
      setLoading(false);
      return;
    }
    if (name === '' || mail === '') {
      alert(t('mandatoryAlert'));
      setLoading(false);
      return;
    }
    if (!agreeChecked || !smsChecked) {
      alert(t('agreeAlert'));
      setLoading(false);
      return;
    }

    setLoading(true);
    const { email = '', nombre = '' } = leaderSelected;
    const Form = {
      nombreDestino: nombre || 'Únete Terramar',
      emailDestino: email || 'unete@terramarbrands.com',
      nombreRemitente: name,
      emailRemitente: mail,
      celular: cellphone,
      ciudad: city,
      mensaje: message,
      token,
    };

    const response = await postEnviarEmail(Form);
    const { mensaje = '', tipoMensaje = 'Error' } = response;
    if (tipoMensaje === 'Error') {
      setAlertText(mensaje);
      setOpen(true);
      setLoading(false);
    } else {
      setAlertText(mensaje);
      setOpen(true);
      setLoading(false);
    }
  }

  const checkAgreements = useCallback(() => {
    setAgreeChecked((oldValue) => !oldValue);
  }, []);

  const checkSMS = useCallback(() => {
    setSMSChecked((oldValue) => !oldValue);
  }, []);

  const handleCaptcha = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then((token) => {
        Send(token);
      });
    });
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log('Script loaded!');
      },
    );
  }, []);

  return (
    <div className={styles.contactForm}>
      <Alert open={open} setOpen={setOpen} title={'Terramar'} text={alertText} />
      <div className={styles.formContainer}>
        <div className={styles.leadersTitle}>{t('areYouNew')}</div>
        <div className={styles.leadersSubTitle}>{t('contactText')}</div>
        {leaderSelected && leaderSelected.nombre && (
          <div className={styles.leaderSelected}>
            {t('reach')} {leaderSelected.nombre}
          </div>
        )}
        <div className={styles.formContainerLeader}>
          <div className={`${styles.inputContainer} ${styles.contactInput}`}>
            <TextField
              id='nameform-outlined-code-input'
              label={t('contactName')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={`${styles.inputContainer} ${styles.contactInput}`}>
            <TextField
              id='mail-outlined-code-input'
              label={t('contactMail')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </div>

          <div className={`${styles.inputContainer} ${styles.contactInput}`}>
            <TextField
              id='cellphone-outlined-code-input'
              label={t('contactCell')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
            />
          </div>

          <div className={`${styles.inputContainer} ${styles.contactInput}`}>
            <TextField
              id='city-outlined-code-input'
              label={t('contactCity')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className={`${styles.inputContainer} ${styles.contactInput}`}>
            <TextField
              id='message-outlined-code-input'
              label={t('contactMessage')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className={styles.agreeContainer}>
            <Checkbox
              className={styles.check}
              checked={agreeChecked}
              onClick={() => checkAgreements()}
            />
            <div className={styles.terms}>
              {t('agreeUSAText1')}:&nbsp;
              <span
                className={styles.legalLink}
                onClick={() => window.open(t('/legal/privacy_us'))}>
                {t('footer:privacy')}
              </span>
              ,&nbsp;
              <span
                className={styles.legalLink}
                onClick={() => window.open(t('/legal/agreement_us'))}>
                {t('footer:agreement')}
              </span>
              ,&nbsp;
              <span
                className={styles.legalLink}
                onClick={() => window.open(t('/legal/policesAndProcedures_us'))}>
                {t('footer:polices')}
              </span>
              ,&nbsp;
              <span
                className={styles.legalLink}
                onClick={() => window.open(t('/legal/californiaPrivacy_us'))}>
                {t('footer:privacyCalifornia')}
              </span>
              ,&nbsp;
              <span
                className={styles.legalLink}
                onClick={() => window.open(t('/legal/handbook_us'))}>
                {t('footer:handBook')}&nbsp;
              </span>
            </div>
          </div>

          <div className={styles.agreeContainer}>
            <Checkbox className={styles.check} checked={smsChecked} onClick={() => checkSMS()} />
            <div className={styles.terms}>
              <span className={styles.terms}>{t('receiveSMS')}&nbsp;</span>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              color='primary'
              className={`${styles.button}`}
              onClick={(e) => handleCaptcha(e)}>
              {(loading && <CircularProgress />) || t('send')}
            </Button>
          </div>
          <div className={styles.recaptchaText}>
            <span>{t('recaptchaText1')}</span>{' '}
            <span
              onClick={() => window.open('https://policies.google.com/privacy')}
              className={styles.legalLink}>
              {t('recaptchaText2')}
            </span>{' '}
            <span>{t('recaptchaText3')}</span>{' '}
            <span
              onClick={() => window.open('https://policies.google.com/terms')}
              className={styles.legalLink}>
              {t('recaptchaText4')}
            </span>{' '}
            <span>{t('recaptchaText5')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
