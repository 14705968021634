import React from 'react';
import styles from '../../index.module.sass';
import { useTranslation } from 'react-i18next';

import TableING from '../Table/ING';

const CaliforniaPrivacyING = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('californiaPrvacy');

  const items = t('items', { returnObjects: true });

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>California</div>
          <div className={styles.subtitle}>Privacy Notice</div>
        </div>
        <p className={styles.paragraph}>
          This California Privacy Notice (“Notice”) is for California residents and supplements our
          Privacy Policy. It explains how we collect, use, and share your Personal Information and
          how to exercise your rights under the California Consumer Privacy Act (“CCPA”).
        </p>

        <p className={styles.paragraph}>
          When we say “Personal Information” in this Notice, we mean information that identifies,
          relates to, describes, is reasonably capable of being associated with, or could reasonably
          be linked, directly or indirectly, with you. Personal Information does not include
          information that is aggregated or information that cannot be reasonably linked to you.
        </p>
        <p className={styles.paragraphTitle}>How We Collect, Use, and Share Personal Information</p>
        <p className={styles.paragraph}>
          To provide the products or features offered by XYZ (“Products"), we must process
          information about you, including Personal Information, whether or not you are registered
          or logged in. Subject to the limitations we describe in our Data Policy, we may share your
          Personal Information for business purposes with strict restrictions on how our business
          partners can use and disclose the data we provide, at your direction, or in ways otherwise
          in accordance with the CCPA.
        </p>
        <p className={styles.paragraph}>
          The best way to learn about the kinds of information we collect and how we use it is to
          review our Data Policy. Here is a summary of the CCPA-related categories of Personal
          Information we may have collected about you over the past 12 months, depending on how you
          use our Products, as well as how we use it and with whom we may have shared it.
        </p>
        <TableING screenSize={screenSize} tableItems={items} />
        <p className={styles.paragraph}>
          To learn more about how we process information, including other types of Personal
          Information we collect, return to our Privacy Policy.
        </p>
        <p className={styles.paragraphTitle}>Sources of Personal Information.</p>
        <p className={styles.paragraph}>
          We receive Personal Information from the information that you and others provide, your
          device(s), and from our partners. The categories of sources from which we’ve collected or
          received Personal Information include:
        </p>
        <ul>
          <li className={styles.list}>You</li>
          <p className={styles.span}>
            We collect the content, communications, and other information you provide when you use
            our Products, including when you sign up for an account, create or share content, and
            message or communicate with others. We collect information about the people, pages,
            accounts, hashtags, and groups you are connected to, and how you interact with them
            across our Products, such as the people you communicate with the most or the groups you
            are part of. We also collect information about how you use our Products, including ads
            we serve on and off XYZ, such as the types of content you view or engage with, the
            features you use, the actions you take, the people or accounts you interact with, and
            the time, frequency, and duration of your activities.
          </p>

          <li className={styles.list}>Other people</li>
          <p className={styles.span}>
            We may also receive and analyze content, communications, and information about you that
            other people provide when they use our Products, such as when others share or comment on
            a photo of you, send a message to you, or upload, sync, or import your contact
            information.
          </p>

          <li className={styles.list}>Your device(s)</li>
          <p className={styles.span}>
            We collect information from and about the computers, phones, connected TVs, and other
            web-connected devices you use that integrate with our Products, and we combine this
            information across different devices you use.
          </p>

          <li className={styles.list}>Partners</li>
          <p className={styles.span}>
            Credit card processors, banks, app developers and publishers, and other partners can
            send us information for business purposes through the XYZ business tools they use,
            including our APIs and SDKs. These partners provide information about your activities
            off XYZ – including information about your device, the websites you visit, things you do
            on their services, and purchases you make. We also receive information about your online
            and offline actions, and purchases from third-party data providers who have the rights
            to provide us with your information. Partners receive your data when you visit or use
            their services or through third parties they work with. We require each of these
            partners to have rights to collect, use, and share your data before providing any data
            to us.
          </p>
        </ul>

        <p className={styles.paragraphTitle}>
          How can you exercise your rights provided under the CCPA?
        </p>

        <p className={styles.paragraph}>Under the CCPA, you have the following rights:</p>

        <ul>
          <li className={styles.list}>Right to Know:</li>
          <p className={styles.span}>
            You have the right to request that we disclose to you the Personal Information we
            collect, use, or disclose, and information about our data practices;
          </p>
          <li className={styles.list}>Right to Request Deletion:</li>
          <p className={styles.span}>
            You have the right to request that we delete your Personal Information that we have
            collected from you;
          </p>

          <li className={styles.list}>Right to Non-Discrimination</li>
          <p className={styles.span}>
            We will not discriminate against you for exercising any of these rights..
          </p>
        </ul>

        {/* <p className={styles.paragraph}>
          To exercise your “right to know” or your “right to request deletion,” click here .
        </p> */}

        <p className={styles.paragraph}>
          Please note that to protect your information and the integrity of our Products and
          services, we may need to verify your identity before processing your request. In some
          cases we may need to collect additional information to verify your identity, such as a
          government issued ID.
        </p>

        <p className={styles.paragraph}>
          Under the CCPA, you may exercise these rights yourself or you may designate an authorized
          agent to make these requests on your behalf. We may request that your authorized agent
          have written permission from you to make requests on your behalf and may need to verify
          your authorized agent’s identity.
        </p>

        <p className={styles.paragraphTitle}>Contact for more information.</p>

        <p className={styles.paragraph}>
          If you have additional questions about this Notice or how to exercise your rights under
          the CCPA, please contact us.
        </p>

        <p className={styles.paragraph}>Effective: July 2, 2020.</p>
      </div>
    </div>
  );
};
export default CaliforniaPrivacyING;
