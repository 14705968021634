import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import LoadingMenu from '../LoadingMenu';

// Queries
import getVideos from '../Queries/getVideos';

// Styles
import styles from './index.module.sass';

const location = process.env.REACT_APP_COUNTRY;

const Videos = (props) => {
  const { i18n = {} } = useTranslation();
  const { language = '' } = i18n;

  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;
  const [videos, setVideos] = useState([]);
  const [catSelected, setCatSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [videoSelected, setVideoSelected] = useState(0);
  const [infoVideoSelected, setInfoVideoSelected] = useState({ enlace: '', texto: '' });

  function videoThumbItem(key, val, enlace, texto) {
    const videoThumb =
      (location === 'MX' && `https://terramarbrands.mx/images/webpage/videos/${enlace}.png`) ||
      `https://webimages.terramarbrands.com.mx/usawebpage/videos/${enlace}.png`;
    return (
      <div
        className={`${styles.thumbBox} ${styles[videoSelected === key]}`}
        key={key}
        onClick={() => {
          setVideoSelected(key);
          setInfoVideoSelected(val);
        }}>
        <div
          className={styles.thumbImage}
          style={{
            backgroundImage: `url("${videoThumb}")`,
          }}></div>
        <label className={styles.strong}>{texto}</label>
      </div>
    );
  }

  useEffect(() => {
    async function videos() {
      window.scrollTo(0, 0);
      setLoading(true);
      const response = await getVideos();
      setLoading(false);
      setVideos(response);
      if (response.length > 0 && response[0].videos.length > 0)
        setInfoVideoSelected(response[0].videos[0] || '');
      setLoading(false);
    }
    videos();
  }, []);

  return (
    <div id={styles.Videos} className={styles[screenSize]}>
      {(loading && (
        <>
          <div className={styles.categoriesContainer}>
            <div className={styles.categories}>
              <LoadingMenu />
            </div>
          </div>
          <div className={styles.thumbsContainer}>
            <div className={styles.thumbs}>
              <div className={`${styles.thumbBox}`}>
                <div className={styles.thumbImage}></div>
                <div className={styles.thumbImage}></div>
              </div>
            </div>
          </div>
          <div className={styles.thumbsContainerframe}>
            <div className={styles.thumbs}>
              <div className={`${styles.thumbBox}`}>
                <div className={styles.thumbImage}></div>
                <div className={styles.thumbImage}></div>
                <div className={styles.thumbImage}></div>
              </div>
            </div>
          </div>
        </>
      )) || (
        <>
          <div className={styles.categoriesContainer}>
            <div className={styles.categories}>
              {videos.map((val, key) => {
                return (
                  <div
                    className={`${styles.category} ${styles[catSelected === key]}`}
                    key={key}
                    onClick={() => {
                      setCatSelected(key);
                      setInfoVideoSelected(val.videos[0]);
                    }}>
                    {(location === 'MX' && val.categoria) ||
                      (location === 'US' && language.includes('es') && val.categoriaES) ||
                      val.categoriaEN}
                    <i className={`material-icons `}>navigate_next</i>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.thumbsContainer}>
            <div className={styles.thumbs}>
              {videos.length > 0 &&
                videos[catSelected].videos.length > 0 &&
                videos[catSelected].videos.map((val, key) => {
                  const {
                    enlace = '',
                    texto = '',
                    enlaceEN = '',
                    textoEN = '',
                    enlaceES = '',
                    textoES = '',
                  } = val;

                  let videoItem = (enlaceEN && videoThumbItem(key, val, enlaceEN, textoEN)) || null;
                  if (location === 'MX') {
                    videoItem = (enlace && videoThumbItem(key, val, enlace, texto)) || null;
                  }
                  if (location === 'US' && language.includes('es')) {
                    videoItem = (enlaceES && videoThumbItem(key, val, enlaceES, textoES)) || null;
                  }
                  return videoItem;
                })}
            </div>
          </div>
          <div className={styles.videoContainer}>
            <h2>
              {(location === 'MX' && infoVideoSelected.texto) ||
                (location === 'US' && language.includes('es') && infoVideoSelected.textoES) ||
                infoVideoSelected.textoEN}
            </h2>

            <iframe
              width='90%'
              height='450'
              src={`https://www.youtube.com/embed/${
                (location === 'MX' && infoVideoSelected.enlace) ||
                (location === 'US' && language.includes('es') && infoVideoSelected.enlaceES) ||
                infoVideoSelected.enlaceEN
              }?rel=0&amp;showinfo=0`}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowfullscreen'
              className={styles.youtube}
              title='TMBVideos'></iframe>
          </div>
        </>
      )}
    </div>
  );
};

export default Videos;
