import React from 'react';
import styles from '../../index.module.sass';

const AgreementING = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Consultant </div>
          <div className={styles.subtitle}>Agreement</div>
        </div>
        <p className={styles.pharagraph}>
          I am of legal age in the state in which I enter this Agreement. I understand that I am not
          a Consultant until the Company has accepted an original Agreement properly signed.
        </p>
        <p className={styles.pharagraph}>
          I will not purchase products solely for the purpose of qualifying for commissions or
          bonuses. I will resell or personally consume at least 70% or more of all products that I
          have purchased from the Company prior to making any subsequent product order. Products
          personally consumed by my household, in reasonable quantities, and not purchased to meet
          sales plan qualifications, are deemed retail sales. Products deemed certified as sold
          under this 70% rule are not eligible for buyback.
        </p>
        <p className={styles.pharagraph}>
          I am entitled to cancel this Agreement at any time and for any reason with written notice
          to the Company. This business model does not provide for the sale of products or sales
          aids into the inventory of an independent contractor for resale. The Company recognizes
          that some buy-back laws extend to fees and other consideration paid, and to the extent
          that such laws are applicable, the Company will honor the buyback laws of Georgia, Idaho,
          Indiana, Louisiana, Maryland, Massachusetts, Mississippi, Montana, Nebraska, New Jersey,
          Oklahoma, Puerto Rico, South Carolina, South Dakota, Tennessee, Texas, Washington, and
          Wyoming. Additionally, Montana consultants who cancel within 15 days are entitled to a
          100% refund of any consideration given to participate.
        </p>
        <p className={styles.pharagraph}>As an Independent Consultant I will:</p>
        <ul>
          <li className={styles.list}>
            <span className={styles.span}>Provide training and motivation to my consultants.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Study the product literature and promote retail sales.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Represent the Company products in an honest manner.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Honor the Company Customer Guarantees.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Be professional, courteous, and considerate.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Not misrepresent the Company’s Compensation Plan.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Become familiar with, and abide by, the Company Statement of Policies and other
              materials as prescribed by the Company.
            </span>
          </li>
        </ul>
        <p className={styles.pharagraph}>
          I understand that Independent Consultants cannot, under any circumstances, incur any debt,
          expense, or obligation on behalf of, or for, the Company.
        </p>
        <p className={styles.pharagraph}>
          I understand that, as an Independent Consultant, I will not, for any reason, act as
          spokesperson for the Company and its products, in any manner, to any media or publication,
          without prior, written authorization. I will not create, print, publish, or distribute any
          literature or materials representing the Company or its products other than those from, or
          approved in writing by, the Company.
        </p>
        <p className={styles.pharagraph}>
          I understand that, as an Independent Consultant, I have the following rights: (a) to
          solicit sale of the products/services offered by the Company in agreement with the Company
          Compensation Plan, and its Sales Policies, including without limitation the Statement of
          Policies, and (b) to sponsor other Independent Consultants in agreement with the Company
          Compensation Plan and Statement of Policies.
        </p>
        <p className={styles.pharagraph}>
          I understand that I will make no claims or warranties of any kind, including, but not
          limited to, any claims for earnings or benefits concerning the Company’s Compensation Plan
          or its products, other than those included in the Company’s official written literature. I
          will not make product claims which are not stated in official Company literature, and I am
          not permitted to create my own literature, sales aids, or training materials, without
          written consent from the Company.
        </p>
        <p className={styles.pharagraph}>
          As an Independent Consultant, I understand that I am an independent contractor, and not an
          agent, employee, or franchisee of the Company. I understand and agree that I will not be
          treated as an employee for federal or state tax purposes, nor for purposes of the Federal
          Unemployment Tax Act, the Federal Insurance Contributions Act, the Social Security Act,
          State Unemployment Acts, State Employment Security Acts, or State Workers Compensation
          Acts. I understand and agree to pay all applicable federal and state self-employment
          taxes, sales taxes, local taxes, and/or local license fees that may become due as a result
          of my activities under this Agreement.
        </p>
        <p className={styles.pharagraph}>
          I understand that my acceptance of this Agreement, does not constitute the sale of a
          franchise or a security, no exclusive territories can be granted to anyone, and that no
          franchise fees have been paid, nor can I acquire any interest in a security by the
          acceptance of this Agreement.
        </p>
        <p className={styles.pharagraph}>
          The Company may, at its discretion, amend the Company Compensation Plan and Statement of
          Policies and/or terms of the Independent Consultant Agreement. Notification of such
          changes shall be published in newsletters, on the Company website, broadcast by Email,
          written or published material circulated or made available to all Independent Consultants.
          I agree to abide by all such amendments. The continuation of my Business, and/or my
          acceptance of products, commissions, and bonus checks, or other payments from the Company,
          constitutes my acceptance of any and all amendments.
        </p>
        <p className={styles.pharagraph}>
          My Consultantship cannot be sold, assigned, or transferred without prior, written approval
          from the Company.
        </p>
        <p className={styles.pharagraph}>
          I have carefully reviewed the Company Compensation Plan and Statement of Policies and
          acknowledge that they are incorporated as a part of this Agreement in their present form
          and as modified from time to time by the Company. My violation of any of the terms of this
          Agreement or the Company Statement of Policies may result, at the Company’s discretion, in
          forfeiture of commission and bonus checks, or other payments from the Company; loss of all
          or part of my marketing organization; or cancellation of this Agreement.
        </p>
        <p className={styles.pharagraph}>
          This Agreement constitutes the entire agreement between the parties, and no other
          promises, representations, guarantees, or agreements of any kind shall be valid unless in
          writing. If any provision herein is held to be invalid, all other provisions shall remain
          valid and enforceable.
        </p>
        <p className={styles.pharagraph}>
          The term of this Agreement is one year. This Agreement can be renewed annually on each
          anniversary date of the acceptance of this Agreement, unless otherwise canceled or
          extended by the Company.
        </p>
        <p className={styles.pharagraph}>
          This Agreement shall be governed by the laws of the State of Florida, and any claims or
          disputes between parties to this Agreement shall be subject to binding arbitration under
          the Commercial Rules of the American Arbitration Association, with arbitration to be held
          in Miami, Florida. Louisiana residents may choose jurisdiction in, and arbitrate in New
          Orleans, Louisiana.
        </p>
      </div>
    </div>
  );
};

export default AgreementING;
