import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Components
import HeroBanner from '../../HeroBanner';

const Responsibility = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('responsibility');

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <div id={styles.Titles}>
        <div>&nbsp;</div>
        <div className={styles.title}>{t('title')}</div>
        <div className={styles.subtitle}>{t('subTit')}</div>
        <div className={styles.subtitle2}>{t('subTitle')}</div>
      </div>
      <div className={styles.Text}>
        <div className={styles.bloque}>
          <div className={`${styles.subBloque} ${styles[screenSize]}`}>
            <div className={styles.titleParagraph}>{t('subject1')}</div>
            {t('text1')}
            <br />
            <br />
            <div className={styles.titleParagraph}>{t('subject2')}</div>
            {t('text2')}
          </div>
          <div>
            <img
              src='https://webimages.terramarbrands.com.mx/webpage/responsability/creando-sonrisas-img.jpg'
              alt=''
              className={styles.img}
            />
          </div>
        </div>
        <br />
        <div className={styles.titleParagraph}>{t('subject3')}</div>
        <div dangerouslySetInnerHTML={{ __html: t('text3') }} />
        <br />
        <br />
        <div dangerouslySetInnerHTML={{ __html: t('dot1') }} />
        <div dangerouslySetInnerHTML={{ __html: t('dot2') }} />
        <div dangerouslySetInnerHTML={{ __html: t('dot3') }} />
      </div>
      <br />
    </div>
  );
};

export default Responsibility;
