import React from 'react';
import styles from '../../index.module.sass';
import tableESP from './manual_table_us_esp.jpg';

const ManualUSAENG = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Manual </div>
          <div className={styles.subtitle}>de consultora .</div>
        </div>

        {/* <ol>
          <li className={styles.list}>
            <span className={styles.span}>Bienvenida</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Glosario</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Categorías de Linaje</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Tabla de Linaje</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Reglamento de Linaje</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Programas de Linaje</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Programa Internacional</span>
          </li>
        </ol> */}

        <p className={styles.paragraphTitleCenter}>BIENVENIDA</p>

        <p className={styles.paragraph}>
          Ron Clark y Gonzalo Rubio ponen en marcha lo que ha sido su gran sueño de toda una vida:
          fundar TERRAMAR BRANDS, la mejor compañía de venta directa y ofrecer una inigualable
          oportunidad de crecimiento personal y económico.
        </p>

        <p className={styles.paragraph}>
          En 2009 TERRAMAR BRANDS inicia oficialmente sus operaciones en México contando con un
          excelente equipo de trabajo dedicado y comprometido con las Consultoras y Líderes que
          disfrutan de las maravillosas oportunidades, productos y el mejor programa multinivel que
          existe hoy en día.
        </p>

        <p className={styles.paragraph}>
          En TERRAMAR BRANDS nuestra misión es: “Absoluto compromiso de mejorar la vida de las
          personas y sus familias”.
        </p>

        <p className={styles.paragraph}>
          Ron Clark, Gonzalo Rubio Senior y Gonzalo Rubio Jr. representan más de 100 años de
          experiencia en la venta directa, habiendo abierto con gran éxito más de 35 países en 5
          continentes. Finalmente, después de una larga carrera en venta directa, eligen México para
          la sede principal, y en 2022 deciden abrir mercado en el mayor mercado del mundo, Estados
          Unidos.
        </p>

        <p className={styles.paragraph}>
          Los invitamos cordialmente a conocer, soñar y enamorarse del Programa TERRAMAR y confirmar
          que es la llave que abrirá las puertas hacia la realización de muchos anhelos.
        </p>

        <p className={styles.paragraph}>
          Estamos seguros que nuestro entusiasmo, entrega y devoción hacia los Consultores será el
          marco del gran éxito de TERRAMAR BRANDS Estados Unidos, cuyo compromiso formal es
          apoyarles en su legítimo deseo de superación personal, profesional y económico.
        </p>

        <p className={styles.paragraphTitle}>EQUIPO TERRAMAR</p>

        <p className={styles.paragraphTitleCenter}>GLOSARIO</p>

        <ol>
          <li className={styles.list}>
            Consultora:
            <span className={styles.span}>
              Comerciante independiente que se inscribe al Programa TERRAMAR por medio de una
              Consultora, Emprendedora o Líder ya inscrita al Programa TERRAMAR. Para ser una
              Consultora y comercializar los productos TERRAMAR puedes ingresar adquiriendo, el Kit
              de Ingreso, con el Pedido Sugerido.
            </span>
          </li>

          <li className={styles.list}>
            Pedido Sugerido:
            <span className={styles.span}>
              Conjunto de productos o promociones de línea regular Letra “A” TERRAMAR, equivalente a
              un módulo.
            </span>
          </li>

          <li className={styles.list}>
            Número de Kit:
            <span className={styles.span}>
              Código único y personal que se le proporciona a cada nueva consultora, con el cual se
              le identificará en el Programa TERRAMAR.
            </span>
          </li>

          <li className={styles.list}>
            Módulo:
            <span className={styles.span}>
              Es la unidad de medida, basada en la compra de Productos de Línea Letra “A” TERRAMAR y
              está diseñado con fines de cálculo de promociones y pago de transacciones comerciales.
              Un módulo equivale a $180 USD, precio consultor más impuestos, gastos operativos y si
              se requiere, más gastos de envío.
            </span>
          </li>

          <li className={styles.list}>
            Precio Consultora:
            <span className={styles.span}>
              Precio especial para la adquisición de Productos de Línea Letra “A” TERRAMAR, que se
              le da a las Consultoras, Emprendedoras y Líderes que están inscritas al Programa
              TERRAMAR.
            </span>
          </li>

          <li className={styles.list}>
            Precio Público:
            <span className={styles.span}>
              Precio sugerido por TERRAMAR BRANDS para vender los Productos de Línea Letra “A”
              TERRAMAR que las Consultoras, Emprendedoras y Líderes ofrecen a sus clientes. Las
              Consultoras deben entregar un recibo a sus clientes por todas las ventas al por menor
              que realice.
            </span>
          </li>

          <li className={styles.list}>
            Productos de Línea Letra “A” TERRAMAR:
            <span className={styles.span}>
              Son los productos de todas las líneas de TERRAMAR BRANDS que se comisionan y que
              cuentan para la acumulación de módulos.
            </span>
          </li>

          <li className={styles.list}>
            Ayuda de Ventas Letra “B”
            <span className={styles.span}>
              Son los productos que una consultora compra para regalar a sus clientes o como
              auxiliares para sus actividades de ventas o Clases TERRAMAR. Estos productos NO son
              comisionables.
            </span>
          </li>

          <li className={styles.list}>
            Patrocinio:
            <span className={styles.span}>
              Inscripción al Programa TERRAMAR de una nueva consultora, por medio de una Consultora,
              Emprendedora o Líder ya inscrita al Programa TERRAMAR y esta debe tener pedido de un
              módulo.
            </span>
          </li>

          <li className={styles.list}>
            Patrocinio Directo:
            <span className={styles.span}>
              Inscripción que realizas (directamente) al Programa TERRAMAR de una nueva consultora.
            </span>
          </li>

          <li className={styles.list}>
            Patrocinio Indirecto:
            <span className={styles.span}>
              Inscripción que realizas (indirectamente) al Programa TERRAMAR de una nueva Consultora
              a través de otra Consultora.
            </span>
          </li>

          <li className={styles.list}>
            Consultora ordenando:
            <span className={styles.span}>
              Es la Consultora que se mantiene haciendo un pedido mínimo de un módulo mensualmente.
            </span>
          </li>

          <li className={styles.list}>
            Consultora Activa:
            <span className={styles.span}>
              Consultora que tiene un módulo pagado, en por lo menos los últimos 4 meses.
            </span>
          </li>

          <li className={styles.list}>
            Consultora patrocinando:
            <span className={styles.span}>
              Consultora que patrocina a una nueva, y ambas tienen pedido de un módulo.
            </span>
          </li>

          <li className={styles.list}>
            Linaje:
            <span className={styles.span}>
              En el Programa TERRAMAR usamos la palabra Linaje para referirnos a la familia o
              profundidad de una Emprendedora o Líder.
            </span>
          </li>

          <li className={styles.list}>
            Emprendedora o Líder Activa:
            <span className={styles.span}>
              Es la comerciante independiente que de acuerdo a su categoría dentro del Linaje
              TERRAMAR, realiza las bases que se requieren para mantenerse en su nivel
              correspondiente.
            </span>
          </li>

          <li className={styles.list}>
            Consultora Inactiva:
            <span className={styles.span}>
              Consultora que pasan más de 4 meses sin pagar un módulo.
            </span>
          </li>

          <li className={styles.list}>
            Reactivación:
            <span className={styles.span}>
              Consultora inactiva, que paga un módulo en después de 4 meses de no haberlo hecho, y
              antes de que pasen 6 meses.
            </span>
          </li>

          <li className={styles.list}>
            Consultora dada de baja:
            <span className={styles.span}>
              Una Consultora que no ha metido pedido en 6 meses, es dada de baja, y para reingresar,
              tiene que inscribirse como nuevo patrocinio.
            </span>
          </li>

          <li className={styles.list}>
            Calificación de una Consultora a Emprendedora:
            <span className={styles.span}>
              Es un proceso en que la Consultora logra para su negocio la categoría de Emprendedora
              considerándose ésta la primera calificación dentro del Linaje TERRAMAR.
            </span>
          </li>

          <p className={styles.paragraph}>
            Para calificar a Emprendedora es necesario contar con los siguientes requisitos:
          </p>

          <ol type='a'>
            <li className={styles.list}>
              <span className={styles.span}>
                Contar con un grupo mínimo de 9 Consultoras activas de las cuales por lo menos tres
                deben ser directas.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Contar con 9 Consultoras ordenando con módulo pagado en su Grupo Central durante el
                mes de la calificación.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Contar con módulo personal pagado y un patrocinio directo.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Una vez lograda la calificación, se deberá tener una consultora patrocinando, un
                patrocinio directo, módulo personal y un mínimo de 9 consultoras ordenando como
                requisito para liberación del pago de transacciones comerciales mercantil.
              </span>
            </li>
          </ol>
          <p className={styles.paragraph}>
            *Se debe tomar en cuenta que en el mes que se califica, se considera “mes de
            calificación”, y al siguiente mes se le denomina “mes de mantenimiento”
          </p>

          <li className={styles.list}>
            Calificación Simultánea:
            <span className={styles.span}>
              Se considera calificación simultánea cuando una consultora y un patrocinio directo o
              indirecto de ésta coinciden en el mes de su calificación. Cada una debe tener los
              requisitos de manera independiente a la otra.
            </span>
          </li>

          <li className={styles.list}>
            Grupo Central:
            <span className={styles.span}>
              El Grupo Central de la Emprendedora o Líder, consta de todos los patrocinios directos
              o indirectos, no promovidos a Emprendedora.
            </span>
          </li>

          <li className={styles.list}>
            Grupo Directo 1ª Línea:
            <span className={styles.span}>
              Grupo de Consultoras que es promovido del Grupo Central de una Emprendedora o Líder
              cuando una de sus Consultoras se califica como Emprendedora (para efectos de linaje
              llamado como Grupo Directo Calificado).
            </span>
          </li>

          <li className={styles.list}>
            Grupo Indirecto 2da Línea:
            <span className={styles.span}>
              Grupo de Consultoras que es promovido a través de una Emprendedora que se encuentra en
              la 1ª línea de Grupos Calificados del linaje correspondiente (para efectos de linaje,
              también llamado como Grupo Indirecto Calificado).
            </span>
          </li>

          <li className={styles.list}>
            Distrito:
            <span className={styles.span}>
              Son los grupos calificados directos e indirectos completos que integran el linaje
              general de la Líder a todos los niveles. Se pagan los niveles indirectos cuando el
              número de grupos activos en la primera línea es igual a la profundidad de las líneas
              activas.
            </span>
          </li>

          <li className={styles.list}>
            Reubicada:
            <span className={styles.span}>
              Es cuando una Emprendedora o Líder regresa al nivel de Consultora por no cumplir con
              las bases de mantenimiento de acuerdo a su categoría correspondiente durante 3 meses
              consecutivos.
            </span>
          </li>
        </ol>

        <p className={styles.paragraphTitle}>CATEGORÍAS DE LINAJE</p>
        <p className={styles.paragraph}>
          Categorías de Linaje TERRAMAR: Contamos con 11 niveles de linaje.
        </p>
        <p className={styles.paragraph}>
          Requerimientos para calificar en el Programa de Carrera TERRAMAR y sus beneficios:
        </p>

        <p className={styles.paragraphTitle}>1° Nivel: Consultora </p>
        <p className={styles.paragraph}>
          BENEFICIOS: 100% ganancias. Reconocimientos, premios mensuales por actividad y por
          patrocinio, cursos de cuidado de la piel, maquillaje, programa Club Elite.
        </p>

        <p className={styles.paragraphTitle}>2° Nivel: Emprendedora </p>
        <p className={styles.paragraph}>
          Contar con 9 Consultoras activas, 9 Consultoras ordenando y 9 módulos pagados. BENEFICIOS:
          Una bonificación del 12% de tu Grupo Central.
        </p>

        <p className={styles.paragraphTitle}>3° Nivel: Líder de Grupo Estrella </p>
        <p className={styles.paragraph}>
          Contar con 1 Grupo Calificado, cumpliendo con las bases de su categoría, con 12
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 12 módulos
          pagados mensualmente. BENEFICIOS: 13% de tu Grupo Central y 7% del Grupo directo
          calificado en tu primera línea.
        </p>

        <p className={styles.paragraphTitle}>4° Nivel: Líder de Grupo 2 Estrellas </p>
        <p className={styles.paragraph}>
          Contar con 2 Grupos Calificados, cumpliendo con las bases de su categoría, con 15
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 15 módulos
          pagados mensualmente. BENEFICIOS: 14% de tu Grupo Central, 7% de los Grupos indirectos
          calificados en tu primera línea y 1% de los Grupos indirectos calificados en tu segunda
          línea.
        </p>

        <p className={styles.paragraphTitle}>5° Nivel: Líder de Grupo 3 Estrellas </p>
        <p className={styles.paragraph}>
          Contar con 3 Grupos Calificados, cumpliendo con las bases de su categoría, con 18
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y con 18
          módulos pagados mensualmente. BENEFICIOS: 15% de tu Grupo Central, 7% de los Grupos
          directos calificados en tu primera línea, 1% de los Grupos indirectos calificados en tu
          segunda línea y 1% de los Grupos indirectos calificados en tu tercera línea en adelante.
        </p>

        <p className={styles.paragraphTitle}>6° Nivel: Líder 5 Estrellas </p>
        <p className={styles.paragraph}>
          Contar con 5 Grupos Calificados, cumpliendo con las bases de su categoría, con 20
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 20 módulos
          pagados mensualmente. BENEFICIOS: 16% de tu Grupo Central, 7% de los Grupos directos
          calificados en tu primera línea, 2% de los Grupos indirectos calificados en tu segunda
          línea, 1% de los Grupos indirectos calificados en tu tercera línea en adelante.
        </p>

        <p className={styles.paragraphTitle}>7° Nivel: Líder 8 Estrellas </p>
        <p className={styles.paragraph}>
          Contar con 8 Grupos Calificados, cumpliendo con las bases de su categoría, con 25
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 25 módulos
          pagados mensualmente. BENEFICIOS: 17% de tu Grupo Central, 7% de los Grupos directos
          calificados en tu primera línea, 2% de los Grupos indirectos calificados en tu segunda
          línea, 1% de los Grupos indirectos calificados en tu tercera línea en adelante.
        </p>

        <p className={styles.paragraphTitle}>8° Nivel: Líder 11 Estrellas </p>
        <p className={styles.paragraph}>
          Contar con 11 Grupos Calificados, cumpliendo con las bases de su categoría, con 30
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 30 módulos
          pagados mensualmente. BENEFICIOS: 18% de tu Grupo Central, 8% de los Grupos directos
          calificados en tu primera línea, 2% de los Grupos indirectos calificados en tu segunda
          línea, 1% de los Grupos indirectos calificados en tu tercera línea en adelante.
        </p>

        <p className={styles.paragraphTitle}>9° Nivel: Líder 15 Estrellas </p>
        <p className={styles.paragraph}>
          Contar con 15 Grupos calificados, cumpliendo con las bases de su categoría, con 35
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 35 módulos
          pagados mensualmente. BENEFICIOS: 19% de tu Grupo Central, 8% de los Grupos directos
          calificados en tu primera línea, 2% de los Grupos indirectos calificados en tu segunda
          línea, 1% de los Grupos indirectos calificados en tu tercera línea en adelante.
        </p>

        <p className={styles.paragraphTitle}>10° Nivel: Partner Independiente </p>
        <p className={styles.paragraph}>
          Contar con 20 Grupos calificados, cumpliendo con las bases de su categoría, con 50
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central y 50 módulos
          pagados mensualmente. BENEFICIOS: 20% de tu Grupo Central, 8% de los Grupos directos
          calificados en tu primera línea, 2% de los Grupos indirectos calificados en tu segunda
          línea, 1% de los Grupos indirectos calificados en tu tercera línea en adelante, hasta el
          infinito.
        </p>

        <p className={styles.paragraphTitle}>11° Nivel: Golden Partner </p>
        <p className={styles.paragraph}>
          Contar con 35 Grupos calificados, cumpliendo con las bases de su categoría, con 95
          Consultoras activas, un mínimo de 9 Consultoras ordenando en tu Grupo Central, 95 módulos
          pagados mensualmente y con 20 Líderes directas con categoría superior a 5 Estrellas.
          BENEFICIOS: 25% de tu Grupo Central, 10% de los Grupos directos calificados en tu primera
          línea, 2% de los Grupos indirectos calificados en tu segunda línea, 1% de los Grupos
          indirectos calificados en tu tercera línea en adelante, hasta el infinito.
        </p>

        <p className={styles.paragraphTitle}>
          Requerimientos para la Consecutividad del Programa de Carrera TERRAMAR:
        </p>

        <ol type='a'>
          <li className={styles.list}>
            <span className={styles.span}>
              La Consultora o Líder debe cumplir mes con mes con los requisitos que exige su nivel
              según la Tabla de Linaje TERRAMAR; de no conseguirlo algún mes incurrirá en su primera
              falta.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              La Líder podrá tener un máximo de dos faltas consecutivas; una vez que se tengan estas
              3 faltas se descenderá automáticamente al nivel inmediato inferior.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              El infinito de la 2da línea en adelante se pagará de acuerdo al número de estrellas o
              líderes en la primera línea. Es decir, si una líder tiene 5 grupos en su primera línea
              debe tener 5 líneas desprendidas (hacia abajo) para cobrar el infinito. Se pagan las
              líneas al infinito dependiendo del número de grupos activos en su primera línea
              directa.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Un reclutador debe de mantener una relación de liderazgo profesional con los
              Consultores Independientes de su organización y debe de cumplir con las obligaciones
              de desempeñarse como mentor de buenas prácticas de ventas para las ventas, o en la
              entrega de productos al consumidor final.
            </span>
          </li>
        </ol>

        <p className={styles.paragraphTitle}>
          Lineamientos para Pagos de Transacciones Comerciales Mercantil
        </p>
        <p className={styles.span}>
          Para obtener el pago de transacciones comerciales mercantil mensual como Emprendedora o
          Líder debe cumplir con los siguientes lineamientos basados en la Tabla de Linaje TERRAMAR
          que a continuación describimos:
        </p>

        <p className={styles.paragraphTitle}>TABLA DE LINAJE</p>

        <div className={styles.imagecenter}>
          <img src={tableESP} alt='linage table' className={styles.legalImage2} />
        </div>

        <p className={styles.paragraphTitle}>REGLAMENTO DE LINAJE</p>

        <p className={styles.paragraphTitle}>
          Calificación de la Patrocinada antes que la Patrocinadora
        </p>
        <p className={styles.span}>
          Si la Patrocinadora tiene una Consultora Directa (Patrocinada) que se promueve a
          Emprendedora antes que ella, la Patrocinadora tendrá 4 meses para promoverse y recuperarla
          de no hacerlo la perderá.
        </p>

        <p className={styles.paragraphTitle}>Rebase de Linaje hasta Partner Independiente</p>
        <p className={styles.span}>
          Para poder recibir el pago de transacciones comerciales de sus Grupos Directos, éstos
          deben de ser de su misma categoría o inferior. Si uno de tus Grupos crece más que tú,
          dejarás de recibir el pago de transacciones comerciales de su linaje hasta que lo iguales
          en categoría. No hay límite de tiempo para esto, siempre y cuando la líder que te haya
          rebasado, no haya alcanzado la categoría de Partner Independiente. Si una líder
          descendente te rebasa y llega al nivel de Partner Independiente, tendrás 2 años para
          alcanzarla en categoría.
        </p>

        <p className={styles.span}>
          Si una líder descendente te rebasa en el nivel de Partner Independiente, tendrás que
          contar con el mismo número de grupos directos que ella para poder acceder a estas
          comisiones.
        </p>

        <p className={styles.paragraphTitle}>Reubicación</p>
        <p className={styles.span}>
          Si en algún momento una Líder se cae de nivel por tener 3 faltas en su categoría, será
          reubicada al nivel al que está cumpliendo de las bases en el último mes de las faltas.
        </p>

        <p className={styles.paragraphTitle}>Cambios de Linaje</p>
        <p className={styles.span}>
          Para poder cambiar de linaje será necesario que hayan pasado 6 meses sin hacer pedido para
          volver a inscribirse con distinta patrocinadora, este beneficio solo se otorgará a las
          CONSULTORAS O EMPRENDEDORAS.
        </p>

        <p className={styles.span}>
          Si una persona fue líder no podrá cambiarse de linaje, si espera los 6 meses de no hacer
          pedido, deberá quedarse como directa de la compañía o con la misma patrocinadora, según
          sea su decisión.
        </p>

        <p className={styles.paragraphTitle}>PROGRAMAS DE LINAJE</p>

        <ol>
          <li className={styles.list}>CLUB ELITE</li>
          <p className={styles.paragraph}>
            Obtienes diferentes beneficios por ser una consultora ordenando mes a mes, tales como:
          </p>
          <p className={styles.paragraph}>
            Una vez que has ingresado como Consultor, a partir del segundo mes de compra consecutiva
            obtienes:
            <ul>
              <li className={styles.list}>Segundo Mes.</li>
              <ul>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Producto de Regalo (Mascarilla de Cabello 500g).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Envío Gratis.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Regalo de actividad del mes anterior (Revisar si en USA habrá premio de
                    actividad).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Comprar alguno de los paquetes Club Elite (ayuda de venta)
                  </span>
                </li>
              </ul>
              <li className={styles.list}>Tercer Mes.</li>
              <ul>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Producto de Regalo (Aceite para el Cabello 3 en 1 Jumbo 500ml).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Envío Gratis.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Comprar alguno de los paquetes Club Elite (ayuda de venta).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Tienda virtual</span>
                </li>
              </ul>
              <li className={styles.list}>Cuarto Mes.</li>
              <ul>
                <li className={styles.list}>
                  <span className={styles.span}>
                    20% de descuento adicional al descuento vigente del mes
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Envío Gratis.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Comprar alguno de los paquetes Club Elite (ayuda de venta).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Tienda virtual</span>
                </li>
              </ul>
              <li className={styles.list}>Quinto Mes.</li>
              <ul>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Terra Voucher (canjeable en las bases de los programas de linaje).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Envío Gratis.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Comprar alguno de los paquetes Club Elite (ayuda de venta).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Tienda virtual</span>
                </li>
              </ul>
              <li className={styles.list}>Sexto Mes.</li>
              <ul>
                <li className={styles.list}>
                  <span className={styles.span}>Set Oleo Elite</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Envío Gratis.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Comprar alguno de los paquetes Club Elite (ayuda de venta).
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>Tienda virtual</span>
                </li>
              </ul>
              <p className={styles.paragraph}>
                Del séptimo mes en adelante se mantiene mensualmente el envío gratis, el acceso a la
                tienda en línea de TERRAMAR y la compra de un paquete club elite por mes, siempre y
                cuando se hagan pedidos consecutivos de un módulo o más.
              </p>
            </ul>
          </p>

          <li className={styles.list}>BUSCANDO TALENTO</li>
          <p className={styles.paragraphTitle}>NIVEL A</p>
          <p className={styles.paragraph}>Participan solamente Consultoras.</p>
          <p className={styles.paragraph}>
            Ingresa a una o dos nuevas consultoras directas, con pedido de un módulo pagado, en el
            mes que se anuncia la promoción, y que las consultoras repitan módulo al siguiente mes.
            Solo la Patrocinadora recibe el regalo anunciado en la revista del mes.
          </p>

          <p className={styles.paragraphTitle}>NIVEL B</p>
          <p className={styles.paragraph}>
            Participan solamente Consultoras a Líder de Grupo Estrella.
          </p>
          <p className={styles.paragraph}>
            Al ingresar 3 nuevas consultoras directas, con un módulo pagado durante el mes, y que
            las 3 repitan módulo al siguiente mes, obtienes una tarjeta de regalo con valor de $50
            USD. No está limitado; por cada 3 nuevos patrocinios directos, recibes $50 USD, el
            regalo es únicamente para la patrocinadora.
          </p>

          <p className={styles.paragraphTitle}>NIVEL C</p>
          <p className={styles.paragraph}>Participan Emprendedoras a Golden Partner.</p>
          <p className={styles.paragraph}>
            Que dos consultoras directas tengan un patrocinio cada uno. Con un módulo pagado,
            durante el mes, y que las consultoras repitan módulo en el mes siguiente. Recibes un
            vale de producto por $60 USD precio público. A partir de 3 consultoras directas
            patrocinando, puedes elegir entre, un vale de producto por $150 USD precio público, ó
            $50 USD en una tarjeta de regalo.
          </p>
          <p className={styles.paragraph}>
            No está limitado; por cada 3 consultoras directas patrocinando, escoges $150 USD precio
            público en producto o $50 USD, en tarjeta de regalo. El regalo es únicamente para la
            patrocinadora
          </p>

          <li className={styles.list}>CRECER TE HACE GANAR</li>
          <p className={styles.paragraph}>
            Esta promoción consiste en pagar durante 4 meses el grupo promovido en su primera línea
            directa como si fuera parte de su grupo central. TERRAMAR BRANDS se enorgullece de ser
            la única compañía que pague significativamente al promover grupos.
          </p>
          <p className={styles.paragraph}>
            Entendemos que promover grupos sólidos es una de las claves de crecimiento dentro del
            Linaje de TERRAMAR. CRECER TE HACE GANAR le permite a una emprendedora crecer al nivel
            más alto, es decir, Partner Independiente en 1 año o menos, si así lo desea.
          </p>
          <p className={styles.paragraph}>
            Esta promoción consiste en pagar durante 4 meses el grupo promovido en su primera línea
            directa como si fuera parte de su grupo central. El porcentaje pagado es el que gana en
            su categoría al promover ese grupo en su primera línea. Si el grupo promovido llega a
            tener una falta en el lapso de estos 4 meses, perderá el derecho de obtener este
            beneficio.
          </p>
          <p className={styles.paragraph}>
            Esta promoción es exclusiva para poder repoblar su grupo central lo antes posible y que
            no le afecte en el crecimiento la promoción de grupos. Esta es la promoción más costosa
            de la compañía. Por su uso es estrictamente para el pago de transacciones comerciales y
            no cuenta para eventos, autos, bonos y otras promociones paralelas.
          </p>
          <p className={styles.paragraph}>
            Así consecutivamente hasta llegar a Partner Independiente. Una vez que la Partner
            Independiente cuente con 20 grupos activos en la primera línea y 50 módulos en Grupo
            Central y que todos estos grupos se hayan beneficiado de la promoción Crecer te hace
            ganar durante sus 4 meses de calificación, esta promoción se termina. Es decir, todos
            los nuevos grupos se pagarán al 8% como lo indica la tabla de linaje
          </p>

          <li className={styles.list}>SACO LÍDER EJECUTIVA</li>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>
                Alcanzar el nivel Líder una estrella y tener un mes de mantenimiento de la estrella
                desprendida para obtener dicho premio.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                El Saco Líder Ejecutiva solo se entrega una sola vez.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Es requisito cerrar en tu categoría</span>
            </li>
          </ul>

          <li className={styles.list}>TU DECIDES</li>
          <ol type='a'>
            <li className={styles.list}>
              <span className={styles.span}>Calificar a Líder de Grupo Estrella.</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Cumplir con las bases establecidas de la tabla de linaje (No aplican los grupos en
                promoción).
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Contar con 5 CONSULTORAS PATROCINANDO en Grupo Central, de las cuales 3 mínimo deben
                ser directas. Esta base se debe cumplir durante 2 meses para que el premio sea
                entregado en el 3er mes.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                El calificar a algún nivel superior NO cuenta como base, es necesario contar con las
                5 CONSULTORAS PATROCINANDO en Grupo Central, de las cuales 3 deben ser directas.
              </span>
            </li>
            <p className={styles.paragraphTitle}>BENEFICIOS:</p>
            <p className={styles.paragraph}>
              Se entrega una un regalo a escoger, por ejemplo, Smartwatch, teléfono celular, laptop,
              Smart tv.
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>Los premios pueden variar sin previo aviso</span>
              </li>
            </ul>

            <p className={styles.paragraph}>Requerimientos para la consecución de la Promoción:</p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>
                  Si la líder tuviera alguna falla en la base, es decir, al segundo mes no lo
                  lograra, empezaría de nuevo, hasta que logre la base 2 meses consecutivos.
                </span>
              </li>
            </ol>
          </ol>

          <li className={styles.list}>BONO IMPULSANDO LÍDERES</li>
          <p className={styles.paragraph}>
            Al iniciar la carrera Terramar y desprende grupos, puedes ganar los siguientes bonos.
          </p>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>
                Si desprendes 2 grupos, es decir, alcanzas el nivel Líder 2 estrellas, recibes un
                bono de $500 USD.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Al desprender la siguiente estrella, es decir, alcanzar el nivel Líder 3 Estrellas,
                recibes un bono de $750 USD.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Al desprender 2 estrellas más, es decir alcanzar el nivel Líder 5 Estrellas, recibes
                un bono de $1,500.00 USD.
              </span>
            </li>
          </ul>

          <p className={styles.paragraph}>
            Una vez alcanzado el nivel Líder 5 Estrellas, puedes ganar bonos, por hacer crecer a tus
            estrellas de tu primera línea, por cada una de ellas puedes ganar:
          </p>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>
                $500 USD si una de ellas alcanza el nivel Líder 1 Estrella.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                $1,000.00 USD si tu estrella alcanza el nivel Líder 3 Estrellas.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                $2,500 USD si tu estrella alcanza el nivel Líder 5 estrellas.
              </span>
            </li>
          </ul>

          <p className={styles.paragraph}>
            Los bonos se entregan al tener dos meses de mantenimiento después de que logran los
            desprendimientos.
          </p>
          <p className={styles.paragraph}>
            La promoción está limitada a 14 GRUPOS y se puede ganar con ESTRELLAS EXISTENTES O CON
            NUEVAS.
          </p>
          <p className={styles.paragraph}>
            Sólo aplica el bono para Líderes que cumplan LA TOTALIDAD de las bases de su categoría.
          </p>
          <p className={styles.paragraph}>
            Las líderes que hayan desprendido estrellas deben mantenerse activas para continuar en
            el Programa.
          </p>
          <p className={styles.paragraph}>
            Si pierdes continuidad y vuelves a ingresar al Programa debes comenzar con el bono del
            nivel siguiente al último que recibiste.
          </p>
          <p className={styles.paragraph}>
            No hay límite de tiempo para tu recuperación en el programa.
          </p>

          <li className={styles.list}>BONO ESTELAR DIAMANTE</li>
          <p className={styles.paragraph}>Bono Estelar: Categoría Partner Independiente (Fase 1)</p>
          <p className={styles.paragraph}>
            GANAS $50,000.00 USD SI CUMPLES LAS SIGUIENTES BASES Y LAS MANTIENES DURANTE 6 MESES
            CONSECUTIVOS.
          </p>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>
                20 Líderes 5 Estrellas EN TU PRIMERA LÍNEA. CADA UNA DE ELLAS DEBE:
              </span>
              <p className={styles.span}>
                Realizar y pagar 30 módulos mensuales y tener 20 consultoras ACTIVAS.
              </p>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>75 Consultoras Activas EN TU GRUPO CENTRAL</span>
            </li>
          </ul>
          <p className={styles.paragraph}>
            Para que se pague el bono no se debe perder la continuidad. Se debe ingresar y pagar los
            módulos correspondientes MENSUALMENTE. No hay períodos de gracia ni excepción en el
            cumplimiento de las bases.
          </p>

          <p className={styles.paragraph}>
            Bono Estelar: Categoría Partner Independiente y Golden Partner (Fase 2)
          </p>
          <p className={styles.paragraph}>
            GANAS $100,000.00 USD SI CUMPLES LAS SIGUIENTES BASES Y LAS MANTIENES DURANTE 6 MESES
            CONSECUTIVOS.
          </p>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>20 Líderes 15 Estrellas EN TU PRIMERA LÍNEA</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>100 Consultoras Activas EN TU GRUPO CENTRAL</span>
            </li>
          </ul>
          <p className={styles.paragraph}>
            Para que se pague el bono no se debe perder la continuidad. Se debe ingresar y pagar los
            módulos correspondientes MENSUALMENTE. No hay períodos de gracia ni excepción en el
            cumplimiento de las bases.
          </p>
          <p className={styles.paragraph}>
            Nota: TERRAMAR BRANDS se reserva el derecho de cambiar el monto de los bonos por
            Promoción de Bonos sin previo aviso
          </p>

          <li className={styles.list}>TERRAMAR AL VOLANTE</li>
          <p className={styles.paragraph}>Participan: Líder 5 Estrellas a Líder 11 Estrellas</p>
          <p className={styles.paragraph}>
            Se tomará como periodo de calificación tres meses del año calendario. El mantenimiento
            corresponde a 24 meses adicionales a los meses de calificación.
          </p>
          <p className={styles.paragraph}>
            Serán elegibles para calificar, las Líderes que en su Grupo Central hayan cumplido con
            los siguientes requisitos durante 1 trimestre consecutivo:
          </p>

          <ul>
            <li className={styles.list}>
              <span className={styles.span}>1 Módulo personal.</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>1 Patrocinio directo.</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>8 Consultoras Patrocinando en Grupo Central.</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                3 o más líderes (Emprendedora en adelante) en la 1ra línea con las siguientes bases:
              </span>
              <ul>
                <li className={styles.list}>
                  <span className={styles.span}>1 Módulo personal.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>1 Patrocinio Directo.</span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    La combinación de las líderes cerrando sumen 12 consultoras patrocinando.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Sólo participan las líderes que tengan 2 o más consultoras patrocinando.
                  </span>
                </li>
                <li className={styles.list}>
                  <span className={styles.span}>
                    Deben tener un desprendimiento neto cada 6 meses
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p className={styles.paragraph}>
            Durante todo el periodo promocional, los patrocinios personales, las consultoras
            patrocinando y sus nuevos patrocinios deberán hacer un pedido consecutivo al mes
            siguiente que se les consideró para la base. Cualquier incumplimiento en las
            repeticiones de dichas consultoras, se les considerará falla o strike.
          </p>
          <p className={styles.paragraph}>
            Al contar con estos requisitos durante 3 meses, al 4° mes se entrega el automóvil,
            condicionado a mantener dicha productividad durante 24 meses.
          </p>
          <p className={styles.paragraph}>
            La líder participante puede llegar a tener hasta 3 strikes en un periodo de 12 meses, es
            decir hasta 6 en 24 meses rotativos.
          </p>
          <p className={styles.paragraph}>
            Se considera falla o strike, el no cumplir cualquiera de los requisitos arriba
            mencionados.
          </p>
          <p className={styles.paragraph}>
            Al tener una 4ª falla, el beneficio del automóvil puede ser retirado, y no podrá volver
            a participar para este beneficio.
          </p>
          <p className={styles.paragraph}>Los strikes o fallas no deben ser consecutivos.</p>
          <p className={styles.paragraph}>
            Adicional a estos strikes, la líder participante puede llegar a tener hasta 2 strikes o
            fallas más por año en las que la líder tiene la opción de cubrir el pago mensual del
            financiamiento del auto correspondiente por la cantidad de $300.00 USD, para que
            continúe haciendo uso del automóvil y no le sea retirado. De igual manera estos strikes
            no deben ser consecutivos entre sí, ni con los strikes anteriores.
          </p>
          <p className={styles.paragraph}>
            En caso de que, en el periodo promocional, la Líder tuviera dos strikes consecutivos,
            ésta tendrá que devolver el automóvil de manera inmediata, en las mismas condiciones en
            que fue entregado inicialmente. O si durante los primeros doce meses, se tuviera seis
            strikes, aunque los 5 anteriores no fueran consecutivos, de igual manera se tendrá que
            devolver el automóvil. Lo mismo si esto sucediera durante el segundo, año, es decir,
            entre el mes 13 y 24 del periodo promocional.
          </p>
          <p className={styles.paragraph}>
            En cualquiera de los casos anteriores, que automóvil fuera retirado a la líder, ésta no
            podrá volver a participar en el programa “Terramar al Volante”
          </p>
          <p className={styles.paragraph}>
            La líder que complete 24 meses cumpliendo las bases, tomando en cuenta las 5 fallas como
            máximo por cada 12 meses, se le hará entrega de la factura del automóvil a su nombre.
          </p>
          <p className={styles.paragraph}>
            EL AUTOMÓVIL DEL PROGRAMA “TERRAMAR AL VOLANTE”, SÓLO SE ENTREGA UNA VEZ, ES DECIR, UNA
            VEZ QUE HAYA CONCLUIDO EL PROGRAMA Y A LA LÍDER SE LA HAYA HECHO ENTREGA DEL AUTOMÓVIL,
            DICHA LÍDER, NO PODRÁ PARTICIPAR EN ESTE PROGRAMA NUEVAMENTE
          </p>

          <li className={styles.list}>BRILLANTE CRECIMIENTO</li>
          <p className={styles.paragraph}>
            LOS PREMIOS (JOYERÍA) SÓLO SE ENTREGAN UNA VEZ, ES DECIR, SI UNA CONSULTORA CAE UN NIVEL
            Y MÁS TARDE SEVUELVE A RECUPERAR NO SE LE VUELVEN A ENTREGAR LOS REGALOS.
          </p>
          <p className={styles.paragraph}>
            Requerimientos para calificar en la Promoción de Joyería por subir de nivel:
          </p>
          <p className={styles.paragraph}>
            (a) Desprender los grupos necesarios según el Linaje de TERRAMAR.
          </p>
          <p className={styles.paragraph}>
            (b) Cerrar con los grupos desprendidos según el Linaje TERRAMAR, es decir cobrar con la
            categoría estipulada.
          </p>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>
                Líder 5 Estrellas > cumplir con las bases de su categoría.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Líder 8 Estrellas > cumplir con las bases de su categoría.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Líder 11 Estrellas > cumplir con las bases de su categoría.
              </span>
            </li>
          </ul>
          <p className={styles.paragraphTitle}>BENEFICIOS</p>
          <p className={styles.paragraph}>
            Las Líderes se llevarán una pieza de joyería u otra en función del nivel al que hayan
            calificado y se entregarán en las Reuniones de Reconocimiento.
          </p>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>
                Líder 5 Estrellas > anillo de oro con zafiros y rubíes.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Líder 8 Estrellas > pendientes de oro con zafiros y rubíes.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Líder 11 Estrellas > gargantilla de oro con zafiros y rubíes.
              </span>
            </li>
          </ul>
          <p className={styles.paragraph}>
            Nota: TERRAMAR BRANDS se reserva el derecho de cambiar los premios del Programa de
            Joyería sin previo aviso.
          </p>

          <li className={styles.list}>PLAN DE RETIRO Y HERENCIA</li>
          <p className={styles.paragraph}>
            REQUERIMIENTOS PARA CALIFICAR EN LA PROMOCIÓN DE RETIRO:
          </p>
          <ol type='a'>
            <li className={styles.list}>
              <span className={styles.span}>
                Haberse mantenido durante 5 años (sin faltas) desde que calificó a Líder 11
                Estrellas, para recibir el derecho de retiro durante10 años.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                Si se mantiene durante 10 años, recibirá el derecho de por vida.
              </span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                El Programa “Crecer te hace ganar”, no cuenta como base.
              </span>
            </li>
          </ol>
          <p className={styles.paragraphTitle}>BENEFICIOS</p>
          <p className={styles.paragraph}>
            Se pagará un 100% de transacciones comerciales durante 10 años a la consultora que se
            haya mantenido durante 5 años desde que calificó a Líder 11 Estrellas, o de por vida si
            se mantuvo 10 años.
          </p>
          <p className={styles.paragraph}>
            El pago de transacciones comerciales se hará de acuerdo al número de grupos que tengan
            en su primera línea; es decir; si la consultora tiene 20 grupos en su primera línea
            deberá tener 20 líneas desprendidas para poder cobrar el infinito.
          </p>
          <p className={styles.paragraph}>
            El pago de transacciones comerciales se hará basada en la productividad de todas sus
            líneas de acuerdo al Linaje de TERRAMAR.
          </p>
          <p className={styles.paragraph}>
            Al estar retirada la consultora no deberá cumplir con las bases de su Grupo Central, ni
            las personales; es decir, sólo cobrará de su 1ra línea en adelante.
          </p>
          <p className={styles.paragraph}>
            Si cumple con las bases de su grupo central, sin el módulo o pedido personal, recibirá
            comisión de su Grupo Central.
          </p>

          <p className={styles.paragraphTitle}>
            REQUERIMIENTOS PARA LA CONSECUCIÓN DE LA PROMOCIÓN:
          </p>
          <p className={styles.paragraph}>
            La líder deberá mantenerse 5 ó 10 años consecutivos desde el nivel de Líder 11
            Estrellas, si por algún motivo ésta cayera a un nivel inferior deberá volver a empezar
            desde cero con todos los requerimientos.
          </p>
          <p className={styles.paragraph}>
            PARA OBTENER ESTE BENEFICIO, LA LÍDER DEBE SOLICITARLO POR ESCRITO AL DEPARTAMENTO DE
            LINAJE Y DICHO DEPARTAMENTO DEBE FIRMAR LA SOLICITUD.
          </p>
          <p className={styles.paragraph}>
            Nota: El Linaje TERRAMAR para Comerciantes Independientes podrá variar o cancelar en
            cualquier momento los lineamientos y políticas de este procedimiento debido a cambios
            que pudieran ocurrir en las condiciones económicas que fueron tomadas en cuenta para
            definirlos.
          </p>

          <li className={styles.list}>EXPERIENCIA 15 ESTRELLAS</li>
          <p className={styles.paragraph}>
            Al llegar al nivel Líder 15 Estrellas, TERRAMAR te obsequiará una experiencia
            personalizada para ti, y las líderes de tu primera línea.
          </p>
          <p className={styles.paragraph}>
            Se requieren 3 meses de mantenimiento para obtener el beneficio, si durante los 3 meses
            desprendes nuevas estrellas, se tomarán en cuenta para la experiencia, hasta un máximo
            de 19 estrellas.
          </p>

          <li className={styles.list}>TERRAMAR AL VOLANTE PREMIUM</li>
          <p className={styles.paragraph}>
            Una vez calificada en la categoría Líder 15 Estrellas tienes que hacer la siguiente
            base:
          </p>
          <p className={styles.paragraph}>Mantener durante 3 meses:</p>
          <ol type='a'>
            <li className={styles.list}>
              <span className={styles.span}>50 Consultoras activas</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>50 módulos en Grupo Central</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>15 Grupos activos en tu primera línea</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>15 consultoras patrocinando</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>
                5 líderes o más en tu primera línea con categorías mayores a L5E
              </span>
            </li>
          </ol>
          <p className={styles.paragraph}>
            Al cumplir esta base durante 3 meses consecutivos al 4to mes se entrega el automóvil,
            pero tendrás que cumplir por 24 meses más esta base para ser el propietario del
            automóvil (es decir se te entreguen los papeles a tu nombre).
          </p>
          <p className={styles.paragraphTitle}>BENEFICIOS:</p>
          <p className={styles.paragraph}>
            Se te entrega un automóvil de lujo, para que puedas ser distinguida por tu trabajo y
            esfuerzo; además de proporcionarte un medio de transporte exclusivo dentro de la
            industria de venta directa y multinivel.
          </p>
          <p className={styles.paragraphTitle}>
            REQUERIMIENTOS PARA LA CONSECUCIÓN DE LA PROMOCIÓN:
          </p>
          <p className={styles.paragraph}>
            Si la líder tuviera alguna falta durante los meses previos a la entrega del automóvil;
            se empezaría a contar de cero otra vez para la entrega del mismo. Es decir, tendría que
            estar otros 2 ó 3 meses realizando las bases de la promoción del Automóvil.
          </p>
          <p className={styles.paragraph}>
            Nota: TERAMAR BRANDS se reserva el derecho de cambiar el modelo del automóvil sin previo
            aviso.
          </p>

          <li className={styles.list}>DIA PARTNER</li>
          <p className={styles.paragraph}>
            Al llegar al máximo nivel de la carrera se te dará un día especial con todo el lujo y
            exclusividad que distinguen a TERRAMAR.
          </p>
          <p className={styles.paragraph}>
            Se necesita cumplir un mantenimiento de 6 meses en la categoría sin fallas.
          </p>
          <p className={styles.paragraph}>
            Todas las experiencias que TERRAMAR otorga, son hechas a la medida y se planean al
            momento.
          </p>

          <li className={styles.list}>SEGURO DE VIDA PARTNER INDEPENDIENTE</li>
          <p className={styles.paragraph}>
            Al llegar a Partner Independiente acreditarás como beneficio un seguro de vida
            individual, anual, renovable. Se otorga este beneficio después de haber cumplido con
            cuatro meses de mantenimiento, y si en algún momento posterior, no se cumple con las
            bases de la categoría, se cancelará el seguro, volviéndose a contratar cuando vuelva a
            lograr las bases de Partner Independiente y cumplir los 6 meses de mantenimiento.
          </p>
          <p className={styles.paragraph}>
            El contrato de seguro se celebrará bajo las condiciones generales vigentes de la
            compañía aseguradora que TM BRANDS MANAGEMENT USA LLC. considere.
          </p>
          <p className={styles.paragraph}>
            La aceptación o declinación de la compañía aseguradora, de la expedición de la Póliza;
            es solamente responsabilidad de quienes celebran el contrato (Aseguradora y líder
            Partner Independiente). Quedando TM BRANDS MANAGEMENT USA LLC. sus ejecutivos,
            representantes, empleados y a las empresas del mismo grupo o intereses corporativos de
            Terramar Brands exentos de esta responsabilidad.
          </p>
          <p className={styles.paragraph}>
            En caso de siniestro operarán las condiciones generales del seguro contratado,
            librándose a TM BRANDS MANAGEMENT USA LLC, a sus ejecutivos, representantes, empleados y
            a las empresas del mismo grupo o intereses corporativos de Terramar Brands de toda
            obligación de pago de la suma asegurada de la póliza contratada.
          </p>

          <li className={styles.list}>VIAJES INTERNACIONALES</li>
          <p className={styles.paragraph}>
            En TERRAMAR tienes la oportunidad de participar para ganarte viajes nacionales e
            internacionales, sin importar el nivel que te encuentres en la carrera TERRAMAR; al
            lanzarse cada evento, se publican las bases específicas para participar en él. Siempre
            adecuadas al nivel que te encuentres en ese momento.
          </p>
        </ol>

        <p className={styles.paragraphTitleCenter}>PROGRAMA INTERNACIONAL</p>
        <p className={styles.paragraph}>
          TERRAMAR te da la oportunidad de desarrollarte actualmente en México y Estados Unidos,
          para poder hacerlo en un país diferente al de tu residencia, debes cumplir con los
          requisitos de grupo central, de acuerdo a la tabla de linaje en tu país de residencia, es
          decir las consultoras ordenando, y módulos mínimos de tu nivel en la carrera, deben
          hacerse en tu país de residencia.
        </p>
        <p className={styles.paragraph}>
          Las consultoras independientes de TERRAMAR están autorizados para promocionar o vender los
          productos de TERRAMAR, y para patrocinar solo en los países en los que TERRAMAR está
          autorizado para realizar negocios, como se anuncia en las publicaciones oficiales de la
          Empresa. Los productos o venta de TERRAMAR no se pueden dar, transferir, distribuir,
          enviar o vender en ningún País No Autorizado.
        </p>
        <p className={styles.paragraph}>
          Las consultoras, emprendedoras o líderes no tienen autoridad para tomar alguna medida en
          algún país para la introducción o promoción de la Empresa.
        </p>
        <p className={styles.paragraph}>
          Esto incluye, pero no se limita a, cualquier intento de registrar, reservar o asegurar de
          cualquier otro modo los nombres, marcas registradas, nombres comerciales, derechos de
          autor, patentes u otra propiedad intelectual de la Empresa, para asegurar la aprobación de
          productos o prácticas comerciales, o para establecer contactos comerciales o
          gubernamentales. Usted acepta indemnizar a la Empresa por todos los costos en los que ésta
          incurra por cualquier acción correctiva necesaria para exonerar a la Empresa en caso de
          que usted actúe de manera inapropiada supuestamente en nombre de la Empresa.
        </p>
        <p className={styles.paragraph}>
          Solo después de que la Empresa haya anunciado que un país está oficialmente abierto al
          negocio las consultoras, emprendedoras o líderes pueden realizar negocios en ese País
          Oficial promoviendo la Empresa (o entidad relacionada) y / o promoviendo, comercializando
          o vendiendo productos, e inscribiendo a otros Consultores o Clientes. Se requiere que las
          consultoras, emprendedoras o líderes cumplan con todas las leyes, normas y regulaciones
          del País Oficial. La Consultora, emprendedora o líder solo puede utilizar materiales
          promocionales aprobados por la Empresa para su uso en un País Oficial, y vender solo
          productos aprobados para su venta en ese país.
        </p>
        <p className={styles.paragraph}>
          Además, ninguna consultora, emprendedora o líder puede, en ningún País No Autorizado:
        </p>
        <ul>
          <li className={styles.list}>
            <span className={styles.span}>
              Anunciar a la Empresa, el Plan de Compensación o sus productos.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Ofrecer los productos de la Empresa para su venta o distribución.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Llevar a cabo reuniones de ventas, de inscripciones o de capacitación.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Inscribir o intentar inscribir a posibles Clientes o Consultores.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Aceptar pagos por inscripción o reclutamiento de ciudadanos de países donde TERRAMAR
              no realiza negocios.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Promover la expansión internacional a través de Internet o en las publicaciones
              promocionales.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Llevar a cabo cualquier otra actividad con el propósito de vender productos TERRAMAR,
              establecer una organización de mercadeo o promover la carrera TERRAMAR.
            </span>
          </li>
        </ul>
        <p className={styles.paragraph}>
          Una consultora, emprendedora o líder es el único responsable del cumplimiento de todas las
          leyes, los requisitos fiscales, las regulaciones aduaneras de inmigración, las normas y
          las regulaciones de cualquier país en el que realice negocios. Las consultoras,
          emprendedoras o líderes aceptan la responsabilidad exclusiva de llevar a cabo sus negocios
          independientes de forma legal dentro de cada país en el que realice negocios.
        </p>
        <p className={styles.paragraph}>
          El derecho de una emprendedora o líder a recibir comisiones en un país puede ser revocado
          en cualquier momento si la Empresa determina que no ha realizado negocios en un país en
          particular, de conformidad con los términos y condiciones contenidos en el presente
          documento o las operaciones que rigen en dicho país.
        </p>
        <p className={styles.paragraph}>
          La Empresa se reserva el derecho de establecer políticas y procedimientos adicionales que
          sean aplicables a un país específico. Una consultora, emprendedora o líder que realiza
          negocios a nivel internacional acepta cumplir con todas las Políticas especiales
          establecidas por la Empresa para el país o países específicos en los que realiza negocios.
        </p>
      </div>
    </div>
  );
};

export default ManualUSAENG;
