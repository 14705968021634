import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Dependencies
import getGlobal from '../Queries/getGlobal';

//Components
import HeroBanner from '../HeroBanner';
import Title from '../Title';
import MenuProducts from '../MenuProducts';
import ProductContainer from '../ProductContainer';

const Products = (props) => {
  const [productList, setProductlist] = useState({});
  const { device, webservice, history, getCookie, match } = props;
  const { screenSize } = device;
  const { t, i18n = {} } = useTranslation('products');
  const { language = '' } = i18n;
  const [loading, setLoading] = useState(false);
  const [catSelected, setCatselected] = useState([0, 0]);
  const [showMenu, setShowMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const menuArray = Array(6).fill(0);
  let { catid, type } = useParams();

  const getArrayProducts = useCallback(
    async (depto, subdepto) => {
      setProductlist([]);
      const productsData = categories[depto]?.subCategorias[subdepto]?.productos || [];
      const productsObject = await productsData
        ?.map((el) => el)
        ?.reduce((oldProducts: any, product) => {
          const { Agrupador = '', Codigo } = product;
          const primaryKey = Agrupador || Codigo;
          oldProducts[primaryKey] = oldProducts[primaryKey] || [];
          oldProducts[primaryKey].push(product);

          return oldProducts;
        }, {});

      setProductlist(productsObject);
    },
    [categories],
  );

  const getCategories = useCallback(
    async (sectionID) => {
      setLoading(true);
      const resp = await getGlobal({
        tmbservice: 'getCatalogoProductos',
        jsonBody: {
          idioma: window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1,
        },
      });
      setCategories(resp?.categorias || []);
      let productsData =
        resp?.categorias[sectionID ? sectionID : 0]?.subCategorias[0]?.productos || [];

      if (type === 'all') {
        const allSubsInCategorie = resp?.categorias[sectionID ? sectionID : 0]?.subCategorias || [];
        let subCategoriesProducts = [];

        for (let i = 0; i < allSubsInCategorie.length; i++) {
          subCategoriesProducts.push(allSubsInCategorie[i].productos);
        }

        const allCategoryProductsArray = subCategoriesProducts
          ?.map((el) => el)
          ?.reduce((oldArray: any, products, index) => {
            const isArray = oldArray instanceof Array;
            if (!isArray) {
              oldArray = [];
            }

            for (let i = 0; i < products.length; i++) {
              oldArray.push(products[i]);
            }

            return oldArray;
          }, {});

        productsData = allCategoryProductsArray;
      }

      const productsObject = productsData
        ?.map((el) => el)
        ?.reduce((oldProducts: any, product) => {
          const { Agrupador = '', Codigo } = product;
          const primaryKey = Agrupador || Codigo;
          oldProducts[primaryKey] = oldProducts[primaryKey] || [];
          oldProducts[primaryKey].push(product);

          return oldProducts;
        }, {});

      setProductlist(productsObject);
      setLoading(false);
    },
    [type],
  );

  useEffect(() => {
    getCategories(catid);
  }, [getCategories, catid]);

  function handleCatSelected(id) {
    setCatselected(id);
    window.scroll(0, 0);
  }

  return (
    <div id={styles.Products} className={styles[screenSize]}>
      {screenSize !== 'desktop' && (
        <>
          <HeroBanner
            device={device}
            desktopImg={t('bannerDesktop')}
            mobileImg={t('bannerMobile')}
          />
          <Title device={device} title={t('title')} subtitle={t('subtitle')} />
        </>
      )}

      {screenSize === 'desktop' && (
        <div
          id={styles.HeroBanner}
          className={`${styles.inblock} ${styles.textStyle} ${styles.mainImg}`}>
          <div className={styles.h1}>{t('title')}</div>
          <div className={styles.h5}>{t('subtitle')}</div>
        </div>
      )}

      <div className={styles.container}>
        <div className={`${styles.menu}`}>
          <div
            className={styles.menuHeader}
            onClick={() => {
              if (screenSize === 'phone') setShowMenu(!showMenu);
            }}>
            <i className={`material-icons ${styles.menuIcon}`}>list</i>
            <div className={styles.title}>{t('categories')}</div>
          </div>
          <div className={styles.menuContainer}>
            {((screenSize === 'phone' && showMenu) || screenSize !== 'phone') && (
              <MenuProducts
                webservice={webservice}
                screenSize={screenSize}
                setCatselected={handleCatSelected}
                catSelected={catSelected}
                getCookie={getCookie}
                history={history}
                match={match}
                loading={loading}
                categories={categories}
                getArrayProducts={getArrayProducts}
              />
            )}
          </div>
        </div>

        {(loading && (
          <div className={styles.leftContainer}>
            <div className={styles.productsContainer}>
              {menuArray.map((val, key) => {
                return (
                  <div className={styles.loadingProductContainer} key={key}>
                    <div className={styles.productContent}>
                      <div className={styles.productImage}>&nbsp;</div>
                      <div className={styles.productName}>&nbsp;</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )) || (
          <div className={styles.leftContainer}>
            <div className={styles.productsContainer}>
              {(Object.keys(productList).length > 0 &&
                Object.keys(productList).map((val, key) => {
                  return (
                    <ProductContainer
                      key={key}
                      product={productList[val]}
                      categories={categories}
                      catSelected={catSelected}
                      history={history}
                      language={language}
                      screenSize={screenSize}
                    />
                  );
                })) || <div className={styles.loading}>No hay productos</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
