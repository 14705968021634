import superagent from 'superagent';
import webservice from './webservice';

export default async function postROPAltas(json) {
  let response = {};
  await superagent
    .post(`${webservice}/postROPAltas`)
    .send(json)
    .then((res) => {
      response = res?.body || {};
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
      };
      response = resp;
    });

  return response;
}
