import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//Components
import Banner from '../Banner';
import HeroBanner from '../HeroBanner';
import RecommendedCarousel from './RecommendedCarousel';
import BannerText from '../BannerText';
import ThreeBanners from '../ThreeBanners';
import BlankSpace from '../BlankSpace';
import HeroWithVideo from '../HeroWithVideo';
import BannerSide from '../BannerSide';
import CategoriesCarousel from '../CategoriesCarousel';

// Styles
import styles from './index.module.sass';

const location = process.env.REACT_APP_COUNTRY;

const ADD1 =
  'https://webimages.terramarbrands.com.mx/webpage/home/Banner-01-Site-Terramar-Home.jpg';
const ADD1MOB = 'https://webimages.terramarbrands.com.mx/webpage/home/producto-inicio-mobile.jpg';
const MAINPRODUCT =
  'https://webimages.terramarbrands.com.mx/webpage/home/Banner-02-Site-Terramar-Home.jpg';
const MAINPRODUCTMOB = 'https://webimages.terramarbrands.com.mx/webpage/home/banner-perfume.jpg';

const Home = (props) => {
  const { device = {}, webservice, history } = props;
  const { screenSize = 'desktop' } = device;
  const [showVideo, setShowVideo] = useState(false);

  const videoMX = 'vVuhjafMmVc';
  const videoUS_ES = process.env.REACT_APP_YOUTUBE_VIDEO_ES;
  const videoUS_EN = process.env.REACT_APP_YOUTUBE_VIDEO_EN;

  const { i18n = {}, t } = useTranslation('home');
  const { language = '' } = i18n;

  function closeVideo() {
    setShowVideo(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id={styles.Home} className={styles[screenSize]}>
      {!showVideo && location === 'MX' && (
        <div className={styles.videoContainer}>
          <div className={styles.overlay} onClick={() => closeVideo()}></div>
          <div className={styles.closeVideo} onClick={() => closeVideo()}>
            X
          </div>
          <div className={styles.videoCard}>
            <iframe
              width='95%'
              height='95%'
              src={`https://www.youtube.com/embed/${videoMX}?autoplay=1&mute=1&rel=0&playlist=${videoMX}`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowfullscreen'></iframe>
          </div>
        </div>
      )}

      {!showVideo && location === 'US' && (
        <div className={styles.videoContainer}>
          <div className={styles.overlay} onClick={() => closeVideo()}></div>
          <div className={styles.closeVideo} onClick={() => closeVideo()}>
            X
          </div>
          <div className={styles.videoCard}>
            <iframe
              width='95%'
              height='95%'
              src={`https://www.youtube.com/embed/${
                (language.includes('es') && videoUS_ES) || videoUS_EN
              }?autoplay=1&mute=1`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowfullscreen'></iframe>
          </div>
        </div>
      )}

      <Banner device={device} />

      <BannerText text={t('welcome')} />

      <ThreeBanners
        screenSize={screenSize}
        urlOne='https://webimages.terramarbrands.com.mx/webpage/home/strenght-system.jpg'
        oneTitle={t('threeBannersOneTitle')}
        oneText={t('threeBannersOneText')}
        onceCallback={() => {
          window.scrollTo(0, 0);
          history.push('/products/product/17001');
        }}
        urlTop='https://webimages.terramarbrands.com.mx/usawebpage/home/notes-extiques-desktop.jpg'
        topMobile='https://webimages.terramarbrands.com.mx/usawebpage/home/notes-exotiques-mobile.jpg'
        topVideo='https://www.youtube.com/embed/2R6_MI6rfRc?autoplay=0&mute=0&rel=0&playlist=2R6_MI6rfRc'
        topTitle={t('threeBannersTopTitle')}
        topText={t('threeBannersTopText')}
        topCallback={() => {
          window.scrollTo(0, 0);
          history.push('/products/product/34017');
        }}
        urlBottom='https://webimages.terramarbrands.com.mx/webpage/home/productos.jpg'
        bottomTitle={t('threeBannersBottomTitle')}
        bottomText={t('threeBannersBottomText')}
        bottomCallback={() => {
          window.scrollTo(0, 0);
          history.push('/catalog');
        }}
      />

      <BlankSpace height={24} />

      <CategoriesCarousel screenSize={screenSize} history={history} />

      <BlankSpace height={24} />

      <HeroWithVideo
        screenSize={screenSize}
        videoURL='https://webimages.terramarbrands.com.mx/usawebpage/videoplayer/corporativousa.MP4'
        titleText={t('heroWithVideoTitle')}
        subtitleText={t('heroWithVideoSubtitle')}
        buttonText={t('heroWithVideoButton')}
        youtubeVideo={`https://www.youtube.com/embed/${
          (language.includes('es') && videoUS_ES) || videoUS_EN
        }?autoplay=1&mute=1`}
        buttonCallback={() => {
          window.scrollTo(0, 0);
          history.push('/joinus');
        }}
        videoThumb='https://webimages.terramarbrands.com.mx/usawebpage/home/videothumb.png'
      />

      <BlankSpace height={24} />

      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/home/nuestra-historia.jpg'
        title={t('historyTitle')}
        subtitle1={t('historySubtitle1')}
        subtitle2={t('historySubtitle2')}
        text={t('historyText')}
        text2={t('historyText2')}
        seeMore={t('historySeeMore')}
        complementText={`${t('historySubtitle1')} ${t('historySubtitle2').toLowerCase()}`}
        textInBottom={false}
        buttonCallback={() => {
          window.scrollTo(0, 0);
          history.push('/about');
        }}
      />

      <BlankSpace height={24} />

      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/home/fundacion-desktop.jpg'
        urlMobile='https://webimages.terramarbrands.com.mx/usawebpage/home/fundacion-mobile.jpg'
        title={t('fundationTitle')}
        subtitle1={t('fundationSubtitle1')}
        subtitle2={t('fundationSubtitle2')}
        text={t('fundationText')}
        text2={t('fundationText2')}
        seeMore={t('fundationSeeMore')}
        complementText={`${t('historySubtitle1')} ${t('historySubtitle2').toLowerCase()}`}
        textInBottom={true}
        rowReverse={true}
        backgroundColor='#f6efe6'
        buttonCallback={() => {
          window.scrollTo(0, 0);
          history.push('/creandoSonrisas');
        }}
      />

      <BlankSpace height={24} />

      {location === 'MX' && <HeroBanner device={device} desktopImg={ADD1} mobileImg={ADD1MOB} />}

      {location === 'MX' && <RecommendedCarousel device={device} webservice={webservice} />}
      {location === 'MX' && (
        <HeroBanner device={device} desktopImg={MAINPRODUCT} mobileImg={MAINPRODUCTMOB} />
      )}
    </div>
  );
};

export default Home;
