import React from 'react';
// Styles
import styles from './index.module.sass';
import { useTranslation } from 'react-i18next';

export default function LastFooter({ device, history }) {
  const screenSize = device?.screenSize || 'desktop';
  const { i18n = {}, t } = useTranslation(['footer', 'socialMedia']);
  const { language = '' } = i18n;
  const socialMedia = t('socialMedia:redes', { returnObjects: true });
  const location = process.env.REACT_APP_COUNTRY;

  function privacy() {
    window.scrollTo(0, 0);
    if (location === 'MX') {
      history.push('/legal/privacy');
    } else {
      history.push('/legal/privacy_us');
    }
  }

  function terms() {
    window.scrollTo(0, 0);
    history.push('/legal/terms');
  }

  function agreement() {
    window.scrollTo(0, 0);
    history.push('/legal/agreement_us');
  }

  function polices() {
    window.scrollTo(0, 0);
    history.push('/legal/policesAndProcedures_us');
  }

  function california() {
    window.scrollTo(0, 0);
    history.push('/legal/californiaPrivacy_us');
  }

  function handBook() {
    window.scrollTo(0, 0);
    // history.push('/legal/handbook_us');
    if (language.includes('es'))
      window.open(
        'https://webimages.terramarbrands.com.mx/usawebpage/documents/legals/MANUAL_CONSULTORA.pdf',
      );
    else
      window.open(
        'https://webimages.terramarbrands.com.mx/usawebpage/documents/legals/CONSULTANT_MANUAL.pdf',
      );
  }

  return (
    <div className={`${styles.lastFooter} ${styles[screenSize]}`}>
      {(screenSize !== 'phone' && (
        <>
          <div className={styles.left}>
            <div className={styles.brandName}>© 2022 Terramar Brands</div>
            <div>{t('label1')}</div>
          </div>
          <div className={styles.right}>
            <span>{t('label2')}</span>
            {socialMedia.map((val, key) => {
              let link;
              const { linkMX = '', linkUS = '', icono = '' } = val;
              location === 'MX' ? (link = linkMX) : (link = linkUS);

              return (
                <a href={link} target='_blank' rel='noopener noreferrer' key={key}>
                  <img src={icono} alt='' className={styles.image} />
                </a>
              );
            })}
          </div>
        </>
      )) || (
        <>
          <div className={styles.left}>
            <span className={styles.span}>{t('label2')}</span>
            <div className={styles.flex}>
              {socialMedia.map((val, key) => {
                let link;
                const { linkMX = '', linkUS = '', icono = '' } = val;
                location === 'MX' ? (link = linkMX) : (link = linkUS);

                return (
                  <a href={link} target='_blank' rel='noopener noreferrer' key={key}>
                    <img src={icono} alt='' className={styles.image} />
                  </a>
                );
              })}
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.brandNameMobile}>
              {t('labelSite')} {t('labelSite2')}
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.brandName}>© 2022 Terramar Brands</div>
            <div>{t('label1')}</div>
          </div>
          <div className={styles.left}>
            <div className={styles.terms}>
              <div className={styles.link} onClick={() => privacy()}>
                {t('privacy')}
              </div>
              {location === 'MX' && (
                <div className={styles.link} onClick={() => terms()}>
                  {t('terms')}
                </div>
              )}
            </div>

            {location === 'MX' && (
              <div className={styles.termsUSA}>
                <div
                  className={styles.link}
                  onClick={() =>
                    window.open(
                      'https://webimages.terramarbrands.com.mx/webpage/catalog/MANUAL_CONSULTORA_MX.pdf',
                    )
                  }>
                  {t('handBook')}
                </div>
              </div>
            )}

            {location === 'US' && (
              <div className={styles.termsUSA}>
                <div className={styles.link} onClick={() => agreement()}>
                  {t('agreement')}
                </div>
                <div className={styles.link} onClick={() => polices()}>
                  {t('polices')}
                </div>
                <div className={styles.link} onClick={() => california()}>
                  {t('privacyCalifornia')}
                </div>
                <div className={styles.link} onClick={() => handBook()}>
                  {t('handBook')}
                </div>
                <div className={styles.link}>&nbsp;</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
