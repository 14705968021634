import React from 'react';

// Styles
import styles from './index.module.sass';

export default function BannerSide({
  screenSize = 'desktop',
  urlImage = '',
  urlMobile = '',
  title = '',
  subtitle1 = '',
  subtitle2 = '',
  text = '',
  text2 = '',
  text3 = '',
  seeMore = '',
  buttonCallback,
  backgroundColor = '#ffffff',
  complementText = '',
  textInBottom = false,
  rowReverse = false,
}) {
  function onClick() {
    if (buttonCallback) {
      buttonCallback();
    }
  }
  return (
    <div className={`${styles.BannerSide} ${styles[screenSize]}`}>
      <div
        className={styles.mainContainer}
        style={{
          flexDirection:
            (screenSize === 'phone' && 'column-reverse') || (rowReverse && 'row-reverse') || 'row',
        }}>
        {(screenSize !== 'phone' || (textInBottom && screenSize === 'phone')) && (
          <div
            className={styles.textContainer}
            style={{
              backgroundColor,
              paddingLeft: screenSize === 'phone' || (rowReverse && '16px') || '0px',
            }}>
            {screenSize !== 'phone' && <div className={styles.title}>{title}</div>}
            <div className={styles.subtitleContainer}>
              <span className={styles.subtitle1}>{subtitle1}</span>
              <span className={styles.subtitle2}> {subtitle2}</span>
            </div>
            <div className={styles.text}>
              {screenSize === 'phone' && <span>{complementText} </span>}
              <span>{text}</span>
            </div>
            {text2 && (
              <div className={`${styles.text} ${styles.text2}`}>
                <span>{text2}</span>
              </div>
            )}
            {text3 && (
              <div className={`${styles.text} ${styles.text2}`}>
                <span>{text3}</span>
              </div>
            )}
            {seeMore && (
              <div className={styles.seeMore} onClick={() => onClick()}>
                {seeMore}
              </div>
            )}
          </div>
        )}
        <div
          className={styles.imageContainer}
          onClick={() => {
            if (screenSize === 'phone') onClick();
          }}
          style={{
            backgroundImage: `url(${(screenSize === 'phone' && urlMobile) || urlImage})`,
          }}>
          {screenSize === 'phone' && !textInBottom && (
            <div className={styles.imageText}>
              <div className={styles.imageTextContainer}>
                <div className={styles.ImageTitle}>{title}</div>
                <div className={styles.imageText}>
                  {screenSize === 'phone' && <span>{complementText} </span>}
                  <span>{text}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
