import React, { useEffect, useState } from 'react';
import MenuItem from '../MenuItem';
import { useLocation } from 'react-router-dom';
import LoadingMenu from '../LoadingMenu';

// Styles
import styles from './index.module.sass';

export default function MenuProducts({
  webservice,
  setCatselected,
  catSelected,
  getCookie,
  history,
  match,
  departments = [],
  loading,
  screenSize,
  categories = [],
  getArrayProducts,
}) {
  const [open, setOpen] = useState('1005');
  const location = useLocation();
  let path = location?.pathname || '';
  path = path.replace(/-/g, ' ');
  const tmpArr = path.split('/');
  let lastInPath = '';
  let secondLast = '';
  if (tmpArr[tmpArr.length - 1]) lastInPath = tmpArr[tmpArr.length - 1];
  if (tmpArr[tmpArr.length - 2]) secondLast = tmpArr[tmpArr.length - 2];

  useEffect(() => {
    for (let i = 0; i < departments.length; i++) {
      const { secciones = [], nombre = '' } = departments[i] || {};

      if (nombre.toLowerCase() === secondLast) {
        for (let h = 0; h < secciones.length; h++) {
          const { nombre = '', subdepto = '' } = secciones[h] || {};
          if (nombre.toLowerCase() === lastInPath) {
            setCatselected(subdepto);
          }
        }
      }
    }
  }, [departments, lastInPath, setCatselected, secondLast]);

  return (
    <div className={styles.menu}>
      {(loading && <LoadingMenu />) ||
        categories.map((val, key) => {
          return (
            <MenuItem
              key={key}
              itemId={key}
              node={val}
              setCatselected={setCatselected}
              catSelected={catSelected}
              open={open}
              setOpen={setOpen}
              history={history}
              match={match}
              screenSize={screenSize}
              getArrayProducts={getArrayProducts}
            />
          );
        })}
    </div>
  );
}
