import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';
import Button from '@material-ui/core/Button';

// Components
// import HeroBanner from '../HeroBanner';
import FindLeader from './findLeader';
import ContactForm from './contactForm';

const location = process.env.REACT_APP_COUNTRY;

const Joinup = (props) => {
  const { device, webservice } = props;
  const { screenSize } = device;
  const { t } = useTranslation('joinus');
  const [leaderSelected, setLeaderSelected] = useState({});

  const options = t('options', { returnObjects: true });
  const legal = t('legal', { returnObjects: true });

  return (
    <div id={styles.Joinup} className={styles[screenSize]}>
      {/* <HeroBanner
        device={device}
        desktopImg={(location === 'MX' && t('bannerDesktop')) || t('bannerDesktopUS')}
        mobileImg={(location === 'MX' && t('bannerMobile')) || t('bannerMobileUS')}
      /> */}
      {location === 'MX' && (
        <div id={styles.Titles}>
          <div className={styles.title}>{t('title')}</div>
          <div className={styles.subtitle}>{t('subTit')}</div>
          <div className={styles.subtitle2}>{t('subtitle')}</div>
        </div>
      )}
      <div className={styles.container}>
        {location === 'MX' && (
          <>
            <div className={styles.headerTitle}>{t('headerTitle')}</div>
            <div className={styles.optionsContainer}>
              {options.map((val, key) => {
                const { image = '', title = '', text = '' } = val;
                return (
                  <div className={styles.option} key={key}>
                    <div className={styles.label}>{title}</div>
                    <div className={styles.image}>
                      <img src={image} alt='' />
                    </div>
                    <div>&nbsp;</div>
                    <div className={styles.text}>{text}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.legal}>
              {legal.map((val, key) => {
                return <div key={key}>{val}</div>;
              })}
            </div>
          </>
        )}

        {location === 'US' && (
          <div className={`${styles.promo} ${styles.right}`}>
            <div className={styles.promoImageContainer}>
              <img
                src='https://webimages.terramarbrands.com.mx/usawebpage/home/producto-estrella-usa-2.jpg'
                alt=''
                className={styles.promoImage}
              />
            </div>
            <div className={`${styles.promoTextContainer} ${styles.promoRight}`}>
              <div className={`${styles.capitalGold} ${styles.promoTitle}`}>{t('promotext1')}</div>
              <div className={`${styles.cursiveBlue} ${styles.promoSubtitle}`}>
                {t('promotext2')}
              </div>
              <div className={styles.descriptionText}>{t('promotext3')}</div>
            </div>
          </div>
        )}
        <div className={styles.withoutCount}>
          <div className={styles.leaderContainer}>
            <div className={styles.leadersTitle}>{t('noAccount')}</div>
            <div className={styles.leadersSubTitle}>{t('enterHere')}</div>
          </div>
          <div className={styles.buttonRegisterContainer}>
            <div className={styles.buttonsContainer}>
              <Button
                type='submit'
                variant='contained'
                size='large'
                color='primary'
                className={`${styles.button}`}
                onClick={() => {
                  window
                    .open('https://cart.terramarbrandsusa.com/contractus/newcontractus', '_blank')
                    .focus();
                }}>
                {t('registerButton')}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.forms}>
          <FindLeader
            screenSize={screenSize}
            webservice={webservice}
            setLeaderSelected={setLeaderSelected}
          />
          <ContactForm
            screenSize={screenSize}
            webservice={webservice}
            leaderSelected={leaderSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default Joinup;
