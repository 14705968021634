//Dependencies
import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Styles
import styles from './index.module.sass';

//images
import NEXT from './images/next.png';
import PREV from './images/prev.png';
import getDescripciones from '../../Queries/getDescripciones';

const RecommendedCarousel = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;

  const [products, setProducts] = useState([]);
  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: (screenSize === 'phone' && 1) || 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    async function getDesc() {
      const response = await getDescripciones();
      setProducts(response);
    }
    getDesc();
  }, []);

  return (
    <div id={styles.RecommendedCarousel} className={styles[screenSize]}>
      {products.length > 0 && (
        <div className={styles.container}>
          <div className={styles.sliderContainer}>
            <Slider {...settings} className={styles.slider} ref={sliderRef}>
              {products.map((val, key) => {
                return (
                  <div className={styles.link} key={key}>
                    <div className={styles.slideContainer}>
                      <div className={styles.slideContent}>
                        <img
                          src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products-gray/${val.clave}.jpg`}
                          alt=''
                          className={styles.image}
                        />
                        <div className={styles.productName}>{val.producto}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          {screenSize === 'desktop' && products.length > 5 && (
            <>
              <div className={styles.arrow}>
                <img onClick={previous} src={PREV} alt='prev' />
              </div>
              <div className={`${styles.arrowLast}`}>
                <img onClick={next} src={NEXT} alt='next' />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RecommendedCarousel;
