import React from 'react';

// Styles
import styles from '../../index.module.sass';

export default function FaqUSAING() {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>FREQUENTLY</div>
          <div className={styles.subtitle}>asked questions</div>
        </div>
        <p className={styles.paragraphTitle}>TERRAMAR CARREER</p>
        <p className={styles.paragraphTitle}>How can I receive commissions?</p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>
          You must qualify as an Entrepreneur and meet the requirements of each category. The
          percentage that will be paid as commission, will be according with the lineage table,
          based on the category that you have and the modules that have been made in the month.
        </p>

        <p className={styles.paragraphTitle}>HOW TO JOIN</p>
        <p className={styles.paragraphTitle}>What are the requirements for a new sponsorship?</p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>
          Fill out the form, indicate date of birth, address, SSN or ITIN (Social Security Number or
          Individual Taxpayer Identification Number).
        </p>
        <p className={styles.paragraphTitle}>
          What can I do if I don't have a sponsor or if I contact the company directly, can I still
          join?
        </p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>Of course you can, welcome, we ask you to call us directly at 1 (800) 313-0903.</p>
        <p className={styles.paragraphTitle}>How can I register a new sponsorship?</p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>
          Go to our website www.terramarbrandsusa.com, log in and then in the "CONTRACTS" option
          select "NEW CONTRACTS". And fill out the form, with all the requirements.
        </p>

        <p className={styles.paragraphTitle}>ORDERS AND PURCHASES</p>
        <p className={styles.paragraphTitle}>Can I change the address of my order?</p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>
          Yes, you can. Each order is shipped to the registered address, however, there is an option
          in the shopping cart to change the shipping address, which you will have to do for each
          order you want to ship to a different address than the one you have registered.
        </p>
        <p className={styles.paragraphTitle}>Is there a minimum monthly purchase?</p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>
          The minimum purchase per month is $180 dollars (plus tax, operating expenses, and shipping
          costs). After placing the first monthly order, your following orders can be for a smaller
          amount (plus tax, operating and shipping costs).
        </p>
        <p>
          This purchase amount is required as a feature of qualifying for commissioning or
          promotion, not as a prerequisite to join or remain involved.
        </p>

        <p className={styles.paragraphTitle}>PRODUCTS</p>
        <p className={styles.paragraphTitle}>
          I received damaged, incomplete, or incorrect products, what should I do?
        </p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>
          You can create a report directly on the terramarbrandsusa.com website, on the "CUSTOMER
          SERVICE" section, in the "SERVICE REQUEST" tab.
        </p>
        <ol>
          <li className={styles.list}>
            <span className={styles.span}>Generate your report according to the discrepancy.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Provide your order number, product name and code, and an explanation of the problem
              with your order.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Attach photos as proof.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Close the report and save the assigned report number.
            </span>
          </li>
        </ol>
        <p>
          In the next 48 hours, an answer to the report will be provided, followed with a set of
          instructions for you to follow
        </p>
        <p>
          To follow up your report, you can enter "CUSTOMER SERVICE", then go to "MY REQUESTS"
          section.
        </p>

        <p className={styles.paragraphTitle}>CUSTOMER SERVICE</p>
        <p className={styles.paragraphTitle}>
          What can I do If I can't find an answer to my question?
        </p>
        <p className={styles.paragraphTitle}>Answer:</p>
        <p>You can contact us calling our customer service number 1 (800) 313-0903.</p>
      </div>
    </div>
  );
}
