import React, { useEffect, useState, useCallback } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import superagent from 'superagent';
import { useTranslation } from 'react-i18next';

import styles from './index.module.sass';

const urlImages = process.env.REACT_APP_BANNER_IMAGES;

const urlPDFs = process.env.REACT_APP_BANNER_DOCS;

const Banner = (props) => {
  const { device } = props;
  const screenSize = device.screenSize;

  const { i18n = {} } = useTranslation();
  const { language = '' } = i18n;

  const [banner, setBanner] = useState([]);
  const [dragging, setDraggin] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: () => {
      setDraggin(true);
    },
    afterChange: () => {
      setDraggin(false);
    },
    appendDots: (dots) => (
      <div
        style={{
          bottom: '80px',
        }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
  };

  const getBanners = useCallback(async () => {
    // const bannerURL =
    //   (language.includes('es') && process.env.REACT_APP_BANNER_ES) ||
    //   process.env.REACT_APP_BANNER_EN;

    const bannerURL = process.env.REACT_APP_BANNER;

    await superagent
      .get(bannerURL)
      .set('Content-Type', 'application/json')
      .then((res) => {
        if (res?.body) setBanner(res.body);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function onClick(utlLink) {
    if (dragging) return;
    window.open(utlLink);
  }

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <div className={styles.Banner}>
      <Slider {...settings}>
        {banner &&
          banner.length > 0 &&
          banner.map((val, key) => {
            const imgMob = (language.includes('es') && val.image_mob) || val.image_mob_eng;
            const imgDesk = (language.includes('es') && val.image) || val.image_eng;
            const linkText = (language.includes('es') && val.linkText) || val.linkText_eng;
            const utlLink = (language.includes('es') && val.url) || val.url_eng;
            if (screenSize !== 'phone' && !imgDesk) return null;
            if (screenSize === 'phone' && !imgMob) return null;
            return (
              <div
                className={styles.slideContainer}
                key={key}
                onClick={() => {
                  if (screenSize === 'phone' && utlLink) {
                    onClick(`${urlPDFs}${utlLink}`);
                  }
                }}>
                <img
                  src={`${
                    (screenSize === 'phone' && `${urlImages}${imgMob}`) || `${urlImages}${imgDesk}`
                  }`}
                  alt=''
                  className={styles.bannerImage}
                />
                {val.url && (
                  <div className={styles.goTo}>
                    <a href={`${urlPDFs}${utlLink}`} target='_blank' rel='noopener noreferrer'>
                      {linkText}
                      {linkText && <i className='material-icons-outlined'>file_download</i>}
                    </a>
                  </div>
                )}
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Banner;
