import React from 'react';
import styles from './index.module.sass';
import { useTranslation } from 'react-i18next';

const Schools = (props) => {
  const { screenSize } = props;
  const { t } = useTranslation('creatingSmiles');
  const schools = t('schools', { returnObjects: true });

  return (
    <div id={styles.Schools} className={styles[screenSize]}>
      <div className={styles.title}>
        <label className={styles.SubtitleBlue}>{t('titleSchools')}</label>
        <br /> <label className={styles.SubtitleGold}>{t('subTitleSchools')}</label>
      </div>
      <div className={styles.schools}>
        {schools.map((val, key) => {
          return (
            <div key={key} className={styles.bloqueSchool}>
              <div className={styles.divBlue}>
                <div className={styles.label}>{val.school}</div>
                {val.detail.map((value, key) => {
                  return (
                    <div className={styles.button} key={key}>
                      <i className={`material-icons ${styles.icon}`}>{value.icon}</i>
                      <label>&nbsp;&nbsp;{value.text}&nbsp;&nbsp;</label>
                    </div>
                  );
                })}
              </div>

              <div className={styles.divImage}>
                <img
                  src={`https://webimages.terramarbrands.com.mx/webpage/creandoSonrisas/${val.image}`}
                  alt=''
                  className={styles.img}
                />
              </div>
            </div>
          );
        })}
        <div className={styles.bloqueSchoolPro}>
          <div className={styles.divGray}>
            <div className={styles.labelGray}>{t('futureProjects')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schools;
