import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.sass';
import { useTranslation } from 'react-i18next';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

//Dependencies
import Parser from 'html-react-parser';

import getDescripciones from '../Queries/getDescripcionesProductos';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Product = (props) => {
  const { device } = props;
  const { screenSize } = device;
  let { id = '' } = useParams();
  const [loading, setLoading] = useState(false);
  const [productID, setProductID] = useState('');
  const [name, setName] = useState([]);
  const [description, setDescription] = useState(['', '']);
  const [ingredients, setIngredients] = useState(['', '']);
  const [application, setApplication] = useState(['', '']);

  const { i18n = {}, t } = useTranslation('products');
  const { language = '' } = i18n;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function getDescriptions() {
      setLoading(true);
      const response = await getDescripciones(id, '0', '');
      const tmpProduct = (response.length > 0 && response[0]) || {};

      const {
        aplicacion = '',
        descripcion = '',
        clave = '',
        producto = '',
        ingredientes = '',
      } = tmpProduct;

      const nameArr = producto.split('@@@');
      const descArr = descripcion.split('@@@');
      const ingArr = ingredientes.split('@@@');
      const apliArraY = aplicacion.split('@@@');

      setProductID(clave);
      setName(nameArr);
      setDescription([descArr[0] || '', descArr[1] || '']);
      setIngredients([ingArr[0] || '', ingArr[1] || '']);
      setApplication([apliArraY[0] || '', apliArraY[1] || '']);
      setLoading(false);
    }
    getDescriptions();
  }, [id]);

  function setAltImg(event) {
    event.target.src =
      'https://webimages.terramarbrands.com.mx/shopping-cart/cart-products-gray/preload.jpg';
  }

  return (
    <div id={styles.Product} className={styles[screenSize]}>
      {(loading && (
        <>
          <div className={styles.imageContainer}>&nbsp;</div>
          <div className={styles.dataContainer}>
            <Box sx={{ width: '100%' }}>
              <div className={styles.productName}></div>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor='secondary'
                indicatorColor='primary'
                aria-label='secondary tabs example'>
                <Tab label={t('description')} className={styles.tabColor} />
                <Tab label={t('ingredients')} className={styles.tabColor} />
                <Tab label={t('aplication')} className={styles.tabColor} />
              </Tabs>
              <TabPanel value={value} index={0} className={styles.textColor}>
                &nbsp;
                <br /> &nbsp; &nbsp;
                <br /> &nbsp;
              </TabPanel>
              <TabPanel value={value} index={1} className={styles.textColor}>
                &nbsp;
              </TabPanel>
              <TabPanel value={value} index={2} className={styles.textColor}>
                &nbsp;
              </TabPanel>
            </Box>
          </div>
        </>
      )) || (
        <>
          <div className={styles.imageSuperContainer}>
            <div className={styles.imageContainer}>
              <img
                src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products-gray/${productID}.jpg`}
                alt={name}
                onError={setAltImg}
                className={styles.imgContainer}
              />
            </div>
          </div>

          <div className={styles.dataContainer}>
            <Box sx={{ width: '100%' }}>
              <div className={styles.productName}>
                {(language.includes('es') && name[0]) || name[1]}
              </div>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor='secondary'
                indicatorColor='primary'
                aria-label='secondary tabs example'>
                <Tab label={t('description')} className={styles.tabColor} />
                <Tab label={t('ingredients')} className={styles.tabColor} />
                <Tab label={t('aplication')} className={styles.tabColor} />
              </Tabs>

              <TabPanel value={value} index={0} className={styles.textColor}>
                {Parser((language.includes('es') && description[0]) || description[1])}
              </TabPanel>
              <TabPanel value={value} index={1} className={styles.textColor}>
                {Parser((language.includes('es') && ingredients[0]) || ingredients[1])}
              </TabPanel>
              <TabPanel value={value} index={2} className={styles.textColor}>
                {Parser((language.includes('es') && application[0]) || application[1])}
              </TabPanel>
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
