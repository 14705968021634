import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Menu from '../Menu';
import LanguageSelect from '../LanguageSelect';

// Styles
import styles from './index.module.sass';

// Images
import logo from '../../images/logo_header.png';
import appIcon from '../../images/icono-terramar-eu.png';
import MEXFLAG from '../../images/home/mex-flag.png';
import USAFLAG from '../../images/home/usa-flag.png';

const location = process.env.REACT_APP_COUNTRY;

const Header = (props) => {
  const { device, match, history } = props;
  const { screenSize, os, width } = device;
  const [open, setOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');

  const { t } = useTranslation('home');

  function appRedirect() {
    if (location === 'MX') {
      if (os === 'Android')
        window.open('https://play.google.com/store/apps/details?id=com.icom.terramar');
      else window.open('https://apps.apple.com/mx/app/terramar-brands-mx/id1492884080');
    } else {
      if (os === 'Android')
        window.open('http://play.google.com/store/apps/details?id=com.terramarbrandsusa.cart');
      else window.open('https://apps.apple.com/us/app/terramar-brands-usa/id6443576364');
    }
  }

  return (
    <div id={styles.Header} className={styles[screenSize]}>
      {device?.device === 'phone' && (
        <div className={styles.appRedirectContainer}>
          <div className={styles.redirectIcon}>
            <img src={appIcon} alt='Terramar App Icon' className={styles.appIcon} />
          </div>
          <div className={styles.redirectText}>
            <div>
              <div
                className={styles.redirectTitle}
                style={{
                  fontSize: (width < 400 && '14px') || '15px',
                }}>
                {t('redirectTitle')}
              </div>
              <div
                className={styles.redirectSubtitle}
                style={{
                  fontSize: (width < 400 && '13px') || '14px',
                }}>
                {t('redirectSubtitle')}
              </div>
            </div>
            <div
              className={styles.redirectStore}
              style={{
                fontSize: (width < 400 && '13px') || '14px',
              }}>
              {(os === 'Android' && t('redirectStoreAndroid')) || t('redirectStoreiOS')}
            </div>
          </div>
          <div className={styles.redirectButton} onClick={() => appRedirect()}>
            {t('redirectButton')}
          </div>
        </div>
      )}
      {screenSize !== 'phone' && (
        <div className={styles.headerLogo}>
          <img src={logo} alt='Logo Terramar Brands' className={styles.centerLogo} />
        </div>
      )}
      <div className={styles.HeaderContainer}>
        <div className={styles.left}>
          {screenSize !== 'phone' && (
            <>
              <div className={styles.flagContainer}>
                <img
                  src={MEXFLAG}
                  className={styles.flagImage}
                  alt='México flag'
                  onClick={() => window.open('https://terramarbrands.com.mx', '_self')}
                />
              </div>
              <div className={styles.flagContainer}>
                <img
                  src={USAFLAG}
                  className={styles.flagImage}
                  alt='USA flag'
                  onClick={() => window.open('https://terramarbrandsusa.com', '_self')}
                />
              </div>
              <LanguageSelect />
            </>
          )}
          <div className={`${styles.logo} ${styles[screenSize]}`}>
            {screenSize === 'phone' && (
              <i className='material-icons' onClick={() => setOpen(true)}>
                menu
              </i>
            )}
          </div>
        </div>

        <div className={styles.center}>
          {screenSize === 'phone' && (
            <div className={`${styles.logo} ${styles[screenSize]}`}>
              <img src={logo} alt='Logo Terramar Brands' />
            </div>
          )}
          <div className={styles.menuLeft}>
            <Menu
              device={device}
              open={open}
              setOpen={setOpen}
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
              match={match}
              history={history}
            />
          </div>
        </div>

        <div className={styles.right}>
          {screenSize !== 'phone' && (
            <div
              className={styles.cart}
              onClick={() => {
                if (location === 'MX')
                  window.open('https://carrito.terramarbrands.com.mx', '_self');
                else window.open('https://cart.terramarbrandsusa.com', '_self');
              }}>
              {t('cartName')}
            </div>
          )}
          {/* {screenSize === 'desktop' && (
          <i
            className={`material-icons-outlined ${styles.shopIcon}`}
            onClick={() => {
              if (location === 'MX') window.open('https://carrito.terramarbrands.com.mx', '_self');
              else window.open('https://cart.terramarbrandsusa.com', '_self');
            }}>
            shopping_bag
          </i>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default Header;
