import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import BannerSide from '../BannerSide';

// Styles
import styles from './index.module.sass';

// Images
import GonzaloSr from '../../images/about/gonzalosr.png';
import GonzaloJr from '../../images/about/gonzalojr.png';
import RonClark from '../../images/about/ronclarck.png';

const About = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;
  const { t } = useTranslation('about');

  return (
    <div id={styles.About} className={styles[screenSize]}>
      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/home/nuestra-historia.jpg'
        title={t('historyTitle')}
        subtitle1={t('historySubtitle1')}
        subtitle2={t('historySubtitle2')}
        text={t('historyText')}
        complementText={`${t('historySubtitle1')} ${t('historySubtitle2').toLowerCase()}`}
        textInBottom={true}
      />

      <div className={styles.foundersContainer}>
        <div className={styles.foundersTitlesContainer}>
          <div className={styles.foundersTop}>{t('foundersTop')}</div>
          <div className={styles.foundersTitle}>{t('foundersTitle')}</div>
          <div className={styles.foundersSubtitle}>{t('foundersSubtitle')}</div>
        </div>
        <div className={styles.foundersProfileContainer}>
          <div className={styles.founderProfile}>
            <div className={styles.fouderCard}>
              <div
                className={styles.founderPicture}
                style={{
                  backgroundImage: `url(${GonzaloJr})`,
                }}
              />
              <div className={styles.founderName}>Gonzalo Rubio Junior</div>
              <div className={styles.founderHistory}>{t('tabs.gonzalojr')}</div>
            </div>
          </div>
          <div className={styles.founderProfile}>
            <div className={styles.fouderCard}>
              <div
                className={styles.founderPicture}
                style={{
                  backgroundImage: `url(${GonzaloSr})`,
                }}
              />
              <div className={styles.founderName}>Gonzalo Rubio Senior</div>
              <div className={styles.founderHistory}>{t('tabs.gonzalosr')}</div>
            </div>
          </div>
          <div className={styles.founderProfile}>
            <div className={styles.fouderCard}>
              <div
                className={styles.founderPicture}
                style={{
                  backgroundImage: `url(${RonClark})`,
                }}
              />
              <div className={styles.founderName}>Ron Clark</div>
              <div className={styles.founderHistory}>{t('tabs.ronclark')}</div>
            </div>
          </div>
        </div>
      </div>

      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/about/experiencia-horizontal.jpg'
        title={t('fundationTitle')}
        subtitle1={t('fundationSubtitle1')}
        subtitle2={t('fundationSubtitle2')}
        text={t('history.text')}
        text2={t('history.paragraph2')}
        complementText={`${t('historySubtitle1')} ${t('historySubtitle2').toLowerCase()}`}
        textInBottom={true}
        rowReverse={true}
      />
    </div>
  );
};

export default About;
