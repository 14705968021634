import React, { useState, useEffect } from 'react';
import styles from './index.module.sass';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

//Dependencies
import getLideres from '../Queries/getLideres';
import getLideresSoloEstados from '../Queries/getLideresSoloEstados';

//Components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '../Alert';
import { CircularProgress } from '@material-ui/core';
import Map from './map.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: '5px',
    '&:hover .MuiOutlinedInput-input': {
      color: '#000000',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#9b722a',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#9b722a',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#000000',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#000000',
      backgroundColor: '#000000',
      padding: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#9b722a',
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      color: '#9b722a',
      backgroundColor: '#ffffff',
      padding: '1px',
    },
  },
});

const FindLeader = (props) => {
  const { setLeaderSelected } = props;

  const { t } = useTranslation('joinus');

  const [state, setSt] = useState('');
  const [name, setName] = useState('');
  const [pc, setPc] = useState('');
  const [loading, setLoading] = useState('');
  const [leaders, setLeaders] = useState('');
  const [isAll, setIsAll] = useState(true);
  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [states, setstates] = useState([]);

  async function Find() {
    setLoading(true);
    let sts = state;
    if (state === '-- Todos los estados --' || state === '-- All the states --' || state === '') {
      setIsAll(true);
      sts = '';
    } else setIsAll(false);
    const query = `nombre=${name}&estado=${sts}&cp=${pc}`;

    const response = await getLideres(query);
    setLeaders(response);
    setLoading(false);
  }

  const setLeader = (leader) => {
    const { nombre = '' } = leader;
    setLeaderSelected(leader);
    setAlertText(`${t('findInstructions')} ${nombre}`);
    setOpen(true);
  };

  useEffect(() => {
    setLoading(true);
    async function getStates() {
      const response = await getLideresSoloEstados();
      const { mensaje = '', tipoMensaje = 'Error' } = response;

      if (tipoMensaje === 'Error') {
        setAlertText(mensaje);
        setOpen(true);
      } else {
        setstates(response.estados || []);
        setLoading(false);
      }
    }

    getStates();
  }, []);

  return (
    <div className={styles.findLeader}>
      <Alert open={open} setOpen={setOpen} title={'Terramar'} text={alertText} />
      <div className={styles.leaderContainer}>
        <div className={styles.leadersTitle}>{t('findYourLeader')}</div>
        <div className={styles.leadersSubTitle}>{t('leader')}</div>
        <div className={styles.formContainerLeader}>
          <div className={styles.inputContainer}>&nbsp;</div>
          <div className={styles.inputContainer}>
            <FormControl variant='outlined' className={classes.root}>
              <InputLabel id='action-select-outlined-label'>{t('searchByState')}</InputLabel>
              <Select
                labelId='state-select-outlined-label'
                id='state-select-outlined'
                value={state}
                onChange={(e) => setSt(e.target.value)}
                label={t('searchByState')}>
                {states.map((val, key) => {
                  return (
                    <MenuItem value={val.estado} key={key}>
                      {val.estadoDescripcion.substring(0, 20)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className={styles.inputContainer}>
            <TextField
              id='pc-outlined-code-input'
              label={t('searchByZipCode')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={pc}
              onChange={(e) => setPc(e.target.value)}
            />
          </div>

          <div className={styles.inputContainer}>
            <TextField
              id='name-outlined-code-input'
              label={t('searchByName')}
              type='text'
              variant='outlined'
              className={classes.root}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={styles.buttonsContainer}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              color='primary'
              className={`${styles.button}`}
              onClick={() => Find()}>
              {(loading && <CircularProgress />) || t('searchButton')}
            </Button>
          </div>

          <Map leaders={leaders} isAll={isAll} />

          {leaders.length > 0 && (
            <div className={styles.leadersList}>
              <div className={styles.resultstTitle}>
                {leaders.length} {t('results')}
              </div>
              {leaders.map((val, key) => {
                const { nombre = '' } = val;
                return (
                  <div className={styles.leader} key={key}>
                    <div className={styles.leaderName}>{nombre}</div>
                    <div className={styles.leaderButton} onClick={() => setLeader(val)}>
                      {t('messageButton')}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindLeader;
