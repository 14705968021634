import React from 'react';
import styles from '../index.module.sass';

const PrivacyMXESP = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>AVISO DE</div>
          <div className={styles.subtitle}>privacidad</div>
        </div>

        <p className={styles.paragraph}>
          Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos
          Personales en Posesión de Particulares hacemos de su conocimiento que Terramar Brands, S.
          de R.L. de C.V., con domicilio en Norte 45 no. 1017 Local 11, Colonia Industrial Vallejo,
          México, DF, Delegación Azcapotzalco, CP 02300 es responsable de recabar sus datos
          personales, del uso que se le dé a los mismos y de su protección.
        </p>

        <p className={styles.paragraph}>
          Su información personal será utilizada para las siguientes finalidades: proveer los
          servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos
          que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los
          mismos; elaborar estudios y programas que son necesarios para determinar hábitos de
          consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de
          mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos,
          mensajería, cobranza, en general, para dar cumplimiento a las obligaciones que hemos
          contraído con usted.
        </p>

        <p className={styles.paragraph}>
          Para las finalidades antes mencionadas, requerimos obtener los siguientes datos
          personales:
        </p>

        <div className={styles.paragraph}>
          <ul>
            <li className={styles.list}>
              <span className={styles.span}>Nombre completo</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Edad</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Estado civil</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Sexo</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Teléfono fijo y/o celular</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Correo electrónico</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>ID de Facebook, Twitter y/o Linkedin</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Firma autógrafa</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>Dirección</span>
            </li>
            <li className={styles.list}>
              <span className={styles.span}>RFC y/o CURP</span>
            </li>
          </ul>
        </div>

        <p className={styles.paragraph}>
          Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de
          sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el
          consentimiento que para dicho fin nos haya otorgado.
        </p>

        <p className={styles.paragraph}>
          Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su
          Artículo 29 al gerente de sistemas, responsable de nuestro Departamento de Protección de
          Datos Personales, ubicado en Norte 45 no. 1017 Local 11, Colonia Industrial Vallejo,
          México, DF, Delegación Azcapotzalco, CP 02300 México, o bien, se comunique al teléfono
          50781600 o vía correo electrónico a
          <span>
            <a href='mailto:soporte@terramarbrands.com' className={styles.link}>
              &nbsp;soporte@terramarbrands.com&nbsp;
            </a>
          </span>
          , el cual solicitamos confirme vía telefónica para garantizar su correcta recepción.
        </p>

        <p className={styles.paragraph}>
          Por otra parte, hacemos de su conocimiento que sus datos podrán ser transferidos a
          entidades del mismo grupo de interés de la empresa, nacionales o extranjeras, con el
          objetivo general de cumplir con las finalidades para las cuales ha proporcionado sus
          datos. Propósitos específicos: mejorar servicio, prestadores de servicios, incrementar los
          servicios, entre otros.
        </p>

        <p className={styles.paragraph}>
          En éste sentido su información podrá ser compartida con las empresas filiales o
          relacionadas con el medio.
        </p>

        <p className={styles.paragraph}>
          En caso de que no obtengamos su oposición expresa para que sus datos personales sean
          transferidos en la forma y términos antes descrita, entenderemos que ha otorgado su
          consentimiento en forma tácita para ello.
        </p>

        <p className={styles.paragraph}>
          En caso de que no desee de recibir mensajes promocionales de nuestra parte, puede
          enviarnos su solicitud por medio de la dirección electrónica:
          <span>
            <a href='mailto:soporte@terramarbrands.com' className={styles.link}>
              &nbsp;soporte@terramarbrands.com&nbsp;
            </a>
          </span>
        </p>

        <p className={styles.paragraph}>
          Importante: Cualquier modificación a este Aviso de Privacidad podrá consultarlo en: &nbsp;
          <span>
            <a href='www.terramarbrands.com' className={styles.link} target='_blank'>
              www.terramarbrands.com&nbsp;
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyMXESP;
