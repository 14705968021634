import React from 'react';

// Styles
import styles from './index.module.sass';

export default function LoadingMenu() {
  const menuArray = Array(8).fill(0);

  return (
    <div className={`${styles.loadingMenu}`}>
      <div className={styles.loadingGrid}>
        {menuArray.map((val, key) => {
          return (
            <div className={styles.item} key={key}>
              &nbsp;
            </div>
          );
        })}
      </div>
    </div>
  );
}
