import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loadingpage from '../Loadingpage';

// Styles
import styles from './index.module.sass';

export default function DigitalMagazine({ device }) {
  const { i18n = {} } = useTranslation();
  const { language = '' } = i18n;

  const digitalCatalog = language.includes('es')
    ? 'https://viewer.ipaper.io/terramar-brands/revista_de_mes'
    : 'https://viewer.ipaper.io/terramar-brands/month_magazine';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Suspense fallback={<Loadingpage />}>
      <div className={styles.catalogContainer}>
        <iframe
          title='Terramar Digital Magazine'
          src={digitalCatalog}
          frameborder='0'
          style={{
            width: '100%',
            height: '100%',
          }}
          allow='autoplay; fullscreen;'
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen></iframe>
      </div>
    </Suspense>
  );
}
