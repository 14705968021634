import React from 'react';
import styles from '../../index.module.sass';
import { useTranslation } from 'react-i18next';

import TableESP from '../Table/ESP';

const CaliforniaPrivacyESP = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('californiaPrvacy');

  const items = t('items', { returnObjects: true });

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>Aviso de Privacidad</div>
          <div className={styles.subtitle}>de California</div>
        </div>
        <p className={styles.paragraph}>
          Este Aviso de Privacidad de California (“Aviso) es para los residentes de California y
          complementa nuestra Política de Privacidad. En este documento se explica como recopilamos,
          utilizamos y compartimos la Información Personal y como ejercemos sus derechos en
          conformidad con la Ley de Privacidad del Consumidor de California (”CCPA”).
        </p>

        <p className={styles.paragraph}>
          Cuando decimos “Información Personal” en este Aviso, nos referimos a la información que
          identifica, se relaciona con, describe, es razonablemente capaz de ser asociada con, o
          podría estar razonablemente ligada, directa o indirectamente, con usted. La Información
          Personal no incluye información agregada o información que no pueda ser razonablemente
          relacionada con usted.
        </p>
        <p className={styles.paragraphTitle}>
          Como Recopilamos, Utilizamos y Compartimos Información Personal.
        </p>
        <p className={styles.paragraph}>
          Para proporcionar los productos o funciones que ofrecen XYZ (“Productos”), debemos
          procesar la información sobre usted, incluyendo Información Personal, esté o no registrado
          o conectado. Sujeto a las limitaciones que describimos en nuestra Política de Información,
          podremos compartir su Información Personal para fines comerciales con estrictas
          restricciones sobre cómo nuestros socios comerciales pueden utilizar y divulgar la
          información que nosotros proporcionamos, a su discreción, o de otra manera de acuerdo con
          la CCPA.
        </p>
        <p className={styles.paragraph}>
          La mejor manera de conocer el tipo de información que recopilamos y como la utilizamos es
          revisar nuestra Política de Información. A continuación, les presentamos un resumen de las
          categorías del CCPA relacionadas con la Información Personal que podríamos haber
          recopilado sobre usted en los últimos 12 meses, dependiendo del modo en que utilice
          nuestros Productos, así como el uso que hacemos de ella y con quién podemos haberla
          compartido.
        </p>
        <TableESP screenSize={screenSize} tableItems={items} />
        <p className={styles.paragraph}>
          Para conocer más sobre cómo procesamos información, incluyendo otros tipos de Información
          Personal que recopilamos, vaya a nuestra Política de Privacidad.
        </p>
        <p className={styles.paragraphTitle}>Fuentes de Información Personal.</p>
        <p className={styles.paragraph}>
          Recibimos Información Personal de la información que usted y otros proporcionan, de su(s)
          dispositivo(s) y de nuestros socios. Las categorías de fuentes a partir de las cuales
          recopilamos o recibimos Información Personal incluyen:
        </p>
        <ul>
          <li className={styles.list}>Usted</li>
          <p className={styles.span}>
            Recopilamos contenido, comunicaciones y otra información que usted proporciona cuando
            utiliza nuestros Productos, incluyendo cuando se registra para una cuenta, crea o
            comparte contenido y mensajes o comunicaciones con otros. Nosotros recopilamos
            información sobre las personas, las páginas, cuentas, hashtags y grupos a los que está
            conectado, y como interactúa con ellos a través de nuestros Productos, como las personas
            con las que más se comunica o los grupos de los cuáles forma parte. También recopilamos
            información acerca de cómo utiliza nuestro Producto, incluyendo anuncios que publicamos
            dentro y fuera de XYZ, como los tipos de contenido que revisa o con los que participa,
            las funciones que utiliza, las acciones que realiza, las personas o cuentas con las que
            interactúa y el tiempo, le frecuencia y la duración de sus actividades.
          </p>

          <li className={styles.list}>Otras personas</li>
          <p className={styles.span}>
            También podremos recibir y analizar el contenido, comunicaciones e información de usted
            que otras personas proporcionan cuando utilizan nuestros Productos, como cuando comparte
            o comenta una foto suya, le envían un mensaje o cargan, sincronizan o importan su
            información de contacto.
          </p>

          <li className={styles.list}>Su(s) dispositivo (s)</li>
          <p className={styles.span}>
            Recopilamos información de y sobre las computadoras, teléfonos, televisores conectados a
            la red y otros dispositivos conectados a la web que usted utiliza y que se integran con
            nuestros Productos, y combinamos esta información entre los diferentes dispositivos que
            usted utiliza.
          </p>

          <li className={styles.list}>Socios</li>
          <p className={styles.span}>
            Los procesadores de tarjetas de créditos, los bancos, los desarrolladores y editores de
            la App y otros socios pueden enviarnos información con fines comercial a través de las
            herramientas comerciales que XYZ utiliza, incluyendo información acerca de su
            dispositivo, los sitios web que visita, las cosas que realiza en sus servicios y las
            compra que hace. También recibimos información acerca de sus acciones dentro y fuera de
            línea y sus compras de proveedores de datos que tienen derechos de proporcionarnos su
            información. Los socios reciben su información cuando visitan o utilizan sus servicios a
            través de terceros con los que trabajan. Exigimos que cada uno de estos socios tengan
            derechos para recopilar, utilizar y compartir su información antes de proporcionarnos
            cualquier información a nosotros.
          </p>
        </ul>

        <p className={styles.paragraphTitle}>
          ¿Cómo puede usted ejercer los derechos que le otorga la CCPA?
        </p>

        <p className={styles.paragraph}>Según la CCPA, usted tiene los siguientes derechos:</p>

        <ul>
          <li className={styles.list}>Derecho a saber</li>
          <p className={styles.span}>
            Tiene el derecho de solicitar que le revelemos la Información Personal que recopilamos,
            utilizamos, o revelamos, así como la información sobre nuestras prácticas de datos.
          </p>
          <li className={styles.list}>Derecho a Solicitar la Eliminación</li>
          <p className={styles.span}>
            Tiene el derecho a solicitar a que eliminemos su Información Personal que hemos
            recopilado de usted.
          </p>

          <li className={styles.list}>Derecho a la no Discriminación</li>
          <p className={styles.span}>
            No le discriminaremos por ejercer cualquiera de estos derechos.
          </p>
        </ul>

        {/* <p className={styles.paragraph}>
          Para ejercer su “derecho a saber” o su “derecho a solicitar la eliminación”, haga clic
          aquí.
        </p> */}

        <p className={styles.paragraph}>
          Por favor tenga en cuenta que, para proteger su información y la integridad de nuestros
          Productos y Servicios, podemos requerir que verifique su identidad antes de procesar su
          solicitud. En algunos casos podríamos necesitar recopilar información adicional para
          verificar su identidad, tal como un documento de identidad emitido por el gobierno.
        </p>

        <p className={styles.paragraph}>
          Conforme a la CCPA, usted mismo puede ejercer estos derechos o puede asignar a un agente
          autorizado para hacer estas solicitudes a su nombre. Podemos solicitar que su agente
          autorizado presente una autorización por escrito de su parte para hacer solicitud a su
          nombre y puede que tengamos que verificar la identidad de su agente autorizado.
        </p>

        <p className={styles.paragraphTitle}>Contacto para más información.</p>

        <p className={styles.paragraph}>
          Si tiene preguntas adicionales sobre este Aviso o cómo ejercer su derecho bajo la CCPA,
          por favor póngase en contacto con nosotros.
        </p>

        <p className={styles.paragraph}>Fecha de entrada en vigor: 2 de julio del 2020.</p>
      </div>
    </div>
  );
};
export default CaliforniaPrivacyESP;
