const menu = [
  {
    id: 'home',
    index: 0,
    parent: null,
    url: '/',
    children: [],
  },
  {
    id: 'products',
    index: 1,
    parent: null,
    url: '/products',
    children: [],
  },
  {
    id: 'catalog',
    index: 1,
    parent: null,
    url: '/catalog',
    children: [],
  },
  {
    id: 'about',
    index: 1,
    parent: null,
    url: '/about',
    children: [],
  },
  {
    id: 'joinus',
    index: 1,
    parent: null,
    url: '/joinus',
    children: [],
  },
  {
    id: 'career',
    index: 1,
    parent: null,
    url: '/career',
    children: [],
  },
  {
    id: 'videos',
    index: 1,
    parent: null,
    url: '/videos',
    children: [],
  },
];

export default menu;
