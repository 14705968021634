import React from 'react';

// Styles
import styles from '../../index.module.sass';

export default function FaqUSAESP() {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>PREGUNTAS</div>
          <div className={styles.subtitle}>frecuentes</div>
        </div>
        <p className={styles.paragraphTitle}>CARRERA TERRAMAR</p>
        <p className={styles.paragraphTitle}>¿Cómo puedo recibir comisiones?</p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          Debes de calificar como Emprendedora y cumplir las bases de cada categoría. El porcentaje
          que se pagará de comisión, es de acuerdo a la tabla de linaje, dependiendo de la categoría
          que se tenga y los módulos que se hayan hecho en el mes.
        </p>

        <p className={styles.paragraphTitle}>COMO UNIRME</p>
        <p className={styles.paragraphTitle}>¿Qué requisitos solicitan para un nuevo patrocinio?</p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          Llenar el formulario, indicando fecha de nacimiento, domicilio, SSN o ITIN (Número de
          Seguridad Social o Número de Identificación Individual de pago de Impuestos)
        </p>

        <p className={styles.paragraphTitle}>
          ¿Si no tengo patrocinadora o contacté directo a la empresa, puedo ingresar?
        </p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          Claro que sí, bienvenida, te pedimos que te comuniques directo al teléfono de atención
          telefónica 1 (800) 313-0903
        </p>

        <p className={styles.paragraphTitle}>
          ¿Cómo puedo realizar la inscripción de un nuevo patrocinio?
        </p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          Debe ingresar a la página terramarbrandsusa.com, inicie sesión y posteriormente en la
          opción de “CONTRATOS” seleccionar “NUEVOS CONTRATOS”. Y debe llenar el formulario, con
          todos los requisitos.
        </p>

        <p className={styles.paragraphTitle}>PEDIDOS Y COMPRAS</p>
        <p className={styles.paragraphTitle}>¿Puedo cambiar la dirección de mi pedido?</p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          Sí, cada pedido se envía al domicilio registrado, sin embargo, existe la opción en el
          carrito de compras de cambiar el destino, esto lo tienes que hacer en cada pedido que
          quieras mandar a un destino diferente al que tienes registrado.
        </p>

        <p className={styles.paragraphTitle}>¿Hay una compra mínima por mes?</p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          La compra mínima por mes es de $180 dólares (más impuestos, gastos operativos y envío).
          Para pedidos posteriores al primer pedido mensual, si puedes realizar pedidos de menor
          cantidad (más impuestos, gastos operativos y de envío).
        </p>
        <p>
          Este importe de compra será necesario para obtener una comisión o una promoción, y no es
          un requisito para afiliarse o permanecer involucrado.
        </p>

        <p className={styles.paragraphTitle}>PRODUCTOS</p>
        <p className={styles.paragraphTitle}>
          He recibido productos dañados, incompletos o incorrectos ¿Qué debo hacer?
        </p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>
          Puede generar su reporte directamente en la página terramarbrandsusa.com, en la sección de
          “SERVICO AL CLIENTE”, en el apartado de “SOLICITUD DE SERVICIO”.
        </p>
        <ol>
          <li className={styles.list}>
            <span className={styles.span}>
              Generar el reporte correspondiente indicando el motivo de la solicitud.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Redacte lo sucedido, junto con el número de su pedido, clave y nombre del producto.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Adjunte las imágenes.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Cierre la solicitud y guarde el número de solicitud asignado.
            </span>
          </li>
        </ol>
        <p>
          En las siguientes 48hrs usted deberá de recibir respuesta a dicha solicitud y los pasos a
          seguir.
        </p>
        <p>
          Para darle seguimiento, usted puede ingresar a “SERVICIO AL CLIENTE”, en el apartado de
          “MIS SOLICITUDES”.
        </p>

        <p className={styles.paragraphTitle}>SERVICIO AL CLIENTE</p>
        <p className={styles.paragraphTitle}>
          ¿En caso de no encontrar respuesta en estas preguntas qué puedo hacer?
        </p>
        <p className={styles.paragraphTitle}>Respuesta:</p>
        <p>Llamar al número de atención telefónica 1 (800) 313-0903</p>
      </div>
    </div>
  );
}
