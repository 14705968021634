import React from 'react';

// Styles
import styles from '../../index.module.sass';

// Images
import MANUAL_TABLE from './manual_table_us_eng.jpg';

export default function ManualUSAENG() {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.Titles}>
          <div className={styles.title}>CONSULTAN</div>
          <div className={styles.subtitle}>manual</div>
        </div>
        <p className={styles.paragraphTitleCenter}>WELCOME</p>
        <p>
          Ron Clark and Gonzalo Rubio Senior set-in motion what has been their lifelong dream: to
          create TERRAMAR BRANDS, the best direct sales company, and to provide an unparalleled
          opportunity for personal and economic growth.
        </p>
        <p>
          In 2009, TERRAMAR BRANDS officially began operations in Mexico with a dedicated and
          committed team working with the Consultants and Leaders who enjoy the wonderful
          opportunities, products, and the best multi-level program that exist today.
        </p>
        <p>
          At TERRAMAR BRANDS our mission is: "Absolute commitment to improving the lives of people
          and their families."
        </p>
        <p>
          Ron Clark, Gonzalo Rubio Senior, and Gonzalo Rubio Jr represent more than one hundred
          years of experience in direct sales, having successfully opened operations in more than 35
          countries on five continents. Finally, after a long career in direct sales, they chose
          Mexico as their headquarters, and in 2022 they decided to expand operations to the largest
          market in the world, the United States.
        </p>
        <p>
          We invite you to learn, dream, and fall in love with the TERRAMAR Program, and discover in
          TERRAMAR the key, that will help you achieve your many dreams.
        </p>
        <p>
          We know that our enthusiasm, dedication, and devotion to the Consultants will be the
          foundation of TERRAMAR BRANDS USA’s great success. Leaders and Consultants have our full
          commitment to helping them fulfill their desire for personal, professional, and economic
          growth.
        </p>

        <p className={styles.paragraphTitle}>TERRAMAR TEAM</p>
        <p className={styles.paragraphTitleCenter}>GLOSSARY</p>

        <ol>
          <li className={styles.list}>
            <span className={styles.listTitle}>Consultant: </span>
            <span className={styles.span}>
              An Independent trader who enrolls in the TERRAMAR Program through a Consultant,
              already enrolled in the TERRAMAR Program. To become a Consultant and distribute
              TERRAMAR products, you can join by purchasing an Entry Kit, or with the Suggested
              Order.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Suggested Order: </span>
            <span className={styles.span}>
              A set of products or promotions from the regular line, TERRAMAR “A Line”, equal to one
              module.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Kit Number: </span>
            <span className={styles.span}>
              A unique and personal code provided for each new consultant, with which they’ll be
              identified in the TERRAMAR Program.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Module: </span>
            <span className={styles.span}>
              : This is the unit of measurement, based on the purchase of “A Line” TERRAMAR Line
              products, and is intended to calculate promotions and commission payments. One module
              equals $180.00 dollars, consultant price plus taxes, operating expenses, and if
              required, shipping and handling expenses.
            </span>
            <p>
              <span className={styles.span}>
                Note: The module cost may change without prior notice.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Consultant Price: </span>
            <span className={styles.span}>
              a special price offered to Consultants, Entrepreneur, and Leaders who are enrolled in
              the TERRAMAR Program to purchase TERRAMAR “A Line” Line Products.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Public Price: </span>
            <span className={styles.span}>
              The recommended price that TERRAMAR BRANDS suggest for the Consultant, Entrepreneur,
              and Leaders to sell TERRAMAR “A Line” Line Products to their clients. The Consultant
              must give a receipt to her clients for all the retail sales she makes.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>TERRAMAR “A Line” Line Products: </span>
            <span className={styles.span}>
              These are all the products of all the different TERRAMAR BRANDS lines that are
              commissioned and count for the module accumulation.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Sales Aids “B Line”: </span>
            <span className={styles.span}>
              Consultants purchase these products to give as a gift to their clients, as a sale aid,
              or TERRAMAR training. These products are NOT commissionable.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Sponsorship: </span>
            <span className={styles.span}>
              Enrollment of a new Consultant into the TERRAMAR Program, through a Consultant,
              Entrepreneur, or a Leader who is already enrolled in the TERRAMAR Program. The new
              Consultant must have at least an order of one module.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Direct Sponsorship: </span>
            <span className={styles.span}>
              A new Consultant that you personally enroll (directly) in the TERRAMAR Program
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Indirect Sponsorship: </span>
            <span className={styles.span}>
              A new Consultant that is enroll in the downline of your group through another
              Consultant.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Ordering Consultant: </span>
            <span className={styles.span}>
              A Consultant who makes an order of one module, in the current month.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Active Consultant: </span>
            <span className={styles.span}>
              A Consultant who has a paid module within the last 4 months.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Sponsoring Consultant: </span>
            <span className={styles.span}>
              A Consultant sponsoring a new Consultant, each has paid an order for one module.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Lineage: </span>
            <span className={styles.span}>
              In the Terramar Program we use the word Lineage to refer to the family or down lines
              the Consultant has in her business
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Entrepreneur or Active Leader: </span>
            <span className={styles.span}>
              Is a Consultant who, according to her category within the TERRAMAR Lineage, completes
              the established requirements to sustain her corresponding level.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Inactive Consultant: </span>
            <span className={styles.span}>
              A Consultant who has not paid for a module in the last 4 months, but not more than 6
              months.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Reactivation: </span>
            <span className={styles.span}>
              An Inactive Consultant that pays for a module after not buying one within the last 4
              months, and before 6 months passed from her last order.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Discharged Consultant: </span>
            <span className={styles.span}>
              A Consultant who has not paid an order in 6 months is deregistered. To rejoin, she
              must enroll as a new sponsorship
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>
              Qualification of a Consultant to Entrepreneur:{' '}
            </span>
            <span className={styles.span}>
              This is the process where a Consultant achieves the Entrepreneur category within her
              business, this is considered the first qualification in the TERRAMAR Lineage Program.
            </span>
            <p>
              <span className={styles.span}>
                To qualify as an Entrepreneur, you must achieve the following requirements:
              </span>
            </p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>
                  Have a group of at least 9 Active Consultants, of which at least three must be
                  direct.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Have 9 Consultants ordering in your Central Group with a paid module during your
                  qualification month.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Have one paid personal module and enroll a direct sponsorship.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Once qualifying, the following requirements must be met to receive commercial
                  transactions payment: have one sponsoring Consultant, one direct sponsorship, one
                  personal module, and a minimum of 9 ordering Consultants.
                </span>
                <p>
                  <span className={styles.span}>
                    * It should be noted that the qualifying month is considered “qualification
                    month”, and the following month is called “maintenance month.”
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Simultaneous Qualification: </span>
            <span className={styles.span}>
              when a Consultant and her Direct or Indirect Sponsorship qualify simultaneously in the
              same month (qualification month). Each one must meet the requirements independently of
              the other.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Central Group: </span>
            <span className={styles.span}>
              The Central Group of an Entrepreneur or Leader includes all her direct or indirect
              sponsorships, which are not promoted to Entrepreneur.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>1st Line Direct Group: </span>
            <span className={styles.span}>
              A group of Consultants that are promoted from the Central Group of an Entrepreneur or
              Leader when one of its consultants qualifies as an Entrepreneur (for lineage purposes
              referred to as a Qualified Direct Group).
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>2nd Line Indirect Group: </span>
            <span className={styles.span}>
              A group of Consultants that are promoted through an Entrepreneur who is in the 1st
              Line of Qualified Group of the corresponding lineage (for lineage purposes referred to
              as a Qualified Indirect Group).
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>District: </span>
            <span className={styles.span}>
              These are all the direct and indirect qualified groups that form the overall lineage
              of a Leader at all levels. Indirect levels are paid when the number of active groups
              in the first line matches the depth of the active lines.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Relocated: </span>
            <span className={styles.span}>
              when an Entrepreneur or Leader returns to the Consultant Level for failing to comply
              with the maintenance requirements according to her corresponding category for 3
              consecutive months.
            </span>
          </li>
        </ol>

        <p className={styles.paragraphTitle}>LINEAGE CATEGORY</p>
        <p>TERRAMAR Category of Lineage: There are 11 Levels of Lineage.</p>
        <p>
          The following requirements are necessary to qualify for the TERRAMAR Career Program and
          obtain their corresponding benefits.
        </p>
        <p className={styles.paragraphTitle}>1st Level: Consultant.</p>
        <p>
          BENEFITS: 100% sales earnings. Recognition, monthly prizes for activity and for
          sponsoring, skincare and makeup workshops, and the Elite Club program.
        </p>
        <p className={styles.paragraphTitle}>2nd Level: Entrepreneur.</p>
        <p>Have 9 active Consultants, 9 Consultants ordering, and 9 paid modules</p>
        <p>BENEFITS: 12% from your Central Group.</p>
        <p className={styles.paragraphTitle}>3rd Level: Leader.</p>
        <p>
          Have 1 Qualified Group complying with their category requirements, with 12 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 12 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 13% from your Central Group, and 7% from the Qualified Direct Group in your
          first line.
        </p>
        <p className={styles.paragraphTitle}>4th Level: Leader 2 Stars.</p>
        <p>
          Have 2 Qualified Groups complying with their category requirements, with 15 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 15 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 14% from your Central Group and 7% from the Qualified Direct Group in your first
          line, and 1% from the Qualified Indirect Groups in your second line.
        </p>
        <p className={styles.paragraphTitle}>5th Level: Leader 3 Stars.</p>
        <p>
          Have 3 Qualified Groups complying with their category requirements, with 18 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 18 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 15% from your Central Group and 7% from the Qualified Direct Group in your first
          line, 1% from the Qualified Indirect Groups in your second line, and 1% from the Qualified
          Indirect Groups in your third line.
        </p>
        <p className={styles.paragraphTitle}>6th Level: Leader 5 Stars.</p>
        <p>
          Have 5 Qualified Groups complying with their category requirements, with 20 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 20 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 16% from your Central Group and 7% from the Qualified Direct Group in your first
          line, 2% from the Qualified Indirect Groups in your second line, and 1% from the Qualified
          Indirect Groups in your third line and down lines.
        </p>
        <p className={styles.paragraphTitle}>7th Level: Leader 8 Stars.</p>
        <p>
          Have 8 Qualified Groups complying with their category requirements, with 25 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 25 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 17% from your Central Group and 7% from the Qualified Direct Group in your first
          line, 2% from the Qualified Indirect Groups in your second line, and 1% from the Qualified
          Indirect Groups in your third line and down lines.
        </p>
        <p className={styles.paragraphTitle}>8th Level: Leader 11 Stars.</p>
        <p>
          Have 11 Qualified Groups complying with their category requirements, with 30 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 30 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 18% from your Central Group and 8% from the Qualified Direct Group in your first
          line, 2% from the Qualified Indirect Groups in your second line, and 1% from the Qualified
          Indirect Groups in your third line and down lines.
        </p>
        <p className={styles.paragraphTitle}>9th Level: Leader 15 Stars.</p>
        <p>
          Have 15 Qualified Groups complying with their category requirements, with 35 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 35 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 19% from your Central Group and 8% of the Qualified Direct Group in your first
          line, 2% from the Qualified Indirect Groups in your second line, and 1% from the Qualified
          Indirect Groups in your third line and down lines.
        </p>
        <p className={styles.paragraphTitle}>10th Level: Independent Partner.</p>
        <p>
          Have 20 Qualified Groups complying with their category requirements, with 50 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, and 50 monthly
          paid modules.
        </p>
        <p>
          BENEFITS: 20% from your Central Group and 8% from the Qualified Direct Group in your first
          line, 2% from the Qualified Indirect Groups in your second line, and 1% from the Qualified
          Indirect Groups in your third line and up, to infinity
        </p>
        <p className={styles.paragraphTitle}>11° Nivel: Golden Partner.</p>
        <p>
          Have 35 Qualified Groups complying with their category requirements, with 95 active
          Consultants, a minimum of 9 Consultants ordering in your Central Group, 95 monthly paid
          modules, and 20 direct Leaders with a category higher than 5 Stars.
        </p>
        <p>
          BENEFITS: 25% from your Central Group and 10% from the Qualified Direct Group in your
          first line, 2% from the Qualified Indirect Groups in your second line, and 1% from the
          Qualified Indirect Groups in your third line and up, to infinity.
        </p>

        <p className={styles.paragraphTitle}>TERRAMAR Career Program Consistency Requirements:</p>
        <ol type='a'>
          <li className={styles.list}>
            <span className={styles.span}>
              The Consultant or Leader must comply each month with the established requirements of
              her level according to the TERRAMAR Lineage Table; failing to do so at any given
              month, will incur in her first non-compliance.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              A Leader may have a maximum of two consecutive non-compliance; after 3 noncompliances
              her level will automatically drop to the next lower level.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              The unlimited from the 2nd line onwards will be paid according to the number of Stars
              or Leaders she has in their first line. Meaning, that if a leader has 5 groups in her
              first line, she needs to have 5 down lines to collect the unlimited. Lines are paid to
              unlimited downlines according to the number of active groups in her first direct line.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              An enroller must maintain an ongoing, professional leadership association with
              Independent Consultants in her organization and must fulfill the obligation of
              performing good practices as a sales mentor or sales trainer in the sale or delivery
              of products to the final consumer.
            </span>
          </li>
        </ol>

        <p className={styles.paragraphTitle}>
          Guidelines for Merchant Commercial Transactions Payments
        </p>
        <p>
          To receive the monthly merchant commercial transaction payment, an Entrepreneur or a
          Leader must comply with the following guidelines established on the TERRAMAR’s Lineage
          Table described below:
        </p>
        <p className={styles.paragraphTitleCenter}>LINEAGE TABLE</p>
        <p className={styles.imagecenter}>
          <img alt='lineage table' src={MANUAL_TABLE} className={styles.legalImage} />
        </p>

        <p className={styles.paragraphTitleCenter}>LINEAGE RULES</p>
        <ul>
          <li className={styles.list}>
            <span className={styles.listTitle}>
              Qualification of the sponsorship before her Sponsor:{' '}
            </span>
            <span className={styles.span}>
              If a Consultant has a Sponsored Consultant who qualifies as an Entrepreneur before
              her, the Sponsor will have 4 months to promote and recover her qualification,
              otherwise, she will lose her sponsorship and will no longer be part of her direct
              line.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Overtake: </span>
            <span className={styles.span}>
              To receive commercial transaction payment from your Direct Groups, they must be in
              your same category or lower. If one of the Group’s Leaders outgrows you, you will stop
              receiving commercial transaction payment from their lineage until you match them in
              rank. There is no time limit for this, as long as the leader who has overtaken you has
              not reached the Independent Partner level. If a downline leader overtakes you and
              reaches the level of Independent Partner, you will have 2 years to catch up with her
              in rank.
            </span>
            <p>
              <span className={styles.span}>
                If a downline leader overtakes you at the Independent Partner level, you will be
                required to have the same number of direct groups as she does to get back these
                commissions.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Relocation: </span>
            <span className={styles.span}>
              If at any time a Leader drops her level for having 3 non-compliances in her category,
              she will be relocated to the level where she fulfilled the last month’s requirements.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.listTitle}>Change of Lineage:</span>
            <span className={styles.span}>
              To change Lineage, it’s necessary to stop placing orders for 6 months and to re-enroll
              with a different Sponsor, this benefit will only be granted to Consultants and
              Entrepreneurs.
            </span>
            <p>
              <span className={styles.span}>
                If a person was a Leader, she will not be able to change lineage, if she waits
                6-month without placing an order, she might be sponsored by the company or stay with
                the same sponsor, this decision is up to her.
              </span>
            </p>
          </li>
        </ul>

        <p className={styles.paragraphTitleCenter}>LINEAGE PROGRAMS</p>
        <ol>
          <li className={styles.list}>
            <span className={styles.listTitle}>ELITE CLUB</span>
            <p>
              <span className={styles.span}>
                As a Consultant you get different benefits by making an order, each month, such as:
              </span>
            </p>
            <div className={styles.legalParagraph}>
              <span className={styles.span}>
                Once you joined as a Consultant, starting the second month of your consecutive
                purchased order you get:
              </span>
              <div className={styles.legalParagraph}>
                <span className={styles.span}>
                  <i>Second Month</i>
                </span>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>One gift product (Hair Mask 500 g).</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Free Shipping.</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Previous month’s activity gift (Pending information if in the USA there will
                      be a monthly activity gift).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Purchase one of the Elite Club packages (Sales Aid).
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles.legalParagraph}>
                <span className={styles.span}>
                  <i>Third Month.</i>
                </span>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      One gift product (3 in 1 Hair Oil Jumbo size 500 ml).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Free Shipping.</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Purchase one of the Elite Club packages (Sales Aid).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Access to the TERRAMAR Online Store.</span>
                  </li>
                </ul>
              </div>
              <div className={styles.legalParagraph}>
                <span className={styles.span}>
                  <i>Fourth Month.</i>
                </span>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      An additional 20% discount to the current additional monthly discount.
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Free Shipping.</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Purchase one of the Elite Club packages (Sales Aid).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Access to the TERRAMAR Online Store.</span>
                  </li>
                </ul>
              </div>
              <div className={styles.legalParagraph}>
                <span className={styles.span}>
                  <i>Fifth Month</i>
                </span>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      One Terra Voucher (redeemable for any of the bases of the lineage program).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Free Shipping.</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Purchase one of the Elite Club packages (Sales Aid).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Access to the TERRAMAR Online Store.</span>
                  </li>
                </ul>
              </div>
              <div className={styles.legalParagraph}>
                <span className={styles.span}>
                  <i>Sixth Month</i>
                </span>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>An Oil Elite Set</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Free Shipping.</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>
                      Purchase one of the Elite Club packages (Sales Aid).
                    </span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>Access to the TERRAMAR Online Store.</span>
                  </li>
                </ul>
              </div>
              <p>
                <span className={styles.span}>
                  From the seventh month forward you get free shipping, access to the TERRAMAR
                  Online Store, and purchasing one of the Elite Club packages per month, as long as
                  you place a consecutive monthly order of a minimum of one module.
                </span>
              </p>
            </div>
          </li>

          <li className={styles.list}>
            <p className={styles.paragraphTitle}>SEEKING TALENT</p>
            <p className={styles.paragraphTitle}>LEVEL A</p>
            <p>
              <span className={styles.span}>This promotion is only eligible for Consultants.</span>
            </p>
            <p>
              <span className={styles.span}>
                Recruit one or two new Direct Consultants, with a paid order of one module each,
                during the month when the promotion is announced, and those Consultants must repeat
                an order of one module the following month. Only the Sponsor Consultant receives the
                advertised gift mentioned in the month’s magazine
              </span>
            </p>
            <p className={styles.paragraphTitle}>LEVEL B</p>
            <p>
              <span className={styles.span}>
                Only Consultants, Entrepreneur and One Star Group Leader can participate.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Recruit 3 new direct Consultants with a paid module during the month, and all 3 new
                Consultants must repeat a one order module the following month. If both conditions
                are met, the Sponsor receives a $50 USD gift card.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                This promotion is not limited; for every 3 new Direct Sponsorships, the Sponsor
                receives $50 USD. The Sponsor only receives the gift.
              </span>
            </p>
            <p className={styles.paragraphTitle}>LEVEL C</p>
            <p>
              <span className={styles.span}>
                This promotion is only eligible for the levels from Entrepreneur to Golden Partner.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Two Direct Consultants from your Lineage must have one sponsorship each, with a paid
                module during the month, and both Consultants must repeat a paid module the
                following month. If both conditions are met, you receive a product voucher for $60
                USD public price.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                For every 3 or more Direct Consultant sponsoring, you can choose between a product
                voucher for $150 USD public price, or a $50 USD gift card.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Not limited; for every 3 Direct Consultant Sponsoring, you choose either a $150 USD
                public price voucher or a $50 USD gift card. The gift is only for the Sponsor.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>GROWING MAKES YOU WIN</p>
            <p>
              <span className={styles.span}>
                This program pays you during the following 4 months of your promoted group as if it
                was part of your Central Group. TERRAMAR BRANDS is proud to be the only company that
                pays you for promoting your groups.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                We understand that promoting strong groups is one of the key ingredients to
                achieving growth within the TERRAMAR Lineage. GROWING MAKES YOU WIN allows an
                Entrepreneur to reach the highest level of the TERRAMAR PROGRAM i.e., Independent
                Partner in 1 year or less, if she chooses.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                By launching a new group, you will earn, for the following 4 months, the commercial
                transaction payment percentage corresponding to the percentage that applies to your
                first line. If the promoted group fails to comply with their category requirements
                during those initial 4 months, you will lose this benefit.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                You can continue benefiting from this program until you reach the Independent
                Partner level. This program ends once an Independent Partner has 20 active groups in
                their first line, 50 modules in their Central Group, and when all the groups have
                benefited from the Growing Makes You Win program during their first 4 months after
                qualifying. This means, that all the new groups will be paid at 8% as indicated in
                the Lineage Table.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                This program allows you the opportunity to repopulate your Central Group as soon as
                possible and not impact your growth by the group promotion. This is the company’s
                most expensive promotion and only applies to commercial transaction payment, it does
                not apply for events, cars, bonuses, and other promotions/programs.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                This will be done consecutively until reaching the Independent Partner level. Once
                the Independent Partner has 20 active groups in her first line and 50 modules in her
                Central.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Group and all these groups have benefited from the Growing Makes You Win program
                during their 4 months of qualification, this promotion ends. That is, all new groups
                will be paid at 8% as indicated in the lineage table.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>EXECUTIVE LEADER BLAZER</p>
            <div className={styles.legalParagraph}>
              <ul>
                <li className={styles.list}>
                  <p>
                    <span className={styles.span}>
                      You will earn the Executive Leader Blazer once you reach the Leader level and
                      maintain it for a one month after your launched star
                    </span>
                  </p>
                </li>
                <li className={styles.list}>
                  <p>
                    <span className={styles.span}>
                      The Executive Leader Blazer is only awarded once.
                    </span>
                  </p>
                </li>
                <li className={styles.list}>
                  <p>
                    <span className={styles.span}>
                      It is a requirement to close in your category.
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>YOU DECIDE</p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>Qualify as a Leader.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Comply with the established requirements of the Lineage Table (growing makes you
                  win do not apply)..
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Have <strong>5 SPONSORING CONSULTANTS</strong> in your Central Group, out of which
                  3 must be direct. This requirement must be achieved for 2 consecutive months for
                  the prize to be given in the 3rd month.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Qualifying at a higher level <strong>DOES NOT</strong> count as one of the
                  requirements, it’s necessary to have <strong>5 SPONSORING CONSULTANTS</strong> in
                  your Central Group, out of which 3 must be direct.
                </span>
              </li>
            </ol>
            <p className={styles.paragraphTitle}>BENEFITS:</p>
            <p>
              <span className={styles.span}>
                You will receive the prize you choose from our established prize list. For example:
                A Smartwatch, a cell phone, a Laptop, a Smart TV
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>Prizes may vary without prior notice.</span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>Requirements to achieve the Promotion:</span>
            </p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>
                  If the leader fails one of the requirements, i.e., she did not comply with the
                  requirements during the second month, she will start the requirements again, until
                  she meets them for 2 consecutive months.
                </span>
              </li>
            </ol>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>PROMOTING LEADERS</p>
            <p>
              <span className={styles.span}>
                When you start the TERRAMAR Career Program and launch groups, you can earn the
                following bonuses:
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  If you launch 2 groups, meaning when you reach the Leader 2 Star Level, you
                  receive a bonus of $500 USD.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  By launching the next star, which means reaching the Leader 3 Star Level, you
                  receive a bonus of $750 USD.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  By launching 2 more stars, that is, reaching the Leader 5 Star Level, you receive
                  a bonus of $1,500.00 USD.
                </span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>
                Once your reach the Leader 5 Star Level, you can earn bonuses for growing your Stars
                from your Direct Line. For each one you can earn:
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  $500 USD if one of them reaches the Leader 1 Star Level.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  $1,000.00 USD if your Star reaches the 3 Star Leader Level.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  $2,500.00 USD if your Star reaches the 5 Star Leader Level.
                </span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>
                -The bonuses are issued after 2 months of maintenance following the new launches.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                -This program is limited to 14 groups and can be earned with EXISTING OR NEW STARS.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                -The bonus only applies to Leaders who achieve <strong>ALL</strong> the requirements
                of their category.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                -Leaders who qualify stars must remain active to continue in the Program
              </span>
            </p>
            <p>
              <span className={styles.span}>
                -If you lose continuity and re-enter the Program, you must start working on the
                bonus requirements of the level following the last one you received.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                -There is no time limit for your program recovery.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>DIAMOND STELLAR BONUS</p>
            <p className={styles.paragraphTitle}>
              Stellar Bonus: Independent Partner Category (Phase 1).
            </p>
            <p>
              <span className={styles.span}>
                YOU EARN $50,000.00 USD IF YOU MEET THE FOLLOWING REQUIREMENTS AND MAINTAIN THEM FOR
                6 CONSECUTIVE MONT
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  20 Leaders 5 Stars IN YOUR FIRST LINE. EACH OF THEM MUST: Complete and pay for 30
                  monthly modules and have 20 ACTIVE Consultants.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>75 Active Consultants IN YOUR CENTRAL GROUP.</span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>
                For the bonus to be paid, continuity must not be lost. You must order and pay the
                corresponding MONTHLY modules.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                There are no grace periods or exceptions in the compliance of the requirements.
              </span>
            </p>
            <p className={styles.paragraphTitle}>
              Stellar Bonus: Independent Partner and Golden Partner Category (Phase 2).
            </p>
            <p>
              <span className={styles.span}>
                YOU EARN $100,000.00 USD IF YOU MEET THE FOLLOWING REQUIREMENTS AND MAINTAIN THEM
                FOR 6 CONSECUTIVE MONTHS.
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>20 Leaders 15 Stars IN YOUR FIRST LINE.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>100 Active Consultants IN YOUR CENTRAL GROUP.</span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>
                For the bonus to be paid, you must not lose continuity. You must order and pay the
                corresponding MONTHLY modules. There are no grace periods or exceptions in the
                compliance of the requirements
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Note: TERRAMAR BRANDS reserves the right to change the amount of the bonuses for
                Bonus Promotion without prior notice.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>DRIVING TO SUCCESS</p>
            <p>
              <span className={styles.span}>
                Participants: From 5 Star Leaders to 11 Star Leaders s.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Three months of a calendar year will be taken as the qualification period. The
                maintenance will correspond to 24 additional months to the qualification months
              </span>
            </p>
            <p>
              <span className={styles.span}>
                The Leaders who in their Central Group have fulfilled the following requirements
                during three consecutive months will be eligible to qualify:
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>1 Personal module.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>1 Direct Sponsorship.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  8 Consultants Sponsoring in their Central Group.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  3 or more Leaders (Independent Entrepreneur and upwards) in the 1st line comply
                  with the following requirements:
                </span>
                <ul>
                  <li className={styles.list}>
                    <span className={styles.span}>1 Personal Module.</span>
                  </li>
                  <li className={styles.list}>
                    <span className={styles.span}>1 Direct Sponsorship.</span>
                  </li>
                </ul>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  The combination of Leaders closing a total of 12 Sponsoring Consultants.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Only Leaders with 2 or more sponsoring Consultants can participate.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Must have a net launch every 6 months.</span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>
                Throughout the promotional period, personal sponsorship, sponsoring Consultants and
                their new Sponsorships must place a consecutive order the following month in which
                they were considered for the requirement. Any failure to comply with the required
                repetitions of such Consultants will be deemed as a strike.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Upon meeting these requirements for 3 consecutive months, the car will be delivered
                in the 4th month, under the condition of maintaining this productivity for 24
                months.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                The participating Leader may have up to 3 strikes in a 12-month period, meaning, up
                to 6 in 24 rotating months.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Failure to comply with any of the requirements mentioned above is considered a
                strike.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Upon having a 4th strike, the car benefit may be withdrawn, and the Leader will not
                be eligible to participate again for this benefit.
              </span>
            </p>
            <p>
              <span className={styles.span}>The strikes must not be consecutive.</span>
            </p>
            <p>
              <span className={styles.span}>
                In addition to these strikes, the participating Leader may have up to 2 strike per
                year in which the Leader will have the option to cover the $300.00 USD monthly
                payment of the car financing, to continue using the car and not have it taken away.
                Likewise, these strikes must not be consecutive to each other, nor with the previous
                strikes.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                If, during the promotional period, the Leader has two consecutive strikes, the car
                will need to be return immediately, in the same conditions in which it was initially
                delivered. Or if during the first twelve months, the Leader has six strikes, even if
                the previous 5 were not consecutive, the car will also need to be returned. The same
                applies if this situation happens during the second year, meaning, between the 13th
                and 24th month of the promotional period.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                In any of the situations mentioned above, if the car is taken away from the Leader,
                she will not be able to participate again in the “Terramar Driving to Success”
                program.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                The Leader who has complied with the requirements for 24 months, considering a
                maximum of 5 failures per 12 months, will receive the car’s ownership paperwork
                under her name.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                THE CAR OF THE “TERRAMAR DRIVING TO SUCCESS” PROGRAM IS ONLY GIVEN ONCE, MEANING,
                ONCE THE PROGRAM HAS CONCLUDED AND THE LEADER HAS BEEN GIVEN THE CAR, SHE WILL NOT
                BE ABLE TO PARTICIPATE IN THIS PROGRAM AGAIN.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>SHINING GROWTH</p>
            <p>
              <span className={styles.span}>
                THE PRIZES (JEWELRY) ARE AWARDED ONLY ONCE, I.E. IF A LEADER DROPS A LEVEL AND LATER
                RECOVERS, THE GIFTS WILL NOT BE AWARDED AGAIN.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Requirements to qualify for the Jewelry Program by leveling up:
              </span>
            </p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>
                  Launch the required groups according to the TERRAMAR Lineage Table.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Close with the corresponding launched groups mentioned in the TERRAMAR Lineage.
                </span>
              </li>
            </ol>
            <p>
              <span className={styles.span}>
                Table, i.e. be paid according to the stipulated category.
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  Leader 5 Stars > meet the requirements of their category.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Leader 8 Stars > meet the requirements of their category.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Leader 11 Stars > meet the requirements of their category.
                </span>
              </li>
            </ul>
            <p className={styles.paragraphTitle}>BENEFITS:</p>
            <p>
              <span className={styles.span}>
                Leaders will receive a piece of jewelry according to the qualified level and will be
                handed to them in an Award Ceremony.
              </span>
            </p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>Leader 5 Stars > sapphire and rubies ring.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Leader 8 Stars > sapphire and rubies earrings.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Leader 11 Stars > sapphire and rubies choker.</span>
              </li>
            </ul>
            <p>
              <span className={styles.span}>
                Note: TERRAMAR BRANDS reserves the right to change the prizes of the Shining Growth
                program without prior notice.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>RETIREMENT AND INHERITANCE PLAN</p>
            <p className={styles.paragraphTitle}>
              REQUIREMENTS TO QUALIFY FOR THE RETIREMENT PLAN:
            </p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>
                  You will receive your retirement right for 10 years if you have maintained during
                  5 years since qualifying to Leader 11 Stars level (with cero non-compliances).
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  You will receive your retirement right for a lifetime if you maintain for 10 years
                  at Leader 11 Stars level (with cero non-compliances).
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  The “Growing Makes You Win” Program doesn’t count as a requirement.
                </span>
              </li>
            </ol>
            <p className={styles.paragraphTitle}>BENEFITS:</p>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  A Leader who has maintained a constant Leader 11 Stars level for 5 years since
                  qualifying will be paid 100% of commercial transaction payment for 10 years, or
                  for a lifetime if the Leader maintains such level for 10 years.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Commercial transaction will be paid based on the number of groups in their first
                  line, i.e., if a Leader has 20 groups in their first line, they must have 20 lines
                  launched to receive to your unlimited downlines.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Commercial transaction will be paid based on the productivity of all its downlines
                  according to their TERRAMAR lineage.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  When a Consultant retires, she will not be required to comply with her personal or
                  Central Group requirements; meaning, she will only receive payment for her 1st
                  line onward.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  If she complies with the requirements of her Central Group, without her personal
                  module, she will also receive a commission from her Central Group.
                </span>
              </li>
            </ul>
            <p className={styles.paragraphTitle}>EXCEPTIONS:</p>
            <p>
              <span className={styles.span}>
                A Leader must maintain for 5 or 10 consecutive years at Leader 11 Stars category. If
                for any reason the Leader drops to a lower level, she must start from scratch all
                the requirements.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                TO OBTAIN THIS BENEFIT, THE LEADER MUST APPLY IN WRITING TO THE LINEAGE DEPARTMENT
                AND THE LINEAGE DEPARTMENT MUST SIGN THE APPLICATION.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Note: The TERRAMAR Lineage for Independent Merchants may vary or cancel the
                guidelines and policies of this procedure at any time due to changes that may occur
                to the economic conditions that were considered to define them.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>15 STARS EXPERIENCE</p>
            <p>
              <span className={styles.span}>
                When you achieve the Leader 15 Stars Level, TERRAMAR gifts you, and the Leaders from
                your first line, a personalized experience to enjoy as a team.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                To receive this benefit, you need to comply with a 3-month maintenance period. If
                during those 3 months you launch a new star, the new Leader will be part of this
                experience, you have a maximum of 19 Stars.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>TERRAMAR PREMIUM CAR</p>
            <p>
              <span className={styles.span}>
                Once you qualify to the Leader 15 Stars category you must complete the following
                requirements:
              </span>
            </p>
            <p>
              <span className={styles.span}>Keep for 3 months:</span>
            </p>
            <ol type='a'>
              <li className={styles.list}>
                <span className={styles.span}>50 Active Consultants.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>50 modules in your Central Group.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>15 Active Groups in your first line.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>15 Sponsoring Consultants.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  5 Leaders or more in your first line with a higher 5 Stars (L5S) category.{' '}
                </span>
              </li>
            </ol>
            <p>
              <span className={styles.span}>
                Once you qualify to the Leader 15 Stars category you must complete the following
                requirements:
              </span>
            </p>
            <p className={styles.paragraphTitle}>TERRAMAR PREMIUM CAR</p>
            <p>
              <span className={styles.span}>
                A luxury car will be given to you, in recognition of your work and effort. In
                addition to providing you with a luxurious means of transportation within the direct
                sales and multilevel industry.
              </span>
            </p>
            <p className={styles.paragraphTitle}>REQUIREMENTS TO ACHIEVE THE PROMOTION:</p>
            <p>
              <span className={styles.span}>
                If the Leader does not comply with one of the requirements in the months before
                delivering the car; the Leader will have to re-start working the requirements from
                zero. Meaning, working on the TERRAMAR PREMIUM CAR requirements once again for 2 or
                3 months.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                Note: TERRAMAR BRANDS has the right to change the vehicle model without prior
                notice.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>INDEPENDENT PARTNER DAY</p>
            <p>
              <span className={styles.span}>
                When reaching the top level of the TERRAMAR career program you will be awarded a
                special day with all the luxury and exclusivity that distinguish TERRAMAR, with
                executive committee members. A six-month maintenance period at the top-level
                category is required without failing to comply with any of the requirements.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                All the TERRAMAR experiences are customized for each Leader.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>INDEPENDENT PARTNER LIFE INSURANCE</p>
            <p>
              <span className={styles.span}>
                When you become an Independent Partner, you will receive as a benefit an individual,
                annual, renewable life insurance. This benefit is granted after complying with six
                months of maintenance in the category, and if at any time thereafter, you do not
                comply with the requirement of the category, the insurance will be canceled. It will
                be reinstated once you comply with the Independent Partner requirements and complete
                a 6-month maintenance period.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                The insurance contract will be made under the current general conditions of the
                insurance company that TM BRANDS MANAGEMENT USA LLC. considers. The acceptance or
                declination of the insurance company, the expedition of the Policy; is solely the
                responsibility of those who celebrate the contract (Insurer and Independent Partner
                Leader). TM BRANDS MANAGEMENT USA LLC., its executives, representatives, employees,
                and companies of the same group or corporate interests of Terramar Brands are exempt
                from this responsibility.
              </span>
            </p>
            <p>
              <span className={styles.span}>
                In case of a loss, the general conditions of the contracted insurance shall apply,
                releasing TM BRANDS MANAGEMENT USA LLC., its executives, representatives, employees,
                and the companies of the same group or corporate interests of Terramar Brands from
                any obligation to pay the insured amount of the contracted policy.
              </span>
            </p>
          </li>
          <li className={styles.list}>
            <p className={styles.paragraphTitle}>NATIONAL AND INTERNATIONAL TRIPS</p>
            <p>
              <span className={styles.span}>
                In TERRAMAR you have the opportunity to compete for national and/or international
                trips, regardless of the level you are in the TERRAMAR Career; when each event is
                announced, a set of rules and guidelines to participate are published as well. Keep
                in mind that TERRAMAR establishes different rules according to each category of the
                TERRAMAR Career.
              </span>
            </p>
          </li>
        </ol>
        <p className={styles.paragraphTitleCenter}>WELCOME</p>
        <p>
          <span className={styles.span}>
            TERRAMAR currently gives you the opportunity to participate in the business program in
            Mexico and the United States, in order to do so in a country other than your residence,
            you must achieve the central group requirements, according to the lineage table in your
            country of residence, that is, ordering consultants, and minimum modules of your level
            in the career, must be done in your country of residence.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            The TERRAMAR Leaders are authorized to promote and/or sell Terramar Brands products and
            enroll Customers or Consultants only in the countries in which Terramar Brands is
            authorized to conduct business, as announced in official Company literature (an
            “Official Country”). Terramar Brands products or sales may not be given, transferred,
            distributed, shipped into or sold in any Unauthorized Country.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            Consultants, Entrepreneurs or Leaders have no authority to take any steps in any country
            toward the introduction or furtherance of the Company.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            This includes, but is not limited to, any attempt to register, reserve or otherwise
            secure any Company names, trademarks, trade names, copyright, patent, other intellectual
            property, to secure approval for products or business practices, or to establish
            business or governmental contacts. You agree to indemnify the Company for all costs
            incurred by it for any remedial action needed to exonerate the Company in the event you
            improperly act purportedly on behalf of the Company.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            Only after the Company has announced that a country is officially open for business may
            Consultants conduct business in that Official Country by promoting the Company (or
            related entity) and/or promoting, marketing or selling Products, and enrolling other
            Consultants or Customers.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            Consultants, Entrepreneurs or Leaders are required to follow all laws, rules and
            regulations of the Official Country. Consultant, Entrepreneurs or Leaders may use only
            promotional materials approved by the Company for use in an Official Country and sell
            only products approved for sale in that country.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            In addition, no Consultant, Entrepreneurs or Leaders may, in any Unauthorized Country:
          </span>
        </p>
        <ul>
          <li className={styles.list}>
            <span className={styles.span}>
              Advertise the Company, the Compensation Plan or its products.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Offer Company products for sale or distribution.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>Conduct sales, enrollment or training meetings.</span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Enroll or attempt to enroll potential Customers or Consultants.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Accept payment for enrollment or recruitment from citizens of countries where TERRAMAR
              does not conduct business.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Promote international expansion via the Internet or in promotional literature.
            </span>
          </li>
          <li className={styles.list}>
            <span className={styles.span}>
              Conduct any other activity for the purpose of selling TERRAMAR products, establishing
              a marketing organization or promoting the TERRAMAR opportunity.
            </span>
          </li>
        </ul>
        <p>
          <span className={styles.span}>
            A Consultant, Entrepreneurs or Leaders is solely responsible for compliance with all
            laws, tax requirements, immigration customs laws, rules, and regulations of any country
            in which she conducts business. Consultants, Entrepreneurs or Leaders accept the sole
            responsibility to conduct their independent businesses lawfully within each country in
            which she conducts business.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            A Consultant, Entrepreneurs or Leader’s right to receive commissions in a country may be
            revoked at any time if the Company determines that she has not conducted business in
            particular country in accordance with the terms and conditions contained herein or the
            governing operations within such country.
          </span>
        </p>
        <p>
          <span className={styles.span}>
            The Company reserves the right to establish additional policies and procedures that are
            applicable to a specific country. A Consultant, Entrepreneurs or Leaders who conducts
            business internationally agrees to abide by all special policies established by the
            Company for the specific country or countries in which she conducts business.
          </span>
        </p>
      </div>
    </div>
  );
}
