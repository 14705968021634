import React, { useState, useEffect } from 'react';
import styles from './index.module.sass';
import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
//Dependencies
import buttonFormROP from '../Queries/buttonROP.js';
import getSepomex from '../Queries/getSepomex';
import postROPAltas from '../Queries/postROPAltas';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const RegistrationForm = (props) => {
  const { device, history } = props;
  const { screenSize } = device;
  const { t } = useTranslation('registrationForm');

  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const [openSucces, setopenSucces] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingKit, setLoadingKit] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [kitValidate, setkitValidate] = useState(false);
  const [message, setmessage] = useState('');
  const [buttonROP, setButtonROP] = useState(true);

  //hooks del formulario
  const [arrayColonias, setColonias] = useState([]);
  const [valuesForm, setvaluesForm] = useState({
    name: '',
    lastName: '',
    firstName: '',
    num1: '',
    num2: '',
    dep: '',
    mza: '',
    street: '',
    edifice: '',
    col: '',
    cp: '',
    statement: '',
    town: '',
    phone1: '',
    phone2: '',
    mail: '',
    Kit: '',
  });

  const changeValue = (e) => {
    if (e.target.name === 'Kit') {
      e.target.value = e.target.value.replace(
        /[`~´!¡@#$%^&*()_|+\-=¿?;:'",.<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}abcdefghijklmnopqrstuvwxyz[\]\\/ ]/gi,
        '',
      );
    }
    setvaluesForm({
      ...valuesForm,
      [e.target.name]: e.target.value,
    });
  };

  function restrictChars($event) {
    if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
      return true;
    } else {
      $event.preventDefault();
    }
  }

  async function verifyCP(e) {
    let cp = e.target.value;
    if (cp.length !== 5) {
      setAlertText(t('cpIncorrect'));
      setOpen(true);
      return;
    } else {
      setLoading(true);
      const response = await getSepomex(cp);
      const { mensaje, colonias, municipio, estado } = response;
      if (mensaje === '') {
        valuesForm.statement = estado;
        let col = [];
        for (let index = 0; index < colonias.length; index++) {
          col.push(colonias[index].colonia);
        }
        setColonias(col);
        valuesForm.town = municipio;
        setLoading(false);
      } else {
        setAlertText(mensaje);
        setOpen(true);
        setLoading(false);
      }
    }
  }

  async function verifyKIT() {
    if (valuesForm.numkit !== '' && !kitValidate) {
      setLoadingKit(true);
      setLoadingForm(true);
      const json = {
        accion: 'CONTINUAR',
        usuarioOK: '9000000',
        kitPatrocinadora: valuesForm.Kit,
      };

      const response = await getSepomex(json);

      const { nombrePatrocinadora } = response;
      if (nombrePatrocinadora === '** Patrocinadora no existe') {
        setAlertText(t('kitIncorrect'));
        setOpen(true);
        setLoadingKit(false);
        setLoadingForm(false);
        return;
      } else {
        setLoadingKit(false);
        setLoadingForm(false);
        sendForm();
        setkitValidate(true);
      }
    }
  }

  async function Save() {
    setLoadingForm(true);
    const response = await buttonFormROP();
    if (response === false) {
      setAlertText('El tiempo ha terminado, no es posible completar el registro');
      setOpen(true);
      setLoadingForm(true);
      setTimeout(() => {
        history.push('/');
      }, 4000);
      return;
    } else {
      if (valuesForm.name === '') {
        setAlertText(t('nameToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.lastName === '') {
        setAlertText(t('lastNameToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.firstName === '') {
        setAlertText(t('firstNameToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }
      if (valuesForm.street === '') {
        setAlertText(t('streetToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }
      if (valuesForm.num1 === '') {
        setAlertText(t('num1ToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.cp === '') {
        setAlertText(t('cpToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }
      if (valuesForm.col === '') {
        setAlertText(t('colToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }
      if (valuesForm.statement === '') {
        setAlertText(t('statementToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }
      if (valuesForm.town === '') {
        setAlertText(t('townToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.mail === '') {
        setAlertText(t('mailToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.phone1 === '') {
        setAlertText(t('phone1ToRegister'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.phone1.length < 10) {
        setAlertText(t('phoneCharacteres'));
        setOpen(true);
        setLoadingForm(false);
        return;
      }

      if (valuesForm.phone2 !== '') {
        if (valuesForm.phone2.length < 10) {
          setAlertText(t('phone2Characteres'));
          setOpen(true);
          setLoadingForm(false);
          return;
        }
      }

      if (valuesForm.Kit !== '') {
        setkitValidate(false);
        verifyKIT();
      } else {
        sendForm();
      }
    }
    setLoadingForm(false);
  }

  async function sendForm() {
    setLoadingForm(true);
    const json = {
      Accion: 'ALTA',
      Nombre: valuesForm.name,
      Paterno: valuesForm.lastName,
      Materno: valuesForm.firstName,
      Calle: valuesForm.street,
      NumeroExt: valuesForm.num1,
      NumeroInt: valuesForm.num2,
      DepCasa: valuesForm.dep,
      Manzana: valuesForm.mza,
      Edificio: valuesForm.edifice,
      Cp: valuesForm.cp,
      Colonia: valuesForm.col,
      Municipio: valuesForm.town,
      Estado: valuesForm.statement,
      Email: valuesForm.mail.toLowerCase(),
      Telefono: valuesForm.phone2,
      Celular: valuesForm.phone1,
      Kit: valuesForm.Kit,
    };

    const response = await postROPAltas(json);

    const { typeMessage, message } = response;
    if (typeMessage === 'error') {
      setAlertText(message);
      setOpen(true);
      setLoadingForm(false);
      return;
    } else {
      setLoadingForm(false);
      setvaluesForm({
        name: '',
        lastName: '',
        firstName: '',
        num1: '',
        num2: '',
        dep: '',
        mza: '',
        street: '',
        edifice: '',
        col: '',
        cp: '',
        statement: '',
        town: '',
        phone1: '',
        phone2: '',
        mail: '',
        Kit: '',
      });
      setmessage(message);
      setopenSucces(true);
    }
  }

  useEffect(() => {
    async function loadROP() {
      const response = await buttonFormROP();
      setButtonROP(response);
      if (buttonROP === false) {
        history.push('/');
      }
    }
    loadROP();
  }, [buttonROP, history]);

  return (
    <div>
      <div className={styles.return}>
        <div className={styles.buttonsReturn}>
          <label onClick={() => history.push('/')} className={styles.labelReturn}>
            Regresar
          </label>
        </div>
      </div>
      <div className={styles[screenSize]} id={styles.RegistrationForm}>
        <Alert open={open} setOpen={setOpen} title={t('messageTitleAlert')} text={alertText} />
        <Dialog
          open={openSucces}
          TransitionComponent={Transition}
          keepMounted
          //onClose={return}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'>
          <DialogTitle id='alert-dialog-slide-title'>Aviso</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => history.push('/')} color='primary'>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <div className={styles.container}>
          <h5>{t('h5Personal')}</h5>

          <div className={styles.personalInformation}>
            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('name')}</label>
                <input
                  type='text'
                  name='name'
                  value={valuesForm.name}
                  autoComplete='off'
                  onChange={(e) => changeValue(e)}
                  maxLength='45'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('lastName')}</label>
                <input
                  type='text'
                  name='lastName'
                  value={valuesForm.lastName}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='45'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('firstName')}</label>
                <input
                  type='text'
                  name='firstName'
                  value={valuesForm.firstName}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='45'
                />
              </span>
            </div>
          </div>

          <h5>{t('h5Address')}</h5>

          <div className={styles.address}>
            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('street')}</label>
                <input
                  type='text'
                  name='street'
                  value={valuesForm.street}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='30'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('num1')}</label>
                <input
                  type='text'
                  name='num1'
                  value={valuesForm.num1}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='40'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('num2')}</label>
                <input
                  type='text'
                  name='num2'
                  value={valuesForm.num2}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='40'
                />
              </span>
            </div>
          </div>

          <div className={styles.address}>
            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('dep')}</label>
                <input
                  type='text'
                  name='dep'
                  value={valuesForm.dep}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='40'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('mza')}</label>
                <input
                  type='text'
                  name='mza'
                  value={valuesForm.mza}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='40'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('edifice')}</label>
                <input
                  type='text'
                  name='edifice'
                  value={valuesForm.edifice}
                  onChange={(e) => changeValue(e)}
                  autoComplete='off'
                  maxLength='40'
                />
              </span>
            </div>
          </div>

          <div className={styles.address}>
            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('cp')}</label>
                <input
                  type='text'
                  name='cp'
                  autoComplete='off'
                  placeholder='00000'
                  onBlur={(e) => verifyCP(e)}
                  maxLength='5'
                  onKeyPress={(e) => restrictChars(e)}
                  onChange={(e) => changeValue(e)}
                  value={valuesForm.cp}
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('col')}</label>

                <select
                  className={styles.select}
                  name='col'
                  onChange={(e) => changeValue(e)}
                  value={valuesForm.col}>
                  <option value=''>{t('selectCol')}</option>
                  {arrayColonias.map((val, key) => {
                    return (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </span>
              {loading && (
                <div className={styles.loading}>
                  <CircularProgress /> <br />
                  {t('searchingCol')}
                </div>
              )}
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('town')}</label>
                <input
                  type='text'
                  autoComplete='off'
                  disabled
                  value={valuesForm.town}
                  onChange={(e) => changeValue(e)}
                  maxLength='40'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('statement')}</label>
                <input
                  type='text'
                  autoComplete='off'
                  disabled
                  value={valuesForm.statement}
                  onChange={(e) => changeValue(e)}
                  maxLength='30'
                />
              </span>
            </div>
          </div>

          <h5>{t('h5contact')}</h5>

          <div className={styles.contact}>
            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('mail')}</label>
                <input
                  type='text'
                  name='mail'
                  autoComplete='off'
                  placeholder='example@terramar.com'
                  onChange={(e) => changeValue(e)}
                  value={valuesForm.mail}
                  maxLength='50'
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('cel')}</label>
                <input
                  type='text'
                  name='phone1'
                  autoComplete='off'
                  placeholder='(00) 00000000'
                  maxLength='10'
                  onKeyPress={(e) => restrictChars(e)}
                  onChange={(e) => changeValue(e)}
                  value={valuesForm.phone1}
                />
              </span>
            </div>

            <div className={styles.subItem}>
              <span>
                <label htmlFor=''>{t('tel')}</label>
                <input
                  type='text'
                  name='phone2'
                  autoComplete='off'
                  placeholder='(00) 00000000'
                  maxLength='10'
                  onKeyPress={(e) => restrictChars(e)}
                  onChange={(e) => changeValue(e)}
                  value={valuesForm.phone2}
                />
              </span>
            </div>
          </div>

          <h5>{t('h5kit')}</h5>

          <div className={styles.contact}>
            <div className={styles.itemKit}>
              <span>
                <label htmlFor=''>{t('kit')}</label>
                <input
                  type='text'
                  name='Kit'
                  autoComplete='off'
                  onChange={(e) => changeValue(e)}
                  value={valuesForm.Kit}
                  maxLength='7'
                />
              </span>
            </div>
            <div className={styles.itemKit}>
              <span>
                {loadingKit && (
                  <div className={styles.loading}>
                    <CircularProgress /> <br />
                    {t('verifyKit')}
                  </div>
                )}
              </span>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            {(!loadingForm && !loadingKit && (
              <Button
                type='submit'
                variant='contained'
                size='large'
                color='primary'
                className={`${styles.button}`}
                onClick={() => {
                  if (loadingForm) return;
                  else Save();
                }}>
                {(loadingForm && <CircularProgress />) || t('buttonSave')}
              </Button>
            )) || (
              <div>
                <Button
                  type='submit'
                  variant='contained'
                  size='large'
                  color='primary'
                  className={`${styles.buttonDisabled}`}>
                  Cargando
                </Button>
              </div>
            )}
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
