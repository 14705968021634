import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Commponents
import BannerSide from '../BannerSide';
import BlankSpace from '../BlankSpace';

const LINEAGE_USA_ESP =
  'https://webimages.terramarbrands.com.mx/usawebpage/lineage/tabla-linaje-desk.png';
const LINEAGE_USA_ESP_MOB =
  'https://webimages.terramarbrands.com.mx/usawebpage/lineage/tabla-linaje-mobile.png';
const LINEAGE_USA_ENG =
  'https://webimages.terramarbrands.com.mx/usawebpage/lineage/tabla-linaje-desk-en.png';
const LINEAGE_USA_ENG_MOB =
  'https://webimages.terramarbrands.com.mx/usawebpage/lineage/tabla-linaje-mobile-en.png';

const Career = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;

  const { i18n = {}, t } = useTranslation('career');
  const { language = '' } = i18n;

  let lineageUSATable = LINEAGE_USA_ENG;

  if (screenSize === 'phone') lineageUSATable = LINEAGE_USA_ENG_MOB;
  if (language.includes('es')) lineageUSATable = LINEAGE_USA_ESP;
  if (language.includes('es') && screenSize === 'phone') lineageUSATable = LINEAGE_USA_ESP_MOB;
  return (
    <div id={styles.Career} className={styles[screenSize]}>
      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/lineage/linaje-mujeres-desk.jpg'
        urlMobile='https://webimages.terramarbrands.com.mx/usawebpage/lineage/linaje-mujeres-mobile.jpg'
        title={t('block1.title')}
        subtitle1={t('title')}
        subtitle2={t('subTit')}
        text={t('block1.content')}
        textInBottom={true}
      />

      <BlankSpace height={24} />

      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/lineage/programa-imagen-desk.jpg'
        urlMobile='https://webimages.terramarbrands.com.mx/usawebpage/lineage/programa-imagen-mobile.jpg'
        title={t('block2.title')}
        subtitle1={t('block2.subTitle1')}
        subtitle2={t('block2.subTitle2')}
        text={t('block2.text1')}
        text2={t('block2.text2')}
        text3={t('block2.text3')}
        textInBottom={true}
        rowReverse={true}
        backgroundColor='#f6efe6'
      />

      <BlankSpace height={24} />

      <div className={styles.lineageTable}>
        <img className={styles.lineageImage1} alt='lineage table' src={lineageUSATable} />
      </div>

      <BlankSpace height={24} />
    </div>
  );
};

export default Career;
