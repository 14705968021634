import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
/** USA **/
import PrivacyUSAEsp from '../Legales/PrivacyUSA/ESP';
import PrivacyUSAIng from '../Legales/PrivacyUSA/ENG';
import AgreementESP from '../Legales/AgreementUSA/ESP';
import AgreementING from '../Legales/AgreementUSA/ING';
import PolicesAndProceduresESP from '../Legales/PoliciesAndProcedures/ESP';
import PolicesAndProceduresING from '../Legales/PoliciesAndProcedures/ING';
import CaliforniaPrivacyESP from '../Legales/CaliforniaPrivacyUSA/ESP';
import CaliforniaPrivacyING from '../Legales/CaliforniaPrivacyUSA/ING';
import HandbookESP from '../Legales/Handbook/ESP';
import HandbookING from './Handbook/ENG';
import FaqUSAESP from '../Legales/FaqUSA/ESP';
import FaqUSAENG from '../Legales/FaqUSA/ENG';

/** MEX **/
import PrivacyMXESP from '../Legales/PrivacyMX';
import TermsMXESP from '../Legales/TermsMX';

const Legal = (props) => {
  const { match, history, device } = props;
  const { i18n = {} } = useTranslation();
  const { language = '' } = i18n;
  return (
    <div>
      <Switch>
        <Route
          path={`${match.path}/privacy`}
          render={(props) => {
            return <PrivacyMXESP {...props} device={device} history={history} />;
          }}
        />
        <Route
          path={`${match.path}/terms`}
          render={(props) => {
            return <TermsMXESP {...props} device={device} history={history} />;
          }}
        />

        {/* RUTAS PARA AVISO DE PRIVACIDAD DE USA  */}
        <Route
          exact
          path={`${match.path}/faq_us`}
          render={(props) => {
            if (language.includes('es'))
              return <FaqUSAESP {...props} device={device} history={history} match={match} />;
            else return <FaqUSAENG {...props} device={device} history={history} match={match} />;
          }}
        />
        <Route
          exact
          path={`${match.path}/privacy_us`}
          render={(props) => {
            if (language.includes('es')) {
              return <PrivacyUSAEsp {...props} device={device} history={history} />;
            } else {
              return <PrivacyUSAIng {...props} device={device} history={history} />;
            }
          }}
        />

        <Route
          exact
          path={`${match.path}/agreement_us`}
          render={(props) => {
            if (language.includes('es')) {
              return <AgreementESP {...props} device={device} history={history} />;
            } else {
              return <AgreementING {...props} device={device} history={history} />;
            }
          }}
        />

        <Route
          exact
          path={`${match.path}/policesAndProcedures_us`}
          render={(props) => {
            if (language.includes('es')) {
              return <PolicesAndProceduresESP {...props} device={device} history={history} />;
            } else {
              return <PolicesAndProceduresING {...props} device={device} history={history} />;
            }
          }}
        />

        <Route
          exact
          path={`${match.path}/californiaPrivacy_us`}
          render={(props) => {
            if (language.includes('es')) {
              return <CaliforniaPrivacyESP {...props} device={device} history={history} />;
            } else {
              return <CaliforniaPrivacyING {...props} device={device} history={history} />;
            }
          }}
        />

        <Route
          exact
          path={`${match.path}/handbook_us`}
          render={(props) => {
            if (language.includes('es')) {
              return <HandbookESP {...props} device={device} history={history} />;
            } else {
              return <HandbookING {...props} device={device} history={history} />;
            }
          }}
        />
      </Switch>
    </div>
  );
};

export default Legal;
