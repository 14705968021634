import React from 'react';
import styles from './../index.module.sass';

const TableESP = (props) => {
  const { screenSize = 'desktop', tableItems = [] } = props;
  const size = '';
  const dataCellWidth = (screenSize === 'phone' && '180px') || `${100 / tableItems.length}%`;

  return (
    <div className={`${styles.tableContainer} ${styles[screenSize]}`}>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.tableHeader}`}>
          <div
            className={`${styles.cell} ${styles.headerCell}`}
            style={{ width: (size !== '' && screenSize !== 'phone' && size) || dataCellWidth }}>
            Categories of Personal Information we collect may include:
          </div>
          <div
            className={`${styles.cell} ${styles.headerCell}`}
            style={{ width: (size !== '' && screenSize !== 'phone' && size) || dataCellWidth }}>
            Examples of how Personal Information is used include:
          </div>
          <div
            className={`${styles.cell} ${styles.headerCell}`}
            style={{ width: (size !== '' && screenSize !== 'phone' && size) || dataCellWidth }}>
            Parties with whom your information may be shared include:
          </div>
        </div>

        <div className={styles.cell}>
          <div className={styles.row}>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>Identifiers</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Data with special protections, if you choose to provide it;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Commercial information, if you choose to provide it;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Photos and face imagery that can be used to create face- recognition templates if
                  you or others choose to provide it and you have the setting turned on.
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Internet or other electronic network activity information, including content you
                  view or engage with;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Location-related information, including precise device location if you choose to
                  allow us to collect it;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Audio or visual Information, including photos and videos, if you or others choose
                  to provide it;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Professional or employment information, if you choose to provide it;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Education information, if you choose to provide it;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Financial information, if you choose to provide it; and
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Information derived from other Personal Information about you, which could include
                  your preferences, interests, and other information used to personalize your
                  experience
                </span>
              </li>
            </ul>
          </div>

          <div className={styles.row}>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>Promoting safety, integrity, and security;</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Facilitating transactions, providing measurement, analytics, advertising, and
                  other business services;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Promoting safety, integrity, and security;</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Communicating with you;.</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>Researching and innovating for social good; and</span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>To perform other business purposes.</span>
              </li>
            </ul>
          </div>

          <div className={styles.row}>
            <ul>
              <li className={styles.list}>
                <span className={styles.span}>
                  People and accounts you share and communicate with;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  People and accounts with which others share or reshare content about you;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Apps, websites, and third-party integrations on or using our Products;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  New owners in the event of a change of ownership or control of all or part of our
                  Products or their assets changes;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Partners, including partners who use our analytics services, advertisers,
                  measurement partners, partners offering goods and services in our Products,
                  vendors and service providers, and researchers and academics;
                </span>
              </li>
              <li className={styles.list}>
                <span className={styles.span}>
                  Law enforcement or other third parties in connection with legal requests; and XYZ
                  Companies
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableESP;
