import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

export default function LanguageSelect({ reloadOnSelect = false }) {
  const [languageOpen, setLanguageOpen] = useState(false);

  const { i18n = {} } = useTranslation();
  const { language = '' } = i18n;

  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
    setLanguageOpen(false);
    if (reloadOnSelect) window.location.reload();
  }

  const country = process.env.REACT_APP_COUNTRY;

  useEffect(() => {
    if (country === 'MX') {
      i18n.changeLanguage('es');
    }
  }, [country, i18n]);

  return (
    (country === 'US' && (
      <div className={styles.languageSelect}>
        <div
          className={`${styles.languageSelected} ${styles[languageOpen]}`}
          onClick={() => {
            setLanguageOpen(!languageOpen);
          }}>
          {(language === 'es' && 'Español') || (language === 'es-US' && 'Español') || 'English'}
          <i className={`material-icons ${styles.icon}`}>
            {(languageOpen && 'expand_less') || 'expand_more'}
          </i>
        </div>
        <div className={styles.optionsContainer}>
          <div className={`${styles.optionsContent} ${styles[languageOpen]}`}>
            <div className={`${styles.languageOption}`} onClick={() => changeLanguage('es')}>
              Español
            </div>
            <div className={`${styles.languageOption}`} onClick={() => changeLanguage('en')}>
              English
            </div>
          </div>
        </div>
      </div>
    )) ||
    null
  );
}
