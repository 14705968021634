import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';
import HeroBanner from '../../HeroBanner';

const Faq = (props) => {
  const { device } = props;
  const { screenSize } = device;
  const { t } = useTranslation('faq');
  const [faq, setfaq] = useState('carrera');
  const [seeAnswer, setseeAnswer] = useState(0);
  const menu = t('menu', { returnObjects: true });
  const questions = t('questions', { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id={styles.Faq} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <div id={styles.Titles}>
        <div className={styles.title}>{t('title')}</div>
        <div className={styles.subtitle}>{t('subTit')}</div>
        <div className={styles.subtitle2}>{t('subTitle')}</div>
      </div>

      <div className={styles.Text}>
        <div className={styles.boxCategory}>
          <div className={styles.menuHeader}>
            <i className={`material-icons ${styles.menuIcon}`}>list</i>
            <div className={styles.titleCategory}>{t('subText')}</div>
          </div>
          {menu.map((val, key) => {
            return (
              <div
                className={val.code === faq ? styles.categoryUnderline : styles.category}
                key={key}
                onClick={() => {
                  setfaq(val.code);
                }}>
                {val.name}
                <i className={`material-icons `}>navigate_next</i>
              </div>
            );
          })}
        </div>

        <div className={styles.answer}>
          {questions.map((val, key) => {
            return (
              <div key={key}>
                {val.code === faq ? (
                  <div className={styles.answerBox2}>
                    <div
                      key={key}
                      onClick={() => {
                        setseeAnswer(key);
                      }}
                      className={styles.respBox}>
                      <div className={styles.questionHeader}>
                        <div className={styles.question}>{val.pregunta}</div>
                        {screenSize !== 'phone' && (
                          <i className={`material-icons-outlined ${styles.openIcon}`}>
                            {key === seeAnswer ? 'do_disturb_on' : 'add_circle_outlined'}
                          </i>
                        )}
                      </div>

                      {key === seeAnswer && (
                        <label className={styles.resp}>{val.respuesta.split('<br>')}</label>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
