import React from 'react';
import ReactDOM from 'react-dom';
import './config/i18next_config';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import MyDigitalCatalog from './components/IpaperOrder/MyDigitalCatalog';

// Queries
import webservice from './components/Queries/webservice';

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route
        path={'/myDigitalCatalog/:kitNumber'}
        render={(props) => {
          return <MyDigitalCatalog {...props} history={history} webservice={webservice} />;
        }}
      />
      <Route
        path='/'
        render={(props) => {
          return <App {...props} history={history} />;
        }}
      />
    </Switch>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
