import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

// Components
import SectionTitle from '../SectionTitle';
import TextInput from '../TextInputTMB';
import Button from '@material-ui/core/Button';

// Queries
import getGlobal from '../Queries/getGlobal';
import postGlobal from '../Queries/postGlobal';
import usStates from '../Queries/usStates';

// Hooks
import useInput from '../../hooks/useInput';
import validateData from '../../hooks/validateData';

// Styles
import styles from './index.module.sass';

export default function IpaperOrder({ device }) {
  const { orderId = '' } = useParams();
  const { t } = useTranslation('digitalOrder');
  const screenSize = device?.screenSize || 'desktop';
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [acceptedPolicies, setAcceptedPolicies] = useState(false);

  const nameInput = useInput({
    name: 'name',
    label: '',
    value: '',
    placeholder: `${t('name')}*`,
    errors: {
      requiredError: t('nameError'),
    },
    required: true,
    validateEvent: 'blur',
  });

  const lastnameInput = useInput({
    name: 'lastname',
    label: '',
    value: '',
    placeholder: `${t('lastName')}*`,
    errors: {
      requiredError: t('lastnameError'),
    },
    required: true,
    validateEvent: 'blur',
  });

  const phoneInput = useInput({
    name: 'phone',
    label: '',
    value: '',
    placeholder: `${t('phone')}*`,
    errors: {
      requiredError: t('phoneError'),
      minlengthError: t('phoneError'),
    },
    required: true,
    validateEvent: 'blur',
    type: 'number',
    maxlength: 10,
    minlength: 10,
    validateMinLengthOnBlur: true,
  });

  const mailInput = useInput({
    name: 'mail',
    label: '',
    value: '',
    placeholder: `${t('mail')}*`,
    errors: {
      requiredError: t('mailError'),
    },
    required: true,
    validateEvent: 'blur',
    regexp: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    type: 'email',
  });

  const addressInput = useInput({
    name: 'address',
    label: '',
    value: '',
    placeholder: `${t('address')}*`,
    errors: {
      requiredError: t('addressError'),
    },
    required: true,
    validateEvent: 'blur',
    maxlength: 50,
  });

  const cityInput = useInput({
    name: 'city',
    label: '',
    value: '',
    placeholder: `${t('city')}*`,
    errors: {
      requiredError: t('cityError'),
    },
    required: true,
    validateEvent: 'blur',
  });

  const stateInput = useInput({
    name: 'state',
    label: '',
    value: '',
    placeholder: `${t('state')}*`,
    errors: {
      requiredError: t('stateError'),
    },
    required: true,
    validateEvent: 'blur',
  });

  const countryInput = useInput({
    name: 'country',
    label: '',
    value: 'US',
    placeholder: `${t('country')}*`,
    errors: {
      requiredError: t('countryError'),
    },
    required: true,
    validateEvent: 'blur',
    disabled: true,
  });

  const postalcodeInput = useInput({
    name: 'postalcode',
    label: '',
    value: '',
    placeholder: `${t('cp')}*`,
    errors: {
      requiredError: t('zipError'),
    },
    required: true,
    validateEvent: 'blur',
    type: 'number',
    maxlength: 5,
  });

  const getOrder = useCallback(async () => {
    setLoadingOrder(true);
    const resp = await getGlobal({
      tmbservice: 'getOrden',
      jsonBody: {
        idPedido: orderId,
        idioma: window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1,
      },
    });
    const { typeMessage, pedido = {}, message } = resp || {};
    if (typeMessage === 'success') {
      setOrder(pedido);
    } else alert(message);
    setLoadingOrder(false);
  }, [orderId]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const { errors, data } = validateData([
      nameInput,
      lastnameInput,
      phoneInput,
      mailInput,
      addressInput,
      cityInput,
      stateInput,
      countryInput,
      postalcodeInput,
    ]);

    if (!errors) {
      if (!data?.mail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        alert(t('validMailError'));
        return;
      }

      const resp = await postGlobal({
        tmbservice: 'postSolicitarPedido',
        jsonBody: {
          idPedido: orderId,
          nombre: `${data?.name} ${data?.lastname}`,
          telefono: data?.phone,
          correo: data?.mail,
          telefonoConsultora: order?.telefonoConsultora,
          correoConsultora: order?.correoConsultora,
          idioma: window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1,
          domicilio: {
            city: data?.city,
            country: data?.country,
            line1: data?.address,
            line2: '',
            line3: '',
            postalCode: data?.postalcode,
            region: data?.stateInput,
          },
        },
      });

      const { typeMessage = 'error', message } = resp;

      if (typeMessage === 'success') {
        alert(t('succsessMessage'));
        window.location.href = 'https://www.terramarbrandsusa.com/';
      } else alert(message);
    } else {
      let errorsText = '';

      for (let i = 0; i < errors.length; i++) {
        errorsText += `- ${errors[i]?.input?.error}`;
      }
      alert(errorsText);
    }
    setLoading(false);
  }, [
    orderId,
    nameInput,
    lastnameInput,
    phoneInput,
    mailInput,
    addressInput,
    cityInput,
    stateInput,
    countryInput,
    postalcodeInput,
    t,
    order.telefonoConsultora,
    order.correoConsultora,
  ]);

  const buttonIsDisabled = useMemo(() => {
    return !(
      nameInput.input.value &&
      lastnameInput.input.value &&
      phoneInput.input.value &&
      mailInput.input.value &&
      addressInput.input.value &&
      cityInput.input.value &&
      stateInput.input.value &&
      countryInput.input.value &&
      postalcodeInput.input.value &&
      acceptedPolicies
    );
  }, [
    nameInput.input.value,
    lastnameInput.input.value,
    phoneInput.input.value,
    mailInput.input.value,
    addressInput.input.value,
    cityInput.input.value,
    stateInput.input.value,
    countryInput.input.value,
    postalcodeInput.input.value,
    acceptedPolicies,
  ]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return (
    <div className={`${styles.container} ${styles[screenSize]}`}>
      <div className={styles.orderContainer}>
        <SectionTitle title={t('title')} subtitle={t('subtitle')} />
        {(loadingOrder && (
          <div className={styles.loadingOrder}>
            <CircularProgress className={styles.circularProgressOrder} />
          </div>
        )) || (
          <>
            <div className={styles.orderTable}>
              {screenSize !== 'phone' && (
                <div className={styles.row}>
                  <div className={styles.column}></div>
                  <div className={styles.column}>
                    <div className={styles.title}>{t('code')}</div>
                  </div>
                  <div className={`${styles.column} ${styles.descri}`}>
                    <div className={styles.title}>{t('description')}</div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.title}>{t('quantity')}</div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.title}>{t('price')}</div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.title}>{t('amount')}</div>
                  </div>
                </div>
              )}

              {(order?.detallePedido || []).map((item, key) => {
                return (
                  <div className={`${styles.row} ${styles.rowItem}`} key={key}>
                    <div className={styles.column}>
                      <img
                        src={`https://webimages.terramarbrands.com.mx/images-usa/shopping-cart/cart-products/${item?.codigo}.jpg`}
                        alt=''
                        className={styles.itemImage}
                      />
                    </div>
                    <div className={styles.column}>
                      <div className={styles.itemCode}>{item?.codigo}</div>
                    </div>

                    <div className={`${styles.column} ${styles.descri}`}>
                      <div className={styles.itemDesc}>{item?.descripcion}</div>
                    </div>
                    {screenSize !== 'phone' && (
                      <>
                        <div className={styles.column}>
                          <div className={styles.itemData}>{item?.cantidad}</div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.itemData}>${item?.precio}</div>
                        </div>
                        <div className={styles.column}>
                          <div className={styles.itemData}>${item?.precio * item?.cantidad}</div>
                        </div>
                      </>
                    )}
                    {screenSize === 'phone' && (
                      <div className={styles.quantityMobile}>
                        <div className={styles.itemData}>
                          {t('quantity')}: {item?.cantidad}
                        </div>
                        <div className={styles.itemData}>
                          {t('price')}: ${item?.precio}
                        </div>
                        <div className={styles.itemData}>
                          {t('amount')}: ${item?.precio * item?.cantidad}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className={styles.resumeContainer}>
              <div className={styles.rightContainer}>
                <div className={styles.resumeTitle}>{t('orderSummary')}</div>
                <div className={`${styles.resumeTotal} ${styles.resumeAmount}`}>
                  <div className={styles.resumeText}>{t('total')}:</div>
                  <div className={styles.resumeNumber}>${order?.total}</div>
                </div>
                <div className={styles.taxesWarning}>* {t('noTaxes')}</div>
              </div>
            </div>
          </>
        )}
        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            <i className={`material-icons-outlined ${styles.icon}`}>local_shipping</i>
            {t('orderData')}
          </div>
          <div className={styles.rightText}>*{t('requiredFields')}</div>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.dataTitle}>{t('personalInformation')}</div>
          <div className={styles.inputRow}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={nameInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={lastnameInput.input} />
            </div>
          </div>
          <div className={`${styles.dataTitle} ${styles.margin24}`}>{t('contactInformation')}</div>
          <div className={`${styles.inputRow}`}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={phoneInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={mailInput.input} />
            </div>
          </div>
          <div className={`${styles.dataTitle} ${styles.margin24}`}>{t('address')}</div>
          <div className={`${styles.inputRow}`}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={addressInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container50) || styles.container100
              }`}>
              <TextInput input={cityInput.input} />
            </div>
          </div>

          <div className={`${styles.inputRow} ${styles.marginTop}`}>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container33) || styles.container100
              }`}>
              <TextInput input={stateInput.input}>
                <option value=''>{t('state')}</option>
                {usStates.map((val, key) => {
                  const { code = '', name = '' } = val;
                  return (
                    <option value={code} key={key}>
                      {name}
                    </option>
                  );
                })}
              </TextInput>
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container33) || styles.container100
              }`}>
              <TextInput input={countryInput.input} />
            </div>
            <div
              className={`${
                (screenSize !== 'phone' && styles.container33) || styles.container100
              }`}>
              <TextInput input={postalcodeInput.input} />
            </div>
          </div>
          <div className={styles.policiesContainer}>
            <Checkbox
              className={styles.check}
              checked={acceptedPolicies}
              onClick={() => setAcceptedPolicies(!acceptedPolicies)}
            />
            <div className={styles.legalText}>
              <span>{t('acceptedPolicies')}</span>
              <span
                className={styles.legalLink}
                onClick={() => window.open('https://www.terramarbrandsusa.com/legal/privacy_us')}>
                {t('privacyPolicy')}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            <i className={`material-icons-outlined ${styles.icon}`}>error_outline</i>
            {t('sentTo')}:
          </div>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.consultantData}>
            <div className={styles.consultantRow}>
              <div className={styles.consultantTitle}>{t('consultantName')}:</div>
              <div className={styles.consultantText}>{order?.nombreConsultora}</div>
            </div>

            <div className={styles.consultantRow}>
              <div className={styles.consultantTitle}>{t('consultantKit')}:</div>
              <div className={styles.consultantText}>{order?.noKitAsignado}</div>
            </div>

            <div className={styles.consultantRow}>
              <div className={styles.consultantTitle}>{t('consultantPhone')}:</div>
              <div className={styles.consultantText}>{order?.telefonoConsultora}</div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <span className={styles.infoTitle}>{t('info')} </span>
            <span className={styles.infoText}>{t('infoText')}</span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant='contained'
            size='large'
            color='primary'
            className={`${styles.button} ${(buttonIsDisabled && styles.buttonDisabled) || null}`}
            onClick={async (e) => {
              if (!buttonIsDisabled) handleSubmit();
            }}>
            {(loading && <CircularProgress className={styles.circularProgress} />) ||
              t('requestButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}
